import api from './apis/api';

import {
  CreatePromotion,
  PromotionQueries,
  GetPromotionResponse,
  GetPromotionByIdResponse,
} from 'types/promotion';

import { objToQuery } from 'utils/query';

export async function postPromotion(payload: CreatePromotion) {
  return await api.post<CreatePromotion>('/promotion', payload);
}

export async function getPromotion(query?: PromotionQueries) {
  return await api.get<GetPromotionResponse>(`/promotion${query ? `?${objToQuery(query)}` : ''}`);
}

export async function getPromotionById(id: string | number) {
  return await api.get<GetPromotionByIdResponse>(`/promotion/${id}`);
}

export async function patchStatusPromotion(id: string | number) {
  return await api.patch(`/promotion/${id}/updateStatus`);
}

import { TagProps } from 'components/Tag/styles';
import { InventoryStatus } from 'types/inventory';

export const INVENTORY_TYPES = {
  entry: 'ENTRY',
  transfer: 'TRANSFER',
};

export const INVENTORY_STATUS: Record<'completed' | 'canceled' | 'progress', InventoryStatus> = {
  completed: 'COMPLETED',
  canceled: 'CANCELED',
  progress: 'PROGRESS',
};

export const inventoryStatusVariants: Record<InventoryStatus, TagProps['variante']> = {
  CANCELED: 'error',
  COMPLETED: 'success',
  PROGRESS: 'warn',
};

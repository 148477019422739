import { ComponentProps, memo } from 'react';
import { RouteComponentProps } from 'react-router';

import { ConfirmModal, Popover } from 'components';
import useBooleanToggle from 'hooks/useBooleanToggle';

import { CASH_DESK_STATUS } from 'constants/cashDesk';
import { resolvePath, routePaths } from 'constants/routes';
import { patchStatusGoal } from 'services/goal';

type PopoverProps = ComponentProps<typeof Popover>;

interface MenuProps {
  id?: string | number;
  operationId?: string | number;
  status?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  history?: RouteComponentProps['history'];
}

function BaseMenu(props: MenuProps) {
  const [isVisibleEditModal, toggleEditModal] = useBooleanToggle(false);

  const handleToggleStatus = async (id: string | number) => {
    try {
      await patchStatusGoal(id);
      props?.onEdit?.();
    } catch (err) {
    } finally {
      toggleEditModal();
    }
  };

  const options: PopoverProps['options'] = [
    {
      label: 'pages/cashDesk:listing.menu.checked',
      onClick: () =>
        props.history?.push(
          resolvePath(routePaths.cashDesk.details, {
            operationId: props.operationId,
            id: props.id,
          }),
        ),
      disabled: !(props?.status === CASH_DESK_STATUS.closed),
    },
  ];

  return (
    <div onClick={event => event.stopPropagation()}>
      <ConfirmModal
        open={isVisibleEditModal}
        title="Atenção"
        bodyText="Deseja realmente alterar o status dessa meta?"
        onClose={toggleEditModal}
        onConfirm={() => props.id && handleToggleStatus(props.id)}
      />
      {options.length > 0 && <Popover options={options} id="simple" />}
    </div>
  );
}

export const Menu = memo(BaseMenu);

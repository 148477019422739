import { format } from 'date-fns';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { toCurrency } from 'utils/help';
import { locales, namespaces, supportedLanguages } from './constants';

const formatCurrency = (value: any, pattern: string, lng?: string) => {
  const currencyArr = pattern.split(':');

  return toCurrency(
    parseFloat(value) || 0,
    currencyArr[1] || 'BRL',
    lng || supportedLanguages.ptBr,
  );
};

const yesOrNoByLng = {
  [supportedLanguages.ptBr]: {
    true: 'Sim',
    false: 'Não',
    '1': 'Sim',
    '0': 'Não',
  },
};

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: supportedLanguages.ptBr,
    ns: namespaces,
    defaultNS: namespaces[0],
    interpolation: {
      escapeValue: false,
      // Format pattern: "value:pattern"
      formatSeparator: ':',
      format: (value, pattern, lng) => {
        if (value instanceof Date) {
          const locale = locales[lng || supportedLanguages.ptBr];
          return format(value, pattern || 'dd/MM/yyyy', { locale });
        }

        if (pattern?.includes('currency')) return formatCurrency(value, pattern, lng);

        if (pattern?.includes('yesOrNo'))
          return yesOrNoByLng[lng || supportedLanguages.ptBr][`${value as boolean}`];

        return value;
      },
    },
    parseMissingKeyHandler: key => {
      const message = `The key "${key}" is missing.`;
      console.log(message);
    },
  });

export default i18n;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 2.5rem;
`;

export const User = styled.strong`
  font-size: 1.125rem;
`;

export const Operation = styled.strong`
  font-size: 0.85rem;
`;

export const TotalSale = styled.p`
  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TableValue = styled.p<{ isReversed?: boolean; isCurrency?: boolean }>`
  color: ${({ isReversed, theme }) => (isReversed ? theme.colors.danger : 'inherit')};
  text-decoration: ${({ isCurrency, isReversed }) =>
    isCurrency && isReversed ? 'line-through' : 'none'};
`;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { PaginatedTable, Button, Tag, Autocomplete } from 'components';
import { Menu } from './components';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';

import { queryToObj, pushHistoryQuery } from 'utils/query';
import { debounce } from 'utils/debounce';

import { Meta } from 'types/api';

import useBooleanToggle from 'hooks/useBooleanToggle';
import { inventoryStatusVariants, INVENTORY_STATUS, INVENTORY_TYPES } from 'constants/inventory';
import { getListInventory } from 'services/inventory';
import { ApiQueriesInventory, InventoryList } from 'types/inventory';
import { useOperationOptions } from 'hooks/useOperationOptions';
import { AutocompleteInputChangeReason } from '@mui/core';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListInventory({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/inventory');
  const { t } = useTranslation();

  const [inventories, setInventories] = useState<InventoryList[]>([]);
  const { isLoading: isLoadingOperations, onLoadOperations, operations } = useOperationOptions();

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const handleGetOperations = useCallback(
    async (e, value, reason: AutocompleteInputChangeReason) => {
      value && reason === 'input' && onLoadOperations({ search: value });
    },
    [],
  );

  const handleGetInventoryList = useCallback(async (query?: ApiQueriesInventory) => {
    try {
      toggleIsLoading();

      const { data } = await getListInventory(query);

      setInventories(data.data);
      setMeta({
        ...baseMeta,
        ...data.meta,
      });
    } catch (error) {
    } finally {
      toggleIsLoading();
    }
  }, []);

  const columns = [
    {
      field: 'type',
      headerName: tPage('listing.table.type'),
    },
    {
      field: 'origin',
      headerName: tPage('listing.table.origin'),
    },
    {
      field: 'destination',
      headerName: tPage('listing.table.operation'),
    },
    {
      field: 'createdAt',
      headerName: tPage('listing.table.createdAt'),
    },
    {
      field: 'tag',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
      _style: {
        width: 1,
      },
    },
  ];

  const rows = useMemo(
    () =>
      inventories.map(inventory => ({
        id: inventory.id,
        type: tPage(`types.${inventory.type}`),
        origin: inventory?.originOperation?.name || inventory.invoiceNumber || '-',
        destination: inventory.destinationOperation.name,
        createdAt: t('dateHours', { date: new Date(inventory.created_at) }),
        menu: (
          <Menu
            inventory={inventory}
            updateList={() => handleGetInventoryList(historyQuery)}
            history={history}
          />
        ),
        tag: (
          <Flex justifyContent="flex-end">
            <Tag
              label={tPage(`status.${inventory.status}`)}
              variante={inventoryStatusVariants[inventory.status]}
            />
          </Flex>
        ),
      })),
    [inventories],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as ApiQueriesInventory;

      handleGetInventoryList(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    handleGetInventoryList(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <h1>{tPage('listing.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <DefaultStyles.SelectContainer>
                <Autocomplete
                  contained
                  loading={isLoadingOperations}
                  onInputChange={debounce(handleGetOperations)}
                  placeholder="labels:input.operation"
                  defaultValue={
                    historyQuery.operationId && {
                      name: historyQuery.operationName,
                      id: historyQuery.operationId,
                    }
                  }
                  options={operations}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(event, value) =>
                    pushHistoryQuery(
                      { operationName: value?.name || '', operationId: value?.id || '' },
                      history,
                    )
                  }
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <Autocomplete
                  contained
                  placeholder="labels:input.type"
                  defaultValue={
                    historyQuery && {
                      label: historyQuery.typeName,
                      status: historyQuery.type,
                    }
                  }
                  options={[
                    {
                      label: tPage(`types.${INVENTORY_TYPES.entry}`),
                      value: INVENTORY_TYPES.entry,
                    },
                    {
                      label: tPage(`types.${INVENTORY_TYPES.transfer}`),
                      value: INVENTORY_TYPES.transfer,
                    },
                  ]}
                  onChange={(event, value) =>
                    pushHistoryQuery(
                      { typeName: value?.label || '', type: value?.value || '' },
                      history,
                    )
                  }
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <Autocomplete
                  contained
                  placeholder="labels:input.status"
                  defaultValue={
                    historyQuery && {
                      label: historyQuery.statusName,
                      status: historyQuery.status,
                    }
                  }
                  options={[
                    {
                      label: tPage(`status.${INVENTORY_STATUS.completed}`),
                      value: INVENTORY_STATUS.completed,
                    },
                    {
                      label: tPage(`status.${INVENTORY_STATUS.progress}`),
                      value: INVENTORY_STATUS.progress,
                    },
                    {
                      label: tPage(`status.${INVENTORY_STATUS.canceled}`),
                      value: INVENTORY_STATUS.canceled,
                    },
                  ]}
                  onChange={(event, value) =>
                    pushHistoryQuery(
                      { statusName: value?.label || '', status: value?.value || '' },
                      history,
                    )
                  }
                />
              </DefaultStyles.SelectContainer>
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => history.push(routePaths.inventory.create)}
            >
              {tPage('listing.create')}
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.inventory.details, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
            defaultPage={historyQuery.page as string}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

import { forwardRef, memo, useImperativeHandle, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';

import * as DefaultStyles from 'components/RadioGroup/styles';
import * as Styles from './styles';

type Option = Pick<MuiRadioProps, 'value' | 'disabled'>;

type RootMuiRadioProps = Pick<
  RadioGroupProps,
  'value' | 'defaultChecked' | 'defaultValue' | 'name' | 'onChange' | 'row'
>;

interface CustomRootRadioProps extends Option {
  label: string;
  id?: string;
  onChange?: () => void;
}

interface PaymentMethodGroupProps extends RootMuiRadioProps {
  options?: CustomRootRadioProps[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  clearHelperTextSpacing?: boolean;
}

const BasePaymentMethodGroup = forwardRef<any, PaymentMethodGroupProps>(
  (
    {
      error,
      onChange,
      defaultValue,
      helperText,
      clearHelperTextSpacing,
      options,
      name,
      value,
      row,
      disabled,
    },
    ref,
  ) => {
    const [data, setData] = useState(defaultValue);

    useImperativeHandle(ref, () => ({ value: data }), [data]);

    const renderRadios = options?.map?.((value, index) => (
      <Styles.Container
        key={index}
        variant={value.disabled || value.value !== data ? 'inactive' : 'primary'}
      >
        <FormControlLabel
          defaultValue={defaultValue}
          onChange={() => {
            setData(value.value);
            value?.onChange?.();
          }}
          disabled={disabled}
          {...value}
          control={<MuiRadio />}
        />
      </Styles.Container>
    ));

    return (
      <FormControl defaultValue={defaultValue} component="fieldset" error={error}>
        <MuiRadioGroup
          defaultValue={defaultValue}
          name={name}
          value={value}
          onChange={onChange}
          row={row}
          style={{ gap: '1rem' }}
        >
          {renderRadios}
        </MuiRadioGroup>
        {!clearHelperTextSpacing && (
          <DefaultStyles.HelperText>{helperText}</DefaultStyles.HelperText>
        )}
      </FormControl>
    );
  },
);

export const PaymentMethodGroup = memo(BasePaymentMethodGroup);

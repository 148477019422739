import { useCallback, useEffect, useState } from 'react';
import { getOperationDistricts } from 'services/districts';
import { GeneralDistricts } from '../types/districts';
import useBooleanToggle from './useBooleanToggle';

interface Props {
  getOnInitialize?: boolean;
}

export const useOperationDistricts = ({ getOnInitialize }: Props = {}) => {
  const [districts, setDistricts] = useState<GeneralDistricts>([]);
  const [isLoading, setIsLoading] = useBooleanToggle(!!getOnInitialize);

  const handleLoadDistricts = useCallback(async () => {
    setIsLoading();

    try {
      const response = await getOperationDistricts();

      setDistricts(response.data);
    } catch (error) {
      setDistricts([]);
    } finally {
      setIsLoading();
    }
  }, []);

  useEffect(() => {
    getOnInitialize && handleLoadDistricts();
  }, [getOnInitialize]);

  return {
    districts,
    isLoading,
    handleLoadDistricts,
  };
};

import styled from 'styled-components';
import { PageContainer } from 'styles/PageContainer';

export const Container = styled(PageContainer)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const Analytics = styled.div``;

export const AnalyticsCount = styled.span``;

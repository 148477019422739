import { useTranslation } from 'react-i18next';
import {
  ChangeEvent,
  DragEventHandler,
  memo,
  useCallback,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Icon } from 'components';
import { Preview } from './Preview';

import { useToggle } from 'hooks/useToggle';

import * as Styles from './styles';

interface IProps {
  label?: string;
  dropMessage?: string;
  multiple?: boolean;
  maxFiles?: number;
  initialValue?: IImage[];
  onRemoveFile?: (file: IImage) => Promise<void>;
}

type Toggles = 'isDragging';

export interface IImage {
  name: string;
  url: string;
  isFromServer?: boolean;
  file?: File;
}

export interface ImagesRef {
  images: IImage[];
}

const DropImageComponent = forwardRef<ImagesRef, IProps>(
  (
    {
      label,
      dropMessage = 'dropArchive.defaultDropMessage',
      multiple,
      maxFiles,
      initialValue,
      onRemoveFile,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [toggles, setToggles] = useToggle<Toggles>({ isDragging: false });
    const [images, setImages] = useState<IImage[]>(initialValue || []);

    const disabled = maxFiles === images.length;

    useImperativeHandle(ref, () => ({ images }), images);

    const onChange = (fileList: FileList) => {
      let files = Array.from(fileList).filter(file => file.type.includes('image'));

      if (files.length) {
        files = multiple ? files : [files[0]];
      }

      if (maxFiles && files.length > maxFiles && files.length + images.length > maxFiles) return;

      files.forEach(file => {
        const { name } = file;

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
          const previewUrl = reader.result as string | null;

          if (!previewUrl) return;

          const image = { name, file, url: previewUrl };
          setImages(prev => (multiple ? [...prev, image] : [image]));
        };
      });
    };

    const handleDragEnter = useCallback<DragEventHandler<HTMLDivElement>>(e => {
      e.preventDefault();
      setToggles('isDragging', true);
    }, []);

    const handleDragLeave = useCallback<DragEventHandler<HTMLDivElement>>(e => {
      e.preventDefault();
      setToggles('isDragging', false);
    }, []);

    const handleDrop = useCallback<DragEventHandler<HTMLDivElement>>(e => {
      e.stopPropagation();
      e.preventDefault();

      if (disabled) return;

      const fileList = e.dataTransfer.files;
      onChange(fileList);
    }, []);

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;
      if (!fileList) return;

      if (
        (maxFiles && fileList.length > maxFiles) ||
        (maxFiles && fileList.length + images.length > maxFiles)
      )
        return;

      onChange(fileList);
    };

    const handleClickDrag = useCallback(() => inputRef.current?.click(), []);

    const handleRemoveFile = async (index: number, file: IImage) => {
      try {
        if (file.isFromServer) {
          await onRemoveFile?.(file);
        }
        setImages(prevState => prevState.filter((value, fileIndex) => fileIndex !== index));
      } catch (err) {}
    };

    return (
      <Styles.Wrapper>
        {label && <label>{t(label)}</label>}
        <Styles.Container>
          <Styles.DropZone
            isDragging={toggles.isDragging}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            disabled={disabled}
            onClick={handleClickDrag}
          >
            <Icon name="image" size={1.5} />

            <span>{t(dropMessage)}</span>
          </Styles.DropZone>

          {images.map((image, index) => (
            <Preview key={index} onDelete={() => handleRemoveFile(index, image)} image={image} />
          ))}
        </Styles.Container>

        <input
          type="file"
          accept="image/*"
          hidden
          ref={inputRef}
          multiple={multiple}
          onChange={handleChangeInput}
          disabled={disabled}
          maxLength={maxFiles}
        />
      </Styles.Wrapper>
    );
  },
);

export const DropImage = memo(DropImageComponent);

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { App } from './App';
import './i18n';

ReactGA.initialize('G-E2W8W5XZE6');
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

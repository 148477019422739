import { ChangeEvent, ComponentProps, memo } from 'react';

import { TextField } from 'components';

import { debounce } from 'utils';
import { pushHistoryQuery } from 'utils/query';
import { useHistory } from 'react-router';

type TextFieldProps = Pick<ComponentProps<typeof TextField>, 'startIcon' | 'label'>;

interface SearchFilterProps extends TextFieldProps {
  defaultValue?: string | string[] | null;
  keyQuery?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

function BaseSearchFilter({
  defaultValue,
  keyQuery = 'search',
  onChange,
  startIcon = 'search',
  value,
  label,
}: SearchFilterProps) {
  const history = useHistory();

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const query = {
      [keyQuery]: value,
    };
    pushHistoryQuery(query, history);
  };

  return (
    <TextField
      fullWidth
      value={value}
      startIcon={startIcon}
      placeholder={label || 'labels:input.search'}
      onChange={onChange || debounce(handleChangeSearch)}
      defaultValue={defaultValue}
      contained
    />
  );
}

export const SearchFilter = memo(BaseSearchFilter);

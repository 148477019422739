import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  table tr td:last-child {
    max-width: 5rem;
  }
`;

import { memo, useState } from 'react';
import { useHistory } from 'react-router';
import { objToQuery, queryToObj } from 'utils/query';

import * as Styles from './styles';

import { Icon, LoadingMessage } from 'components';
import { useTranslation } from 'react-i18next';

export interface Column {
  field: string;
  headerName: string;
  orderable?: boolean;
  onClick?: () => void;
  colSpan?: number;
  _style?: Styles.RowStyle;
}

interface Row {
  [key: string]: any;
}

export type TableVariant = 'primary' | 'secondary';

export interface TableProps {
  columns: Column[];
  rows: Row[];
  onClickRow?: (row: any) => void;
  defaultOrder?: string;
  tableVariant?: TableVariant;
  isLoading?: boolean;
  loadingMessage?: string;
  noDataMessage?: string;
  renderTds?: (row: any) => JSX.Element;
}

function BaseTable(props: TableProps) {
  const history = useHistory();

  const [order, setOrder] = useState<string>(props.defaultOrder || '');
  const [orderField, setOrderField] = useState('');
  const { t } = useTranslation();

  const handleSetOrder = (field: string) => {
    const newOrder = order === 'ASC' && field === orderField ? 'DESC' : 'ASC';

    setOrderField(field);
    setOrder(newOrder);

    return {
      order: newOrder,
      order_field: field,
    };
  };

  const handlePaginate = (field: string) => {
    const orderFilter = handleSetOrder(field);
    const pathname = history.location.pathname;
    const currentQuery = queryToObj(history.location.search);

    const objQuery = {
      ...currentQuery,
      ...orderFilter,
    };

    const query = objToQuery(objQuery);

    history.push(`${pathname}?${query}`);
  };

  const renderColumns = props.columns.map((value, index) => (
    <Styles.Th
      key={index}
      onClick={value.orderable ? () => handlePaginate(value.field) : undefined}
      colSpan={value.colSpan}
    >
      {value.headerName}

      {value.orderable && (
        <Styles.OrderContainer>
          <Icon
            name="dropUp"
            color={order === 'ASC' && orderField === value.field ? 'primary' : 'hightLight'}
          />

          <Icon
            name="dropDown"
            color={order === 'DESC' && orderField === value.field ? 'primary' : 'hightLight'}
          />
        </Styles.OrderContainer>
      )}
    </Styles.Th>
  ));

  const renderRows = props.rows.map((row, index) => {
    const renderTds = () =>
      props.columns.map((column, headerIndex) => {
        return (
          <Styles.Td key={headerIndex} _style={{ ...(column?._style || {}) }}>
            {row[column.field]}
          </Styles.Td>
        );
      });

    return (
      <Styles.Tr
        key={index}
        clickable={!!props.onClickRow}
        onClick={() => props?.onClickRow?.(row)}
      >
        {props?.renderTds?.(row) || renderTds()}
      </Styles.Tr>
    );
  });

  if (props.isLoading)
    return (
      <Styles.LoadingContainer>
        <LoadingMessage text={t(props.loadingMessage || 'table.defaultLoadingMessage')} />
      </Styles.LoadingContainer>
    );

  return (
    <Styles.Container>
      <Styles.Table noData={!renderRows.length} variant={props.tableVariant}>
        <Styles.TableHead>
          <Styles.Tr>{renderColumns}</Styles.Tr>
        </Styles.TableHead>

        <Styles.TableBody>
          {renderRows.length === 0 ? (
            <Styles.Tr>
              <Styles.Td transparent>
                <Styles.NoDataMessage>{props?.noDataMessage || t('noData')}</Styles.NoDataMessage>
              </Styles.Td>
            </Styles.Tr>
          ) : (
            renderRows
          )}
        </Styles.TableBody>
      </Styles.Table>
    </Styles.Container>
  );
}

export const Table = memo(BaseTable);

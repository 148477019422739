import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.text200};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
  margin-bottom: 1rem;
  width: 100%;
`;

export const Header = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.text200};
  margin-bottom: -2px;
`;

export const Body = styled.div`
  padding: 0.5rem 1rem;
  display: grid;
`;

export const Title = styled.strong``;

import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Table } from 'components';

import * as DefaultStyles from 'styles/Details';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { getPromotionById as getPromotionByIdApi } from 'services/promotion';

import { GetPromotionByIdResponse } from 'types/promotion';

import { DISCOUNT_TYPE } from 'constants/promotion';
import { getProductById } from 'services/product';
import { GetProductByIdResponse } from 'types/product';

interface InfoProps {
  id: string | number;
}

function BaseInfo(props: InfoProps) {
  const { t } = useTranslation();
  const [tPage] = useTranslation('pages/promotion');

  const [promotion, setPromotion] = useState<Partial<GetPromotionByIdResponse>>({});
  const [product, setProduct] = useState<Partial<GetProductByIdResponse>>({});
  const [productName, setProductName] = useState<string>('');

  const getPromotionById = async (id: string | number) => {
    const { data } = await getPromotionByIdApi(id);
    setPromotion(data);
    localStorage.setItem('productId', data.product_id.toString());
  };
  const id = localStorage.getItem('productId');
  const handleGetDetailsProduct = async (id: string) => {
    try {
      const { data } = await getProductById(id);
      setProduct(data);
      setProductName(data?.name);
    } catch (err) {}
  };

  useEffect(() => {
    getPromotionById(props.id);
    handleGetDetailsProduct(id ?? '');
  }, []);

  const discontTypeMapLabel = (type: string) => {
    const label = {
      [DISCOUNT_TYPE.FIXED_VALUE]: {
        label: t('pages/promotion:log.fixedValue'),
        value: t('currency', { value: promotion?.fixedValue }),
      },
      [DISCOUNT_TYPE.PERCENTAGE]: {
        label: t('pages/promotion:log.percentage'),
        value: `${promotion?.percentage} %`,
      },
      [DISCOUNT_TYPE.PRODUCT_REQUIRED]: {
        label: t('pages/promotion:log.productRequired'),
        value: productName,
      },
    };

    return label[type] ? `${label[type].label} - ${label[type].value}` : '';
  };

  const rowProductGen = promotion?.productsGen?.map(product => ({
    name: product.name,
    amount: product.amount,
  }));

  const columnProductGen: Column[] = [
    {
      field: 'name',
      headerName: tPage('detail.table.productGen.column1'),
    },
    {
      field: 'amount',
      headerName: tPage('detail.table.productGen.column2'),
    },
  ];

  const rowOperationGen = promotion?.operationsGen?.map(operation => ({
    name: `${operation.name} - ${operation?.city}/${operation?.uf}`,
  }));

  const columnOperationGen: Column[] = [
    {
      field: 'name',
      headerName: tPage('detail.table.operationGen.column1'),
    },
  ];

  const rowProductUse = promotion?.productsUse?.map(product => ({
    name: product.name,
    amount: product?.amount,
  }));

  const rowProductRequiredName = promotion?.productsUse?.map(product => ({
    name: product.name,
    amount: product?.amount,
  }));

  const columnProductUse: Column[] = [
    {
      field: 'name',
      headerName: tPage('detail.table.productUse.column1'),
    },
    {
      field: 'amount',
      headerName: tPage('detail.table.productGen.column2'),
    },
  ];

  const rowOperationtUse = promotion?.operationsUse?.map(operation => ({
    name: `${operation.name} - ${operation?.city}/${operation?.uf}`,
  }));

  const columnOperationUse: Column[] = [
    {
      field: 'name',
      headerName: tPage('detail.table.operationUse.column1'),
    },
  ];

  return (
    <DefaultStyles.Card>
      <Styles.Container>
        <Flex flex={1} column gap={2}>
          <h2>{promotion?.name}</h2>
          <Flex gap={2} wrap>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('detail.fieldset.vigencyPeriody')}</DefaultStyles.Label>
              <DefaultStyles.Value>
                {promotion?.initial_validity &&
                  promotion?.final_validity &&
                  `${t('date', { date: new Date(promotion?.initial_validity) })} - ${t('date', {
                    date: new Date(promotion?.final_validity),
                  })}`}
              </DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('detail.fieldset.discountType')}</DefaultStyles.Label>
              <DefaultStyles.Value>
                {promotion?.discountType && discontTypeMapLabel(promotion?.discountType)}
              </DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('log.minimumValueGenerate')}</DefaultStyles.Label>
              <DefaultStyles.Value>
                {t('currency', { value: promotion?.minimumValueGenerate })}
              </DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('log.minimumValueUse')}</DefaultStyles.Label>
              <DefaultStyles.Value>
                {t('currency', { value: promotion?.minimumValueUse })}
              </DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('log.validDays')}</DefaultStyles.Label>
              <DefaultStyles.Value>{`${promotion?.validDays} (${tPage(
                'create.fieldset.topicUseRoles.inDays',
              )})`}</DefaultStyles.Value>
            </Flex>
          </Flex>
        </Flex>
      </Styles.Container>
      <hr />
      <Table tableVariant="secondary" columns={columnProductGen} rows={rowProductGen || []} />
      <hr />
      <Table tableVariant="secondary" columns={columnOperationGen} rows={rowOperationGen || []} />
      <hr />
      <Table tableVariant="secondary" columns={columnProductUse} rows={rowProductUse || []} />
      <hr />
      <Table tableVariant="secondary" columns={columnOperationUse} rows={rowOperationtUse || []} />
    </DefaultStyles.Card>
  );
}

export const Info = memo(BaseInfo);

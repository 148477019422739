import {
  AcquireQueries,
  CreateAcquirer,
  GetAcquirerById,
  GetAcquireResponse,
  GetAllAcquirerByOperationResponse,
  UpdateAcquirer,
} from 'types/acquire';
import { objToQuery } from 'utils/query';

import api from './apis/api';

export function getAcquirer(queries?: AcquireQueries) {
  return api.get<GetAcquireResponse>(`/acquirer${queries ? `?${objToQuery(queries)}` : ''}`);
}

export function getAcquirerById(id: number | string) {
  return api.get<GetAcquirerById>(`/acquirer/${id}`);
}

export function getAllAcquirerByOperation(operationId: number | string) {
  return api.get<GetAllAcquirerByOperationResponse>(`/operation/${operationId}/acquirer`);
}

export function postAcquire(payload: CreateAcquirer) {
  return api.post('/acquirer', payload);
}

export function putAcquirer(id: number | string, payload: UpdateAcquirer) {
  return api.put(`/acquirer/${id}`, payload);
}

import { memo } from 'react';
import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';

interface IProps extends TooltipProps {}

const TooltipComponent = ({
  children,
  placement = 'top',
  arrow = true,
  ...props
}: IProps): JSX.Element => {
  return (
    <MUITooltip placement={placement} arrow={arrow} {...props}>
      {children}
    </MUITooltip>
  );
};

export const Tooltip = memo(TooltipComponent);

import {
  CreateOperation,
  OperationResponse,
  ListOperation,
  Operation,
  SimpleOperationList,
  LogOperationResponse,
  ApiQueriesProduct,
  PostProductOnOperation,
  IntegrationOnOperation,
} from 'types/operation';
import { IImage } from 'components';

import { objToQuery, resolveQueryStart } from 'utils/query';

import { ApiQueries } from 'types/api';

import api from './apis/api';
import { ProductInventoryOperation } from 'types/product';

export const postOperation = async (data: CreateOperation) =>
  await api.post<Operation>('operation', data);

export const getOperations = async (query?: ApiQueries) =>
  await api.get<OperationResponse>(`operation${query ? `?${objToQuery(query)}` : ''}`);

export const putOperation = async (operationId: string | number, data: CreateOperation) =>
  await api.put<Operation>(`operation/${operationId}`, data);

export const getOperationById = async (id: number | string) =>
  await api.get<ListOperation>(`operation/${id}`);

export const deleteOperation = async (id: number | string) => await api.delete(`operation/${id}`);

export const getOperationsList = async (query?: ApiQueries) =>
  await api.get<SimpleOperationList[]>(`operationsList${query ? `?${objToQuery(query)}` : ''}`);

export const getOperationUserAccesSelectsList = async (query?: ApiQueries) =>
  await api.get<SimpleOperationList[]>(`operationAccesList${query ? `?${objToQuery(query)}` : ''}`);

export const patchStatus = async (id: number | string) =>
  await api.patch(`operation/${id}/updateStatus`);

export async function putImage(operationId: string | number, file: FormData) {
  return api.put(`operation/${operationId}/background`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function handleUploadImage(id: string | number, files: Omit<IImage, 'name'>[]) {
  for (const file of files) {
    if (!file.isFromServer && file.file) {
      const formData = new FormData();
      formData.append('image', file.file);
      await putImage(id, formData);
    }
  }
}

export async function getLogOperation(id: string | number, query?: ApiQueriesProduct) {
  return await api.get<LogOperationResponse>(
    `/log/operation/${id}${query ? `?${objToQuery(query)}` : ''}`,
  );
}

export const postIntegrationOnOperation = (
  operationId: string | number,
  data: IntegrationOnOperation,
) => api.post(`/operation/${operationId}/integration`, data);

export const getIntegrationOnOperation = (operationId: string | number) =>
  api.get<IntegrationOnOperation>(`/operation/${operationId}/integration`);

export const postProductOnOperation = (
  operationId: string | number,
  productId: string | number,
  data: PostProductOnOperation,
) => api.post(`/operation/${operationId}/inventory/product/${productId}`, data);

export const getInventoryProductsOperation = (operationId: string | number, queryParams?: string) =>
  api.get<ProductInventoryOperation[]>(
    `/operation/${operationId}/inventory/product${resolveQueryStart(queryParams)}`,
  );

export const deleteProductOnOperation = (
  operationId: string | number,
  productId: string | number,
) => api.delete(`/operation/${operationId}/inventory/product/${productId}`);

export const putProductOnOperation = (
  operationId: string | number,
  productId: string | number,
  data: PostProductOnOperation,
) =>
  api.put<ProductInventoryOperation>(
    `/operation/${operationId}/inventory/product/${productId}`,
    data,
  );

import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from 'components';
import { Depletations } from 'types/cashDesk';
import { Accordion } from '..';

interface BleedProps {
  data: Depletations[];
}

const _columns = {
  created_at: 'created_at',
  pdv_name: 'pdv_name',
  total: 'total',
};

function BaseBleed(props: BleedProps) {
  const [tPage] = useTranslation('pages/cashDesk');
  const { t } = useTranslation();
  const totalValue = useMemo(() => {
    const total = props.data.reduce((acc, cur) => acc + cur.value, 0);

    return t('currency', { value: total });
  }, [props.data]);

  const rows = props.data.map(value => ({
    created_at: t('dateHours', { date: new Date(value.created_at) }),
    pdv_name: value.pdv.name,
    total: t('currency', { value: value.value }),
  }));

  const columns: Column[] = [
    {
      field: _columns.created_at,
      headerName: tPage('detail.table.created_at'),
      _style: {
        width: '100%',
      },
    },
    {
      field: _columns.pdv_name,
      headerName: tPage('detail.table.pdv_name'),
      _style: {
        width: '100%',
      },
    },
    {
      field: _columns.total,
      headerName: tPage('detail.table.total'),
      _style: {
        width: '100%',
      },
    },
  ];

  return (
    <Accordion
      columns={columns}
      rows={rows}
      value={totalValue}
      title={tPage('detail.groups.bleed')}
    />
  );
}

export const Bleed = memo(BaseBleed);

import { ComponentProps, memo, SyntheticEvent, useMemo } from 'react';

import { UnAutocomplete } from 'components';

import { CardFlagType } from 'types/acquire';

type RootAutoCompletoProps = Pick<
  ComponentProps<typeof UnAutocomplete>,
  | 'label'
  | 'placeholder'
  | 'returnAllOption'
  | 'disabled'
  | 'value'
  | 'optionLabel'
  | 'optionValue'
  | 'disabled'
  | 'returnAllOption'
  | 'hidden'
  | 'isOptionEqualToValue'
>;

interface UnCarFlagProps extends RootAutoCompletoProps {
  onChange?: (value: CardFlagType) => void;
  name: string;
  onValidate?: (value: CardFlagType) => Promise<void>;
  onSelect?: (value?: CardFlagType | null) => void;
  cardFlags?: CardFlagType[];
}

function BaseUnCarFlag({
  label,
  name,
  value,
  returnAllOption,
  onSelect,
  optionLabel = 'name',
  optionValue = 'id',
  onChange,
  cardFlags,
  ...props
}: UnCarFlagProps) {
  const options = useMemo(() => cardFlags || [], [cardFlags]);

  const handleSelect = async (event: SyntheticEvent, value?: CardFlagType) => {
    onSelect?.(value);
    if (!value) return;

    onChange?.(value);
  };

  return (
    <UnAutocomplete
      {...props}
      name={name}
      label={label || 'labels:input.cardFlag'}
      optionLabel={optionLabel}
      optionValue={optionValue}
      returnAllOption={returnAllOption}
      options={options}
      onChange={handleSelect}
      contained
      value={value}
    />
  );
}

export const UnCarFlag = memo(BaseUnCarFlag);

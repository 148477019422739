import { ComponentProps, memo } from 'react';
import { RouteComponentProps } from 'react-router';

import { Popover, ConfirmModal } from 'components';
import useBooleanToggle from 'hooks/useBooleanToggle';

import { patchStatusPromotion } from 'services/promotion';

type PopoverProps = ComponentProps<typeof Popover>;

interface MenuProps {
  id?: string | number;
  status?: number;
  onDelete?: () => void;
  onEdit?: () => void;
  history?: RouteComponentProps['history'];
}

function BaseMenu(props: MenuProps) {
  const [isVisibleEditModal, toggleEditModal] = useBooleanToggle(false);

  const handleToggleStatus = async (id: string | number) => {
    try {
      await patchStatusPromotion(id);
      props?.onEdit?.();
    } catch (err) {
    } finally {
      toggleEditModal();
    }
  };

  const options: PopoverProps['options'] = [
    {
      label: props.status !== 1 ? 'commons:popoverList.active' : 'commons:popoverList.inactive',
      onClick: toggleEditModal,
    },
  ];

  return (
    <div onClick={event => event.stopPropagation()}>
      <ConfirmModal
        open={isVisibleEditModal}
        title="Atenção"
        bodyText="Deseja realmente alterar o status dessa operação?"
        onClose={toggleEditModal}
        onConfirm={() => props.id && handleToggleStatus(props.id)}
      />
      <Popover options={options} id="simple" />
    </div>
  );
}

export const Menu = memo(BaseMenu);

import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1.5rem 2rem 1.5rem;
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.foreground};
`;

export const WarnValueExceeded = styled.span`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.red};
  font-weight: 500;
`;

export const PaymentContainer = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;
  width: 100%;
`;

import styled from 'styled-components';

export const Color = styled.div<{ color: string }>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: ${props => props.theme.borderRadius.sm};
  background: ${props => props.color};
`;

export const Image = styled.img`
  width: 9.12rem;
  height: 9.12rem;
  border-radius: ${props => props.theme.borderRadius.md};
  object-fit: cover;
  object-position: center;
`;

import styled from 'styled-components';

export const Ul = styled.ul`
  display: flex;
  gap: 0.5rem;

  > span {
    color: ${({ theme }) => theme.colors.text400};
  }
`;

export const Li = styled.li`
  color: ${({ theme }) => theme.colors.text400};
  font-size: ${({ theme }) => theme.fonts.sizes.xs};
  font-weight: 500;

  :not(:last-child) {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    transition: filter 0.3s;

    :hover {
      filter: brightness(90%);
      text-decoration: underline;
    }

    :active {
      filter: brightness(90%);
    }
  }
`;

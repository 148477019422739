export function isOdd(value: number) {
  return value % 2 === 0;
}

export function getBase64FromImageUrl(url: Blob) {
  const reader = new FileReader();
  reader.readAsDataURL(url);

  let previewUrl: string | null = null;

  reader.onloadend = () => {
    previewUrl = reader.result as string | null;
  };

  return previewUrl as string | null;
}

export const toCurrency = (value: number, currency: string, lang: string) =>
  value.toLocaleString(lang, { currency, style: 'currency' });

export function truncateString(value: string, index: number) {
  return value.length > index ? `${value.substring(0, index)}...` : value;
}

export const floatToCurrency = (value: number) =>
  value.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' });

export function currencyToFloat(value: string) {
  return Number(
    value
      .replaceAll('.', '')
      .replaceAll(',', '.')
      .replace(/[^0-9.-]+/g, ''),
  );
}

export function removeDuplicateObjectFroArray<T = Record<string, any>>(
  data: T[],
  key: string,
  compareKey: string,
) {
  return data?.filter(
    (thing, index, self) =>
      index ===
      self.findIndex(
        t =>
          t &&
          t[key as keyof typeof t] === thing[key as keyof typeof t] &&
          t[compareKey as keyof typeof t] === thing[key as keyof typeof t],
      ),
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  width: 25.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.foreground};
  padding: 1rem;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0 auto 3rem auto;
  }
`;

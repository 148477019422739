import { forwardRef, memo, useImperativeHandle, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';

import * as Styles from './styles';

type Option = Pick<MuiRadioProps, 'value' | 'disabled'>;

type RootMuiRadioProps = Pick<
  RadioGroupProps,
  'value' | 'defaultChecked' | 'defaultValue' | 'name' | 'onChange' | 'row'
>;

interface CustomRootRadioProps extends Option {
  label: string;
  id?: string;
  onChange?: () => void;
}

interface RadioProps extends RootMuiRadioProps {
  options?: CustomRootRadioProps[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  clearHelperTextSpacing?: boolean;
}

const BaseRadio = forwardRef<any, RadioProps>(
  (
    {
      error,
      onChange,
      defaultValue,
      helperText,
      clearHelperTextSpacing,
      options,
      name,
      value,
      row,
      disabled,
    },
    ref,
  ) => {
    const [data, setData] = useState(defaultValue);

    useImperativeHandle(ref, () => ({ value: data }), [data]);

    const renderRadios = options?.map?.((value, index) => (
      <FormControlLabel
        key={index}
        defaultValue={defaultValue}
        onChange={() => {
          setData(value.value);
          value?.onChange?.();
        }}
        disabled={disabled}
        {...value}
        control={<MuiRadio />}
      />
    ));

    return (
      <FormControl defaultValue={defaultValue} component="fieldset" error={error}>
        <MuiRadioGroup
          defaultValue={defaultValue}
          name={name}
          value={value}
          onChange={onChange}
          row={row}
        >
          {renderRadios}
        </MuiRadioGroup>
        {!clearHelperTextSpacing && <Styles.HelperText>{helperText}</Styles.HelperText>}
      </FormControl>
    );
  },
);

export const RadioGroup = memo(BaseRadio);

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  flex-direction: column;
`;

export const DropZone = styled.div<{ isDragging: boolean; disabled?: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 2px dashed
    ${({ theme, isDragging }) => (isDragging ? theme.colors.text600 : theme.colors.text400)};
  background-color: ${({ theme }) => theme.colors.background600};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  transition: border-color 0.2s;
  color: ${({ theme }) => theme.colors.text600};
  height: 7rem;
  width: 100%;
  text-align: center;
  padding: 1rem;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  flex: none;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const FilesContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const FileItem = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.colors.background600};
  padding: 0.7rem 1rem;
  display: flex;
  width: 100%;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  }
`;

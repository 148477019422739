import styled from 'styled-components';

import { IconButton } from 'components';

export const Fieldset = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  legend {
    display: block;
    font-size: 0.85rem;
    font-weight: 600;
  }
`;

export const RemoveButton = styled(IconButton)`
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.3rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Item = styled.li<{ isOdd?: boolean }>`
  padding: 0.5rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${({ isOdd }) => (isOdd ? 'rgba(0, 0, 0, .03)' : 'none')};

  span:nth-child(2) {
    flex: 1;
  }
`;

export const List = styled.ul`
  width: 100%;
`;

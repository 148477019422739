import { Grid } from '@mui/material';
import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Flex } from 'styles/Flex';
import { Legend } from 'styles/Legend';

import { CheckboxGroup, UnRadioGroup } from 'components';
import { AWARD_TYPE, GOAL_OPERATION_TYPE, SUB_TYPES } from 'constants/goal';

import { SubTypeGoal } from 'types/goal';

interface AwardProps {
  type?: string;
  subTypes?: SubTypeGoal[];
  onChangeSubType?: (id: number) => void;
  isBilling?: boolean;
  isCapitaBilling?: boolean;
  isCaptureSale?: boolean;
  isProductsGoal?: boolean;
}

function BaseAwards({ type, subTypes = [], onChangeSubType, ...props }: AwardProps) {
  const { id } = useParams<{ id: string }>();

  const [isBilling, setIsBilling] = useState(!!props?.isBilling);
  const [isCapitaBilling, setIsCapitaBilling] = useState(!!props?.isCapitaBilling);
  const [isCaptureSale, setIsCaptureSale] = useState(!!props?.isCaptureSale);
  const [isProductsGoal, setIsProductsGoal] = useState(!!props?.isProductsGoal);

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={6}>
        <Flex column gap={1}>
          <Legend>Metas</Legend>
          <CheckboxGroup
            asUnform
            getBoolean
            disabled={!!id}
            options={[
              {
                name: 'isBilling',
                label: 'pages/goal:create.goals.revenues',
                onChange: event => {
                  setIsBilling(event.target.checked);

                  const subType = subTypes.find(value => value.name === SUB_TYPES.isBilling);
                  subType && onChangeSubType?.(subType.id);
                },
              },
              {
                name: 'isCapitaBilling',
                label: 'pages/goal:create.goals.revenuesPerCapita',
                hidden: type === GOAL_OPERATION_TYPE.PROFESSIONAL,
                onChange: event => {
                  setIsCapitaBilling(event.target.checked);

                  const subType = subTypes.find(value => value.name === SUB_TYPES.isCapitaBilling);
                  subType && onChangeSubType?.(subType.id);
                },
              },
              {
                name: 'isProductsGoal',
                label: 'pages/goal:create.goals.productQuantity',
                onChange: event => {
                  setIsProductsGoal(event.target.checked);

                  const subType = subTypes.find(value => value.name === SUB_TYPES.isProductsGoal);
                  subType && onChangeSubType?.(subType.id);
                },
              },
              {
                name: 'isCaptureSale',
                label: 'pages/goal:create.goals.captureSale',
                onChange: event => {
                  setIsCaptureSale(event.target.checked);

                  const subType = subTypes.find(value => value.name === SUB_TYPES.isCaptureSale);
                  subType && onChangeSubType?.(subType.id);
                },
              },
            ]}
          ></CheckboxGroup>
        </Flex>
      </Grid>
      <Grid item xs={6}>
        <Flex column gap={1}>
          <Legend>Prêmios</Legend>
          <Flex column gap={2}>
            <UnRadioGroup
              name="billing_award_type"
              row
              disabled={!!id || !isBilling}
              options={[
                {
                  label: 'Valor',
                  value: AWARD_TYPE.VALUE,
                },
                {
                  label: 'Porcentagem',
                  value: AWARD_TYPE.PERCENTAGE,
                },
              ]}
            />
            {type !== GOAL_OPERATION_TYPE.PROFESSIONAL && (
              <UnRadioGroup
                name="capita_billing_award_type"
                row
                disabled={!!id || !isCapitaBilling}
                options={[
                  {
                    label: 'Valor',
                    value: AWARD_TYPE.VALUE,
                  },
                  {
                    label: 'Porcentagem',
                    value: AWARD_TYPE.PERCENTAGE,
                  },
                ]}
              />
            )}

            <UnRadioGroup
              name="products_goal_award_type"
              row
              disabled={!!id || !isProductsGoal}
              options={[
                {
                  label: 'Valor',
                  value: AWARD_TYPE.VALUE,
                },
                {
                  label: 'Porcentagem',
                  value: AWARD_TYPE.PERCENTAGE,
                },
              ]}
            />
            <UnRadioGroup
              name="capture_sale_award_type"
              row
              disabled={!!id || !isCaptureSale}
              options={[
                {
                  label: 'Valor',
                  value: AWARD_TYPE.VALUE,
                },
                {
                  label: 'Porcentagem',
                  value: AWARD_TYPE.PERCENTAGE,
                },
              ]}
            />
          </Flex>
        </Flex>
      </Grid>
    </Grid>
  );
}

export const Awards = memo(BaseAwards);

import * as Yup from 'yup';
import { invalidEmail, invalidPassword, passwordNotMatch, requiredField } from './utils/messages';

export const definePasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, invalidPassword)
    .max(16, invalidPassword)
    .required(requiredField)
    .oneOf([Yup.ref('confirmPassword'), null], passwordNotMatch),
  confirmPassword: Yup.string()
    .min(6, invalidPassword)
    .max(16, invalidPassword)
    .required(requiredField)
    .oneOf([Yup.ref('password'), null], passwordNotMatch),
});

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().required(requiredField).email(invalidEmail),
  password: Yup.string().required(requiredField),
});

export const emailValidationSchema = Yup.object().shape({
  email: Yup.string().required(requiredField).email(invalidEmail),
});

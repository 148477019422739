import * as Yup from 'yup';
import { invalidFormat, requiredField } from './utils/messages';

export const addressValidationSchema = Yup.object().shape({
  cep: Yup.string().min(8, invalidFormat).max(8, invalidFormat).required(requiredField),
  address: Yup.string().required(requiredField),
  number: Yup.string().required(requiredField),
  complements: Yup.string().required(requiredField),
  district: Yup.string().required(requiredField),
  city: Yup.string().required(requiredField),
  uf: Yup.string().required(requiredField).typeError(requiredField),
});

import { breakpoints } from 'constants/layout';
import styled from 'styled-components';

export const Header = styled.nav`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.foreground};
  padding: 0rem 0rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 100;
  top: 0;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1rem;
  }
`;

export const DivPonteiroMouse = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  align-items: flex-start;
  gap: 0.5rem;

  > p {
    font-size: ${({ theme }) => theme.fonts.sizes.sm};
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }

  > span {
    font-size: ${({ theme }) => theme.fonts.sizes.xs};
    border-radius: 999px;
    background-color: ${({ theme }) => theme.colors.background600};
    padding: 0.25rem 0.625rem;
    display: flex;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-left: auto;
    margin-right: 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  width: 11.25rem;
  margin-top: 5px;

  @media (max-width: ${breakpoints.sm}) {
    margin-left: 0;
    width: 100%;
  }
`;

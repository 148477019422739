import styled from 'styled-components';

import { ButtonBase } from '@mui/material';

export const Container = styled.div`
  width: max-content;
  height: 2.3rem;
  background: ${props => props.theme.colors.foreground};
  display: flex;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
  gap: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const Button = styled(ButtonBase)`
  height: 100%;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Display = styled.input`
  font-size: 0.87rem;
  text-align: center;
  width: 2.6rem;
  border: none;
  outline: none;
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

import { memo, useRef, useEffect, ComponentProps } from 'react';
import { useField } from '@unform/core';

import { CheckboxGroup } from 'components';
import { useTranslation } from 'react-i18next';

type UnCheckboxGroupRootProps = Omit<ComponentProps<typeof CheckboxGroup>, 'defaultValue'>;

interface UnCheckboxGroupProps extends UnCheckboxGroupRootProps {
  name: string;
}

function BaseUnCheckboxGroup({ name, helperText, ...props }: UnCheckboxGroupProps) {
  const [tErros] = useTranslation('formErrors');
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => ({ ...ref.current.values }),
    });
  }, [fieldName, registerField, error, defaultValue]);

  console.log(error, name);

  return (
    <CheckboxGroup
      {...props}
      defaultValue={defaultValue}
      ref={inputRef}
      helperText={error ? tErros(error) : helperText}
    />
  );
}

export const UnCheckboxGroup = memo(BaseUnCheckboxGroup);

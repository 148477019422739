import { Fragment, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Legend } from 'styles/Legend';

import { useFormContext } from '..';

import { Button, Column, Table, Unform, UnMaskInput } from 'components';
import { Fee } from './components';

import { FormHandles, SubmitHandler } from '@unform/core';
import { ACQUIRE_CARD_FLAG_TYPE } from 'constants/acquire';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { Flex } from 'styles/Flex';
import { Installment } from 'types/acquire';
import { createTaxAcquire } from 'yupSchemas/acquire';
import * as Styles from '../../styles';

export interface AcquirerVincule {
  operation_id: number;
  card_flags: {
    id: number;
    installments: Installment[];
  }[];
}

export interface DetailTax {
  operations_value_required: number[];
  acquirer_vincule: AcquirerVincule[];
}

interface TaxProps {
  onBackward: () => void;
  data?: Partial<DetailTax>;
}

const FIELDS = {
  flag: 'flag',
  operation_id: 'operation_id',
  card_flag_id: 'card_flag_id',
  debit: 'debit',
  cred1x: 'cred1x',
  '1x': '1x',
  '2x': '2x',
  '3x': '3x',
  '4x': '4x',
  '5x': '5x',
  '6x': '6x',
  '7x': '7x',
  '8x': '8x',
  '9x': '9x',
  '10x': '10x',
};

function BaseTax({ onBackward, data }: TaxProps) {
  const [tPage] = useTranslation('pages/acquire');
  const [t] = useTranslation('');

  const formRef = useRef<FormHandles>(null);
  const [isSaved, toggleIsSaved] = useBooleanToggle(false);

  const context = useFormContext();

  const [details] = useState<Partial<DetailTax>>(data || {});

  const handleSubmit: SubmitHandler<DetailTax> = payload => {
    try {
      if (isSaved) return context?.onSubmit();

      !isSaved && toggleIsSaved();

      const acquirer_vincule: AcquirerVincule[] = payload.acquirer_vincule.map(acquirerVincule => ({
        ...acquirerVincule,
        card_flags: acquirerVincule.card_flags.map(cardFlags => ({
          ...cardFlags,
          installments: cardFlags.installments.filter(installments => installments.fee_value),
        })),
      }));

      const data: DetailTax = {
        acquirer_vincule,
        operations_value_required: payload.operations_value_required,
      };

      context?.saveForm('table', data);
    } catch (err) {}
  };

  const columns: Column[] = [
    {
      field: FIELDS.flag,
      headerName: tPage('create.table.flag'),
    },
    {
      field: FIELDS.debit,
      headerName: tPage('create.table.debit'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS.cred1x,
      headerName: tPage('create.table.cred1x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['2x'],
      headerName: t('installment.2x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['3x'],
      headerName: t('installment.3x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['4x'],
      headerName: t('installment.4x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['5x'],
      headerName: t('installment.5x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['6x'],
      headerName: t('installment.6x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['7x'],
      headerName: t('installment.7x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['8x'],
      headerName: t('installment.8x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['9x'],
      headerName: t('installment.9x'),
      _style: {
        minWidth: '6rem',
      },
    },
    {
      field: FIELDS['10x'],
      headerName: t('installment.10x'),
      _style: {
        minWidth: '6rem',
      },
    },
  ];

  const renderForms = context?.data?.base?.operations?.map((operation, indexOperation) => {
    const rows = context?.data?.base?.card_flags?.map((cardFlag, indexCardFlag) => {
      return {
        [FIELDS.flag]: (
          <Fragment key={indexOperation}>
            {cardFlag.name}
            <UnMaskInput
              value={cardFlag.id}
              name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].id`}
              hidden
              type="hidden"
              cleanValue
              parseToFloat
              style={{ display: 'none' }}
            />
            <UnMaskInput
              value={operation.id}
              name={`acquirer_vincule[${indexOperation}].operation_id`}
              type="hidden"
              cleanValue
              parseToFloat
              style={{ display: 'none' }}
            />
          </Fragment>
        ),
        [FIELDS.debit]: (
          <Fee
            installment={1}
            type={ACQUIRE_CARD_FLAG_TYPE.DEBIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[0]`}
          />
        ),
        [FIELDS.cred1x]: (
          <Fee
            installment={1}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[1]`}
          />
        ),
        [FIELDS['2x']]: (
          <Fee
            installment={2}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[2]`}
          />
        ),
        [FIELDS['3x']]: (
          <Fee
            installment={3}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[3]`}
          />
        ),
        [FIELDS['4x']]: (
          <Fee
            installment={4}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[4]`}
          />
        ),
        [FIELDS['5x']]: (
          <Fee
            installment={5}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[5]`}
          />
        ),
        [FIELDS['6x']]: (
          <Fee
            installment={6}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[6]`}
          />
        ),
        [FIELDS['7x']]: (
          <Fee
            installment={7}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[7]`}
          />
        ),
        [FIELDS['8x']]: (
          <Fee
            installment={8}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[8]`}
          />
        ),
        [FIELDS['9x']]: (
          <Fee
            installment={9}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[9]`}
          />
        ),
        [FIELDS['10x']]: (
          <Fee
            installment={10}
            type={ACQUIRE_CARD_FLAG_TYPE.CREDIT}
            key={indexCardFlag}
            name={`acquirer_vincule[${indexOperation}].card_flags[${indexCardFlag}].installments[10]`}
          />
        ),
      };
    });

    return (
      <Flex column gap={1} key={indexOperation}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Styles.OperationName>{operation.name}</Styles.OperationName>
          <div style={{ maxWidth: '20rem' }}>
            <UnMaskInput
              name={`operations_value_required[${indexOperation}]`}
              mask="currency"
              label="pages/acquire:create.minFee"
              cleanValue
              parseToFloat
            />
          </div>
        </Flex>
        <Table tableVariant="secondary" columns={columns} rows={rows || []} />
      </Flex>
    );
  });

  return (
    <Unform
      ref={formRef}
      initialData={details}
      validationSchema={createTaxAcquire}
      onSubmit={handleSubmit}
    >
      <Styles.Container>
        <Legend>Vincular taxa</Legend>
      </Styles.Container>
      <hr />
      <Styles.Container>
        <Flex column gap={2}>
          {renderForms}
        </Flex>
      </Styles.Container>
      <hr />
      <Styles.Container>
        <Flex flex={1} justifyContent="flex-end" gap={2}>
          <Button variant="cancel" onClick={onBackward}>
            {t('button.backward')}
          </Button>
          <Button
            isLoading={context?.isLoading}
            variant={isSaved ? 'primary' : 'green'}
            type="submit"
          >
            {isSaved ? tPage('create.save') : tPage('create.finish')}
          </Button>
        </Flex>
      </Styles.Container>
    </Unform>
  );
}

export const Tax = memo(BaseTax);

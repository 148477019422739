import { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import {
  IconButton,
  LoadingMessage,
  Unform,
  UnInputFIeld,
  UnDatePicker,
  UnRadioGroup,
  UnMaskInput,
  Button,
  CheckboxGroup,
  ConfirmModal,
  UnProductArray,
  UnOperationArray,
} from 'components';

import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import * as Styles from './styles';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { DISCOUNT_TYPE } from 'constants/promotion';

import { CreatePromotion as CreatePromotionRequest, Product } from 'types/promotion';

import { createPromotionValidateSchema } from 'yupSchemas/promotion';

import { postPromotion } from 'services/promotion';

import { openNotification } from 'utils/notification';
import { routePaths } from 'constants/routes';

import { SearchProductByNameFilter } from '../../../../components/QueryFilters/SearchProdutByName';

type Data = Pick<
  CreatePromotionRequest,
  | 'percentage'
  | 'discountType'
  | 'validDays'
  | 'name'
  | 'fixedValue'
  | 'minimumValueGenerate'
  | 'minimumValueUse'
  | 'isMinimumValueGenerate'
  | 'isRequiredProductGenerate'
  | 'isMinimumValueUse'
  | 'isRequiredProductUse'
> & {
  initial_validity: Date;
  final_validity: Date;
  product_id?: number | undefined;
};

interface ItemOperation {
  id: number;
}

interface Detail extends Data {
  productsUse: Product[];
  operationsUse: ItemOperation[];
  productsGenerate: Product[];
  operationGenerate: ItemOperation[];
}

const baseDetails: Partial<Detail> = {
  isMinimumValueGenerate: false,
  isRequiredProductGenerate: false,
  isMinimumValueUse: false,
  isRequiredProductUse: false,
};

export function CreatePromotion({ match, history }: RouteComponentProps<{ id?: string }>) {
  const [t] = useTranslation();
  const [tPages] = useTranslation('pages/promotion');

  const formRef = useRef<FormHandles>(null);

  const id = match.params?.id;

  const [details] = useState(baseDetails as Detail);

  const [isCancelModal, toggleCancelModal] = useBooleanToggle(false);
  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const [discountType, setDiscountType] = useState(details.discountType);

  const [initialLoading] = useBooleanToggle(!!match.params?.id);

  const [initialValidate, setInitialValidate] = useState(null);

  const [isMinimumValueGenerate, toggleIsMinimumValueGenerate] = useBooleanToggle(
    details?.isMinimumValueGenerate || false,
  );
  const [isMinimumValueUse, toggleIsMinimumValueUse] = useBooleanToggle(
    details?.isMinimumValueUse || false,
  );
  const [isRequiredProductUse, toggleIsRequiredProductUse] = useBooleanToggle(
    details?.isRequiredProductGenerate || false,
  );
  const [isRequiredProductGenerate, toggleIsRequiredProductGenerate] = useBooleanToggle(
    details?.isRequiredProductGenerate || false,
  );

  const handleSubmit: SubmitHandler<Detail> = async data => {
    try {
      toggleIsLoading();

      const {
        productsUse,
        productsGenerate,
        operationGenerate,
        operationsUse,
        initial_validity,
        final_validity,
        ...payload
      } = data;

      const productsRequiredGenerate = productsGenerate.map(value => ({
        id: value.id,
        amount: value.amount,
      }));

      const productsRequiredUse = productsUse.map(value => ({
        id: value.id,
        amount: value.amount,
      }));

      const operationsIdGenerate = operationGenerate.map(value => value.id);

      const operationsIdUse = operationsUse.map(value => value.id);

      const productIdString = localStorage.getItem('productId');
      const product_id = productIdString ? parseInt(productIdString) : undefined;

      const createData: CreatePromotionRequest = {
        ...payload,
        initial_validity: new Date(initial_validity).toISOString(),
        final_validity: new Date(final_validity).toISOString(),
        isMinimumValueGenerate: !!isMinimumValueGenerate,
        operationsIdGenerate,
        operationsIdUse,
        productsRequiredGenerate,
        productsRequiredUse,
        product_id,
      };

      await postPromotion(createData);

      openNotification(tPages('create.createMessage'));
      history.push(routePaths.promotion.list);
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const handleToggleIsMinimumValueGenerate = () => {
    toggleIsMinimumValueGenerate();
    formRef.current?.clearField('minimumValueGenerate');
  };

  return (
    <PageContainer md>
      <ConfirmModal
        open={isCancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={toggleCancelModal}
        onConfirm={() => history.goBack()}
      />
      <Flex column gap={1.62}>
        <Flex alignItems="center" gap={1}>
          <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={toggleCancelModal} />
          <h1>{tPages('create.title')}</h1>
        </Flex>
        <Styles.FormContainer>
          {id && initialLoading ? (
            <LoadingMessage text={t('commons:loadingDetails')} />
          ) : (
            <Unform
              onSubmit={handleSubmit}
              ref={formRef}
              validationSchema={createPromotionValidateSchema}
              initialData={details}
            >
              <Styles.Container>
                <UnInputFIeld name="name" label="pages/promotion:log.name" />
              </Styles.Container>
              <hr />
              <Styles.Container>
                <Flex column gap={3}>
                  <Styles.Topic>{tPages('create.fieldset.topicGenerateRoles.title')}</Styles.Topic>
                  <Flex column gap={3}>
                    <Styles.FieldLegend>
                      {tPages('create.fieldset.topicGenerateRoles.vigencyPeriod')}
                    </Styles.FieldLegend>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <UnDatePicker
                          name="initial_validity"
                          minDate={new Date()}
                          label="pages/promotion:log.initial_validity"
                          onChange={date => setInitialValidate(date as any)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <UnDatePicker
                          name="final_validity"
                          minDate={initialValidate || new Date()}
                          label="pages/promotion:log.final_validity"
                        />
                      </Grid>
                    </Grid>
                    <Flex column gap={1}>
                      <Styles.FieldLegend>
                        {tPages('create.fieldset.topicGenerateRoles.discountType')}
                      </Styles.FieldLegend>
                      <Flex gap={3} wrap>
                        <UnRadioGroup
                          row
                          name="discountType"
                          onChange={event => setDiscountType(event.target.value)}
                          options={[
                            {
                              label: tPages('log.percentage'),
                              value: DISCOUNT_TYPE.PERCENTAGE,
                            },
                            {
                              label: tPages('log.fixedValue'),
                              value: DISCOUNT_TYPE.FIXED_VALUE,
                            },
                            {
                              label: tPages('log.productRequired'),
                              value: DISCOUNT_TYPE.PRODUCT_REQUIRED,
                            },
                          ]}
                        />
                        <Grid xs={12} sm={6} md={4}>
                          {discountType === DISCOUNT_TYPE.FIXED_VALUE ? (
                            <UnMaskInput
                              mask="currency"
                              name="fixedValue"
                              label="pages/promotion:log.minimumValueGenerate"
                              cleanValue
                              disabled={!discountType}
                              parseToFloat
                            />
                          ) : discountType === DISCOUNT_TYPE.PERCENTAGE ? (
                            <UnMaskInput
                              mask="percent"
                              name="percentage"
                              label="pages/promotion:log.minimumValueGenerate"
                              cleanValue
                              disabled={!discountType}
                              parseToFloat
                            />
                          ) : null}
                        </Grid>
                      </Flex>
                      {discountType === DISCOUNT_TYPE.PRODUCT_REQUIRED && (
                        <>
                          <Styles.FieldLegend>{tPages('log.productRequired')}</Styles.FieldLegend>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <SearchProductByNameFilter
                                types={['PHYSICAL', 'DIGITAL']}
                                placeholder="pages/products:listing.filters.product"
                                queryValue="id"
                                queryKey="productId"
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <UnMaskInput
                                name="amount"
                                parseToFloat
                                label="labels:inventory.amount"
                                disabled={discountType !== DISCOUNT_TYPE.PRODUCT_REQUIRED}
                                type="number"
                                parseDefaultValue={value => value || '1'}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Flex>
                    <Flex column gap={1}>
                      <Styles.FieldLegend>
                        {tPages('create.fieldset.topicGenerateRoles.termsOfUse')}
                      </Styles.FieldLegend>
                      <CheckboxGroup
                        asUnform
                        row
                        getBoolean
                        options={[
                          {
                            name: 'isMinimumValueGenerate',
                            label: 'pages/promotion:log.isMinimumValueGenerate',
                            onChange: () => handleToggleIsMinimumValueGenerate(),
                          },
                          {
                            name: 'isRequiredProductGenerate',
                            label: 'pages/promotion:log.isRequiredProductGenerate',
                            onChange: () => toggleIsRequiredProductGenerate(),
                          },
                        ]}
                      />
                    </Flex>
                  </Flex>
                  <Flex wrap gap={2}>
                    <Grid xs={12} sm={4}>
                      <UnMaskInput
                        name="minimumValueGenerate"
                        mask="currency"
                        disabled={!isMinimumValueGenerate}
                        label="pages/promotion:create.fieldset.topicGenerateRoles.defineMinValueUse"
                        parseToFloat
                      />
                    </Grid>
                  </Flex>
                  <Flex column gap={1}>
                    <Styles.FieldLegend>
                      {tPages('create.fieldset.topicUseRoles.defineProductRequired')}
                    </Styles.FieldLegend>
                    <UnProductArray name="productsGenerate" disabled={!isRequiredProductGenerate} />
                  </Flex>
                  <Flex column gap={1}>
                    <Styles.FieldLegend>
                      {tPages('create.fieldset.topicGenerateRoles.defineOperationRequired')}
                    </Styles.FieldLegend>
                    <UnOperationArray name="operationGenerate" />
                  </Flex>
                </Flex>
              </Styles.Container>
              <hr />
              <Styles.Container>
                <Flex column gap={3}>
                  <Styles.Topic>{tPages('create.fieldset.topicUseRoles.title')}</Styles.Topic>
                  <Grid item xs={12} sm={6} md={4}>
                    <UnMaskInput
                      name="validDays"
                      placeholder="pages/promotion:create.fieldset.topicUseRoles.inDays"
                      mask="number"
                      parseToFloat
                      label="pages/promotion:log.validDays"
                    />
                  </Grid>
                  <Flex column gap={1}>
                    <Styles.FieldLegend>
                      {tPages('create.fieldset.topicUseRoles.termsOfUse')}
                    </Styles.FieldLegend>
                    <CheckboxGroup
                      asUnform
                      row
                      getBoolean
                      options={[
                        {
                          name: 'isRequiredProductUse',
                          label: 'pages/promotion:log.isRequiredProductUse',
                          onChange: () => toggleIsRequiredProductUse(),
                        },
                        {
                          name: 'isMinimumValueUse',
                          label: 'pages/promotion:log.isMinimumValueUse',
                          onChange: () => toggleIsMinimumValueUse(),
                        },
                      ]}
                    />
                  </Flex>
                  <Flex gap={2} wrap>
                    <Grid xs={12} sm={4}>
                      <UnMaskInput
                        name="minimumValueUse"
                        mask="currency"
                        parseToFloat
                        label="pages/promotion:log.minimumValueUse"
                        disabled={!isMinimumValueUse}
                      />
                    </Grid>
                    <Grid xs={12} sm={4}></Grid>
                  </Flex>
                  <Flex column gap={1}>
                    <Styles.FieldLegend>
                      {tPages('create.fieldset.topicUseRoles.defineProductRequired')}
                    </Styles.FieldLegend>
                    <UnProductArray name="productsUse" disabled={!isRequiredProductUse} />
                  </Flex>
                  <Flex column gap={1}>
                    <Styles.FieldLegend>
                      {tPages('create.fieldset.topicUseRoles.operationValid')}
                    </Styles.FieldLegend>
                    <UnOperationArray name="operationsUse" />
                  </Flex>
                </Flex>
              </Styles.Container>
              <Styles.SubmitContainer>
                <Flex justifyContent="flex-end" gap={1}>
                  <Button
                    disabled={isLoading}
                    type="button"
                    variant="cancel"
                    onClick={toggleCancelModal}
                  >
                    {t('labels:button.cancel')}
                  </Button>
                  <Button isLoading={isLoading} type="submit">
                    {t('labels:button.save')}
                  </Button>
                </Flex>
              </Styles.SubmitContainer>
            </Unform>
          )}
        </Styles.FormContainer>
      </Flex>
    </PageContainer>
  );
}

import { useField } from '@unform/core';
import { memo, useEffect, useRef } from 'react';

interface IProps {
  name: string;
  value: any;
}

const UnHiddenInputComponent = ({ name, value }: IProps): JSX.Element => {
  const { fieldName, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: () => value,
    });
  }, [value]);

  return <input type="hidden" ref={inputRef} value={value} />;
};

export const UnHiddenInput = memo(UnHiddenInputComponent);

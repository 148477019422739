import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { IconButton, ConfirmModal } from 'components';
import { Tax, FormProvider, BaseForm, useFormContext } from './components';

import { PageContainer } from 'styles/PageContainer';
import { Flex } from 'styles/Flex';
import * as Styles from './styles';

import useBooleanToggle from 'hooks/useBooleanToggle';
import { routePaths } from 'constants/routes';
import { useState } from 'react';
import { withContext } from 'hoc';

const TABS = {
  base: 'base',
  table: 'table',
};

function BaseCreateAcquire({ history, match }: RouteComponentProps<{ id?: string }>) {
  const [tPage] = useTranslation('pages/acquire');
  const [t] = useTranslation();

  const id = match.params.id;

  const context = useFormContext();

  const [isCancelModal, toggleCancelModal] = useBooleanToggle(false);

  const [currentTab, setCurrentTab] = useState(TABS.base);

  const forms = {
    [TABS.base]: <BaseForm data={context?.data.base} onFoward={() => setCurrentTab(TABS.table)} />,
    [TABS.table]: <Tax data={context?.data.table} onBackward={() => setCurrentTab(TABS.base)} />,
  };

  return (
    <PageContainer md>
      <ConfirmModal
        open={isCancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={toggleCancelModal}
        onConfirm={() => history.push(routePaths.acquire.list)}
      />
      <Flex column gap={1.62}>
        <Flex alignItems="center" gap={1}>
          <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={toggleCancelModal} />
          <h1>{id ? tPage('edit.title') : tPage('create.title')}</h1>
        </Flex>
        <Styles.Card>{forms[currentTab]}</Styles.Card>
      </Flex>
    </PageContainer>
  );
}

export const CreateAcquire = withContext(BaseCreateAcquire, FormProvider);

import { PRODUCT_TYPES } from 'constants/product';
import { GetProductAllList } from 'types/product';
import * as Yup from 'yup';
import { addressValidationSchema } from './address';
import { requiredField, invalidCnpj, minBiggerMax, maxLowerMin } from './utils/messages';

import { validateCNPJ } from './utils/validateCnpj';

export const createOperationValidationSchema = Yup.object().shape({
  operation: Yup.object({
    name: Yup.string().required(requiredField),
    cnpj: Yup.string()
      .test('validate-cnpj', invalidCnpj, value => !!validateCNPJ(value || ''))
      .required(requiredField),
    abbreviation: Yup.string().required(requiredField),
    corporate_name: Yup.string().required(requiredField),
    color: Yup.string().required(requiredField),
  }),
  address: addressValidationSchema,
});

const isPhysical = (productType?: GetProductAllList['type']) =>
  productType === PRODUCT_TYPES.physical;

function testRequired(value?: number | string, params?: Yup.TestContext<Record<string, any>>) {
  const product = params?.parent.product as GetProductAllList;

  if (!isPhysical(product?.type)) return true;

  if (value === 0) return true;

  return !!value;
}

function validateMin(value?: number | string, params?: Yup.TestContext<Record<string, any>>) {
  const product = params?.parent.product as GetProductAllList;

  if (!isPhysical(product.type)) return true;

  if (!value) return true;

  return value <= params?.parent.max;
}

function validateMax(value?: number | string, params?: Yup.TestContext<Record<string, any>>) {
  const product = params?.parent.product as GetProductAllList;

  if (!isPhysical(product.type)) return true;

  if (!value) return true;

  return value >= params?.parent.min;
}

export const addProductInventoryValidationSchema = Yup.object().shape({
  product: Yup.mixed().required(requiredField),
  min: Yup.mixed()
    .test('test-min', minBiggerMax, validateMin)
    .test('test-required', requiredField, testRequired),
  max: Yup.mixed()
    .test('test-max', maxLowerMin, validateMax)
    .test('test-required', requiredField, testRequired),
});

export const addIntegrationValidationSchema = Yup.object().shape({
  active: Yup.boolean().required(requiredField),
  company: Yup.string().required(requiredField),
  cost_center: Yup.string().required(requiredField),
  family: Yup.string().required(requiredField),
  integration_type: Yup.mixed().required(requiredField),
});

export const editProductInventoryValidationSchema = Yup.object().shape({
  min: Yup.number()
    .max(Yup.ref('max'), minBiggerMax)
    .required(requiredField)
    .typeError(requiredField),
  max: Yup.number()
    .min(Yup.ref('min'), maxLowerMin)
    .required(requiredField)
    .typeError(requiredField),
});

import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  background-color: ${({ theme }) => theme.colors.foreground};
  z-index: 2;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

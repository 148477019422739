import { UnMaskInput } from 'components';

import { AWARD_TYPE } from 'constants/goal';
import { useTranslation } from 'react-i18next';

interface CorrectMaskInputPros {
  name: string;
  type?: string;
}

export function CorrectMaskInput({ name, type }: CorrectMaskInputPros) {
  const { t } = useTranslation();

  const mapInput = {
    [AWARD_TYPE.PERCENTAGE]: (
      <UnMaskInput
        cleanValue
        parseToFloat
        name={name}
        mask="percent"
        placeholderAlternative="0 %"
      />
    ),
    [AWARD_TYPE.VALUE]: (
      <UnMaskInput
        cleanValue
        parseToFloat
        name={name}
        mask="currency"
        placeholderAlternative={t('currency', { value: 0 })}
      />
    ),
    default: (
      <UnMaskInput
        cleanValue
        parseToFloat
        name={name}
        mask="currency"
        placeholderAlternative={t('currency', { value: 0 })}
      />
    ),
  };
  return mapInput[(type as keyof typeof mapInput) || 'default'];
}

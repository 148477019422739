import { memo } from 'react';

import { useHistory } from 'react-router';

import { Autocomplete } from 'components';

import { useTranslation } from 'react-i18next';
import { pushHistoryQuery } from 'utils/query';

interface Option {
  label: string;
  value: string | number;
}

interface StatusFilterProps {
  defaultValue?: any;
  options?: Option[];
  sendBooleanToQuery?: boolean;
  removePageOnFilter?: boolean;
  fullWidth?: boolean;
}

function BaseStatusFilter({
  defaultValue,
  options,
  removePageOnFilter,
  sendBooleanToQuery,
  ...props
}: StatusFilterProps) {
  const { t } = useTranslation();

  const history = useHistory();

  const handleChangeStatus = (status: { value: number }) => {
    if (!status) return;

    const query = { status: status.value };

    pushHistoryQuery(query, history, { removedProps: removePageOnFilter ? ['page'] : [] });
  };

  const _options = options || [
    { label: t('labels:tag.active'), value: sendBooleanToQuery ? true : 1 },
    { label: t('labels:tag.inactive'), value: sendBooleanToQuery ? false : 0 },
  ];

  return (
    <Autocomplete
      {...props}
      placeholder="labels:input.status"
      onChange={(event, value) => handleChangeStatus(value)}
      onInputChange={(event, value) =>
        value.length === 0 && pushHistoryQuery({ status: '' }, history)
      }
      defaultValue={defaultValue}
      contained
      options={_options}
    />
  );
}

export const StatusFilter = memo(BaseStatusFilter);

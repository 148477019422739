import { SubmitHandler } from '@unform/core';
import { Button, UnAutocomplete, UnDatePicker, Unform } from 'components';
import { useOperationOptions } from 'hooks/useOperationOptions';
import { useCallback, useState } from 'react';
import { Flex } from 'styles/Flex';
import { SimpleOperationList } from 'types/operation';
import { ReportTypes } from 'types/report';
import { openNotification } from 'utils/notification';
import { saleReportValidationSchema } from 'yupSchemas/report';
import { getClosingReport, getGenerateSellerReport } from 'services/report';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/api';
import * as Styles from './styles';
interface FormData {
  final_date: Date;
  initial_date: Date;
  operation_id: number;
  user_id: number;
  file_format: number;
}

interface ClosingReportData {
  date: string;
  payment_value_sum: number;
}

interface Props {
  type: ReportTypes;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ClosingReport = ({ type }: Props) => {
  const [tPage] = useTranslation('pages/report');
  const { isLoading: isLoadingOperations, operations } = useOperationOptions({ loadOnInit: true });
  const [operation, setOperation] = useState<SimpleOperationList>();
  const [loadingButton, setLoadingButton] = useState<number | null>(null);
  const [selectedFileFormat, setSelectedFileFormat] = useState(0);
  const [period, setPeriod] = useState<{ initialDate: Date; finalDate: Date }>({
    initialDate: new Date(),
    finalDate: new Date(),
  });
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [reportData, setReportData] = useState<ClosingReportData[] | []>([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);

  const handleSetPeriod = useCallback((field: keyof typeof period, value: Date) => {
    setPeriod(prev => ({ ...prev, [field]: value }));
  }, []);

  const handleSubmit = useCallback<SubmitHandler<FormData>>(
    async data => {
      try {
        setLoadingButton(selectedFileFormat);
        setIsLoadingReport(true);
        const initialDate = format(data.initial_date, 'yyyy-MM-dd');
        const finalDate = format(data.final_date, 'yyyy-MM-dd');

        const response2 = await getGenerateSellerReport({
          ...data,
          initial_date: initialDate,
          final_date: finalDate,
          file_format: selectedFileFormat,
          type,
        });

        if ((response2.data && selectedFileFormat === 1) || selectedFileFormat === 2) {
          downloadFile(response2.data, tPage(`fullNameReportTypes.${type}`));
        } else {
          console.error('Invalid response from getGenerateSellerReport');
        }

        const response = await getClosingReport({
          finalDate: data.final_date.toISOString(),
          initialDate: data.initial_date.toISOString(),
          operationId: data.operation_id,
          fileFormat: selectedFileFormat,
        });

        setReportData(response.data);

        if (response.data.length === 0) {
          setNoDataFound(true);
          openNotification('Nenhum dado encontrado', 'error');
        } else {
          setNoDataFound(false);
          openNotification('Relatório gerado com sucesso');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingReport(false);
      }
    },
    [type, selectedFileFormat],
  );

  return (
    <Unform onSubmit={handleSubmit} validationSchema={saleReportValidationSchema}>
      <Flex column gap={0.2}>
        <Flex>
          <UnAutocomplete
            label="pages/report:form.operation"
            name="operation_id"
            optionLabel="name"
            optionValue="id"
            options={operations}
            loading={isLoadingOperations}
            onChange={(_, opt) => setOperation(opt)}
            value={operation}
          />
        </Flex>

        <Flex gap={1}>
          <UnDatePicker
            onChange={date => handleSetPeriod('initialDate', date as Date)}
            defaultValue={period.initialDate}
            label="pages/report:form.initialDate"
            name="initial_date"
            maxDate={new Date()}
          />

          <UnDatePicker
            clearHelperTextSpacing={false}
            defaultValue={period.finalDate}
            label="pages/report:form.finalDate"
            name="final_date"
            onChange={date => handleSetPeriod('finalDate', date as Date)}
            maxDate={new Date()}
          />
        </Flex>
        <Flex>
          <Button
            type="submit"
            fullWidth
            disabled={!operation}
            isLoading={isLoadingReport && loadingButton === 3}
            onClick={() => {
              setIsTableVisible(true);
              setSelectedFileFormat(3);
            }}
            style={{ marginBottom: '6px' }}
          >
            Exibir
          </Button>
        </Flex>
        <Flex>
          <Button
            type="submit"
            fullWidth
            disabled={!reportData.length}
            isLoading={isLoadingReport && loadingButton === 2}
            onClick={() => setSelectedFileFormat(2)}
            style={{ marginRight: '4px' }}
          >
            Gerar CSV
          </Button>
          <Button
            type="submit"
            fullWidth
            disabled={!reportData.length}
            isLoading={isLoadingReport && loadingButton === 1}
            onClick={() => setSelectedFileFormat(1)}
            style={{ marginLeft: '4px' }}
          >
            Gerar PDF
          </Button>
        </Flex>
      </Flex>
      {reportData.length > 0 && isTableVisible && (
        <Styles.tableDiv>
          <table>
            <thead>
              <tr>
                <th>Data</th>
                <th>Valor (Dia)</th>
              </tr>
            </thead>
            <tbody>
              {reportData.map((data, index) => {
                const formattedDate = new Date(data.date).toLocaleDateString('pt-BR', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                });

                return (
                  <tr key={index}>
                    <td>{formattedDate}</td>
                    <td>
                      R${' '}
                      {data.payment_value_sum.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Styles.tableDiv>
      )}
    </Unform>
  );
};

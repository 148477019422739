import { useTranslation } from 'react-i18next';
import { memo } from 'react';

import { Button } from 'components';
import { Modal } from 'components/Modal';

import { Flex } from 'styles/Flex';
import * as Styles from './styles';

interface IProps {
  open: boolean;
  onClose(newOpen: boolean): void;
  title: string;
  bodyText: string;
  onConfirm(): void;
  isLoading?: boolean;
  disabled?: boolean;
}

const ModalComponent = ({
  open,
  onClose,
  bodyText,
  title,
  onConfirm,
  isLoading,
  disabled,
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      open={open}
      PaperProps={{ style: { maxWidth: '25rem', width: '100%' } }}
      onClose={onClose}
    >
      <Flex marginVertical={3}>
        <Styles.Text>{bodyText}</Styles.Text>
      </Flex>

      <Flex justifyContent="flex-end" gap={1}>
        <Button variant="cancel" disabled={disabled} onClick={() => onClose(false)}>
          {t('button.cancel')}
        </Button>

        <Button onClick={onConfirm} disabled={disabled} isLoading={isLoading}>
          {t('button.confirm')}
        </Button>
      </Flex>
    </Modal>
  );
};

export const ConfirmModal = memo(ModalComponent);

import api from './apis/api';

import {
  CheckCashdesk,
  GetCashdeskById,
  GetCashDeskQueries,
  GetCashDeskResponse,
} from 'types/cashDesk';
import { objToQuery } from 'utils/query';

export async function getCashDesk(query?: GetCashDeskQueries) {
  return await api.get<GetCashDeskResponse>(`/cash-desk${query ? `?${objToQuery(query)}` : ''}`);
}

export async function getCashdeskById(id: number | string, operationId: number | string) {
  return await api.get<GetCashdeskById>(`/cash-desk/${id}?operationId=${operationId}`);
}

export function checkCashDesk(
  id: number | string,
  operationId: number | string,
  payload: CheckCashdesk,
) {
  return api.put(`/cash-desk/${id}/check?operationId=${operationId}`, payload);
}

import {
  ApiQueryUser,
  CreateUser,
  GetUserResponse,
  GetUsersByOperationId,
  UserDetails,
  UserType,
} from 'types/user';

import { objToQuery } from 'utils/query';

import api from 'services/apis/api';

export const getUserTypes = () => api.get<UserType[]>('user/type');

export const postUser = (data: CreateUser) => api.post('user', data);

export const putUser = (userId: string | number, data: CreateUser) =>
  api.put(`user/${userId}`, data);

export async function getUsers(query?: ApiQueryUser) {
  return await api.get<GetUserResponse>(`/user${query ? `?${objToQuery(query)}` : ''}`);
}

export const getUserById = (userId: number | string) => api.get<UserDetails>(`/user/${userId}`);

export async function deleteUser(id: string | number) {
  return await api.delete(`/user/${id}`);
}

export async function patchStatusUser(id: string | number) {
  return await api.patch(`/user/${id}/updateStatus`);
}

export async function getUsersByOperationId(id: string | number, query?: { search: string }) {
  return await api.get<GetUsersByOperationId[]>(
    `/user/by-operation/${id}${query ? `?${objToQuery(query)}` : ''}`,
  );
}

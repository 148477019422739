import { Grid } from '@mui/material';
import { UnInputFIeld } from 'components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'styles/Flex';
import { Legend } from 'styles/Legend';

const BankDataPartComponent = (): JSX.Element => {
  const [tPage] = useTranslation('pages/touristGuide');

  return (
    <>
      <Grid item xs={12}>
        <Legend>{tPage('createGuide.bankData')}</Legend>
      </Grid>
      <Grid container item xs={12}>
        <Flex maxWidth={50}>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <UnInputFIeld name="pix" label="labels:input.user.pix" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <UnInputFIeld name="bank" label="labels:input.user.bank" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <UnInputFIeld name="agency" label="labels:input.user.agency" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <UnInputFIeld name="account" label="labels:input.user.currentAccount" />
            </Grid>
          </Grid>
        </Flex>
      </Grid>
    </>
  );
};

export const BankDataPart = memo(BankDataPartComponent);

import { ComponentProps, memo } from 'react';
import { useHistory } from 'react-router';

import { Popover, ConfirmModal } from 'components';
import { RelateModal } from '../RelateOperationModal';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { deleteUser } from 'services/user';
import { patchStatusPriceList } from 'services/priceList';

import { resolvePath, routePaths } from 'constants/routes';
import { useTranslation } from 'react-i18next';

type PopoverProps = ComponentProps<typeof Popover>;

interface MenuProps {
  id: string | number;
  status?: number;
  onDelete?: () => void;
  onEdit?: () => void;
  onRelate?: () => void;
  relateTitle: string;
}

function BaseMenu(props: MenuProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const [isVisibleDeleteModal, toggleDeleteModal] = useBooleanToggle(false);
  const [isVisibleEditModal, toggleEditModal] = useBooleanToggle(false);
  const [isVisibleRelateModal, toggleRelateModal] = useBooleanToggle(false);

  const handleDeleteProduct = async (id: string | number) => {
    try {
      await deleteUser(id);
      props?.onDelete?.();
    } catch (err) {
    } finally {
      toggleDeleteModal();
    }
  };

  const handleToggleStatus = async (id: string | number) => {
    try {
      await patchStatusPriceList(id);
      props?.onEdit?.();
    } catch (err) {
    } finally {
      isVisibleEditModal && toggleEditModal();
    }
  };

  const handleToggleRelate = () => {
    toggleRelateModal();
  };

  const options: PopoverProps['options'] = [
    {
      label: 'pages/priceList:listing.menu.relateOperation',
      onClick: handleToggleRelate,
    },
    {
      label: 'commons:popoverList.edit',
      onClick: () => history?.push(resolvePath(routePaths.priceList.edit, { id: props.id })),
    },
    {
      label: props.status !== 1 ? 'commons:popoverList.active' : 'commons:popoverList.inactive',
      onClick: props.status !== 1 ? () => handleToggleStatus(props.id) : toggleEditModal,
    },
    // {
    //   label: 'commons:popoverList.delete',
    //   variante: 'error',
    //   onClick: toggleDeleteModal,
    // },
  ];

  return (
    <div onClick={event => event.stopPropagation()}>
      <ConfirmModal
        open={isVisibleDeleteModal}
        title="Atenção"
        bodyText={t('pages/priceList:listing.menu.delete')}
        onClose={toggleDeleteModal}
        onConfirm={() => props.id && handleDeleteProduct(props.id)}
      />
      <ConfirmModal
        open={isVisibleEditModal}
        title="Atenção"
        bodyText={t('pages/priceList:listing.menu.status.inactive')}
        onClose={toggleEditModal}
        onConfirm={() => props.id && handleToggleStatus(props.id)}
      />
      {isVisibleRelateModal && (
        <RelateModal
          title={props.relateTitle}
          onClose={toggleRelateModal}
          onConfirm={props?.onRelate}
          priceListId={props.id}
          open={isVisibleRelateModal}
        />
      )}
      <Popover options={options} id="simple" />
    </div>
  );
}

export const Menu = memo(BaseMenu);

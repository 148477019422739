import { FormHandles, SubmitHandler } from '@unform/core';
import { memo, useRef, useState } from 'react';

import { Button, Unform } from 'components';
import { RenderOperation, RenderUser } from './components';

import * as DefaultStyles from 'pages/Private/Goal/Create/styles';
import { Flex } from 'styles/Flex';

import { useGoalContext } from 'pages/Private/Goal/Create/components';

import { GOAL_OPERATION_TYPE } from 'constants/goal';

import { Goal } from 'types/goal';

import { createProductGoalValidationSchema } from 'yupSchemas/goal';

import { Masks } from '../..';

interface Product {
  products: (Goal & {
    product_id: number;
  })[];
}

interface User {
  products: (Goal & {
    product_id: number;
  })[];
}

export interface DetailsProduct {
  operations?: Product[];
  users?: User[];
  products?: { name: string; id: number }[];
}

interface ProductsGoalProps {
  isTheLast?: boolean;
  mask?: Masks;
  onBackward: () => void;
  initialData?: DetailsProduct;
}

function BaseProductsGoal({ isTheLast, onBackward, initialData }: ProductsGoalProps) {
  const context = useGoalContext();
  const formRef = useRef<FormHandles>(null);

  const [details] = useState<Partial<DetailsProduct>>(initialData || {});
  const [isSaved, setIsSaved] = useState(false);

  const handleSubmit: SubmitHandler<DetailsProduct> = async payload => {
    try {
      context?.saveData('products_goal', payload);
      setIsSaved(true);

      if (isTheLast && isSaved && context?.canSubmit)
        await context?.onSubmit?.('products_goal', payload);
    } catch (err) {
      setIsSaved(false);
    }
  };

  return (
    <Unform
      onSubmit={handleSubmit}
      ref={formRef}
      validationSchema={createProductGoalValidationSchema(
        context?.data?.base?.type === GOAL_OPERATION_TYPE.OPERATION ? 'operations' : 'users',
      )}
      initialData={details}
    >
      {context?.data?.base?.type === GOAL_OPERATION_TYPE.OPERATION ? (
        <RenderOperation
          initialData={initialData}
          type={context?.data?.base?.products_goal_award_type || ''}
          data={context?.data?.base?.operations || []}
        />
      ) : (
        <RenderUser
          operationId={context?.data?.base?.operation?.id}
          initialData={initialData}
          type={context?.data?.base?.products_goal_award_type || ''}
          data={context?.data?.base?.users || []}
        />
      )}
      <hr style={{ marginTop: '5rem' }} />
      <DefaultStyles.Container>
        <Flex justifyContent="flex-end" gap={1}>
          <Button variant="cancel" disabled={context?.isLoading} onClick={onBackward}>
            Voltar
          </Button>
          <Button
            isLoading={context?.isLoading}
            variant={isTheLast && context?.canSubmit && isSaved ? 'green' : 'primary'}
            type="submit"
          >
            {isTheLast && context?.canSubmit && isSaved ? 'Enviar' : 'Salvar'}
          </Button>
        </Flex>
      </DefaultStyles.Container>
    </Unform>
  );
}

export const ProductsGoal = memo(BaseProductsGoal);

import styled from 'styled-components';
import { breakpoints } from 'constants/layout';

export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}) {
    flex-wrap: nowrap;
  } ;
`;

export const InputFields = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;

  @media (min-width: ${breakpoints.md}) {
    flex-wrap: nowrap;
    max-width: 80%;
  } ;
`;

import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  PaginatedTable,
  SearchFilter,
  StatusFilter,
  OperationFilter,
  Column,
  Tag,
} from 'components';

import { Menu } from '../components/Menu';

import useBooleanToggle from 'hooks/useBooleanToggle';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { pushHistoryQuery, queryToObj } from 'utils/query';

import { resolvePath, routePaths } from 'constants/routes';

import { getPromotion as getPromotionApi } from 'services/promotion';

import { Meta } from 'types/api';
import { GetPromotion, PromotionQueries } from 'types/promotion';
import { DISCOUNT_TYPE } from 'constants/promotion';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListingPromotion({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/promotion');
  const { t } = useTranslation();

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const [promotions, setPromotions] = useState<GetPromotion[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getPromotion = async (query?: PromotionQueries) => {
    try {
      toggleIsLoading();

      const { data } = await getPromotionApi(query);

      setMeta(data.meta);
      setPromotions(data.data);
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const column: Column[] = [
    {
      field: 'name',
      headerName: tPage('listing.table.name'),
    },
    {
      field: 'validity',
      headerName: tPage('listing.table.validity'),
    },
    {
      field: 'discount',
      headerName: tPage('listing.table.discount'),
    },
    {
      field: 'status',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const rows = useMemo(
    () =>
      promotions.map(promotion => ({
        id: promotion?.id,
        name: promotion?.name,
        validity: `${t('date', { date: new Date(promotion.initial_validity) })} - ${t('date', {
          date: new Date(promotion.final_validity),
        })}`,
        discount:
          promotion?.discountType === DISCOUNT_TYPE.FIXED_VALUE
            ? t('currency', { value: promotion?.fixedValue })
            : promotion?.discountType === DISCOUNT_TYPE.PERCENTAGE
            ? `${promotion?.percentage} %`
            : promotion?.discountType === DISCOUNT_TYPE.PRODUCT_REQUIRED
            ? 'Produto'
            : '',
        menu: (
          <Menu
            key={promotion?.id}
            id={promotion?.id}
            status={promotion?.status}
            onDelete={() => getPromotion(historyQuery)}
            onEdit={() => getPromotion(historyQuery)}
            history={history}
          />
        ),
        status: (
          <Tag
            key={promotion?.id}
            label={promotion?.status === 0 ? 'Inativo' : 'Ativo'}
            variante={promotion.status === 0 ? 'inactive' : 'success'}
          />
        ),
      })),
    [promotions],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as PromotionQueries;

      getPromotion(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getPromotion(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <h1>{tPage('listing.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <DefaultStyles.SelectContainer>
                <SearchFilter
                  defaultValue={(queryToObj(history.location.search)?.search ?? '') as any}
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <OperationFilter />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <StatusFilter defaultValue={historyQuery?.status} />
              </DefaultStyles.SelectContainer>
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => history.push(routePaths.promotion.create)}
            >
              {tPage('listing.actionButton')}
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={column}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.promotion.details, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

import { TextField } from 'components';
import React, { ComponentProps, forwardRef, memo, useImperativeHandle, useRef } from 'react';
// Doc: https://github.com/text-mask/text-mask/tree/master/react#readme
import MaskedInput from 'react-text-mask';

import { masks } from './masks';

interface IProps extends ComponentProps<typeof TextField> {
  mask?: keyof typeof masks;
}

// eslint-disable-next-line react/display-name
const MaskInputComponent = forwardRef<any, IProps>(
  ({ mask = 'number', onChange, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>();

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [inputRef]);

    return (
      <MaskedInput
        mask={masks[mask]}
        guide={false}
        onChange={onChange}
        render={(maskRef, maskProps) => (
          <TextField
            ref={node => {
              if (node) {
                maskRef(node);
                inputRef.current = node;
              }
            }}
            {...maskProps}
            {...props}
          />
        )}
      />
    );
  },
);

export const MaskInput = memo(MaskInputComponent);

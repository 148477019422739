import styled from 'styled-components';

import { IconButton } from 'components/IconButton';

export const Item = styled.li<{ isOdd?: boolean }>`
  padding: 0.5rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${({ isOdd }) => (isOdd ? 'rgba(0, 0, 0, .03)' : 'none')};
`;

export const List = styled.ul`
  width: 100%;
`;

export const RemoveButton = styled(IconButton)`
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.3rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

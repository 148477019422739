import { useTranslation } from 'react-i18next';
import { ComponentProps, memo, SyntheticEvent, useEffect, useState } from 'react';
import { SubmitHandler } from '@unform/core';

import { RootModal, IconButton, UnFieldArray, Button } from 'components';

import { getOperationsList } from 'services/operation';

import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { debounce } from 'utils';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { isOdd } from 'utils/help';

import { getProductRelatedPriceList, putRelateOperationToPriceList } from 'services/priceList';

import { openNotification } from 'utils/notification';

type ModalProps = ComponentProps<typeof RootModal>;

interface RelateOperation extends Pick<ModalProps, 'open'> {
  priceListId: string | number;
  onClose?: () => void;
  onConfirm?: () => void;
  title: string;
}

interface Operation {
  name: string;
  id: number;
}

interface Details {
  operations: Operation[];
}

function BaseRelateOperationModal({ open, priceListId, title, ...props }: RelateOperation) {
  const [tPage] = useTranslation('pages/priceList');
  const [t] = useTranslation();

  const [initialLoading, setInitialLoading] = useState(true);

  const [isLoading, toggleLoading] = useBooleanToggle(false);
  const [operations, setOperations] = useState<Operation[]>([]);
  const [detail, setDetails] = useState<Details>();

  const getInitialOperations = async () => {
    try {
      const { data } = await getProductRelatedPriceList(priceListId);

      if (data.length === 0) return;

      const initialOperations: Operation[] = data.map(operation => ({
        name: operation.name,
        id: operation.id,
      }));

      setDetails({
        operations: initialOperations,
      });

      setOperations(initialOperations);
    } finally {
      setInitialLoading(false);
    }
  };

  const handleSubmit: SubmitHandler<Details> = async data => {
    try {
      toggleLoading();

      const operationsId = data.operations.map(value => value.id);

      await putRelateOperationToPriceList(priceListId, operationsId);

      props?.onConfirm?.();
      props?.onClose?.();
      openNotification(tPage('listing.relateMessage'));
    } finally {
      toggleLoading();
    }
  };

  const getOperation = async (event: SyntheticEvent<Element, Event>, value: string) => {
    const { data } = await getOperationsList({
      search: value,
      status: 1,
    });

    const mapOperations: Operation[] = data.map(operation => ({
      id: operation.id,
      name: operation.name,
    }));

    setOperations(mapOperations);
  };

  const handleRemoveOperation = (index: number) => {
    setOperations(prevState => prevState.filter((operation, _index) => _index !== index));
  };

  useEffect(() => {
    !open && setInitialLoading(false);

    open && getInitialOperations();
  }, [open]);

  return (
    <RootModal open={open}>
      <Styles.Container>
        <Flex alignItems="center" justifyContent="space-between">
          <Styles.Title>{title}</Styles.Title>
          <IconButton
            onClick={props?.onClose}
            icon={{
              name: 'close',
              size: 1.3,
            }}
          />
        </Flex>
      </Styles.Container>
      {!initialLoading && (
        <Styles.Form initialData={detail} onSubmit={handleSubmit}>
          <Styles.Container>
            <Flex column gap={1.4}>
              <Styles.Legend>{tPage('listing.menu.relateModal.legend')}</Styles.Legend>

              <UnFieldArray
                name="operations"
                placeholder="pages/priceList:listing.menu.relateModal.searchPlaceholder"
                options={operations}
                onInputChange={debounce(getOperation)}
                optionLabel="name"
                optionValue="id"
                unicKeyFilter="id"
                render={({ remove }, operations) => (
                  <Styles.List>
                    <Styles.ScrollView>
                      <Flex column>
                        {operations.map((operation, index) => (
                          <Styles.Item key={index} isOdd={isOdd(index)}>
                            <span>{operation.name}</span>
                            <Styles.RemoveButton
                              onClick={() => {
                                remove(index);
                                handleRemoveOperation(index);
                              }}
                              icon={{
                                name: 'close',
                                color: 'text100',
                              }}
                            />
                          </Styles.Item>
                        ))}
                      </Flex>
                    </Styles.ScrollView>
                  </Styles.List>
                )}
              />
            </Flex>
          </Styles.Container>
          <hr />
          <Styles.Container>
            <Flex justifyContent="flex-end" gap={1}>
              <Button disabled={isLoading} onClick={props.onClose} variant="base">
                {t('button.cancel')}
              </Button>
              <Button isLoading={isLoading} type="submit">
                {t('button.save')}
              </Button>
            </Flex>
          </Styles.Container>
        </Styles.Form>
      )}
    </RootModal>
  );
}

export const RelateModal = memo(BaseRelateOperationModal);

import { ReportTypes } from 'types/report';

export const reportTypes: Record<ReportTypes, string> = {
  analytical: 'analytical',
  synthetic: 'synthetic',
  product: 'product',
  financial: 'financial',
  photoproduction: 'photoproduction',
  sales: 'sales',
  closing: 'closing',
  paymentmethod: 'paymentmethod',
};

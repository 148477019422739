import { UnInputFIeld, UnMaskInput } from 'components';
import { ChangeEvent, memo } from 'react';
import { useFormContext } from '../../../FormProvider';

interface FeeProps {
  name: string;
  type: string;
  installment: number;
}

function BaseFee({ name, type, installment }: FeeProps) {
  const context = useFormContext();

  const handleToggleTaxDeletedList = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    installmentValue: number,
    type: string,
  ) => {
    const { value } = event.target;

    if (!value) return context?.addTaxToDeletedList?.(installmentValue, type);

    context?.removeTaxFromDeletedList(installmentValue, type);
  };

  return (
    <>
      <UnMaskInput
        mask="decimalPercent"
        onChange={event => handleToggleTaxDeletedList(event, installment, type)}
        name={`${name}.fee_value`}
        parseToFloat
        parseDefaultValue={(value: number) => `${value}`.replace('.', ',')}
      />
      <UnMaskInput
        name={`${name}.installment`}
        value={installment}
        hidden
        cleanValue
        parseToFloat
        style={{ display: 'none' }}
      />
      <UnInputFIeld name={`${name}.type`} value={type} hidden style={{ display: 'none' }} />
      <UnMaskInput name={`${name}.id`} cleanValue parseToFloat hidden style={{ display: 'none' }} />
    </>
  );
}

export const Fee = memo(BaseFee);

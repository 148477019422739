import { Grid, IconButton } from '@mui/material';
import { FormHandles, Scope } from '@unform/core';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  Button,
  ConfirmModal,
  Icon,
  LoadingMessage,
  UnColorPicker,
  UnDropImage,
  Unform,
  UnInputFIeld,
  IImage,
  UnMaskInput,
  UnSwitch,
} from 'components';

import { useToggle } from 'hooks/useToggle';

import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { Hr } from 'styles/Hr';
import { AddressSection } from './components';
import * as Styles from './styles';
import { Legend } from 'styles/Legend';

import {
  getOperationById,
  postOperation,
  putOperation,
  handleUploadImage,
} from 'services/operation';
import { getFile, deleteFile } from 'services/file';

import { CreateOperation as ICreateOperation, ListOperation } from 'types/operation';

import { openNotification } from 'utils/notification';

import { createOperationValidationSchema } from 'yupSchemas/operation';

interface IProps extends RouteComponentProps<{ id: string }> {}

interface Image extends Omit<IImage, 'name'> {
  id?: string | number;
}

interface FormData extends ICreateOperation {
  img?: Image[];
}

interface Detail extends ListOperation {
  img?: Image[];
}

export const CreateOperation: FC<IProps> = ({ history, match }) => {
  const { t } = useTranslation();
  const [tPage] = useTranslation('pages/operations');
  const [toggles, setToggles] = useToggle<'cancelModal' | 'saving' | 'getDetails'>({
    cancelModal: false,
    saving: false,
    getDetails: true,
  });
  const formRef = useRef<FormHandles>(null);
  const [details, setDetails] = useState<Detail>();
  const [isInventory, setIsInventory] = useState<boolean>(false);
  const id = useMemo(() => match.params.id, [match.params]);
  const handleSubmit = useCallback(async (data: FormData) => {
    setToggles('saving', true);
    try {
      const { img, ...restData } = data;

      const requestData = {
        ...restData,
        operation: {
          ...restData.operation,
          isInventory: id ? null : restData.operation.isInventory,
        },
        partner: restData.operation.isInventory ? null : restData.partner,
      };

      const response = id ? await putOperation(id, requestData) : await postOperation(requestData);

      if (img) {
        await handleUploadImage(String(response?.data?.id || id), img);
      }

      openNotification(tPage(id ? 'create.updatedMessage' : 'create.createdMessage'));
      history.goBack();
    } finally {
      setToggles('saving', false);
    }
  }, []);

  const handleRedirect = useCallback(() => {
    setToggles('cancelModal', true);
  }, []);

  const handleConfirmRedirect = useCallback(() => {
    history.goBack();
    setToggles('cancelModal', false);
  }, []);

  const handleGetDetails = useCallback(async () => {
    try {
      const response = await getOperationById(id);

      setIsInventory(!!response.data.operation.isInventory);

      if (response.data.operation.background) {
        const img = await getFile(response.data.operation.background.id);

        setDetails({
          ...response.data,
          img: [
            {
              url: img,
              isFromServer: true,
              id: response.data.operation.background.id,
            },
          ],
        });

        return;
      }
      setDetails(response.data);
    } catch (error) {
    } finally {
      setToggles('getDetails', false);
    }
  }, [id]);

  useEffect(() => {
    id && handleGetDetails();
  }, []);

  return (
    <PageContainer md>
      <h1>
        <IconButton onClick={handleRedirect}>
          <Icon name="arrowBack" size={1.4} color="text" />
        </IconButton>

        {tPage(id ? 'edit.title' : 'create.title')}
      </h1>

      <Styles.FormContainer>
        {id && toggles.getDetails ? (
          <LoadingMessage text="Carregando detalhes" />
        ) : (
          <Unform
            onSubmit={handleSubmit}
            ref={formRef}
            validationSchema={createOperationValidationSchema}
            initialData={details}
          >
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <Legend>{tPage('create.operationSection')}</Legend>
              </Grid>

              <Scope path="operation">
                <Grid item xs={9}>
                  <UnInputFIeld name="name" label="labels:input.operationName" />
                </Grid>

                <Grid item xs={3}>
                  <UnInputFIeld
                    name="abbreviation"
                    label="pages/operations:log.operation.abbreviation"
                    inputProps={{ maxLength: 4, minLength: 4 }}
                  />
                </Grid>
                <Grid item xs={12} sm={5} lg={3}>
                  <UnMaskInput name="cnpj" mask="cnpj" cleanValue label="labels:input.cnpj" />
                </Grid>

                <Grid item xs={12} sm={7} lg={9}>
                  <UnInputFIeld name="corporate_name" label="labels:input.corporateName" />
                </Grid>

                <Grid item xs={12}>
                  <UnSwitch
                    name="isInventory"
                    label="labels:input.inventory"
                    onChange={(e, checked) => setIsInventory(checked)}
                    disabled={!!id}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Legend>{tPage('create.sacSection')}</Legend>
                </Grid>

                <Grid item xs={12} md={3}>
                  <UnMaskInput
                    name="sac_phone"
                    cleanValue
                    mask="cellphone"
                    label="labels:input.sacPhone"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <UnInputFIeld name="sac_mail" label="labels:input.sacMail" />
                </Grid>
              </Scope>

              <Grid item xs={12}>
                <Legend>{tPage('create.addressSection')}</Legend>
              </Grid>

              <AddressSection formRef={formRef} />

              <Styles.PartnerContainer
                container
                columnSpacing={3}
                item
                xs={12}
                hidden={isInventory}
              >
                <Grid item xs={12} lg={12}>
                  <Legend>{tPage('create.partnerSection')}</Legend>
                </Grid>

                <Scope path="partner">
                  <Grid item xs={12} md={5}>
                    <UnInputFIeld name="name" label="labels:input.partnerName" />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <UnMaskInput
                      name="phone"
                      cleanValue
                      mask="cellphone"
                      label="labels:input.cellphone"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <UnInputFIeld name="email" label="labels:input.partnerEmail" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <UnMaskInput
                      mask="decimal"
                      name="profit"
                      label="labels:input.commissionPercentage"
                      parseToFloat
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <UnInputFIeld name="bank" label="labels:input.bank" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <UnInputFIeld name="agency" label="labels:input.agency" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <UnInputFIeld name="current_account" label="labels:input.checkingAccount" />
                  </Grid>
                </Scope>

                <Scope path="operation">
                  <Grid item xs={12} md={3}>
                    <UnColorPicker name="color" label="labels:input.platformColor" />
                  </Grid>
                </Scope>
              </Styles.PartnerContainer>

              <Grid item xs={12}>
                <UnDropImage
                  onRemoveFile={async (file: Image) => {
                    if (file.isFromServer && file.id) {
                      await deleteFile(file.id);
                    }
                  }}
                  name="img"
                  label="labels:input.backgroundImage"
                />
              </Grid>
            </Grid>

            <Flex marginTop={2} fullWidth>
              <Hr margin="0 -1.5rem" />
            </Flex>

            <Flex marginTop={2}>
              <Flex gap={1} marginLeft="auto">
                <Button variant="cancel" onClick={handleRedirect}>
                  {t('button.cancel')}
                </Button>

                <Button type="submit" isLoading={toggles.saving}>
                  {t('button.save')}
                </Button>
              </Flex>
            </Flex>
          </Unform>
        )}
      </Styles.FormContainer>

      <ConfirmModal
        open={toggles.cancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={() => setToggles('cancelModal', false)}
        onConfirm={handleConfirmRedirect}
      />
    </PageContainer>
  );
};

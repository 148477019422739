import { Grid } from '@mui/material';
import { UnAutocomplete, UnInputFIeld, UnMaskInput } from 'components';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'styles/Flex';
import { Legend } from 'styles/Legend';
import { getUserTypes } from 'services/user';
import { UserType } from 'types/user';

const UserDataPartComponent = (): JSX.Element => {
  const [tPage] = useTranslation('pages/user');
  const { t } = useTranslation();
  const [userTypes, setUserTypes] = useState<UserType[]>([]);

  const handleGetUserTypes = useCallback(async () => {
    try {
      const response = await getUserTypes();

      setUserTypes(response.data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    handleGetUserTypes();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Legend>{tPage('create.userData')}</Legend>
      </Grid>

      <Grid item xs={12}>
        <Flex maxWidth={20}>
          <UnAutocomplete
            name="typeId"
            label="labels:input.user.userType"
            optionLabel="name"
            optionValue="id"
            options={userTypes.map(type => ({ ...type, name: t(`labels:userTypes.${type.name}`) }))}
          />
        </Flex>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <UnInputFIeld name="fullName" label="labels:input.user.completeName" />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <UnInputFIeld name="registration" label="labels:input.user.registration" />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <UnMaskInput cleanValue name="cpf" mask="cpf" label="labels:input.user.cpf" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <UnMaskInput name="phone" cleanValue mask="cellphone" label="labels:input.user.phone" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <UnInputFIeld name="email" label="labels:input.user.partnerEmail" />
      </Grid>
    </>
  );
};

export const UserDataPart = memo(UserDataPartComponent);

import { ComponentProps, memo, SyntheticEvent } from 'react';

import { UnFieldArray } from 'components';

import { debounce } from 'utils';
import { isOdd } from 'utils/help';

import * as Styles from './styles';

import { SimpleOperationList } from 'types/operation';
import { useCardFlags } from 'hooks/useCardFlags';

interface UnCardFlagArrayProps
  extends Pick<
    ComponentProps<typeof UnFieldArray>,
    'label' | 'disabled' | 'value' | 'onChangeValue' | 'onSelect'
  > {
  name: string;
  onValidate?: (option: { id: number | string }) => Promise<void>;
  onRemoveRow?: (option: { id: number | string }) => void;
}

function BaseUnCardFlagArray({
  onValidate,
  onRemoveRow,
  onChangeValue,
  ...props
}: UnCardFlagArrayProps) {
  const { data, getData } = useCardFlags();

  const handleChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    if (!value) return;

    getData({
      search: value,
      status: 1,
    });
  };

  return (
    <UnFieldArray
      optionLabel="name"
      optionValue="id"
      options={data}
      onInputChange={debounce(handleChange)}
      onValidate={onValidate}
      onChangeValue={onChangeValue}
      unicKeyFilter="id"
      {...props}
      render={({ remove }, _operations) =>
        _operations.map((operation: SimpleOperationList, index) => (
          <Styles.Item isOdd={isOdd(index)} key={index}>
            <span>{operation.name}</span>
            <Styles.RemoveButton
              onClick={() => {
                onRemoveRow?.(operation);
                remove(index);
              }}
              icon={{
                name: 'close',
                color: 'text100',
              }}
            />
          </Styles.Item>
        ))
      }
    />
  );
}

export const UnUnCardFlagArray = memo(BaseUnCardFlagArray);

import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.foreground};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const Container = styled.div`
  padding: 3rem 1.5rem 2rem 1.5rem;
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.foreground};
`;

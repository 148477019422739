import React from 'react';

import * as Styles from './styles';
import { ReactComponent as Logo } from '../../assets/vyoo-logo-novo.svg';

export const Secondary: React.FC = props => {
  return (
    <Styles.Container>
      <Styles.Header>
        <Logo />
      </Styles.Header>

      <Styles.Body>{props.children}</Styles.Body>
    </Styles.Container>
  );
};

import { Grid } from '@mui/material';
import { memo, useMemo } from 'react';
import { InventoryDetails } from 'types/inventory';
import * as DefaultStyles from 'styles/Details';
import { useTranslation } from 'react-i18next';
import { Flex } from 'styles/Flex';
import { inventoryStatusVariants, INVENTORY_TYPES } from 'constants/inventory';
import { Table, Tag } from 'components';
import * as Styles from './styles';
import { downloadFileById } from 'utils/api';

interface IProps {
  inventory: InventoryDetails;
}

const BaseInfo = ({ inventory }: IProps): JSX.Element => {
  const [tPage] = useTranslation('pages/inventory');
  const { t } = useTranslation();
  const isEntry = useMemo(() => inventory.type === INVENTORY_TYPES.entry, [inventory.type]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Flex justifyContent="flex-end">
          <Tag
            label={tPage(`status.${inventory.status}`)}
            variante={inventoryStatusVariants[inventory.status]}
          />
        </Flex>
      </Grid>

      <Grid item xs={isEntry ? 6 : 4}>
        <Flex column gap={1}>
          <DefaultStyles.Label>{tPage('details.type')}</DefaultStyles.Label>

          {tPage(`types.${inventory.type}`)}
        </Flex>
      </Grid>

      {!isEntry && (
        <>
          <Grid item xs={3}>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.originOperation')}</DefaultStyles.Label>

              {inventory.originOperation.name}
            </Flex>
          </Grid>
        </>
      )}

      <Grid item xs={isEntry ? 6 : 4}>
        <Flex column gap={1}>
          <DefaultStyles.Label>
            {tPage(`details.${isEntry ? 'operation' : 'destinationOperation'}`)}
          </DefaultStyles.Label>

          {inventory.destinationOperation.name}
        </Flex>
      </Grid>

      {isEntry && (
        <>
          <Grid item xs={3}>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.nameProvider')}</DefaultStyles.Label>

              {inventory.nameProvider}
            </Flex>
          </Grid>

          <Grid item xs={3}>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.invoiceNumber')}</DefaultStyles.Label>

              {inventory.invoiceNumber}
            </Flex>
          </Grid>

          <Grid item xs={3}>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.invoiceDate')}</DefaultStyles.Label>

              {t('date', { date: new Date(inventory.invoiceDate) })}
            </Flex>
          </Grid>

          <Grid item xs={3}>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.totalValueInvoice')}</DefaultStyles.Label>

              {t('currency', { value: inventory.totalValueInvoice })}
            </Flex>
          </Grid>

          <Grid item />
        </>
      )}

      <Grid item xs={12}>
        <Flex column gap={1}>
          <DefaultStyles.Label>{tPage('details.observation')}</DefaultStyles.Label>

          {inventory.observation}
        </Flex>
      </Grid>

      <Grid item xs={12}>
        <Flex column gap={1}>
          {inventory.files.map((file, index) => (
            <Styles.FileLine key={index} onClick={() => downloadFileById(file.id, file.filename)}>
              <span>{file.filename}</span>
            </Styles.FileLine>
          ))}
        </Flex>
      </Grid>

      <Grid item xs={12}>
        <Flex marginVertical={0.5} />
      </Grid>

      <Grid item xs={12}>
        <Table
          tableVariant="secondary"
          columns={[
            {
              field: 'name',
              headerName: tPage('details.addedProducts'),
            },
            {
              field: 'amount',
              headerName: tPage('details.amount'),
            },
            {
              field: 'coast',
              headerName: tPage('details.coast'),
            },
          ]}
          rows={inventory.products.map(product => ({
            name: `${product.id} -  ${product.name}`,
            amount: product.amount,
            coast: t('currency', { value: product.price }),
          }))}
        />
      </Grid>
    </Grid>
  );
};

export const Info = memo(BaseInfo);

import { memo, SyntheticEvent, useRef, useState } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useTranslation } from 'react-i18next';

import { Button, RootModal, UnAutocomplete, Unform, UnMaskInput } from 'components';

import * as Styles from './styles';

import { getOperationsList as getOperationsListApi } from 'services/operation';
import { getDownloadQRCode, patchQrCode } from 'services/qrcode';

import { CreateQRCode as CreateQRCodeRequest } from 'types/QRCode';

import { debounce } from 'utils';

import { Flex } from 'styles/Flex';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { createQRCodeValidation } from 'yupSchemas/qrCode';
import { downloadFile } from 'utils/api';
import { openNotification } from 'utils/notification';

interface CreateQRCodeProps {
  visible?: boolean;
  onOpen?: () => void;
  onFinished?: () => void;
  onClose?: () => void;
}

interface Operation {
  name: string;
  id: number | string;
}

function BaseCreateQRCode(props: CreateQRCodeProps) {
  const [tPage] = useTranslation('/pages/qrCode');

  const formRef = useRef<FormHandles>(null);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const [details] = useState<Partial<CreateQRCodeRequest>>();

  const [operations, setOperations] = useState<Operation[]>([]);

  const handleSubmit: SubmitHandler<CreateQRCodeRequest> = async data => {
    try {
      toggleIsLoading();
      const response = await patchQrCode(data);
      const responseFile = await getDownloadQRCode(response.data.id);

      downloadFile(responseFile.data, responseFile?.headers?.filename);
      openNotification(tPage('create.successMessage'));
      props?.onFinished?.();
    } finally {
      toggleIsLoading();
      props?.onClose?.();
    }
  };

  const getOperations = async (event: SyntheticEvent, value: string) => {
    try {
      if (value.length === 0) return;

      const { data } = await getOperationsListApi({ search: value });

      setOperations(
        data.map(value => ({
          name: value.name,
          id: value.id,
        })),
      );
    } catch (err) {}
  };

  return (
    <RootModal open={props?.visible || false}>
      <Unform
        onSubmit={handleSubmit}
        initialData={details}
        ref={formRef}
        validationSchema={createQRCodeValidation}
      >
        <Styles.Container>
          <Styles.Title>{tPage('create.title')}</Styles.Title>
        </Styles.Container>
        <Styles.Container>
          <Flex column gap={2}>
            <UnAutocomplete
              name="operationId"
              placeholder="labels:input.operation"
              optionLabel="name"
              optionValue="id"
              options={operations}
              onInputChange={debounce(getOperations)}
              contained
            />
            <UnMaskInput name="amount" label="labels:inventory.amount" cleanValue parseToFloat />
          </Flex>
        </Styles.Container>
        <hr />
        <Styles.Container>
          <Flex alignItems="center" justifyContent="flex-end" gap={1}>
            <Button disabled={isLoading} type="button" variant="cancel" onClick={props?.onClose}>
              {tPage('create.cancelButton')}
            </Button>
            <Button
              icon={{
                name: 'download',
              }}
              isLoading={isLoading}
              type="submit"
            >
              {tPage('create.saveButton')}
            </Button>
          </Flex>
        </Styles.Container>
      </Unform>
    </RootModal>
  );
}

export const CreateQRCode = memo(BaseCreateQRCode);

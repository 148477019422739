import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Navbar = styled.nav`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.foreground};
  order: 1;
  position: relative;
  object-fit: cover;
`;

export const Main = styled.main`
  width: 100%;
  flex: 1;
  order: 2;
`;

export const divBotao = styled.div`
  position: absolute;
  top: 22px;
  right: 1.5rem;
`;

export const botaoFaq = styled.button`
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  color: #0b91f5;
  text-decoration: none;

  &:hover {
    color: #07233c;
    text-decoration: underline;
  }
`;

import styled from 'styled-components';

export const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  padding: 0 2rem;
`;

export const Header = styled.nav`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.foreground};
  padding: 1rem 2.5rem;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  margin-right: auto;
  margin-left: 2rem;
  align-items: flex-start;
  gap: 0.5rem;

  > p {
    font-size: ${({ theme }) => theme.fonts.sizes.sm};
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }

  > span {
    font-size: ${({ theme }) => theme.fonts.sizes.xs};
    border-radius: 999px;
    background-color: ${({ theme }) => theme.colors.background600};
    padding: 0.25rem 0.625rem;
    display: flex;
  }
`;

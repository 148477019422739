import React, { ComponentProps, memo, useEffect, useRef } from 'react';
import { ColorPicker } from 'components';
import { useField } from '@unform/core';
import { ColorPickerRef } from '../../ColorPicker';
import { useTranslation } from 'react-i18next';

interface IProps extends ComponentProps<typeof ColorPicker> {
  name: string;
}

const UnColorPickerComponent: React.FC<IProps> = ({ name, helperText, onChange, ...props }) => {
  const [tErros] = useTranslation('formErrors');
  const { registerField, fieldName, defaultValue, clearError, error } = useField(name);

  const pickerRef = useRef<ColorPickerRef>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: pickerRef,
      getValue: ref => ref.current.color,
      setValue: (ref, value) => ref.current.setColor(value),
    });
  }, [fieldName, registerField]);

  return (
    <ColorPicker
      {...props}
      ref={pickerRef}
      error={!!error}
      helperText={error ? tErros(error) : helperText}
      defaultValue={defaultValue}
      onChange={color => {
        error && clearError();

        onChange?.(color);
      }}
    />
  );
};

export const UnColorPicker = memo(UnColorPickerComponent);

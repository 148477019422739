import { routePaths } from 'constants/routes';
import {
  CreateAcquire,
  CreateGoal,
  CreateInventory,
  CreateOperation,
  CreatePDVComponent,
  CreatePriceList,
  CreateProduct,
  CreatePromotion,
  CreateTouristGuidePage,
  CreateUserPage,
  DetailAcquirer,
  DetailCashDesk,
  DetailGoal,
  DetailOperation,
  DetailPDV,
  DetailProduct,
  DetailPromotion,
  EditCashdeskPayment,
  ForgotPassword,
  Gallery,
  Galery,
  Home,
  InventoryDetails,
  ListAcquire,
  ListCashDesk,
  ListGoal,
  ListingPromotion,
  ListingQRCode,
  ListInventory,
  ListOperation,
  ListPdv,
  ListPriceList,
  ListProduct,
  ListTouristGuidePage,
  ListUser,
  LoadQrCode,
  Login,
  PAX,
  RecoveryPassword,
  ReportPage,
  ClosingPage,
  TouristGuideDetailsPage,
  UserDetails,
  Recover,
  Faq,
  DashboardPage,
} from 'pages';
import { FunctionComponent } from 'react';
import { PermissionKey } from 'types/auth';

export interface IRoute {
  path: string;
  component: FunctionComponent<any>;
  exact?: boolean;
  permission?: PermissionKey;
  removeLayout?: boolean;
}

const promotionRoutes: IRoute[] = [
  {
    component: ListingPromotion,
    path: routePaths.promotion.list,
    exact: true,
    permission: 'CREATE_PRICE_TABLE',
  },
  {
    component: CreatePromotion,
    path: routePaths.promotion.create,
    exact: true,
    permission: 'CREATE_PRICE_TABLE',
  },
  {
    component: DetailPromotion,
    path: routePaths.promotion.details,
    exact: true,
    permission: 'CREATE_PRICE_TABLE',
  },
];

const inventoryRoutes: IRoute[] = [
  {
    component: ListInventory,
    path: routePaths.inventory.list,
    exact: true,
    permission: 'INVENTORY_MANAGE_MANAGE_ALL',
  },
  {
    component: CreateInventory,
    path: routePaths.inventory.create,
    exact: true,
    permission: 'INVENTORY_MANAGE_MANAGE_ALL',
  },
  {
    component: InventoryDetails,
    path: routePaths.inventory.details,
    exact: true,
    permission: 'CREATE_PRICE_TABLE',
  },
];

const priceListRoutes: IRoute[] = [
  {
    component: ListPriceList,
    path: routePaths.priceList.list,
    exact: true,
    permission: 'CREATE_PRICE_TABLE',
  },
  {
    component: CreatePriceList,
    path: routePaths.priceList.create,
    exact: true,
    permission: 'CREATE_PRICE_TABLE',
  },
  {
    component: CreatePriceList,
    path: routePaths.priceList.edit,
    exact: true,
    permission: 'CREATE_PRICE_TABLE',
  },
];

const userRoutes: IRoute[] = [
  {
    component: UserDetails,
    path: routePaths.user.details,
    exact: true,
    permission: 'CREATE_USER',
  },
  {
    component: CreateUserPage,
    path: routePaths.user.create,
    exact: true,
    permission: 'CREATE_USER',
  },
  {
    component: CreateUserPage,
    path: routePaths.user.edit,
    exact: true,
    permission: 'CREATE_USER',
  },
  {
    component: ListUser,
    path: routePaths.user.list,
    exact: true,
    permission: 'CREATE_USER',
  },
];

const goalRoutes: IRoute[] = [
  {
    component: ListGoal,
    path: routePaths.goal.list,
    exact: true,
    permission: 'CREATE_GOAL',
  },
  {
    component: CreateGoal,
    path: routePaths.goal.create,
    exact: true,
    permission: 'CREATE_GOAL',
  },
  {
    component: CreateGoal,
    path: routePaths.goal.edit,
    exact: true,
    permission: 'CREATE_GOAL',
  },
  {
    component: DetailGoal,
    path: routePaths.goal.details,
    exact: true,
    permission: 'CREATE_GOAL',
  },
];

const PDVRoutes: IRoute[] = [
  {
    component: DetailPDV,
    path: routePaths.pdv.details,
    exact: true,
    permission: 'CREATE_PDV',
  },
  {
    component: ListPdv,
    path: routePaths.pdv.list,
    exact: true,
    permission: 'CREATE_PDV',
  },
  {
    component: CreatePDVComponent,
    path: routePaths.pdv.create,
    exact: true,
    permission: 'CREATE_PDV',
  },
  {
    component: CreatePDVComponent,
    path: routePaths.pdv.edit,
    exact: true,
    permission: 'CREATE_PDV',
  },
];

const cashDeskRoutes: IRoute[] = [
  {
    component: ListCashDesk,
    path: routePaths.cashDesk.list,
    exact: true,
    permission: 'CASH_DESK_MANAGE_CONSULT_CASH_DESK',
  },
  {
    component: EditCashdeskPayment,
    path: routePaths.cashDesk.edit,
    exact: true,
    permission: 'CASH_DESK_MANAGE_ALTER_PAYMENT_METHODS',
  },
  {
    component: DetailCashDesk,
    path: routePaths.cashDesk.details,
    exact: true,
    permission: 'CASH_DESK_MANAGE_CONSULT_CASH_DESK',
  },
];

const operationRoutes: IRoute[] = [
  {
    component: DetailOperation,
    path: routePaths.operation.details,
    exact: true,
    permission: 'CREATE_OPERATION',
  },
  {
    component: CreateOperation,
    path: routePaths.operation.create,
    exact: true,
    permission: 'CREATE_OPERATION',
  },
  {
    component: CreateOperation,
    path: routePaths.operation.edit,
    exact: true,
    permission: 'CREATE_OPERATION',
  },
  {
    component: ListOperation,
    path: routePaths.operation.list,
    exact: true,
    permission: 'CREATE_OPERATION',
  },
];

const productRoutes: IRoute[] = [
  {
    component: CreateProduct,
    path: routePaths.products.create,
    exact: true,
    permission: 'CREATE_PRODUCT',
  },
  {
    component: ListProduct,
    path: routePaths.products.list,
    exact: true,
    permission: 'CREATE_PRODUCT',
  },
  {
    component: DetailProduct,
    path: routePaths.products.details,
    exact: true,
    permission: 'CREATE_PRODUCT',
  },
  {
    component: CreateProduct,
    path: routePaths.products.edit,
    exact: true,
    permission: 'CREATE_PRODUCT',
  },
];

const paxRoutes: IRoute[] = [
  {
    component: PAX,
    path: routePaths.pax.index,
    exact: true,
    permission: 'CREATE_PAX',
  },
];

const qrCodeRoutes: IRoute[] = [
  {
    component: ListingQRCode,
    path: routePaths.qrCode.list,
    exact: true,
    permission: 'CREATE_QR_CODE',
  },
];

const acquireRoutes: IRoute[] = [
  {
    component: ListAcquire,
    path: routePaths.acquire.list,
    exact: true,
    permission: 'CREATE_ACQUIRE',
  },
  {
    component: CreateAcquire,
    path: routePaths.acquire.create,
    exact: true,
    permission: 'CREATE_ACQUIRE',
  },
  {
    component: DetailAcquirer,
    path: routePaths.acquire.details,
    exact: true,
    permission: 'CREATE_ACQUIRE',
  },
  {
    component: CreateAcquire,
    path: routePaths.acquire.edit,
    exact: true,
    permission: 'CREATE_ACQUIRE',
  },
];

const reportRoutes: IRoute[] = [
  {
    component: ReportPage,
    path: routePaths.report.index,
    exact: true,
    permission: 'REPORT_SALES_REPORT',
  },
];

const dashboardRoutes: IRoute[] = [
  {
    component: DashboardPage,
    path: routePaths.dashboard.index,
    exact: true,
    permission: 'ACCESS_DASHBOARD',
  },
];

const closingRoutes: IRoute[] = [
  {
    component: ClosingPage,
    path: routePaths.closing.index,
    exact: true,
    permission: 'REPORT_CLOSING',
  },
];

const touristGuideRoutes: IRoute[] = [
  {
    component: ListTouristGuidePage,
    path: routePaths.touristGuide.list,
    exact: true,
    permission: 'CREATE_TOURIST_GUIDE',
  },
  {
    component: CreateTouristGuidePage,
    path: routePaths.touristGuide.create,
    exact: true,
    permission: 'CREATE_TOURIST_GUIDE',
  },
  {
    component: TouristGuideDetailsPage,
    path: routePaths.touristGuide.details,
    exact: true,
    permission: 'CREATE_TOURIST_GUIDE',
  },
  {
    component: CreateTouristGuidePage,
    path: routePaths.touristGuide.edit,
    exact: true,
    permission: 'CREATE_TOURIST_GUIDE',
  },
];

export const adminRoutes: IRoute[] = [
  {
    component: Home,
    path: routePaths.home,
    exact: true,
  },
  ...priceListRoutes,
  ...userRoutes,
  ...PDVRoutes,
  ...operationRoutes,
  ...productRoutes,
  ...paxRoutes,
  ...inventoryRoutes,
  ...promotionRoutes,
  ...qrCodeRoutes,
  ...goalRoutes,
  ...cashDeskRoutes,
  ...acquireRoutes,
  ...reportRoutes,
  ...closingRoutes,
  ...touristGuideRoutes,
  ...dashboardRoutes,
];

export const publicRoutes: IRoute[] = [
  {
    component: Login,
    path: routePaths.login,
    exact: true,
  },
  {
    component: ForgotPassword,
    path: routePaths.forgotPassword,
    exact: true,
  },
];

export const secondaryLayoutRoutes: IRoute[] = [
  {
    component: RecoveryPassword,
    path: routePaths.recoveryPassword,
    exact: true,
  },
];

export const primaryClientRoutes: IRoute[] = [
  {
    component: LoadQrCode,
    path: routePaths.client.loadQrCode,
    exact: true,
  },
  {
    component: Gallery,
    path: routePaths.client.gallery,
    exact: true,
    removeLayout: true,
  },
  {
    component: Recover,
    path: routePaths.client.recover,
  },
  {
    component: Galery,
    path: routePaths.client.galery,
    exact: true,
    removeLayout: true,
  },
  {
    component: Faq,
    path: routePaths.client.faq,
  },
];

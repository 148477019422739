import { useState, useEffect } from 'react';

import { getAllProducts } from 'services/product';
import { GetProductAllList, GetProductAllListQuery } from 'types/product';
import useBooleanToggle from './useBooleanToggle';

interface Options {
  loadOnInit?: boolean;
  defaultValue?: GetProductAllList[];
  query?: GetProductAllListQuery;
}
function useProductOptions(options?: Options) {
  const [products, setProducts] = useState<GetProductAllList[]>(options?.defaultValue || []);
  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const handleGetAllProducts = async (query?: GetProductAllListQuery) => {
    try {
      toggleIsLoading();

      const { data } = await getAllProducts(query);

      setProducts(data);
    } finally {
      toggleIsLoading();
    }
  };

  useEffect(() => {
    options?.loadOnInit && handleGetAllProducts(options?.query);
  }, []);

  return {
    products,
    onLoadProducts: handleGetAllProducts,
    isLoading,
  };
}

export default useProductOptions;

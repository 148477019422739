import styled from 'styled-components';
import { Switch as MuiSwitch } from '@mui/material';

export const Switch = styled(MuiSwitch)``;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;

  label {
    font-size: 0.87rem;
    font-weight: 600;
  }
`;

import { Grid } from '@mui/material';
import { SubmitHandler } from '@unform/core';
import { Button, ConfirmModal, IconButton, LoadingMessage, Unform } from 'components';
import { useBooleanObj } from 'hooks/useBooleanObj';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { getUserById, postUser, putUser } from 'services/user';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { PageContent } from 'styles/PageContent';
import { CreateUser, UserDetails } from 'types/user';
import { openNotification } from 'utils/notification';
import { createUserValidationSchema } from 'yupSchemas/user';
import { PermissionPart } from '../components';
import { BankDataPart, OperationPart, UserDataPart } from './components';

interface BooleansObj {
  toggleCancelModal: boolean;
  saving: boolean;
  getDetails: boolean;
}

interface FormData extends Omit<CreateUser, 'permissions'> {
  permissions: {
    group: string;
    modules: (string | false)[];
  }[];
}

export const CreateUserPage = ({
  history,
  match,
}: RouteComponentProps<{ id: string }>): JSX.Element => {
  const [tPage] = useTranslation('pages/user');
  const { t } = useTranslation();
  const [booleansObj, setBooleanProp] = useBooleanObj<BooleansObj>({
    toggleCancelModal: false,
    saving: false,
    getDetails: true,
  });
  const [user, setUser] = useState<UserDetails>();
  const userId = useMemo(() => match.params.id, [match.params]);

  const handleSubmit = useCallback<SubmitHandler<FormData>>(async data => {
    setBooleanProp('saving', true);
    try {
      const serializedData = {
        ...data,
        isSetPassword: false,
        permissions: data.permissions
          .filter(permission => !!permission.group)
          .map(permission => ({
            ...permission,
            modules: permission.modules.filter(module => !!module),
          })),
      } as CreateUser;

      userId ? await putUser(userId, serializedData) : await postUser(serializedData);

      openNotification(tPage('create.created'));
      history.goBack();
    } finally {
      setBooleanProp('saving', false);
    }
  }, []);

  const handleToggleCancelModal = useCallback(() => {
    setBooleanProp('toggleCancelModal');
  }, []);

  const handleGetDetails = useCallback(async () => {
    setBooleanProp('getDetails', true);
    try {
      const response = await getUserById(userId);

      setUser(response.data);
    } catch (error) {
    } finally {
      setBooleanProp('getDetails', false);
    }
  }, []);

  useEffect(() => {
    userId && handleGetDetails();
  }, []);

  return (
    <PageContainer md>
      <Flex alignItems="center" gap={1}>
        <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={handleToggleCancelModal} />
        <h1>{tPage('create.title')}</h1>
      </Flex>

      <PageContent>
        {booleansObj.getDetails && userId ? (
          <LoadingMessage text={tPage('create.getDetailsLoading')} />
        ) : (
          <Unform
            onSubmit={handleSubmit}
            validationSchema={createUserValidationSchema}
            initialData={{
              ...user,
              typeId: user && { name: t(`labels:userTypes.${user?.type.name}`), id: user?.type_id },
              operationsAccessId: user?.operationsAccess,
              permissions: user?.permissions.map(permission => ({
                ...permission,
                modules: permission.modules.map(module => module.havePermission),
              })),
            }}
          >
            <Grid container columnSpacing={3}>
              <UserDataPart />

              <BankDataPart />

              <OperationPart accessAll={!!user?.operationAccessAll} />

              <PermissionPart
                notLoadPermissions={!!user}
                permissions={user?.permissions.map(permission => ({
                  ...permission,
                  checked: !!permission.modules.find(module => module.havePermission),
                  modules: permission.modules.map(module => module.name),
                }))}
              />

              <Grid item xs={12}>
                <Flex gap={1} justifyContent="flex-end">
                  <Button variant="cancel" onClick={handleToggleCancelModal}>
                    {t('button.cancel')}
                  </Button>

                  <Button type="submit" isLoading={booleansObj.saving}>
                    {t('button.save')}
                  </Button>
                </Flex>
              </Grid>
            </Grid>
          </Unform>
        )}
      </PageContent>

      <ConfirmModal
        open={booleansObj.toggleCancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={handleToggleCancelModal}
        onConfirm={() => history.goBack()}
      />
    </PageContainer>
  );
};

import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useState } from 'react';

import { ConfirmModal, IconButton } from 'components';
import { GoalProvider, Parameters, Monetize } from './components';

import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import * as Styles from './styles';

import useBooleanToggle from 'hooks/useBooleanToggle';

const STEP = {
  parameters: 'parameters',
  monetize: 'monetize',
};

export function CreateGoal({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/goal');
  const { t } = useTranslation();

  const [isCancelModal, toggleIsCancelModal] = useBooleanToggle();
  const [currentStep, setCurrentStep] = useState(STEP.parameters);

  const mapComponent = {
    [STEP.parameters]: <Parameters onFoward={() => setCurrentStep(STEP.monetize)} />,
    [STEP.monetize]: <Monetize onBackward={() => setCurrentStep(STEP.parameters)} />,
  };

  return (
    <GoalProvider>
      <PageContainer md>
        <ConfirmModal
          open={isCancelModal}
          title={t('confirmCancelOperation.title')}
          bodyText={t('confirmCancelOperation.body')}
          onClose={toggleIsCancelModal}
          onConfirm={() => history.goBack()}
        />
        <Flex column gap={1.62}>
          <Flex alignItems="center" gap={1}>
            <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={toggleIsCancelModal} />
            <h1>{tPage('create.title')}</h1>
          </Flex>
          <Styles.FormContainer>{mapComponent[currentStep]}</Styles.FormContainer>
        </Flex>
      </PageContainer>
    </GoalProvider>
  );
}

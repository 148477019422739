import { memo, SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router';

import { Autocomplete } from 'components';

import { debounce } from 'utils';

import { pushHistoryQuery } from 'utils/query';

import { getUsers as getUsersApi } from 'services/user';

interface DefaultValue {
  name: string;
  id: any;
}

interface User {
  name: string;
  id: number | string;
}

interface UserFilterProps {
  defaultValue?: DefaultValue;
  onChange?: (operation: User) => void;
  placeholder?: string;
}

const queryKey = 'user_id';
const queryName = 'user_name';

function BaseUserFilter({ defaultValue, placeholder, ...props }: UserFilterProps) {
  const history = useHistory();

  const [users, setUsers] = useState<User[]>([]);

  const handleSelect = (event: SyntheticEvent, user?: User) => {
    if (!user) return;

    props?.onChange?.({
      id: user?.id,
      name: user?.name,
    });

    const query = {
      [queryKey]: user?.id,
      [queryName]: user?.name,
    };

    !props?.onChange && pushHistoryQuery(query, history);
  };

  const getUsers = async (event: SyntheticEvent, value: string) => {
    try {
      if (value.length === 0) pushHistoryQuery({ [queryKey]: '' }, history);

      const { data } = await getUsersApi({ search: value });

      setUsers(
        data.data.map(value => ({
          name: value.fullName,
          id: value.id,
        })),
      );
    } catch (err) {}
  };

  return (
    <Autocomplete
      placeholder={placeholder}
      optionLabel="name"
      optionValue="id"
      options={users}
      defaultValue={defaultValue}
      onInputChange={debounce(getUsers)}
      onChange={handleSelect}
      contained
    />
  );
}

export const UserFilter = memo(BaseUserFilter);

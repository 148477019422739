import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  gap: 2.3rem;
  flex: 1;
`;

export const Label = styled.span`
  height: 32px;
  flex: 1;
  display: flex;
  padding: 0.85rem;
  align-items: center;
  font-size: 0.85rem;
`;

export const Input = styled.input`
  background: none;
  font-size: 0.85rem;
  height: 32px;
  padding: 0.85rem;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: none;
  outline: none;
  flex: 1;
  color: ${({ theme }) => theme.colors.text};

  &:disabled {
    color: ${({ theme }) => theme.colors.text600};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.background};
  }
`;

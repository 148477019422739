import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { PaginatedTable, Button, Tag, Autocomplete, StatusFilter, SearchFilter } from 'components';
import { Menu } from '../components/Menu';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';

import { getUsers as getUsersApi, getUserTypes as getUserTypespi } from 'services/user';

import { queryToObj, pushHistoryQuery } from 'utils/query';

import { Meta } from 'types/api';

import useBooleanToggle from 'hooks/useBooleanToggle';
import { ApiQueryUser, GetUserData, UserType } from 'types/user';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListUser({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/listing');
  const { t } = useTranslation();

  const [users, setUsers] = useState<GetUserData[]>([]);
  const [userTypes, setUserTypes] = useState<UserType[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const handleChangeTypeFilter = (type: { value: string }) => {
    if (!type) return;

    const query = { typeId: type.value };
    pushHistoryQuery(query, history);
  };

  const getUsers = async (query?: ApiQueryUser) => {
    try {
      toggleIsLoading();
      const { data } = await getUsersApi(query);

      setUsers(data.data);
      setMeta(data.meta);
    } catch (error) {
    } finally {
      toggleIsLoading();
    }
  };

  const getUserTypes = async () => {
    const { data } = await getUserTypespi();
    setUserTypes(data);
  };

  const columns = [
    {
      field: 'fullName',
      orderable: true,
      headerName: tPage('user.table.name'),
    },
    {
      field: 'phone',
      headerName: tPage('user.table.phone'),
    },
    {
      field: 'type',
      headerName: tPage('user.table.type'),
    },
    {
      field: 'email',
      headerName: tPage('user.table.email'),
    },
    {
      field: 'status',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const rows = useMemo(
    () =>
      users.map(user => ({
        id: user.id,
        phone: user.phone,
        fullName: user?.fullName,
        type: t(`labels:userTypes.${user.type.name}`),
        email: user.email,
        status: (
          <Tag
            label={user?.status === 0 ? 'Inativo' : 'Ativo'}
            variante={user?.status === 0 ? 'inactive' : 'success'}
          />
        ),
        menu: (
          <Menu
            id={user?.id}
            status={user?.status}
            onDelete={() => getUsers(historyQuery)}
            onEdit={() => getUsers(historyQuery)}
            history={history}
          />
        ),
      })),
    [users],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as ApiQueryUser;

      getUsers(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getUsers(historyQuery);
    getUserTypes();
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <h1>{tPage('user.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <SearchFilter
                defaultValue={(queryToObj(history.location.search)?.search ?? '') as any}
              />
              <DefaultStyles.SelectContainer>
                <Autocomplete
                  contained
                  onInputChange={(event, value) =>
                    value.length === 0 && pushHistoryQuery({ typeId: '' }, history)
                  }
                  defaultValue={
                    historyQuery.typeId && {
                      label: historyQuery.typeId,
                    }
                  }
                  placeholder="pages/listing:user.filters.type"
                  options={userTypes.map(type => ({
                    value: type.id,
                    label: t(`labels:userTypes.${type.name}`),
                  }))}
                  onChange={(event, value) => handleChangeTypeFilter(value)}
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <StatusFilter defaultValue={historyQuery?.status} />
              </DefaultStyles.SelectContainer>
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => history.push(routePaths.user.create)}
            >
              Cadastrar
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.user.details, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

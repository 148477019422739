import api from 'services/apis/api';

import {
  CreateGoalRequest,
  GetGoalById,
  GoalQuery,
  SubTypeGoal,
  ValidateOperationGoalPayload,
  ValidateUserGoalPayload,
} from 'types/goal';
import { objToQuery } from 'utils/query';

export async function postGoal(payload: CreateGoalRequest) {
  return await api.post('/goal', payload);
}

export async function putGoal(id: string | number, payload: CreateGoalRequest) {
  return await api.put(`/goal/${id}`, payload);
}

export async function validateOperationGoal(payload: ValidateOperationGoalPayload) {
  return await api.post('/goal/validate-operation', payload);
}

export async function validateUserGoal(payload: ValidateUserGoalPayload) {
  return await api.post('/goal/validate-user', payload);
}

export async function getSubTypesGoal() {
  return await api.get<SubTypeGoal[]>('/goal/subtypes-goal');
}

export async function getGoal(query?: GoalQuery) {
  return await api.get(`/goal${query ? `?${objToQuery(query)}` : ''}`);
}

export const patchStatusGoal = async (id: number | string) =>
  await api.patch(`/goal/${id}/update-status`);

export async function getGoalById(id: string | number) {
  return await api.get<GetGoalById>(`/goal/${id}`);
}

import { memo, ReactNode } from 'react';

import { IconButton, Dialog, DialogProps } from '@mui/material';

import * as Styles from './styles';
import { Icon } from 'components';

interface IProps extends DialogProps {
  open: boolean;
  onClose(nextOpen: false): void;
  title: string;
  children: ReactNode;
}

const ModalComponent = ({ open, onClose, title, children, ...props }: IProps): JSX.Element => {
  return (
    <Dialog {...props} open={!!open} onClose={() => onClose(false)}>
      <Styles.Container>
        <Styles.Header>
          <h3>{title}</h3>

          <IconButton onClick={() => onClose(false)}>
            <Icon name="close" size={1.5} />
          </IconButton>
        </Styles.Header>

        <Styles.Body>{children}</Styles.Body>
      </Styles.Container>
    </Dialog>
  );
};

export const Modal = memo(ModalComponent);

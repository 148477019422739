import { memo } from 'react';
import { format } from 'date-fns';

import { Tag } from 'components';

import * as DefaultStyles from 'styles/Details';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { ListOperation } from 'types/operation';

import { cepMask, cnpjMask } from 'utils/masks';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface InfoProps {
  id: string;
  operation: Partial<ListOperation>;
  file?: string;
}

function BaseOperationInfo({ file, operation }: InfoProps) {
  const [tPage] = useTranslation('pages/operations');

  const { colors } = useTheme();

  return (
    <DefaultStyles.Card>
      <DefaultStyles.Title>{operation?.operation?.name}</DefaultStyles.Title>
      <Flex column gap={3.56}>
        <Flex flex={1} gap={2} wrap>
          <Flex column gap={1} flex={1}>
            <DefaultStyles.Value>{operation?.operation?.corporate_name}</DefaultStyles.Value>
            <DefaultStyles.Label>
              {operation?.operation?.cnpj && cnpjMask(operation?.operation?.cnpj)}
            </DefaultStyles.Label>
          </Flex>
          <Flex column gap={1}>
            <DefaultStyles.Label>{tPage('details.inventory')}</DefaultStyles.Label>
            <DefaultStyles.Value>
              {tPage(`details.inventoryLabels.${operation.operation?.isInventory ? 'yes' : 'no'}`)}
            </DefaultStyles.Value>
          </Flex>
          <Flex column gap={1}>
            <DefaultStyles.Label>{tPage('log.operation.abbreviation')}</DefaultStyles.Label>
            <DefaultStyles.Value>{operation.operation?.abbreviation}</DefaultStyles.Value>
          </Flex>
          <Flex column gap={1}>
            <DefaultStyles.Label>{tPage('details.createdAt')}</DefaultStyles.Label>
            <DefaultStyles.Value>
              {operation?.operation?.created_at &&
                format(new Date(operation?.operation?.created_at), 'dd/MM/yyyy')}
            </DefaultStyles.Value>
          </Flex>
          <Flex column gap={1}>
            <DefaultStyles.Label>{tPage('details.status')}</DefaultStyles.Label>
            <Tag
              label={operation?.operation?.status === 1 ? 'Ativo' : 'Inativo'}
              variante={operation?.operation?.status === 1 ? 'success' : 'inactive'}
            />
          </Flex>
        </Flex>
        <Flex column>
          <DefaultStyles.SectionTitle>{tPage('details.address')}</DefaultStyles.SectionTitle>
          <Flex flex={1} gap={4} justifyContent="space-between" wrap>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.cep')}</DefaultStyles.Label>
              <DefaultStyles.Value>{cepMask(operation?.address?.cep || '')}</DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.street')}</DefaultStyles.Label>
              <DefaultStyles.Value>{operation?.address?.address}</DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.number')}</DefaultStyles.Label>
              <DefaultStyles.Value>{operation?.address?.number}</DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.complement')}</DefaultStyles.Label>
              <DefaultStyles.Value>{operation?.address?.complements}</DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.district')}</DefaultStyles.Label>
              <DefaultStyles.Value>{operation?.address?.district}</DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.city')}</DefaultStyles.Label>
              <DefaultStyles.Value>{operation?.address?.city}</DefaultStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('details.state')}</DefaultStyles.Label>
              <DefaultStyles.Value>{operation?.address?.uf}</DefaultStyles.Value>
            </Flex>
          </Flex>
        </Flex>
        <Flex column>
          {!operation.operation?.isInventory && (
            <DefaultStyles.SectionTitle>{tPage('details.partner')}</DefaultStyles.SectionTitle>
          )}

          <Flex flex={1} gap={4} justifyContent="space-between" wrap>
            {!operation.operation?.isInventory && (
              <>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('details.partnerName')}</DefaultStyles.Label>
                  <DefaultStyles.Value>{operation?.partner?.name}</DefaultStyles.Value>
                </Flex>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('details.phone')}</DefaultStyles.Label>
                  <DefaultStyles.Value>{operation?.partner?.phone}</DefaultStyles.Value>
                </Flex>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('details.email')}</DefaultStyles.Label>
                  <DefaultStyles.Value>{operation?.partner?.email}</DefaultStyles.Value>
                </Flex>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('details.commission')}</DefaultStyles.Label>
                  <DefaultStyles.Value>{operation?.partner?.profit}</DefaultStyles.Value>
                </Flex>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('details.bank')}</DefaultStyles.Label>
                  <DefaultStyles.Value>{operation?.partner?.bank}</DefaultStyles.Value>
                </Flex>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('details.agency')}</DefaultStyles.Label>
                  <DefaultStyles.Value>{operation?.partner?.agency}</DefaultStyles.Value>
                </Flex>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('details.currentAccount')}</DefaultStyles.Label>
                  <DefaultStyles.Value>{operation?.partner?.current_account}</DefaultStyles.Value>
                </Flex>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('details.templateColor')}</DefaultStyles.Label>
                  <Flex gap={0.5}>
                    <Styles.Color color={operation?.operation?.color || colors.primary} />
                    <DefaultStyles.Value>
                      {operation?.operation?.color || colors.primary}
                    </DefaultStyles.Value>
                  </Flex>
                </Flex>
              </>
            )}
            {file && (
              <Flex column gap={1}>
                <DefaultStyles.Label>{tPage('details.backgroundImage')}</DefaultStyles.Label>
                <Styles.Image src={file} alt="" />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </DefaultStyles.Card>
  );
}

export const OperationInfo = memo(BaseOperationInfo);

import { useField } from '@unform/core';
import { DatePicker } from 'components';
import { ComponentProps, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type DatePickerProps = Omit<ComponentProps<typeof DatePicker>, 'value' | 'error' | 'onChange'> & {
  name: string;
  onChange?: (date: unknown, keyboardInputValue?: string) => void;
  onGetValue?: (date: Date) => any;
};

function BaseUnDatePicker({ onChange, helperText, onGetValue, ...props }: DatePickerProps) {
  const { t } = useTranslation('formErrors');

  const refInput = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(props.name);

  const handleChange = (date?: unknown, keyboardInputValue?: string) => {
    onGetValue?.(date as Date);
    onChange?.(date, keyboardInputValue);
  };

  useEffect(() => {
    registerField({
      ref: refInput,
      getValue: ref => onGetValue?.(ref?.current?.value) || ref?.current?.value,
      name: fieldName,
    });
  }, [fieldName, registerField, refInput?.current, defaultValue, error]);

  return (
    <DatePicker
      {...props}
      defaultValue={props.defaultValue || defaultValue}
      onChange={handleChange}
      error={!!error}
      helperText={error ? t(error) : helperText}
      ref={refInput}
    />
  );
}

export const UnDatePicker = memo(BaseUnDatePicker);

import { Button, IconButton, Tabs } from 'components';
import { resolvePath, routePaths } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import * as DefaultStyles from 'styles/Details';
import { Infos } from './components';

export const UserDetails = ({
  history,
  match,
}: RouteComponentProps<{ id: string }>): JSX.Element => {
  const { t } = useTranslation('pages/details');
  const userId = match.params.id;

  return (
    <PageContainer md>
      <Flex alignItems="center" marginBottom={1} wrap gap={1} justifyContent="space-between">
        <Flex alignItems="center" gap={1}>
          <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={() => history.goBack()} />
          <h1 style={{ margin: 0 }}>{t('user.title')}</h1>
        </Flex>
        <Button onClick={() => history.push(resolvePath(routePaths.user.edit, { id: userId }))}>
          {t('pages/details:editButton')}
        </Button>
      </Flex>

      <DefaultStyles.Wrapper>
        <Tabs tabs={[{ label: t('user.detailsTab') }]}>
          <Infos userId={userId} />
        </Tabs>
      </DefaultStyles.Wrapper>
    </PageContainer>
  );
};

import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { PaginatedTable, Button, Tag, Autocomplete, StatusFilter, SearchFilter } from 'components';
import { Menu } from '../components/Menu';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';

import { getOperations as getOperationsApi } from 'services/operation';
import { getOperationDistricts } from 'services/districts';

import { queryToObj, pushHistoryQuery } from 'utils/query';
import { cnpjMask } from 'utils/masks';

import { ApiQueries, Meta } from 'types/api';
import { GeneralDistricts } from 'types/districts';

import useBooleanToggle from 'hooks/useBooleanToggle';

interface Operation {
  name?: string;
  id?: number;
  cnpj?: string;
  corporate_name?: string;
  created_at?: string;
  status?: 0 | 1;
}

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListOperation({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/listing');
  const { t } = useTranslation();

  const [operations, setOperations] = useState<Operation[]>([]);
  const [districts, setDistricts] = useState<GeneralDistricts>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getOperations = async (query?: ApiQueries) => {
    try {
      toggleIsLoading();

      const { data } = await getOperationsApi(query);

      setOperations([...data.data]);
      setMeta({
        ...baseMeta,
        ...data.meta,
      });
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const getDistricts = async () => {
    const { data } = await getOperationDistricts();
    setDistricts(data);
  };

  const handleChangeDistrict = (district: { label: string }) => {
    if (!district) return;

    const query = { district: district.label };
    pushHistoryQuery(query, history);
  };

  const columns = [
    {
      field: 'id',
      headerName: tPage('operations.table.id'),
    },
    {
      field: 'cnpj',
      headerName: tPage('operations.table.cnpj'),
    },
    {
      field: 'name',
      orderable: true,
      headerName: tPage('operations.table.operationName'),
    },
    {
      field: 'corporate_name',
      headerName: tPage('operations.table.corporationName'),
      orderable: true,
    },
    {
      field: 'created_at',
      orderable: true,
      headerName: tPage('operations.table.createdAt'),
    },
    {
      field: 'tag',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const rows = useMemo(
    () =>
      operations.map(operation => ({
        id: operation?.id,
        cnpj: operation?.cnpj ? cnpjMask(operation?.cnpj) : '',
        corporate_name: operation?.corporate_name,
        name: operation?.name,
        created_at: t('date', { date: new Date(operation?.created_at || '') }),
        menu: (
          <Menu
            id={operation?.id}
            status={operation?.status}
            onDelete={() => getOperations(historyQuery)}
            onEdit={() => getOperations(historyQuery)}
            history={history}
          />
        ),
        tag: (
          <Tag
            label={operation?.status === 0 ? 'Inativo' : 'Ativo'}
            variante={operation.status === 0 ? 'inactive' : 'success'}
          />
        ),
      })),
    [operations],
  );

  useEffect(() => {
    getDistricts();

    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as ApiQueries;

      getOperations(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getOperations(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <h1>{tPage('operations.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <SearchFilter
                defaultValue={(queryToObj(history.location.search)?.search ?? '') as any}
              />
              <DefaultStyles.SelectContainer>
                <Autocomplete
                  contained
                  onInputChange={(event, value) =>
                    value.length === 0 && pushHistoryQuery({ district: '' }, history)
                  }
                  defaultValue={
                    historyQuery.district && {
                      label: historyQuery.district,
                    }
                  }
                  placeholder="labels:input.region"
                  options={districts.map(district => ({ label: district }))}
                  onChange={(event, value) => handleChangeDistrict(value)}
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <StatusFilter defaultValue={historyQuery?.status as any} />
              </DefaultStyles.SelectContainer>
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => history.push(routePaths.operation.create)}
            >
              Cadastrar
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.operation.details, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  max-width: 30rem;
  margin: 0 auto;

  h1 {
    margin: 0;
  }
`;

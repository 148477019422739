import { PaginatedTable } from 'components';
import { LOG_PRODUCT_TYPES } from 'constants/log';
import { memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getLogPdv as getLogPdvApi } from 'services/pdv';
import { Flex } from 'styles/Flex';
import { Meta } from 'types/api';

interface Log {
  from: string;
  to: string;
  type: string;
  field: string;
}

interface LogData {
  createdAt: string;
  user: string;
  log: Log;
}

interface LogProps {
  id: string | number;
}

function BaseLogs(props: LogProps) {
  const [logs, setLogs] = useState<LogData[]>([]);
  const [meta, setMeta] = useState<Partial<Meta>>({});
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const { t } = useTranslation();

  const getLogPdv = async (id: string | number) => {
    const { data } = await getLogPdvApi(id, {
      page: currentPage,
    });

    const newLogs: LogData[] = data.data.map(log => ({
      createdAt: log.created_at,
      user: log.user.fullName,
      log: {
        field: log.field,
        from: log.original,
        to: log.new,
        type: log.operation_type,
      },
    }));

    setMeta(data.meta);
    setLogs(newLogs);
  };

  const parseLogLabel = (_log: Log) => {
    const type = {
      [LOG_PRODUCT_TYPES.CREATE]: (
        <Trans i18nKey="pages/pdv:log.messages.create" values={{ to: _log.to }} />
      ),
      [LOG_PRODUCT_TYPES.UPDATE]: (
        <Trans
          i18nKey="pages/pdv:log.messages.update"
          values={{
            to: _log.to,
            from: _log.from,
            field: t(`pages/pdv:log.${_log.field}`),
          }}
          tOptions={{
            context: _log.field === 'is_totem' && 'is-totem',
          }}
        />
      ),
      [LOG_PRODUCT_TYPES.UPDATE_STATUS]: (
        <Trans
          i18nKey="pages/pdv:log.messages.updateStatus"
          values={{ from: _log.from }}
          tOptions={{
            context: String(Number(_log.to) === 1),
          }}
        />
      ),
    };

    return type[_log.type];
  };

  const logParse = logs.map(log => ({
    label: parseLogLabel(log.log),
    user: log.user,
    createdAt: t('dateHours', { date: new Date(log.createdAt) }),
  }));

  const columns = [
    { field: 'label', headerName: t('pages/pdv:log.table.label') },
    { field: 'user', headerName: t('pages/pdv:log.table.user') },
    { field: 'createdAt', headerName: t('pages/pdv:log.table.createdAt') },
  ];

  useEffect(() => {
    getLogPdv(props.id);
  }, [currentPage]);

  return (
    <Flex column gap={2.26} alignItems="flex-start">
      <PaginatedTable
        tableVariant="secondary"
        count={totalPages}
        rows={logParse}
        columns={columns}
        onPageChange={(event, _page) => setCurrentPage(_page)}
      />
    </Flex>
  );
}

export const Logs = memo(BaseLogs);

import { breakpoints } from 'constants/layout';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Body = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

export const CardContainer = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.foreground};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;

  > h2 {
    font-size: ${({ theme }) => theme.fonts.sizes.lg};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StatusCard = styled.div`
  padding: 0.5rem 1.3em;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  max-width: 8rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  > span {
    font-size: ${({ theme }) => theme.fonts.sizes.xs};
  }

  > h3 {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    font-size: ${({ theme }) => theme.fonts.sizes.xl};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

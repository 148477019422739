import { FormHandles } from '@unform/core';
import { ConfirmModal, Unform } from 'components';
import { usePax } from 'hooks/store';
import { useToggle } from 'hooks/useToggle';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deletePaxHistoric } from 'services/pax';
import { Flex } from 'styles/Flex';
import { HistoricPax } from 'types/pax';
import { openNotification } from 'utils/notification';
import { objToQuery } from 'utils/query';
import { filterHistoricPaxValidationSchema } from 'yupSchemas/pax';

import { EditModal, HistoricTable } from './components';
import { Filter } from './components/Filter';

const HistoricComponent = (): JSX.Element => {
  const [toggles, setToggle] = useToggle<'openEditModal' | 'openDeleteModal' | 'deleting'>({
    openEditModal: false,
    openDeleteModal: false,
    deleting: false,
  });
  const [tPage] = useTranslation('pages/pax');
  const [selectedHistoric, setSelectedHistoric] = useState<HistoricPax>();
  const searchFormRef = useRef<FormHandles>(null);
  const { loadHistoricList } = usePax();
  const handleConfirmDelete = useCallback(async () => {
    if (!selectedHistoric) return;

    setToggle('deleting', true);

    try {
      await deletePaxHistoric(selectedHistoric.id);

      openNotification(tPage('historic.deletedSuccess'));
      setToggle('openDeleteModal', false);
      loadHistoricList({ onlyUpdate: true });
    } catch (error) {
    } finally {
      setToggle('deleting', false);
    }
  }, [selectedHistoric]);

  const handleSubmitSearch = useCallback(data => {
    console.log(data);

    loadHistoricList({ query: objToQuery(data) });
  }, []);

  return (
    <Flex column>
      <Unform
        ref={searchFormRef}
        onSubmit={handleSubmitSearch}
        validationSchema={filterHistoricPaxValidationSchema}
      >
        <Filter formRef={searchFormRef} />
      </Unform>

      <HistoricTable
        onClickEdit={historic => {
          setSelectedHistoric(historic);
          setToggle('openEditModal', true);
        }}
        onClickDelete={(historic: HistoricPax) => {
          setSelectedHistoric(historic);
          setToggle('openDeleteModal', true);
        }}
      />

      <EditModal
        historic={selectedHistoric}
        open={toggles.openEditModal}
        onClose={() => setToggle('openEditModal', false)}
      />

      <ConfirmModal
        open={toggles.openDeleteModal}
        onClose={() => setToggle('openDeleteModal', false)}
        title={tPage('historic.deleteModalTitle')}
        bodyText={tPage('historic.deleteModalBody')}
        onConfirm={handleConfirmDelete}
        isLoading={toggles.deleting}
      />
    </Flex>
  );
};

export const Historic = memo(HistoricComponent);

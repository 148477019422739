import { ComponentProps, memo } from 'react';

import { Popover, ConfirmModal } from 'components';
import useBooleanToggle from 'hooks/useBooleanToggle';

import { deleteOperation as deleteOperationApi, patchStatus } from 'services/operation';
import { RouteComponentProps } from 'react-router';
import { resolvePath, routePaths } from 'constants/routes';

type PopoverProps = ComponentProps<typeof Popover>;

interface MenuProps {
  id?: string | number;
  status?: number;
  onDelete?: () => void;
  onEdit?: () => void;
  history?: RouteComponentProps['history'];
}

function BaseMenu(props: MenuProps) {
  const [isVisibleDeleteModal, toggleDeleteModal] = useBooleanToggle(false);
  const [isVisibleEditModal, toggleEditModal] = useBooleanToggle(false);

  const handleDeleteOperation = async (id: string | number) => {
    try {
      await deleteOperationApi(id);
      props?.onDelete?.();
    } catch (err) {
    } finally {
      toggleDeleteModal();
    }
  };

  const handleToggleStatus = async (id: string | number) => {
    try {
      await patchStatus(id);
      props?.onEdit?.();
    } catch (err) {
    } finally {
      toggleEditModal();
    }
  };

  const options: PopoverProps['options'] = [
    {
      label: 'commons:popoverList.edit',
      onClick: () => props.history?.push(resolvePath(routePaths.operation.edit, { id: props.id })),
    },
    {
      label: props.status !== 1 ? 'commons:popoverList.active' : 'commons:popoverList.inactive',
      onClick: toggleEditModal,
    },
    {
      label: 'commons:popoverList.delete',
      variante: 'error',
      onClick: toggleDeleteModal,
    },
  ];

  return (
    <div onClick={event => event.stopPropagation()}>
      <ConfirmModal
        open={isVisibleDeleteModal}
        title="Atenção"
        bodyText="Deseja realmente excluir esta operação?"
        onClose={toggleDeleteModal}
        onConfirm={() => props.id && handleDeleteOperation(props.id)}
      />
      <ConfirmModal
        open={isVisibleEditModal}
        title="Atenção"
        bodyText="Deseja realmente alterar o status dessa operação?"
        onClose={toggleEditModal}
        onConfirm={() => props.id && handleToggleStatus(props.id)}
      />
      <Popover options={options} id="simple" />
    </div>
  );
}

export const Menu = memo(BaseMenu);

import { isAfter, isBefore } from 'date-fns';
import * as Yup from 'yup';
import { invalidDateBiggerThan } from './utils/messages';

export const throwPaxValidationSchema = Yup.object().shape({
  paxList: Yup.array().of(
    Yup.object({
      visitors: Yup.mixed(),
    }),
  ),
});

export const filterHistoricPaxValidationSchema = Yup.object().shape({
  finalDate: Yup.mixed().test('initialDate-test', invalidDateBiggerThan, (value, params) => {
    const initialDate = params.parent.initialDate;

    if (initialDate && value) {
      return isAfter(value, initialDate);
    }
    return true;
  }),
  initialDate: Yup.mixed().test('finalDate-test', invalidDateBiggerThan, (value, params) => {
    const finalDate = params.parent.finalDate;

    if (finalDate && value) {
      return isBefore(value, finalDate);
    }
    return true;
  }),
});

import { Grid } from '@mui/material';
import { memo } from 'react';
import { GetQRCodeDigitalPhotos } from 'types/QRCode';
import { Photo } from './components';
import * as Styles from './styles';

interface Props {
  photos: GetQRCodeDigitalPhotos[];
  qrCode: string;
}

const PhotosBase = ({ photos, qrCode }: Props): JSX.Element => {
  return (
    <Styles.Container>
      <Grid container spacing="1rem">
        {photos.map(photo => (
          <Photo photo={photo} qrCode={qrCode} />
        ))}
      </Grid>
    </Styles.Container>
  );
};

export const Photos = memo(PhotosBase);

import { IAplicationState } from 'store';
import { GetState, SetState } from 'zustand';
import { IModule } from './types';

export const getModule = (
  module: IModule<any>,
  storeName: keyof IAplicationState,
  set: SetState<IAplicationState>,
  get: GetState<IAplicationState>,
) => {
  const newSet = (partial: Object | Function) => {
    set(prev => {
      return {
        ...prev,
        [storeName]: {
          ...prev[storeName],
          state: {
            ...prev[storeName].state,
            ...(typeof partial === 'function' ? partial(prev[storeName].state) : partial),
          },
        },
      };
    });
  };

  const newGet = () => get()[storeName];

  return module(newSet, newGet);
};

import { Grid } from '@mui/material';
import { FormHandles, Scope } from '@unform/core';
import { UnAutocomplete, UnInputFIeld, UnMaskInput } from 'components';
import { states } from 'constants/states';
import React, { memo, RefObject, useCallback } from 'react';
import { getAddressByCep } from 'services/address';
import { debounce } from 'utils/debounce';

interface IProps {
  formRef: RefObject<FormHandles>;
}

const AddressSectionComponent: React.FC<IProps> = ({ formRef }) => {
  const handleChangeCep = useCallback(async () => {
    try {
      const cep = formRef.current?.getFieldValue('address.cep') as string;

      if (cep.length === 8) {
        const response = await getAddressByCep(cep);

        if (response) {
          formRef.current?.setFieldValue('address.address', response.street);
          formRef.current?.setFieldValue('address.complements', response.complement);
          formRef.current?.setFieldValue('address.district', response.district);
          formRef.current?.setFieldValue('address.city', response.city);
          formRef.current?.setFieldValue('address.uf', response.state);
        }
      }
    } catch (error) {}
  }, [formRef]);

  return (
    <Scope path="address">
      <Grid item xs={12} sm={4} md={4} lg={2}>
        <UnMaskInput
          name="cep"
          mask="cep"
          label="labels:input.cep"
          cleanValue
          onChange={debounce(handleChangeCep)}
        />
      </Grid>

      <Grid item xs={12} sm={8} md={8} lg={6}>
        <UnInputFIeld name="address" label="labels:input.street" />
      </Grid>

      <Grid item xs={12} sm={4} md={2} lg={2}>
        <UnMaskInput name="number" mask="freeNumber" label="labels:input.number" />
      </Grid>

      <Grid item xs={12} sm={8} md={5} lg={2}>
        <UnInputFIeld name="complements" label="labels:input.complement" />
      </Grid>

      <Grid item xs={12} sm={4} md={5} lg={3}>
        <UnInputFIeld name="district" label="labels:input.district" />
      </Grid>

      <Grid item xs={12} sm={5} md={6} lg={7}>
        <UnInputFIeld name="city" label="labels:input.city" />
      </Grid>

      <Grid item xs={12} sm={3} md={6} lg={2}>
        <UnAutocomplete name="uf" label="labels:input.state" options={states} />
      </Grid>
    </Scope>
  );
};

export const AddressSection = memo(AddressSectionComponent);

import { Paginated } from 'types/api';
import {
  ApiQueriesInventory,
  GetLogInventoryEntryResponse,
  InventoryDetails,
  InventoryList,
  LogQueries,
  PostCreateInventoryJson,
  PostValidateProdutoToEntry,
} from 'types/inventory';

import { objToQuery } from 'utils/query';

import api from 'services/apis/api';

export const postValidateProductToEntry = (
  destinationOperationId: string | number,
  data: PostValidateProdutoToEntry,
) => api.post(`/operation/${destinationOperationId}/inventory/entry/product/validate`, data);

export const getListInventory = (query?: ApiQueriesInventory) =>
  api.get<Paginated<InventoryList>>(
    `/operation/inventory/entry${query ? `?${objToQuery(query)}` : ''}`,
  );

export const patchCancelInventory = (inventoryId: string | number) =>
  api.patch(`/operation/inventory/entry/${inventoryId}/cancel`);

export const patchCompleteInventory = (inventoryId: string | number) =>
  api.patch(`/operation/inventory/entry/${inventoryId}/complete`);

export const getInventoryDetails = (inventoryId: string | number) =>
  api.get<InventoryDetails>(`/operation/inventory/entry/${inventoryId}`);

export const postInventory = async (
  inventory: PostCreateInventoryJson,
  files?: { name: string; file: File }[],
) => {
  const formData = new FormData();

  formData.append('entryData', JSON.stringify(inventory));

  files?.forEach?.(async file => {
    formData.append('file', file.file);
  });

  return api.post('/operation/inventory/entry', formData);
};

export async function getLogInventoryEntry(id: number | string, query?: LogQueries) {
  return await api.get<GetLogInventoryEntryResponse>(
    `/log/entry/${id}${query ? `?${objToQuery(query)}` : ''}`,
  );
}

import styled from 'styled-components';

export const Title = styled.strong`
  size: 2rem;
  display: block;
  margin-bottom: 1.2rem;
  font-weight: bold;
`;

export const InputField = styled.div<{ size: number }>`
  min-width: ${({ size }) => size}rem;
`;

export const CASH_COLUMNS_LISTING = {
  user: 'user',
  operation: 'operation',
  opened: 'opened_at',
  closed: 'closed_at',
  hasDiffLastCashDeskFund: 'hasDiffLastCashDeskFund',
};

export const CASH_DESK_STATUS = {
  opened: 'opened',
  closed: 'closed',
  checked: 'checked',
};

export const CASH_DESK_STATUS_VARIANT_MAP = {
  [CASH_DESK_STATUS.opened]: 'success',
  [CASH_DESK_STATUS.closed]: 'error',
  [CASH_DESK_STATUS.checked]: 'blue',
  default: 'success',
};

export const CASH_DESK_GROUP_TYPE = {
  pix: 'pix',
  money: 'money',
  debt: 'debt',
  bleed: 'bleed',
  credit: 'credit',
  debt_pos: 'debt_pos',
  credit_pos: 'credit_pos',
};

export const CASH_DESK_PAYMENT_METHOD = {
  DEBIT: 'DEBIT',
  DEBIT_POS: 'DEBIT_POS',
  CREDIT: 'CREDIT',
  CREDIT_POS: 'CREDIT_POS',
  PIX: 'PIX',
  MONEY: 'MONEY',
};

export const isNotIntegrationPayment = [
  CASH_DESK_PAYMENT_METHOD.MONEY,
  CASH_DESK_PAYMENT_METHOD.PIX,
];

export const isInstallment = [
  CASH_DESK_PAYMENT_METHOD.CREDIT,
  CASH_DESK_PAYMENT_METHOD.CREDIT_POS,
] as const;

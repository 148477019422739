import { SubmitHandler } from '@unform/core';
import { Button, UnCheckbox, Unform, UnInputFIeld, UnPasswordInput } from 'components';
import { routePaths } from 'constants/routes';
import { expiresAtKey, tokenKey, tokenTypeKey, userKey } from 'constants/storage';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { postLogin } from 'services/auth';
import { Flex } from 'styles/Flex';
import { Login as ILogin } from 'types/auth';
import { userIsLogged } from 'utils/authorization';
import { openNotification } from 'utils/notification';
import { setItemStorage } from 'utils/storage';
import { loginValidationSchema } from 'yupSchemas/auth';
import { ReactComponent as Logo } from '../../../assets/vyoo-logo-novo.svg';
import * as Styles from './styles';


export const Login = ({ history }: RouteComponentProps): JSX.Element => {
  const [tPage] = useTranslation('pages/login');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = useCallback<SubmitHandler<ILogin>>(async data => {
    setIsLoading(true);

    try {
      const {
        data: { token, type, expires_at, user },
      } = await postLogin(data);

      setItemStorage(tokenKey, token, !data.remember_me);
      setItemStorage(tokenTypeKey, type, !data.remember_me);
      setItemStorage(expiresAtKey, expires_at, !data.remember_me);
      setItemStorage(userKey, user, !data.remember_me);

      history.push(routePaths.home);
    } catch(err: any) {
      if (err.data === 'Invalid credentials') openNotification(tPage('genericError'), 'error')
    } finally {
      setIsLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    if (userIsLogged()) history.push(routePaths.home);
  }, []);

  return (
    <Styles.Container>
      <Styles.Body>
        <Logo />

        <Unform onSubmit={handleSubmit} validationSchema={loginValidationSchema}>
          <Flex column gap={1}>
            <UnInputFIeld name="email" label={'labels:input.email'} />

            <UnPasswordInput name="password" label={'labels:input.password'} />

            <UnCheckbox disableRipple name="remember_me" label="labels:input.rememberMe" />

            <Flex column fullWidth marginTop={1}>
              <Button fullWidth type="submit" isLoading={isLoading}>
                {tPage('enterButton')}
              </Button>
            </Flex>
          </Flex>
        </Unform>
        <Styles.ForgotContainer>
          {tPage('forgotPassword')}
          <Link to={routePaths.forgotPassword}>{`${tPage('clickHere')}`}</Link>
        </Styles.ForgotContainer>
      </Styles.Body>

      <Styles.Version>{process.env.REACT_APP_VERSION}</Styles.Version>
    </Styles.Container>
  );
};

import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { PaginatedTable, Button, SearchFilter } from 'components';
import { ListModal, Managment } from './components';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';

import { pushHistoryQuery, queryToObj } from 'utils/query';

import { Meta } from 'types/api';

import useBooleanToggle from 'hooks/useBooleanToggle';
import { getAcquirer as getAcquireApi } from 'services/acquire';
import { AcquireQueries, GetAcquire } from 'types/acquire';

const baseMeta = {
  total: 0,
  last_page: 0,
};

const FIELDS = {
  id: 'id',
  acquire: 'acquire',
  flag: 'flag',
  operation: 'operation',
};

export function ListAcquire({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/acquire');

  const [details, setDetails] = useState<GetAcquire[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [isManagmentOpen, toggleIsManagmentOpem] = useBooleanToggle(false);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getAcquirer = async (query?: AcquireQueries) => {
    toggleIsLoading();

    try {
      const { data } = await getAcquireApi(query);
      setDetails(data.data);
      setMeta(data.meta);
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const columns = [
    {
      field: FIELDS.acquire,
      headerName: tPage(`listing.table.${FIELDS.acquire}`),
    },
    {
      field: FIELDS.flag,
      headerName: tPage(`listing.table.${FIELDS.flag}`),
    },
    {
      field: FIELDS.operation,
      headerName: tPage(`listing.table.${FIELDS.operation}`),
    },
  ];

  const rows = useMemo(
    () =>
      details.map(detail => ({
        [FIELDS.id]: detail?.id,
        [FIELDS.acquire]: detail?.name,
        [FIELDS.flag]: (
          <ListModal
            title={tPage(`listing.table.${FIELDS.flag}`)}
            data={detail.card_flags.map(value => value.name)}
          >
            {detail?.card_flags.length}
          </ListModal>
        ),
        [FIELDS.operation]: (
          <ListModal
            title={tPage(`listing.table.${FIELDS.operation}`)}
            data={detail.operations.map(value => value.name)}
          >
            {detail?.operations.length}
          </ListModal>
        ),
      })),
    [details],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as AcquireQueries;

      getAcquirer(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getAcquirer(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Managment open={isManagmentOpen} onClose={toggleIsManagmentOpem} />
      <Flex column gap={1.5}>
        <h1>{tPage('listing.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <SearchFilter
                defaultValue={(queryToObj(history.location.search)?.search ?? '') as any}
              />
            </Styles.InputFields>
            <Flex gap={2}>
              <Button
                icon={{
                  name: 'add',
                }}
                onClick={() => history.push(routePaths.acquire.create)}
              >
                {tPage('listing.actionButton')}
              </Button>
              <Button variant="base" onClick={toggleIsManagmentOpem}>
                {tPage('listing.managmentFlagButton')}
              </Button>
            </Flex>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.acquire.details, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

import axios, { AxiosError, AxiosResponse } from 'axios';
import { routePaths } from 'constants/routes';
import i18n from 'i18n';
import { endpointLogout } from 'services/auth';
import { getUserToken, geUserTokenType, logout } from 'utils/auth';
import { userIsLogged } from 'utils/authorization';
import { history } from 'utils/history';
import { openNotification } from 'utils/notification';

export const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3333/';

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(config => {
  if (userIsLogged()) {
    config.headers = config.headers ?? {};

    config.headers['Authorization'] = `${geUserTokenType()} ${getUserToken()}`;
  }

  return config;
});

const getErrorMessageFromBlobResponseType = async (response: AxiosResponse<any, any>) => {
  const responseText = await response.data.text();
  const responseJson = JSON.parse(responseText);
  return responseJson.message;
};

api.interceptors.response.use(undefined, async (data: AxiosError) => {
  const { response, config } = data;

  try {
    if (response) {
      const responseType = response.config.responseType;

      const specificPagePath = '/';
      const isSpecificPage = config.url?.includes(specificPagePath);

      let errorMessageFromData = response.data.message;

      if (responseType === 'blob') {
        errorMessageFromData = await getErrorMessageFromBlobResponseType(response);
      }

      if (response.status >= 500 && !isSpecificPage) {
        openNotification(i18n.t('apiErrorMessages:genericMessage'), 'error');
      } else if (response.status === 401) {
        logout(data.config.url !== endpointLogout);
        openNotification(i18n.t('apiErrorMessages:autoLogout'), 'error', 5000, { toastId: 1 });
      } else if (response.status === 403) {
        history.push(routePaths.home);
        openNotification('apiErrorMessages:resourceNotAllowed', 'error');
      } else if (response.status >= 400) {
        if (errorMessageFromData) {
          openNotification(i18n.t(`apiErrorMessages:${errorMessageFromData}`), 'error');
        }
      }
    }

    throw response;
  } catch (error) {
    throw response;
  }
});

export default api;

import { SubmitHandler } from '@unform/core';
import { Button, Unform, UnPasswordInput } from 'components';
import { routePaths } from 'constants/routes';
import { useBooleanObj } from 'hooks/useBooleanObj';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { resetPassword } from 'services/auth';
import { Flex } from 'styles/Flex';
import { openNotification } from 'utils/notification';
import { queryToObj } from 'utils/query';
import { definePasswordValidationSchema } from 'yupSchemas/auth';
import * as Styles from './styles';

interface FormData {
  password: string;
  confirmPassword: string;
}

interface BooleansObj {
  sending: boolean;
}

export const RecoveryPassword = ({ location, history }: RouteComponentProps): JSX.Element => {
  const [tPage] = useTranslation('pages/recoveryPassword');
  const [booleansObj, setBooleanProp] = useBooleanObj<BooleansObj>({ sending: false });
  const token = useMemo(
    () => queryToObj(location.search)?.token as string | undefined,
    [location.search],
  );

  const handleSubmit = useCallback<SubmitHandler<FormData>>(async data => {
    setBooleanProp('sending', true);

    try {
      const sendData = {
        token: token || '',
        ...data,
      };

      await resetPassword(sendData);

      openNotification(tPage('definePassword.successMessage'));
      history.push(routePaths.login);
    } finally {
      setBooleanProp('sending', false);
    }
  }, []);

  useEffect(() => {
    if (!token) {
      history.push(routePaths.login);
    }
  }, []);

  return (
    <Styles.Container>
      <Styles.Body>
        <h1>{tPage('definePassword.title')}</h1>

        <Unform onSubmit={handleSubmit} validationSchema={definePasswordValidationSchema}>
          <Flex column>
            <UnPasswordInput name="password" label="labels:input.newPassword" />

            <UnPasswordInput name="confirmPassword" label="labels:input.confirmPassword" />

            <Button type="submit" fullWidth isLoading={booleansObj.sending}>
              {tPage('send')}
            </Button>
          </Flex>
        </Unform>
      </Styles.Body>
    </Styles.Container>
  );
};

import { LoadingMessage } from 'components';
import { qrCodeParamKey } from 'constants/client';
import { routePaths } from 'constants/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { getQRCodeDigitalPhotos } from 'services/qrcode';
import { GetQRCodeDigitalPhotos } from 'types/QRCode';
import { openNotification } from 'utils/notification';
import { queryToObj } from 'utils/query';
import { Header, Photos } from './components';
import ReactGA from 'react-ga4';
import * as Styles from './styles';

interface Props extends RouteComponentProps<{ qrCode: string }> {}

export const Gallery = ({ history, location }: Props) => {
  const { t } = useTranslation();
  const [tPage] = useTranslation('pages/gallery');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [photos, setPhotos] = useState<GetQRCodeDigitalPhotos[]>([]);
  const qrCode = useMemo<string | undefined>(
    () => queryToObj(location?.search || '')?.[qrCodeParamKey] as string | undefined,
    [location],
  );

  ReactGA.initialize('G-E2W8W5XZE6');

  const handleLoadPhotos = useCallback(async () => {
    if (!qrCode) return;

    setIsLoading(true);
    try {
      const response = await getQRCodeDigitalPhotos(qrCode);

      if (response.status === 200) {
        setPhotos(response.data);
        return;
      }

      throw new Error();
    } catch (error) {
      openNotification(t('commons:invalidQRCode'), 'error');

      history.push(routePaths.client.loadQrCode);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleLoadPhotos();
  }, []);

  if (!qrCode) {
    history.push(routePaths.client.loadQrCode);

    return null;
  }

  if (isLoading) {
    return (
      <Styles.LoadingContainer>
        <LoadingMessage text={tPage('loadingMessage')} />
      </Styles.LoadingContainer>
    );
  }

  return (
    <>
      <Header photos={photos} qrCode={qrCode} />

      <Photos photos={photos} qrCode={qrCode} />
    </>
  );
};

import { RouteComponentProps, useHistory } from 'react-router-dom';

import { parse, stringify } from 'query-string';

export const objToQuery = (obj: Record<string, any>) =>
  stringify(obj, { skipNull: true, skipEmptyString: true });

export const queryToObj = (query: string) => parse(query);

interface PushHistoryQueryConfig {
  removedProps: string[];
}

export const pushHistoryQuery = (
  query: Record<string, any>,
  history: RouteComponentProps['history'],
  configs?: PushHistoryQueryConfig,
) => {
  const historyQuery = queryToObj(history.location.search);

  if (configs && configs?.removedProps?.length > 0) {
    configs.removedProps.forEach(key => {
      historyQuery[key] = '';
    });
  }

  const newQuery = historyQuery ? objToQuery({ ...historyQuery, ...query }) : objToQuery(query);

  const pathname = history.location.pathname;
  history.push(`${pathname}?${newQuery}`);
};

export const resolveQueryStart = (query?: string) =>
  query ? (query[0] === '?' ? query : `?${query}`) : '';

export const getQueryParams = <T extends string = string>(
  keys: T[],
): Record<T, string> | undefined => {
  const history = useHistory();

  const obj = {} as Record<T, string>;
  let notHaveAnyKey = true;
  const searchObj = queryToObj(history.location.search || '');

  keys.forEach(key => {
    if (searchObj[key]) {
      notHaveAnyKey = false;

      obj[key] = searchObj[key] as string;
    }
  });

  if (notHaveAnyKey) {
    return undefined;
  }

  return obj;
};

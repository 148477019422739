import { Grid } from '@mui/material';
import { Scope } from '@unform/core';
import { LoadingMessage } from 'components';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPermissions } from 'services/permission';
import { Flex } from 'styles/Flex';
import { Legend } from 'styles/Legend';
import { Permission } from 'types/permission';
import { PermissionAccordion } from './components';

interface Props {
  permissions?: (Permission & { checked?: boolean })[];
  isDetails?: boolean;
  notLoadPermissions?: boolean;
}

const PermissionPartComponent = ({
  isDetails,
  notLoadPermissions,
  permissions: permissionsProp,
}: Props): JSX.Element => {
  const [tPage] = useTranslation('pages/user');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [permissions, setPermission] = useState<Permission[]>(permissionsProp || []);

  const handleGetPermissions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getPermissions();

      setPermission(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    !notLoadPermissions && handleGetPermissions();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Flex marginTop={2}>
          <Legend>{tPage(isDetails ? 'details.permissions' : 'create.definePermissions')}</Legend>
        </Flex>
      </Grid>

      <Grid item xs={12}>
        {isLoading && <LoadingMessage text={tPage('create.loadingPermissions')} />}

        {!isLoading &&
          permissions.map((permission, index) => (
            <Scope key={`permissionAccordion-${index}`} path={`permissions[${index}]`}>
              <PermissionAccordion permission={permission} disabled={isDetails} />
            </Scope>
          ))}
      </Grid>
    </>
  );
};

export const PermissionPart = memo(PermissionPartComponent);

import { AutocompleteInputChangeReason } from '@mui/core';
import { Grid } from '@mui/material';
import { UnAutocomplete, UnDatePicker, UnInputFIeld, UnMaskInput } from 'components';
import { UnFileInput } from 'components/Unform/UnFileInput';
import { INVENTORY_TYPES } from 'constants/inventory';
import { OperationIds, SelectedOperation } from 'pages';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOperationsList } from 'services/operation';
import { Legend } from 'styles/Legend';
import { SimpleOperationList } from 'types/operation';
import { debounce } from 'utils';

interface Props {
  selectedOperation?: SelectedOperation;
  haveProduct: boolean;
  type: { label: string; value: string };
  operationIds: OperationIds;
  onToggleHaveOperation(field: 'origin' | 'destination', id?: string | number): void;
  onSetType(type: { label: string; value: string }): void;
}

const GeneralDataComponent = ({
  selectedOperation,
  haveProduct,
  onToggleHaveOperation,
  type,
  onSetType,
  operationIds,
}: Props): JSX.Element => {
  const [tPage] = useTranslation('pages/inventory');
  const [operations, setOperations] = useState<SimpleOperationList[]>([]);

  const handleSearchOperations = useCallback(
    async (e, value?: string, reason?: AutocompleteInputChangeReason) => {
      if (reason !== 'input') return;

      try {
        const response = await getOperationsList({ search: value });

        setOperations(response.data);
      } catch (error) {}
    },
    [],
  );

  return (
    <>
      <Grid item xs={12} sm={type.value === INVENTORY_TYPES.transfer ? 12 : 6} md={4}>
        <UnAutocomplete
          fullWidth
          name="type"
          label="labels:inventory.type"
          disableClearable
          onChange={(e, value) => {
            onSetType(value);
            value?.id === INVENTORY_TYPES.entry && onToggleHaveOperation('origin');
          }}
          options={[
            {
              value: INVENTORY_TYPES.entry,
              label: tPage(`types.${INVENTORY_TYPES.entry}`),
            },
            {
              value: INVENTORY_TYPES.transfer,
              label: tPage(`types.${INVENTORY_TYPES.transfer}`),
            },
          ]}
        />
      </Grid>

      {type.value === INVENTORY_TYPES.transfer && (
        <Grid item xs={12} sm={6} md={4}>
          <UnAutocomplete
            fullWidth
            name="originOperationId"
            label="labels:inventory.origin"
            optionLabel="name"
            optionValue="id"
            options={operations.filter(
              operation => `${operation.id}` !== `${operationIds.destination}`,
            )}
            onInputChange={debounce(handleSearchOperations)}
            onChange={(e, value) => {
              setOperations([]);
              onToggleHaveOperation('origin', value?.id);
            }}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={4}>
        <UnAutocomplete
          name="destinationOperationId"
          label={`labels:inventory.${
            type.value === INVENTORY_TYPES.entry ? 'operation' : 'destination'
          }`}
          optionLabel="name"
          optionValue="id"
          options={operations.filter(operation => `${operation.id}` !== `${operationIds.origin}`)}
          onInputChange={debounce(handleSearchOperations)}
          disabled={!!selectedOperation || haveProduct}
          onChange={(e, value) => {
            setOperations([]);
            onToggleHaveOperation('destination', value?.id);
          }}
        />
      </Grid>

      {type.value === INVENTORY_TYPES.entry && (
        <Grid item xs={12} md={4}>
          <UnInputFIeld name="nameProvider" label="labels:inventory.provider" />
        </Grid>
      )}

      <Grid item xs={12}>
        <UnInputFIeld
          name="observation"
          label="labels:inventory.observations"
          multiline
          minRows={1}
          maxRows={4}
        />
      </Grid>

      {type.value === INVENTORY_TYPES.entry && (
        <>
          <Grid item xs={12}>
            <Legend>{tPage('create.invoice')}</Legend>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <UnInputFIeld name="invoiceNumber" label="labels:inventory.invoiceNumber" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <UnDatePicker name="invoiceDate" label="labels:inventory.invoiceDate" />
          </Grid>

          <Grid item xs={12} md={4}>
            <UnMaskInput
              name="totalValueInvoice"
              mask="currency"
              label="labels:inventory.totalValueInvoice"
              cleanValue
              parseToFloat
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <UnFileInput name="files" accept="application/pdf,image/jpg,image/jpeg,text/xml" />
      </Grid>
    </>
  );
};

export const GeneralData = memo(GeneralDataComponent);

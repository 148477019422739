import { FormHandles } from '@unform/core';
import { UnAutocomplete, UnDatePicker } from 'components';
import { useOperationOptions } from 'hooks/useOperationOptions';
import { memo, RefObject, useCallback } from 'react';
import { Flex } from 'styles/Flex';
import { debounce } from 'utils';
import { formatDateToUs, isInvalidDate } from 'utils/date';

interface IProps {
  formRef: RefObject<FormHandles>;
}

const FilterComponent = ({ formRef }: IProps): JSX.Element => {
  const { operations, isLoading, onLoadOperations } = useOperationOptions();

  const handleLoadOperations = useCallback(
    (e, value: string) => onLoadOperations({ search: value || '' }),
    [],
  );

  const handleChangeField = useCallback(() => formRef.current?.submitForm(), []);

  return (
    <Flex marginTop={0.5} gap={1} maxWidth="60rem">
      <Flex flex={3}>
        <UnAutocomplete
          name="operation_id"
          onInputChange={debounce(handleLoadOperations)}
          options={operations}
          optionLabel="name"
          optionValue="id"
          filterOptions={x => x}
          loading={isLoading}
          label="pages/pax:historic.operationFilter"
          noOptionsText="autocomplete.typeToSearch"
          onChange={debounce(handleChangeField, 100)}
        />
      </Flex>
      <Flex flex={2}>
        <UnDatePicker
          name="init_date"
          label="pages/pax:historic.initialDateFilter"
          onChange={debounce(handleChangeField)}
          onGetValue={date => (isInvalidDate(date) ? null : formatDateToUs(date))}
        />
      </Flex>
      <Flex flex={2}>
        <UnDatePicker
          name="final_date"
          label="pages/pax:historic.finalDateFilter"
          onChange={debounce(handleChangeField)}
          onGetValue={date => (isInvalidDate(date) ? null : formatDateToUs(date))}
        />
      </Flex>
    </Flex>
  );
};

export const Filter = memo(FilterComponent);

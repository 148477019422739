import { memo, useCallback, useEffect, useState } from 'react';
import { Flex } from 'styles/Flex';

import * as DefaultStyles from 'styles/Details';
import * as Styles from './styles';
import { UserDetails } from 'types/user';
import { useBooleanObj } from 'hooks/useBooleanObj';
import { getUserById } from 'services/user';
import { LoadingMessage, Switch, Tag, Unform } from 'components';
import { useTranslation } from 'react-i18next';
import { cpfMask } from 'utils/masks';
import * as OperationPartStyles from '../../../Create/components/OperationPart/styles';
import { PermissionPart } from 'pages/Private/User/components';

interface IProps {
  userId: string;
}

interface BooleansObj {
  isLoading: boolean;
}

const InfosComponent = ({ userId }: IProps): JSX.Element => {
  const [tPage] = useTranslation('pages/details');
  const { t } = useTranslation();
  const [user, setUser] = useState<UserDetails>();
  const [booleanObj, setBooleanObjProp] = useBooleanObj<BooleansObj>({ isLoading: true });

  const handleGetDetails = useCallback(async () => {
    setBooleanObjProp('isLoading', true);
    try {
      const response = await getUserById(userId);

      setUser(response.data);
    } catch (error) {
    } finally {
      setBooleanObjProp('isLoading', false);
    }
  }, []);

  useEffect(() => {
    handleGetDetails();
  }, []);

  return (
    <Styles.Container>
      {booleanObj.isLoading && !user ? (
        <LoadingMessage text={tPage('user.isLoading')} />
      ) : (
        <>
          <Flex marginBottom={2}>
            <Flex column gap={0.8}>
              <h2>{user?.fullName}</h2>

              <span>
                {tPage('user.registration')} {user?.registration}
              </span>
            </Flex>

            <Flex gap={1.2} column marginLeft="auto">
              <DefaultStyles.Label>{tPage('user.createdAt')}</DefaultStyles.Label>

              {t('date', { date: user?.created_at && new Date(user?.created_at) })}
            </Flex>

            <Flex gap={1.2} column marginLeft={2}>
              <DefaultStyles.Label>{tPage('user.status')}</DefaultStyles.Label>

              <Tag
                variante={user?.status ? 'success' : 'inactive'}
                label={t(`status.${user?.status}`)}
              />
            </Flex>
          </Flex>

          <Flex marginBottom={3} wrap gap={5}>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('user.userType')}</DefaultStyles.Label>

              {t(`labels:userTypes.${user?.type.name}`)}
            </Flex>

            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('user.cpf')}</DefaultStyles.Label>

              {user?.cpf && cpfMask(user?.cpf)}
            </Flex>

            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('user.phone')}</DefaultStyles.Label>

              {user?.phone}
            </Flex>

            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('user.email')}</DefaultStyles.Label>

              {user?.email}
            </Flex>
          </Flex>

          <Flex marginBottom={2}>
            <strong>{tPage('user.bankData')}</strong>
          </Flex>

          <Flex wrap marginBottom={3} gap={5}>
            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('user.pix')}</DefaultStyles.Label>

              {user?.pix || '-'}
            </Flex>

            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('user.bank')}</DefaultStyles.Label>

              {user?.bank || '-'}
            </Flex>

            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('user.agency')}</DefaultStyles.Label>

              {user?.agency || '-'}
            </Flex>

            <Flex column gap={1}>
              <DefaultStyles.Label>{tPage('user.currentAccount')}</DefaultStyles.Label>

              {user?.account || '-'}
            </Flex>
          </Flex>

          <Flex marginBottom={1}>
            <strong>{tPage('user.operations')}</strong>
          </Flex>

          <Flex>
            {user?.operationAccessAll ? (
              <Switch disabled defaultChecked label="labels:input.user.accessAll" />
            ) : (
              <OperationPartStyles.List>
                {user?.operationsAccess.map((value, index) => (
                  <OperationPartStyles.ListItem key={`operation-${index}`}>
                    <Flex marginVertical={0.5}>{value.name}</Flex>
                  </OperationPartStyles.ListItem>
                ))}
              </OperationPartStyles.List>
            )}
          </Flex>

          <Flex>
            <Unform
              initialData={{
                permissions: user?.permissions.map(permission => ({
                  ...permission,
                  modules: permission.modules.map(module => module.havePermission),
                })),
              }}
            >
              <PermissionPart
                isDetails
                notLoadPermissions
                permissions={user?.permissions.map(permission => ({
                  ...permission,
                  checked: !!permission.modules.find(module => module.havePermission),
                  modules: permission.modules.map(module => module.name),
                }))}
              />
            </Unform>
          </Flex>
        </>
      )}
    </Styles.Container>
  );
};

export const Infos = memo(InfosComponent);

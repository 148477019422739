import styled from 'styled-components';
import { Tab as MuiTab } from '@mui/material';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 2.5rem;
`;

export const Tab = styled(MuiTab)`
  text-transform: none;

  &.Mui-selected {
    color: #000;
  }
`;

export const Header = styled.div``;

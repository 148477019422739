import * as Yup from 'yup';

import { requiredField } from './utils/messages';

import { PRODUCT_TYPES } from 'constants/product';
import { dateValidation } from './date';

const dateSchema = dateValidation('initial_validity', 'final_validity');

export const createProductValidationSchema = Yup.object({
  name: Yup.string().required(requiredField),
  type: Yup.string().required(requiredField).typeError(requiredField),
  printDimension: Yup.string()
    .nullable()
    .when(['type', 'isPrinted'], {
      is: (type: string, isPrinted: boolean) => {
        return type === PRODUCT_TYPES.physical && isPrinted;
      },
      then: Yup.string().nullable().required(requiredField),
    }),
  categoryId: Yup.string().when('type', {
    is: PRODUCT_TYPES.physical,
    then: () => Yup.string().nullable().required(requiredField),
  }),
  overlay: Yup.boolean().when('type', {
    is: PRODUCT_TYPES.digital,
    then: Yup.boolean().required(requiredField),
  }),
  ...dateSchema,
});

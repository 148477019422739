import { GetOrder, UpdateOrder } from 'types/order';
import api from './apis/api';

export function putOrder(id: string | number, operationId: string | number, payload: UpdateOrder) {
  return api.put(`/order/${id}?operationId=${operationId}`, payload);
}

export function getOrder(id: string | number, operationId: string | number) {
  return api.get<GetOrder>(`/order/${id}?operationId=${operationId}`);
}

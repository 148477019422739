import { baseURL } from 'services/apis/api';
import { getFileBlob } from 'services/file';

export const downloadFileById = async (id: number | string, name: string) => {
  try {
    const response = await getFileBlob(id);

    const url = URL.createObjectURL(response.data);

    const a = document.createElement('a');

    a.href = url;
    a.download = name;
    a.click();

    return true;
  } catch (error) {
    return false;
  }
};

export function downloadFile(file: Blob, name: string) {
  const url = URL.createObjectURL(file);

  const a = document.createElement('a');

  a.href = url;
  a.download = name;
  a.click();

  a.remove();
}

export const getStorageUrl = (endpoint: string) => {
  let baseUrl = process.env.REACT_APP_STORAGE_BASE_URL || '';
  const parsedEndpoint = endpoint;

  if (!baseUrl.endsWith('/') && !parsedEndpoint.startsWith('/')) {
    baseUrl += '/';
  }

  if (baseUrl.endsWith('/') && parsedEndpoint.startsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return baseUrl + endpoint;
};

export const getDigitalPhotoSrc = (qrCode: string, imageId: number | string, small?: boolean) => {
  let baseUrl = baseURL;
  const endpoint = `/operation/qr-code/${qrCode}/photo/${imageId}${small ? `?size=small` : ''}`;

  if (!baseUrl.endsWith('/') && !endpoint.startsWith('/')) {
    baseUrl += '/';
  }

  if (baseUrl.endsWith('/') && endpoint.startsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return baseUrl + endpoint;
};

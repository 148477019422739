import { Grid } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'styles/Flex';
import { GetQRCodeDigitalPhotos } from 'types/QRCode';
import { getDigitalPhotoSrc } from 'utils/api';
import { DownloadButton } from './components';
import * as Styles from './styles';

interface Props {
  photo: GetQRCodeDigitalPhotos;
  qrCode: string;
}

const PhotoBase = ({ photo, qrCode }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Styles.Container>
        <Styles.ImageContainer>
          <Styles.Image src={getDigitalPhotoSrc(qrCode, photo.id, true)} />
        </Styles.ImageContainer>

        <Flex justifyContent="space-between">
          <Flex column gap={0.5}>
            <strong>#{photo.id}</strong>

            <legend>{t('dateHours', { date: new Date(photo.date) })}</legend>
          </Flex>

          <Flex>
            <DownloadButton photo={photo} />
          </Flex>
        </Flex>
      </Styles.Container>
    </Grid>
  );
};

export const Photo = memo(PhotoBase);

import React, { memo, useRef, useEffect, ComponentProps, useState } from 'react';
import { useField } from '@unform/core';

import { TextField } from 'components';
import { useTranslation } from 'react-i18next';

type InputRoot = Omit<ComponentProps<typeof TextField>, 'defaultValue' | 'error' | 'ref'>;

interface UnInputFieldProps extends InputRoot {
  name: string;
}

function BaseUnInputField({
  name,
  helperText,
  onChange,
  onFocus,
  onBlur,
  ...props
}: UnInputFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [tErros] = useTranslation('formErrors');
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name);
  const [shrinkLabel, setShrinkLabel] = useState(!!defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => ref.current.value,
      setValue: (ref, value) => {
        if (value) setShrinkLabel(true);

        clearError();

        ref.current.value = value;
      },
      clearValue: ref => (ref.current.value = ''),
    });
  }, [fieldName, registerField, inputRef]);

  return (
    <TextField
      {...props}
      ref={inputRef}
      placeholder={props.placeholder}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error ? tErros(error) : helperText}
      onFocus={e => {
        onFocus?.(e);
        setShrinkLabel(true);
      }}
      onBlur={e => {
        onBlur?.(e);

        if (e.target.value) return;

        setShrinkLabel(false);
      }}
      onChange={e => {
        onChange?.(e);
        error && clearError();

        if (e.target.value) setShrinkLabel(true);
      }}
      InputLabelProps={{ shrink: shrinkLabel }}
    />
  );
}

export const UnInputFIeld = memo(BaseUnInputField);

import { Grid } from '@mui/material';
import { UnOperationArray } from 'components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Legend } from 'styles/Legend';

const VinculeOperationPartBase = (): JSX.Element => {
  const [tPage] = useTranslation('pages/touristGuide');

  return (
    <>
      <Grid item xs={12}>
        <Legend>{tPage('createGuide.vinculeOperation')}</Legend>
      </Grid>

      <Grid item xs={12}>
        <UnOperationArray
          name="operationsAccessId"
          label="pages/touristGuide:createGuide.form.operation"
          onGetValue={options => options.map(operation => operation.id)}
        />
      </Grid>
    </>
  );
};

export const VinculeOperationPart = memo(VinculeOperationPartBase);

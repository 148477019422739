import { Grid } from '@mui/material';
import { FormHandles, SubmitHandler } from '@unform/core';
import {
  Button,
  ConfirmModal,
  IconButton,
  IImage,
  LoadingMessage,
  UnAutocomplete,
  UnDropImage,
  Unform,
  UnInputFIeld,
  UnSwitch,
} from 'components';
import { PRODUCT_TYPES } from 'constants/product';
import { routePaths } from 'constants/routes';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { deleteFile, getFile } from 'services/file';
import {
  getDimensionsPrinter,
  getProductById,
  getProductCategory,
  handleUploadImage,
  postProduct,
  putProduct,
} from 'services/product';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { DimensionsPrinter } from 'types/dimensionsPrinter';
import {
  CreateProduct as CreateProductData,
  GetProductCategory,
  ProductByIdItem,
} from 'types/product';
import { openNotification } from 'utils/notification';
import { createProductValidationSchema } from 'yupSchemas/product';
import { Combo, Digital } from './components';
import * as Styles from './styles';

interface Image extends Omit<IImage, 'name'> {
  id?: string | number;
}

interface Details extends Omit<CreateProductData, 'products'> {
  'products-physical': CreateProductData['products'];
  'products-digital': CreateProductData['products'];
  img: Image[];
}

export function CreateProduct({ history, match }: RouteComponentProps<{ id?: string }>) {
  const [details, setDetails] = useState<Partial<Details>>();
  const [type, setType] = useState('');
  const [productCategories, setProductCategories] = useState<GetProductCategory[]>([]);
  const [dimensionsPrinter, setDimensionsPrinter] = useState<DimensionsPrinter[]>([]);
  const [isPrinted, setIsPrinted] = useState(false);

  const [isCancelModal, toggleCancelModal] = useBooleanToggle(false);
  const [isOverlay, toggleOverlay] = useBooleanToggle(false);
  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [initialLoading, toggleInitialLoading] = useBooleanToggle(!!match.params?.id);

  const { t } = useTranslation();

  const id = match.params?.id;

  const formRef = useRef<FormHandles>(null);

  const handleGetProductCategory = async () => {
    try {
      const { data } = await getProductCategory();
      setProductCategories(data);
    } catch (err) {}
  };

  const parseProductCategoryToOptions = (categories: GetProductCategory[]) =>
    categories.map(value => ({
      label: t(`pages/products:log.category.${value.name}`),
      value: value.id,
    }));

  const handleGetDimensionsPrinter = async (returnOptions?: boolean) => {
    try {
      const { data } = await getDimensionsPrinter();

      if (returnOptions) {
        return data;
      }

      setDimensionsPrinter(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDetails = async (id: string) => {
    try {
      const dimensionPrintData = await handleGetDimensionsPrinter(true);

      if (dimensionPrintData) {
        setDimensionsPrinter(dimensionPrintData);
      }

      const { data } = await getProductById(id);

      const { images, products } = data;

      const imageList = [];

      for (const image of images) {
        imageList.push({
          url: await getFile(image.id),
          isFromServer: true,
          id: image.id,
        });
      }

      const parseProduct = (products: ProductByIdItem[], type: string) => {
        return products
          .filter(product => product.type === type)
          .map(product => ({
            amount: product.amount,
            id: product.id,
            name: product.name,
          }));
      };

      setDetails({
        final_validity: data?.final_validity,
        initial_validity: data?.initial_validity,
        name: data?.name,
        overlay: data?.overlay,
        type: data?.type,
        'products-digital': parseProduct(products, PRODUCT_TYPES.digital),
        'products-physical': parseProduct(products, PRODUCT_TYPES.physical),
        img: imageList,
        categoryId: data?.category?.id,
        isPrinted: data.isPrinted,
        printDimension: data.printDimension,
      });

      setIsPrinted(!!data?.isPrinted);

      if (data?.overlay) toggleOverlay();

      setType(data.type);
    } catch (err) {
    } finally {
      toggleInitialLoading();
    }
  };

  const handleSubmit: SubmitHandler<Details> = async data => {
    try {
      toggleIsLoading();

      const {
        'products-digital': productsDigital,
        'products-physical': productsPhysical,
        initial_validity,
        final_validity,
        img,
        ...payload
      } = data;
      const products = [
        ...(productsDigital?.map(value => ({
          id: value.id,
          amount: value.amount,
        })) || []),
        ...(productsPhysical?.map(value => ({
          id: value.id,
          amount: value.amount,
        })) || []),
      ];
      const productCombo =
        payload.type === PRODUCT_TYPES.combo
          ? {
              products,
              initial_validity: initial_validity && new Date(initial_validity).toISOString(),
              final_validity: final_validity && new Date(final_validity).toISOString(),
            }
          : {};
      const productData = {
        ...productCombo,
        ...payload,
        ...(payload.type === PRODUCT_TYPES.physical &&
          payload.isPrinted && {
            printDimension: payload.printDimension,
          }),
      };
      const response = id ? await putProduct(id, productData) : await postProduct(productData);
      if (img) {
        await handleUploadImage(String(response?.data?.id || id), img);
      }
      !id
        ? openNotification(t('pages/create:createMessage'))
        : openNotification(t('pages/create:editMessage'));
      history.push(routePaths.products.list);
    } finally {
      toggleIsLoading();
    }
  };

  const form = {
    [PRODUCT_TYPES.combo]: <Combo />,
    [PRODUCT_TYPES.digital]: (
      <Digital disabled={!!id} isOverlay={isOverlay} toggleOverlay={toggleOverlay} />
    ),
  };

  useEffect(() => {
    handleGetProductCategory();

    if (!id) handleGetDimensionsPrinter();

    if (!id) return;

    handleGetDetails(id);
  }, [id]);

  return (
    <PageContainer md>
      <ConfirmModal
        open={isCancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={toggleCancelModal}
        onConfirm={() => history.goBack()}
      />
      <Flex column gap={1.62}>
        <Flex alignItems="center" gap={1}>
          <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={toggleCancelModal} />
          <h1>Produto</h1>
        </Flex>
        <Styles.FormContainer>
          {id && initialLoading ? (
            <LoadingMessage text={t('commons:loadingDetails')} />
          ) : (
            <Unform
              onSubmit={handleSubmit}
              ref={formRef}
              validationSchema={createProductValidationSchema}
              initialData={details}
            >
              <Styles.Container>
                <Grid container columnSpacing={3} rowSpacing={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <UnAutocomplete
                      name="type"
                      label="labels:input.product.type"
                      optionValue="value"
                      onChange={(event, option) => option && setType(option.value)}
                      filterOptions={x => x}
                      disabled={!!id && !!details}
                      options={[
                        {
                          label: t('labels:input.product.listOfType.digital'),
                          value: PRODUCT_TYPES.digital,
                        },
                        {
                          label: t('labels:input.product.listOfType.physical'),
                          value: PRODUCT_TYPES.physical,
                        },
                        {
                          label: t('labels:input.product.listOfType.combo'),
                          value: PRODUCT_TYPES.combo,
                        },
                      ]}
                    />
                  </Grid>
                  {type === PRODUCT_TYPES.physical && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <UnAutocomplete
                          name="categoryId"
                          label="pages/products:log.categoryId"
                          optionValue="value"
                          filterOptions={x => x}
                          options={parseProductCategoryToOptions(productCategories)}
                        />
                      </Grid>

                      <Grid item container rowSpacing={1} xs={12}>
                        <Grid item xs={12}>
                          <UnSwitch
                            name="isPrinted"
                            label="pages/products:log.printedProduct"
                            onChange={(e, checked) => setIsPrinted(checked)}
                          />
                        </Grid>

                        {isPrinted && (
                          <Grid item xs={12} sm={6} md={4}>
                            <UnAutocomplete
                              name="printDimension"
                              label="pages/products:log.printDimension"
                              options={dimensionsPrinter}
                              optionLabel="dimension"
                              optionValue="dimension"
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={8}>
                    <UnInputFIeld name="name" label="labels:input.product.name" />
                  </Grid>
                </Grid>
                {form[type]}
                <UnDropImage
                  onRemoveFile={async (file: Image) => {
                    if (file.isFromServer && file.id) {
                      await deleteFile(file.id);
                    }
                  }}
                  name="img"
                  maxFiles={isOverlay ? 5 : 1}
                  multiple={isOverlay}
                />
              </Styles.Container>
              <hr />
              <Styles.Container>
                <Flex justifyContent="flex-end" gap={1}>
                  <Button
                    disabled={isLoading}
                    type="button"
                    variant="cancel"
                    onClick={toggleCancelModal}
                  >
                    {t('labels:button.cancel')}
                  </Button>
                  <Button isLoading={isLoading} type="submit">
                    {t('labels:button.save')}
                  </Button>
                </Flex>
              </Styles.Container>
            </Unform>
          )}
        </Styles.FormContainer>
      </Flex>
    </PageContainer>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.foreground};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.foreground};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 2rem;
  width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    margin-bottom: 3rem;
  }

  .MuiCheckbox-root {
    padding-left: 0;
  }
`;

export const Title = styled.h1`
  align-self: flex-start;
  display: flex;
  gap: 3rem;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const SubTitle = styled.div`
  align-self: flex-start;
  margin: 2.5rem 0;
  color: ${({ theme }) => theme.colors.text600};
  font-size: ${({ theme }) => theme.fonts.sizes.sm};
`;

import { AuthorizeView, Icon, Popover } from 'components';
import { routePaths } from 'constants/routes';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Flex } from 'styles/Flex';
import { geUserStorage, logout } from 'utils/auth';
import { Sidebar } from '..';

import * as Styles from './styles';

interface IProps {}

const NavbarComponent: React.FC<IProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);

  const user = geUserStorage();

  const handleToggleDrawer = useCallback(() => setOpenDrawer(prev => !prev), []);

  const redirectPax = useCallback(() => history.push(routePaths.pax.index), []);

  const redirectHome = useCallback(() => history.push(routePaths.home), []);

  const handleLogout = useCallback(() => {
    logout();
  }, []);

  return (
    <Styles.Header>
      <Icon name="menu" color="primary" size={1.3} clickable onClick={handleToggleDrawer} />

      <Styles.VyooLogo onClick={redirectHome} />

      <AuthorizeView permission="CREATE_PAX">
        <Styles.ThrowPaxButton onClick={redirectPax}>{t('navbar.throwPax')}</Styles.ThrowPaxButton>
      </AuthorizeView>

      <Flex marginRight={0.5}>
        <Icon name="bell" size={1.3} marginLeft={3} color="text300" clickable />
      </Flex>

      <Popover
        options={[
          {
            label: (
              <>
                <Icon name="userCircle" marginRight={0.5} color="primary" />
                {user?.fullName}
              </>
            ),
          },
          {
            label: (
              <>
                <Icon name="infoOutline" marginRight={0.5} color="primary" />
                {process.env.REACT_APP_VERSION}
              </>
            ),
          },
          {
            label: (
              <>
                <Icon name="logout" marginRight={0.5} color="danger" />
                {t('navbar.logout')}
              </>
            ),
            onClick: handleLogout,
          },
        ]}
      >
        <Icon name="user" size={1.3} color="text300" />
      </Popover>

      <Sidebar onClose={handleToggleDrawer} openDrawer={openDrawer} />
    </Styles.Header>
  );
};

export const Navbar = memo(NavbarComponent);

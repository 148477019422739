import { colors } from './colors';

export const paymentMethodRadio = {
  primary: {
    background: colors.primaryLight,
    border: colors.primary,
  },
  inactive: {
    background: colors.transparent,
    border: colors.text500,
  },
};

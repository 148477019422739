import { LoadingMessage } from 'components';
import * as Styles from './styles';

function corrigirURL(url: string) {
  const padrao = /\/galery/;

  if (url.match(padrao)) {
    url = url.replace(padrao, '/gallery');
  }

  window.location.href = url;

  return url;
}

export const Galery = (): JSX.Element => {
  corrigirURL(window.location.href);
  return (
    <Styles.LoadingContainer>
      <LoadingMessage text={'Carregando fotos e validando o QRCode'} />
    </Styles.LoadingContainer>
  );
};

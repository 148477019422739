import {
  ReactNode,
  memo,
  useState,
  useImperativeHandle,
  ComponentProps,
  forwardRef,
  useEffect,
} from 'react';

import { Autocomplete } from 'components';

import { Flex } from 'styles/Flex';

type Value = Record<string, any>;

interface Options {
  label: string;
  [key: string]: any;
}

type RootAutocompleteProps = Pick<
  ComponentProps<typeof Autocomplete>,
  | 'options'
  | 'onInputChange'
  | 'label'
  | 'placeholder'
  | 'filterOptions'
  | 'noOptionsText'
  | 'optionLabel'
  | 'optionValue'
  | 'helperText'
  | 'loading'
  | 'disabled'
  | 'value'
  | 'onChange'
>;

interface ArrayHelper {
  remove: (index: number) => void;
  removeBy: (key: string, value: any) => void;
  disabled: boolean;
  push: (value?: Value) => void;
}

export interface FieldArrayRef {
  values: Value[];
}

interface UnFieldArrayProps extends RootAutocompleteProps {
  name: string;
  initialData?: Value[];
  error?: boolean;
  helperText?: string;
  unicKeyFilter?: string;
  render?: (helpers: ArrayHelper, values: any[]) => ReactNode;
  onValidate?: (option: any) => Promise<void>;
  onSelect?: (option: any) => void;
  onChangeValue?: (option: any[]) => void;
  value?: Value[];
  searhcField?: boolean;
}

// eslint-disable-next-line react/display-name
const BaseFieldArray = forwardRef<FieldArrayRef, UnFieldArrayProps>(
  (
    {
      initialData,
      unicKeyFilter,
      options,
      helperText,
      onValidate,
      onSelect,
      onChangeValue,
      value,
      searhcField = true,
      ...props
    },
    ref,
  ) => {
    const [values, setValue] = useState<Value[]>(initialData || []);

    useImperativeHandle(ref, () => ({ values }), [values]);

    const handleChange = async (option: Options) => {
      if (!option) return;

      await onValidate?.(option);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars

      if (unicKeyFilter) {
        const alreadyValues = values.map(value => value[unicKeyFilter]);

        if (alreadyValues.includes(option[unicKeyFilter])) return;
      }
      onChangeValue?.([...values, option]);
      onSelect?.(option);
      !value && setValue(prevState => [...prevState, option]);
    };

    const handleFilter = (index: number) => {
      setValue(prevState => [...prevState.filter((_, itemIndex) => itemIndex !== index)]);
    };

    const handleFilterBy = (key: string, payload: any) => {
      setValue(prevState => prevState.filter(value => value[key] !== payload));
    };

    const handlePush = (value?: Value) => {
      setValue(prevState => [...prevState, value || {}]);
    };

    useEffect(() => {
      if (!value) return;

      setValue(value);
    }, [value]);

    useEffect(() => {
      if (!initialData) return;
      onChangeValue?.(initialData);
    }, [initialData]);

    return (
      <Flex column fullWidth>
        {searhcField && (
          <Autocomplete
            {...props}
            helperText={helperText}
            options={
              unicKeyFilter
                ? options?.filter(
                    option =>
                      !values.map(value => value[unicKeyFilter]).includes(option[unicKeyFilter]),
                  )
                : options
            }
            onChange={(event, value) => handleChange(value)}
            value={''}
            blurOnSelect
          />
        )}
        {props?.render?.(
          {
            remove: handleFilter,
            removeBy: handleFilterBy,
            disabled: !!props.disabled,
            push: handlePush,
          },
          values,
        )}
      </Flex>
    );
  },
);

export const FieldArray = memo(BaseFieldArray);

import api from '../services/apis/api';
import { resolveQueryStart } from 'utils/query';
import { CreatePax, HistoricPax, UpdatePaxData } from 'types/pax';
import { Paginated } from 'types/api';

export const getPax = (query?: string) => api.get(`/pax${resolveQueryStart(query)}`);

export const postPax = (data: CreatePax[]) => api.post('/pax', data);

export const getPaxHistoric = (query?: string) =>
  api.get<Paginated<HistoricPax>>(`history/pax${resolveQueryStart(query)}`);

export const deletePaxHistoric = (paxId: number | string) => api.delete(`pax/${paxId}`);

export const patchPaxHistoric = (paxId: number | string, data: UpdatePaxData) =>
  api.patch(`pax/${paxId}`, data);

import React, { memo, useMemo, ReactNode, useCallback, Children } from 'react';
import { PermissionKey } from 'types/auth';
import { verifyAuthentication } from 'utils/authorization';
import { authorizedName } from './Authorized';
import { notAuthorizedName } from './NotAuthorized';

const getAuthorizeComponent = (
  children: any,
  name: typeof notAuthorizedName | typeof authorizedName,
) => {
  let selectedChild = null;

  Children.map(children, child => {
    if (child?.type?.type?.defaultProps?.name === name) {
      selectedChild = child;
    }
  });

  return selectedChild;
};

interface IProps {
  children: ReactNode | ReactNode[];
  permission?: PermissionKey;
}

const AuthorizeViewComponent: React.FC<IProps> = ({ children, permission }) => {
  const isAuthenticated = useMemo(() => verifyAuthentication(permission), []);

  const conditionalRender = useCallback(
    (authorizedElement: ReactNode, notAuthorizedElement: ReactNode = null) => (
      <>{isAuthenticated ? authorizedElement : notAuthorizedElement}</>
    ),
    [isAuthenticated],
  );

  if (!Array.isArray(children)) {
    return conditionalRender(children);
  }

  const authorizedComponent = getAuthorizeComponent(children, authorizedName);

  if (authorizedComponent) {
    const notAuthorizedComponent = getAuthorizeComponent(children, notAuthorizedName);

    return conditionalRender(authorizedComponent, notAuthorizedComponent);
  }

  return conditionalRender(children);
};

export const AuthorizeView = memo(AuthorizeViewComponent);

import { useField, FormHandles } from '@unform/core';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { memo, useEffect, useRef, useState } from 'react';
import ptBrLocale from 'date-fns/locale/pt-BR';

const CustomDateTimePicker = ({
  name,
  formRef,
  ...props
}: {
  name: string;
  formRef: React.RefObject<FormHandles>;
}) => {
  const { defaultValue, fieldName, registerField, error } = useField(name);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const inputRef = useRef();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current ? ref.current.value : undefined;
      },
      setValue: (ref, value) => {
        if (ref.current) {
          ref.current.value = value as string;
        }
      },
      clearValue: ref => {
        if (ref.current) {
          ref.current.value = '';
        }
      },
    });
  }, [fieldName, registerField, defaultValue]);

  const handleChange = (date: any) => {
    if (date.getHours() === 0 && date.getMinutes() === 0) {
      date.setHours(23);
      date.setMinutes(59);
    }
    // @ts-ignore
    setSelectedDate(date);
    if (formRef.current) {
      formRef.current.setFieldValue(name, date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBrLocale}>
      <DateTimePicker
        {...props}
        renderInput={props => <TextField {...props} label="" inputRef={inputRef} />}
        value={selectedDate}
        onChange={handleChange}
      />
      {error && <span style={{ color: 'red' }}>{error}</span>}
    </LocalizationProvider>
  );
};

export const UnDateTimePicker = memo(CustomDateTimePicker);

import { Autocomplete, IconButton } from 'components';
import { reportTypes as reportTypesConst } from 'constants/report';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { getReportTypes } from 'services/report';
import { Label } from 'styles/Details';
import { Flex } from 'styles/Flex';
import { PageContent } from 'styles/PageContent';
import { ReportTypes } from 'types/report';
import { openNotification } from 'utils/notification';
import { SaleReport } from './Reports';
import * as Styles from './styles';

type Props = RouteComponentProps;

export const ReportPage = ({ history }: Props) => {
  const [tPage] = useTranslation('pages/report');
  const [reportTypes, setReportTypes] = useState<{ label: string; value: string }[]>([]);
  const [type, setType] = useState<ReportTypes>();

  const renderReport = useMemo(() => {
    if (!type) return null;

    return {
      [reportTypesConst.analytical]: <SaleReport type={type} />,
      [reportTypesConst.synthetic]: <SaleReport type={type} />,
      [reportTypesConst.product]: <SaleReport type={type} />,
      [reportTypesConst.financial]: <SaleReport type={type} />,
      [reportTypesConst.sales]: <SaleReport type={type} />,
      [reportTypesConst.photoproduction]: <SaleReport type={type} />,
      [reportTypesConst.closing]: <SaleReport type={type} />,
      [reportTypesConst.paymentmethod]: <SaleReport type={type} />,
    }[type];
  }, [type]);

  const handleGetReportTypes = useCallback(async () => {
    try {
      const { data } = await getReportTypes();

      const newOptions = data.map(type => ({ label: tPage(`reportTypes.${type}`), value: type }));

      setReportTypes(newOptions);
    } catch (error) {
      openNotification(tPage('notGetReportTypesError'), 'error');
      console.log(error);
    }
  }, []);

  useEffect(() => {
    handleGetReportTypes();
  }, []);

  return (
    <Styles.Container>
      <Flex alignItems="center" gap={0.5} marginBottom={0.8}>
        <IconButton
          icon={{
            name: 'arrowBack',
            color: 'text',
            size: 1.5,
          }}
          onClick={history.goBack}
        />

        <h1>{tPage('title')}</h1>
      </Flex>

      <PageContent>
        <Flex marginBottom={0.8}>
          <Label>{tPage('reportData')}</Label>
        </Flex>

        <Flex>
          <Autocomplete
            label="pages/report:form.reportName"
            value={reportTypes.find(opt => opt.value === type)}
            onChange={(_, opt) => setType(opt.value)}
            options={reportTypes}
            disableClearable
          />
        </Flex>

        {renderReport}
      </PageContent>
    </Styles.Container>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
`;

export const Header = styled.header`
  width: 100%;
  height: 3.75rem;
  background-color: ${({ theme }) => theme.colors.foreground};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
`;

export const Body = styled.section`
  width: 100%;
  height: calc(100vh - 3.75rem);
`;

// @ts-nocheck
import { TextFieldProps as TextFieldMuiProps } from '@mui/material';
import { forwardRef, memo } from 'react';

import { Icon, IIconProps } from 'components';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';

export type TextFieldProps = Omit<TextFieldMuiProps, 'variant' | 'inputRef'> & {
  label?: string;
  clearHelperTextSpacing?: boolean;
  contained?: boolean;
  startIcon?: IIconProps | IIconProps['name'];
  placeholderAlternative?: string;
  endIcon?: IIconProps | IIconProps['name'];
};

// eslint-disable-next-line react/display-name
const BaseTextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      clearHelperTextSpacing,
      helperText,
      startIcon,
      endIcon,
      contained,
      placeholder,
      placeholderAlternative,
      size = 'medium',
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <Styles.TextField
        contained={contained}
        variant="outlined"
        inputRef={ref}
        label={label && t(label)}
        size={size}
        helperText={helperText || (clearHelperTextSpacing ? undefined : ' ')}
        placeholder={placeholderAlternative || (placeholder && t(placeholder))}
        InputProps={{
          startAdornment: startIcon && (
            <Icon
              color="text500"
              {...(typeof startIcon === 'string' ? { name: startIcon } : startIcon)}
            />
          ),
          endAdornment: endIcon && (
            <Icon
              color="text500"
              {...(typeof endIcon === 'string' ? { name: endIcon } : endIcon)}
            />
          ),
          ...props.InputProps,
        }}
        {...props}
      />
    );
  },
);

export const TextField = memo(BaseTextField);

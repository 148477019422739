import { memo } from 'react';

import { LoadingMessage as LoadingMessageComponent } from 'components';
import * as Styles from './styles';

interface IProps {
  title: string;
}

const LoadingMessageBase = ({ title }: IProps): JSX.Element => {
  return (
    <Styles.Container>
      <h2>{title}</h2>

      <Styles.LoadingContainer>
        <LoadingMessageComponent size={50} />
      </Styles.LoadingContainer>
    </Styles.Container>
  );
};

export const LoadingMessage = memo(LoadingMessageBase);

import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
    font-size: ${({ theme }) => theme.fonts.sizes.lg};
  }

  button {
    margin: -1rem -1rem 0 0;
  }
`;

export const Body = styled.div`
  margin-top: 1rem;
`;

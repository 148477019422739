import { ComponentProps, memo, SyntheticEvent } from 'react';

import { FieldArray, UnFieldArray } from 'components';
import useProductOptions from 'hooks/useProductOptions';
import { debounce } from 'utils';
import { useGoalContext } from 'pages/Private/Goal/Create/components';

type RootFieldArray = Pick<ComponentProps<typeof FieldArray>, 'render' | 'name' | 'initialData'>;

interface ProductArrayProps extends RootFieldArray {
  operationId?: number;
}

function BaseProductArray(props: ProductArrayProps) {
  const context = useGoalContext();

  const query = props.operationId
    ? {
        operation_id: props.operationId,
      }
    : {};

  const { onLoadProducts, products } = useProductOptions({
    query,
  });

  const handleChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    if (!value) return;

    onLoadProducts({
      search: value,
      ...query,
    });
  };

  return (
    <UnFieldArray
      options={products}
      onInputChange={debounce(handleChange)}
      optionLabel="name"
      optionValue="id"
      unicKeyFilter="id"
      onSelect={option => {
        if (!option) return;
        context?.setListProductDeleted?.(prevState =>
          prevState.filter(product => {
            const currentProductsId = products.map(value => value.id);

            return !currentProductsId.includes(Number(product.id));
          }),
        );
      }}
      {...props}
    />
  );
}

export const ProductArray = memo(BaseProductArray);

import { routePaths } from 'constants/routes';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

export const DashboardPage = ({ history }: Props): JSX.Element => {
  useEffect(() => {
    const token = sessionStorage.getItem('@vyoo/token');

    const tokenWithoutQuotes = token!.replace(/['"]+/g, '');

    const url = process.env.REACT_APP_BI! + `dashboards?p2_token=${tokenWithoutQuotes}`;
    window.open(url, '_blank');
    history.push(routePaths.home);
  }, []);

  return <div></div>;
};

import React from 'react';

import { Popover } from 'components';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { ProductInventoryOperation } from 'types/product';
import { EditModal } from './EditModal';
import { RemoveModal } from './RemoveModal';
import { PRODUCT_TYPES as prod_types } from 'constants/product';

interface IProps {
  product: ProductInventoryOperation;
  operationId: string | number;
  updateCreate: () => void;
}
const MenuComponent = (props: IProps): JSX.Element => {
  const [isRemoveProd, toggleIsRemoveProd] = useBooleanToggle(false);
  const [isEditProd, toggleIsEditProd] = useBooleanToggle(false);
  return (
    <>
      <RemoveModal
        title="Atenção"
        bodyText={`Confirma remover produto ${props.product.name}?`}
        open={isRemoveProd}
        onClose={toggleIsRemoveProd}
        updateCreate={props.updateCreate}
        requestParams={[props.operationId, props.product.id]}
      />
      <EditModal
        title={props.product.name}
        open={isEditProd}
        onClose={toggleIsEditProd}
        updateCreate={props.updateCreate}
        product={props.product}
        operationId={props.operationId}
      />
      <Popover
        options={[
          {
            label: 'pages/operations:details.inventoryTab.tooltip.delete',
            onClick: toggleIsRemoveProd,
          },
          {
            label: 'pages/operations:details.inventoryTab.tooltip.edit',
            onClick: toggleIsEditProd,
            disabled: [prod_types.digital, prod_types.combo].some(
              type => type === props.product.type,
            ),
          },
        ]}
      />
    </>
  );
};

export const Menu = React.memo(MenuComponent);

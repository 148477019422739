import { Grid } from '@mui/material';
import { ChangeEvent, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  UnAcquirer,
  UnAutocomplete,
  UnCarFlag,
  UnHiddenInput,
  UnInputFIeld,
  UnMaskInput,
} from 'components';
import { UnPaymentMethodGroup } from '..';

import { CASH_DESK_PAYMENT_METHOD } from 'constants/cashDesk';

import { FormHandles, Scope } from '@unform/core';
import { getAllAcquirerByOperation } from 'services/acquire';
import { Flex } from 'styles/Flex';
import { AcquirerType, CardFlagType, GetAllAcquirerByOperationResponse } from 'types/acquire';
import { debounce } from 'utils';
import { currencyToFloat } from 'utils/help';
import { Details, PaymentType } from '../..';
import * as Styles from '../../styles';

interface PaymentProps {
  name: string;
  onChangeValue: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChangeUnique: number,
  ) => void;
  type: string;
  /** data are comming from the API */
  isEdit?: boolean;
  canEdit?: boolean;
  installmentTypes: string[];
  withoutIntegration: string[];
  tefTypes: string[];
  disableRemove: boolean;
  details: Details;
  onClickRemove: (onChangeUnique: number) => void;
  payment: PaymentType;
  onChangeUnique: number;
  formRef: RefObject<FormHandles>;
}

function BasePayment({ formRef, ...props }: PaymentProps) {
  const [tPage] = useTranslation('pages/cashDesk');
  const [t] = useTranslation();

  const [type, seType] = useState(props.type);

  const [value, setValue] = useState<number>(props.payment.value || 0);

  const [acquirers, setAcquirers] = useState<GetAllAcquirerByOperationResponse>();

  const [acquirerValue, setAcquirerValue] = useState<AcquirerType | null>(
    props.withoutIntegration.includes(type) ? null : (props.payment.acquirer as AcquirerType),
  );

  const [cardFlagValue, setCardFlagValue] = useState<CardFlagType | null>(
    props.withoutIntegration.includes(type) ? null : (props.payment.card_flag as CardFlagType),
  );

  const [hidden, setHidden] = useState(false);

  const installmentOptions = useMemo(() => {
    if (props.withoutIntegration.includes(type) || !props.installmentTypes?.includes(type)) return;

    const newInstallments = cardFlagValue?.feesCredit?.filter(
      installment => value / installment.installment >= (cardFlagValue?.valueRequired || 0),
    );

    return (
      newInstallments?.map(feeCredit => ({
        ...feeCredit,
        value: feeCredit.installment,
        label: `${feeCredit.installment}x`,
      })) || []
    );
  }, [value, cardFlagValue]);

  const getAcquirers = useCallback(async () => {
    try {
      const { data } = await getAllAcquirerByOperation(props.details.operation_id);

      setAcquirers(data);
    } catch (e) {
      console.log(e);
    }
  }, [props.details.operation_id]);

  useEffect(() => {
    if (!props.withoutIntegration.includes(type) && !acquirers) {
      getAcquirers();
    }
  }, [type]);

  return (
    <Styles.PaymentContainer hidden={hidden}>
      <Scope path={props.name}>
        <Flex column fullWidth>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Flex fullWidth>
                <Flex flex={1}>
                  <UnPaymentMethodGroup
                    name="type"
                    row
                    onChange={event => seType(event.target.value)}
                    disabled={!!props?.canEdit}
                    defaultValue={type}
                    options={[
                      {
                        label: tPage('detail.transaction_types.MONEY'),
                        id: CASH_DESK_PAYMENT_METHOD.MONEY,
                        value: CASH_DESK_PAYMENT_METHOD.MONEY,
                      },
                      {
                        label: tPage('detail.transaction_types.PIX'),
                        id: CASH_DESK_PAYMENT_METHOD.PIX,
                        value: CASH_DESK_PAYMENT_METHOD.PIX,
                      },
                      // {
                      //   label: tPage('detail.transaction_types.DEBIT'),
                      //   id: CASH_DESK_PAYMENT_METHOD.DEBIT,
                      //   value: CASH_DESK_PAYMENT_METHOD.DEBIT,
                      // },
                      // {
                      //   label: tPage('detail.transaction_types.CREDIT'),
                      //   id: CASH_DESK_PAYMENT_METHOD.CREDIT,
                      //   value: CASH_DESK_PAYMENT_METHOD.CREDIT,
                      // },
                      {
                        label: tPage('detail.transaction_types.DEBIT_POS'),
                        id: CASH_DESK_PAYMENT_METHOD.DEBIT_POS,
                        value: CASH_DESK_PAYMENT_METHOD.DEBIT_POS,
                      },
                      {
                        label: tPage('detail.transaction_types.CREDIT_POS'),
                        id: CASH_DESK_PAYMENT_METHOD.CREDIT_POS,
                        value: CASH_DESK_PAYMENT_METHOD.CREDIT_POS,
                      },
                    ]}
                  />
                </Flex>

                <Flex>
                  <Button
                    disabled={props.disableRemove}
                    onClick={() => {
                      if (props.payment?.id) setHidden(true);
                      props.onClickRemove(props.onChangeUnique);
                    }}
                    variant="cancel"
                  >
                    Remover
                  </Button>
                </Flex>
              </Flex>
            </Grid>

            <Grid item md={3} sm={2}>
              <UnMaskInput
                mask="currency"
                onChange={debounce((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  props.onChangeValue(e, props.onChangeUnique);

                  setValue(currencyToFloat(e.target.value));
                  if (formRef.current?.getFieldValue(`${props.name}.installment`))
                    formRef.current.setFieldValue(`${props.name}.installment`, null);
                }, 500)}
                parseToFloat
                disabled={!!props?.canEdit}
                cleanValue
                name="value"
                label="pages/cashDesk:create.fieldset.payment.amountPay"
                placeholderAlternative={t('currency', { value: 0 })}
              />
            </Grid>

            <Grid item md={3} sm={2}>
              <UnAcquirer
                hidden={props.withoutIntegration.includes(type)}
                name="acquirer"
                returnAllOption
                onChange={value => {
                  setAcquirerValue(value);
                  formRef.current?.setFieldValue(`${props.name}.card_flag`, null);
                  formRef.current?.setFieldValue(`${props.name}.installment`, null);
                  setCardFlagValue(null);
                }}
                disabled={props.tefTypes.includes(props.type)}
                operationId={props.details.operation_id}
                acquirers={acquirers}
                value={acquirerValue}
              />
            </Grid>

            <Grid item md={3} sm={2}>
              <UnCarFlag
                hidden={props.withoutIntegration.includes(type)}
                returnAllOption
                name="card_flag"
                onSelect={value => {
                  setCardFlagValue(value || null);
                  formRef.current?.setFieldValue(`${props.name}.installment`, null);
                }}
                disabled={props.tefTypes.includes(props.type)}
                value={cardFlagValue}
                cardFlags={acquirerValue?.cardFlags || []}
              />
            </Grid>

            <Grid item md={3} sm={2}>
              {props.installmentTypes?.includes(type) && (
                <UnAutocomplete
                  name="installment"
                  optionValue="value"
                  optionLabel="label"
                  isOptionEqualToValue={(option, value) => option.value === String(value)}
                  disabled={!!props?.canEdit}
                  label="pages/cashDesk:create.fieldset.payment.installment"
                  value={{
                    label: `${props.payment.installment}x`,
                    value: props.payment.installment,
                  }}
                  options={installmentOptions}
                />
              )}
            </Grid>

            {!props.withoutIntegration.includes(type) && (
              <Grid item md={3} sm={2}>
                <UnInputFIeld
                  name="authorization"
                  label="pages/cashDesk:create.fieldset.payment.authorization"
                />
              </Grid>
            )}

            {props.tefTypes.includes(type) && (
              <Grid item xs={12}>
                <UnMaskInput
                  mask="currency"
                  // onChange={props.onChangeValue}
                  parseToFloat
                  disabled={!!props?.canEdit}
                  cleanValue
                  name="fee"
                  label="pages/cashDesk:create.fieldset.payment.fee"
                  placeholderAlternative={t('currency', { value: 0 })}
                />
              </Grid>
            )}
          </Grid>
          <UnMaskInput name="order_id" hidden parseToFloat cleanValue style={{ display: 'none' }} />
          <UnMaskInput name="id" hidden parseToFloat cleanValue style={{ display: 'none' }} />
          <UnHiddenInput name="hidden" value={hidden} />
        </Flex>
      </Scope>

      <hr />
    </Styles.PaymentContainer>
  );
}

export const Payment = BasePayment;

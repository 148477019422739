import { Redirect, Switch, Router } from 'react-router-dom';
import { isAdminApplication, isClientApplication } from 'utils/application';
import { history } from 'utils/history';
import { PublicRoutes } from './PublicRoutes';
import { AdminLayoutRoutes, SecondaryLayoutRoutes } from './withLayout';
import { PrimaryClientLayoutRoutes } from './withLayout/PrimaryClient';

const getRedirectRoute = () => (isClientApplication() ? '/' : '/login');

function RootRoutes() {
  return (
    <Router history={history}>
      <Switch>
        {isAdminApplication() && SecondaryLayoutRoutes()}
        {isAdminApplication() && AdminLayoutRoutes()}
        {isAdminApplication() && PublicRoutes()}

        {isClientApplication() && PrimaryClientLayoutRoutes()}

        <Redirect to={getRedirectRoute()} />
      </Switch>
    </Router>
  );
}

export default RootRoutes;

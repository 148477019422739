import { ComponentProps, memo, ReactNode } from 'react';

import { Dialog } from '@mui/material';

type DialogProps = ComponentProps<typeof Dialog>;

interface ModalProps extends Pick<DialogProps, 'open' | 'fullWidth' | 'maxWidth'> {
  children: ReactNode;
  onClose?: () => void;
}

function BaseModal({ children, ...props }: ModalProps) {
  if (!props.open) return null;

  return <Dialog {...props}>{children}</Dialog>;
}

export const RootModal = memo(BaseModal);

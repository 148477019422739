import {
  Button,
  Column,
  DateFilter,
  OperationFilter,
  PaginatedTable,
  SearchFilter,
} from 'components';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { getDownloadQRCode, getQrcode as getQrcodeApi } from 'services/qrcode';
import { Flex } from 'styles/Flex';
import * as DefaultStyles from 'styles/Listing';
import { Meta } from 'types/api';
import { GetQRCOde, GetQRCodeQueries } from 'types/QRCode';
import { downloadFile } from 'utils/api';
import { pushHistoryQuery, queryToObj } from 'utils/query';
import { CreateQRCode } from '../components';
import * as Styles from './styles';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListingQRCode({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/qrCode');
  const { t } = useTranslation();

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [isVisibleCreateQRCodeModal, setIsVisibleCreateQRCodeModal] = useState(false);

  const [qrCodes, setQrCodes] = useState<GetQRCOde[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getQrCode = async (query?: GetQRCodeQueries) => {
    try {
      toggleIsLoading();

      const { data } = await getQrcodeApi(query);

      setMeta(data.meta);
      setQrCodes(data.data);
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const handleDownloadQRCode = async (id: string | number) => {
    const { data, headers } = await getDownloadQRCode(id);

    downloadFile(data, headers?.filename);
  };

  const column: Column[] = [
    {
      field: 'created_at',
      headerName: tPage('listing.table.date'),
    },
    {
      field: 'user',
      headerName: tPage('listing.table.user'),
    },
    {
      field: 'operation',
      headerName: tPage('listing.table.operation'),
    },
    {
      field: 'amount',
      headerName: tPage('listing.table.amount'),
    },
    {
      field: 'download',
      headerName: '',
    },
  ];

  const rows = useMemo(
    () =>
      qrCodes.map(qrCode => ({
        id: qrCode?.id,
        created_at: t('date', { date: new Date(qrCode?.created_at || '') }),
        operation: qrCode?.operation?.name,
        amount: qrCode?.amount,
        user: qrCode?.user?.fullName,
        download: (
          <Button
            variant="base"
            onClick={() => handleDownloadQRCode(qrCode.id)}
            icon={{
              name: 'download',
            }}
          >
            Download
          </Button>
        ),
      })),
    [qrCodes],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as GetQRCodeQueries;

      getQrCode(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getQrCode(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <CreateQRCode
        visible={isVisibleCreateQRCodeModal}
        onClose={() => setIsVisibleCreateQRCodeModal(false)}
        onFinished={() => getQrCode(historyQuery)}
      />
      <Flex column gap={1.5}>
        <h1>{tPage('listing.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <DefaultStyles.SelectContainer>
                <OperationFilter />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <DateFilter placeholder="labels:inventory.invoiceDate" />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <SearchFilter
                  label="labels:input.qrcode"
                  startIcon="photoCamera"
                  keyQuery="code"
                  defaultValue={historyQuery?.status as any}
                />
              </DefaultStyles.SelectContainer>
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => setIsVisibleCreateQRCodeModal(true)}
            >
              {tPage('listing.actionButton')}
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={column}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

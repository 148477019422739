import { useCallback } from 'react';
import { useStore } from 'store';

export const useHome = () => {
  const { state, actions } = useStore(useCallback(state => state.home, []));

  const handleIncrease = useCallback(() => {
    actions.increaseCount();
  }, []);

  return {
    count: state.count,
    increaseCount: handleIncrease,
  };
};

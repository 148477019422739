import { Grid } from '@mui/material';
import { Button, ConfirmModal, IconButton, Tabs, TextField } from 'components';
import { routePaths } from 'constants/routes';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { FormEvent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { getPriceListById, postProductList } from 'services/priceList';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { openNotification } from 'utils/notification';
import { Combo, Digital, Physical } from './components';
import * as Styles from './styles';

interface PriceList {
  name: string;
  id: number | string;
}

function BaseCreatePriceList({ history, match }: RouteComponentProps<{ id?: string }>) {
  const [tPage] = useTranslation('pages/priceList');
  const [t] = useTranslation();

  const [priceListName, setPriceListName] = useState('');
  const [priceList, setPriceList] = useState<PriceList>();

  const [isCancelModal, toggleCancelModal] = useBooleanToggle(false);
  const [isSavingPriceListName, toggleIsSavingPriceListName] = useBooleanToggle(false);
  const [isSaved, setIsSaved] = useState(false);

  const id = match.params.id;

  const getPriceList = async (id: string | number) => {
    const { data } = await getPriceListById(id);

    setPriceList(data);
    setPriceListName(data.name);
  };

  const handleSavePriceList = async (event: FormEvent) => {
    event.preventDefault();

    if (!!id || isSaved) {
      history.push(routePaths.priceList.list);
      return;
    }

    try {
      toggleIsSavingPriceListName();

      const { data } = await postProductList({
        name: priceListName,
      });

      setPriceList({
        name: data.name,
        id: data.id,
      });
      setIsSaved(true);
      openNotification(tPage(id ? 'create.editMessage' : 'create.saveMessage'));
    } finally {
      toggleIsSavingPriceListName();
    }
  };

  useEffect(() => {
    if (!id) return;

    getPriceList(id);
  }, []);

  return (
    <PageContainer md>
      <ConfirmModal
        open={isCancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={toggleCancelModal}
        onConfirm={() => history.push(routePaths.priceList.list)}
      />
      <Flex column gap={1.62}>
        <Flex alignItems="center" gap={1}>
          <IconButton
            icon={{ name: 'arrowBack', size: 1.4 }}
            onClick={
              !priceList?.id ? toggleCancelModal : () => history.push(routePaths.priceList.list)
            }
          />
          <h1>{tPage('create.title')}</h1>
        </Flex>
        <Styles.Card>
          <Styles.Form onSubmit={handleSavePriceList}>
            <Styles.Container>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={!!id || isSaved}
                    clearHelperTextSpacing
                    label="pages/priceList:create.registerFieldTableName"
                    onChange={event => setPriceListName(event.target.value)}
                    value={priceListName}
                  />
                </Grid>
                <Grid item>
                  <Button isLoading={isSavingPriceListName} type="submit">
                    {tPage(!!id || isSaved ? 'create.finishButton' : 'create.actionButton')}
                  </Button>
                </Grid>
              </Grid>
            </Styles.Container>
          </Styles.Form>
          {priceList?.id && (
            <Tabs
              tabs={[
                { label: tPage('create.tabs.digitalProducts') },
                { label: tPage('create.tabs.physicalProducts') },
                { label: tPage('create.tabs.comboProducts') },
              ]}
            >
              <Digital priceListId={priceList?.id} />
              <Physical priceListId={priceList?.id} />
              <Combo priceListId={priceList?.id} />
            </Tabs>
          )}
        </Styles.Card>
      </Flex>
    </PageContainer>
  );
}

export const CreatePriceList = memo(BaseCreatePriceList);

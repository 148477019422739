import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { IconButton, Button, Tabs } from 'components';
import { Info, Logs } from './components';

import { resolvePath, routePaths } from 'constants/routes';

import * as DefaultStyles from 'styles/Details';
import { Flex } from 'styles/Flex';

export function DetailProduct(props: RouteComponentProps<{ id: string }>) {
  const { t } = useTranslation('pages/details');

  const productId = props.match.params.id;

  return (
    <DefaultStyles.Container>
      <Flex alignItems="center" wrap gap={1} justifyContent="space-between">
        <Flex alignItems="center" gap={1}>
          <IconButton
            icon={{ name: 'arrowBack', size: 1.4 }}
            onClick={() => props.history.goBack()}
          />
          <h1 style={{ margin: 0 }}>{t('product.title')}</h1>
        </Flex>
        <Button
          onClick={() =>
            props.history.push(resolvePath(routePaths.products.edit, { id: productId }))
          }
        >
          {t('pages/details:editButton')}
        </Button>
      </Flex>
      <DefaultStyles.Wrapper>
        <Tabs tabs={[{ label: 'Dados do produto' }, { label: 'Log de alteração' }]}>
          <Info id={productId} />
          <Logs id={productId} />
        </Tabs>
      </DefaultStyles.Wrapper>
    </DefaultStyles.Container>
  );
}

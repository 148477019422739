import { ComponentProps, memo, useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormHandles, SubmitHandler } from '@unform/core';

import { Button, Column, RootModal, Table, Unform, UnInputFIeld, IconButton } from 'components';
import { UpdateInput } from './UpdateInput';

import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { createCardFlag, putCardFlagById } from 'services/cardFlag';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { createCardFlagSchemaValidation } from 'yupSchemas/cardFlag';

import { useCardFlags } from 'hooks/useCardFlags';

import { UpdateCardFlag } from 'types/cardFlag';
import { openNotification } from 'utils/notification';

type RootModalProps = Pick<ComponentProps<typeof RootModal>, 'open'>;

interface ManagmentProps extends RootModalProps {
  onClose?: () => void;
}

interface Details {
  name: string;
}

const FIELDS = {
  flag: 'flag',
};

function BaseManagment({ open, onClose }: ManagmentProps) {
  const [tPage] = useTranslation('pages/acquire');
  const [t] = useTranslation();
  const formRef = useRef<FormHandles>(null);

  const {
    data: cardFlags,
    isLoading: isLoadingCardFlags,
    getData: getCardFlag,
  } = useCardFlags({ getOnInit: true });

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const [details] = useState<Details>();

  const handleUpdate = async (id: string | number, payload: UpdateCardFlag) => {
    try {
      await putCardFlagById(id, payload);
      openNotification(t('editMessage'));
    } finally {
      getCardFlag();
    }
  };

  const handleSubmit: SubmitHandler = async payload => {
    try {
      toggleIsLoading();
      await createCardFlag(payload);
      await getCardFlag();
      formRef.current?.reset();
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const rows = cardFlags.map(value => ({
    [FIELDS.flag]: (
      <UpdateInput defaultValue={value.name} onSubmit={name => handleUpdate(value.id, { name })} />
    ),
  }));

  const columns: Column[] = [
    {
      field: FIELDS.flag,
      headerName: tPage('listing.table.flag'),
    },
  ];

  useEffect(() => {
    if (!open) return;

    getCardFlag();
  }, [open]);

  return (
    <RootModal open={open} onClose={onClose}>
      <Styles.Container>
        <Flex marginBottom={2.8} justifyContent="space-between" alignItems="center">
          <Styles.Title>{tPage('listing.managment.title')}</Styles.Title>
          <IconButton onClick={onClose} icon={{ name: 'close' }} />
        </Flex>
        <Unform
          ref={formRef}
          initialData={details}
          onSubmit={handleSubmit}
          validationSchema={createCardFlagSchemaValidation}
        >
          <Flex column gap={2}>
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item xs={12} sm={8}>
                <UnInputFIeld name="name" label="pages/acquire:listing.managment.form.name" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button isLoading={isLoading} fullWidth type="submit">
                  {tPage('listing.managment.form.done')}
                </Button>
              </Grid>
            </Grid>
          </Flex>
        </Unform>
      </Styles.Container>
      <hr />
      <Styles.Container>
        <Table
          tableVariant="secondary"
          isLoading={isLoadingCardFlags}
          columns={columns}
          rows={rows}
        />
      </Styles.Container>
    </RootModal>
  );
}

export const Managment = memo(BaseManagment);

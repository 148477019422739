import { memo, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { PaginatedTable } from 'components';

import { Flex } from 'styles/Flex';

import { INVENTORY_STATUS } from 'constants/inventory';

import { Meta } from 'types/api';
import useBooleanToggle from 'hooks/useBooleanToggle';

import { getLogInventoryEntry as getLogInventoryEntryApi } from 'services/inventory';

interface Log {
  status: string;
}

interface LogData {
  createdAt: string;
  user: string;
  log: Log;
}

interface LogProps {
  id: string | number;
}

function BaseLogs(props: LogProps) {
  const [logs, setLogs] = useState<LogData[]>([]);
  const [meta, setMeta] = useState<Partial<Meta>>({});

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const { t } = useTranslation();

  const getLogInventoryEntry = async (id: string | number) => {
    try {
      toggleIsLoading();

      const { data } = await getLogInventoryEntryApi(id, {
        page: currentPage,
      });

      const newLogs: LogData[] = data.data.map(log => ({
        createdAt: log.created_at,
        user: log.user.fullName,
        log: {
          status: log.status,
        },
      }));

      setMeta(data.meta);
      setLogs(newLogs);
    } finally {
      toggleIsLoading();
    }
  };

  const parseLogLabel = (_log: Log) => {
    const type = {
      [INVENTORY_STATUS.completed]: <Trans i18nKey="pages/inventory:log.messages.create" />,
      [INVENTORY_STATUS.progress]: <Trans i18nKey="pages/inventory:log.messages.progreess" />,
      [INVENTORY_STATUS.canceled]: <Trans i18nKey="pages/inventory:log.messages.canceled" />,
    };

    return type[_log.status];
  };

  const logParse = logs.map(log => ({
    label: parseLogLabel(log.log),
    user: log.user,
    createdAt: t('dateHours', { date: log.createdAt ? new Date(log.createdAt) : '' }),
  }));

  const columns = [
    { field: 'label', headerName: t('pages/inventory:log.table.label') },
    { field: 'user', headerName: t('pages/inventory:log.table.user') },
    { field: 'createdAt', headerName: t('pages/inventory:log.table.createdAt') },
  ];

  useEffect(() => {
    getLogInventoryEntry(props.id);
  }, [currentPage]);

  return (
    <Flex column gap={2.26} alignItems="flex-start">
      <PaginatedTable
        tableVariant="secondary"
        count={totalPages}
        rows={logParse}
        columns={columns}
        isLoading={isLoading}
        onPageChange={(event, _page) => setCurrentPage(_page)}
      />
    </Flex>
  );
}

export const Logs = memo(BaseLogs);

import { Grid } from '@mui/material';
import {
  Column,
  Icon,
  MaskInput,
  PaginatedTable,
  SearchFilter,
  SearchProductByNameFilter,
  Tooltip,
} from 'components';
import { ChangeEvent, FocusEvent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getComboPriceList, postPrice } from 'services/priceList';
import { Flex } from 'styles/Flex';
import { Meta } from 'types/api';
import { CreatePrice, GetProductPriceListQueries } from 'types/priceList';
import { debounce } from 'utils';
import { currencyToFloat, floatToCurrency, truncateString } from 'utils/help';
import * as Styles from './styles';

interface ComboProps {
  priceListId: string | number;
}

interface FieldPrice {
  value: string;
}

interface Product {
  name: string;
  comboTotal: number;
  comboValue?: number;
  discont?: number;
  id: string | number;
  haveUnpricedProducts?: boolean;
}

function BaseCombo({ priceListId }: ComboProps) {
  const [tPage] = useTranslation('pages/priceList');
  const [t] = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState<Product[]>([]);
  const [fieldsPrice, setFieldPrice] = useState<FieldPrice[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>({});

  const [search, setSearch] = useState('');

  const parseDiscont = (value: string, totalValue: number) => {
    const parseValue = currencyToFloat(value) || 0;

    const discont = totalValue - parseValue;

    return discont < totalValue && discont > 0 ? discont : 0;
  };

  const getProducts = async (priceListId: string | number, query?: GetProductPriceListQueries) => {
    const { data } = await getComboPriceList(priceListId, query);

    const mapProducts: Product[] = data.data.map(value => ({
      comboTotal: value.totalPriceProducts,
      name: value.name,
      discont: parseDiscont(String(value.priceList?.price || 0), value.totalPriceProducts),
      id: value.id,
      haveUnpricedProducts: value.haveUnpricedProducts,
    }));

    setProducts(mapProducts);
    setMeta(data.meta);

    setFieldPrice(
      data.data.map(value =>
        value.priceList
          ? {
              value: value?.priceList?.price ? floatToCurrency(value?.priceList?.price) : '',
            }
          : {
              value: '',
            },
      ),
    );
  };

  const handleChangePrice = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const { value } = event.target;

    setFieldPrice(prevState =>
      prevState.map((product, _index) => (_index === index ? { ...product, value } : product)),
    );

    const refrashDiscont: Product[] = products.map((product, _index) =>
      _index === index
        ? {
            ...product,
            discont: parseDiscont(value, product.comboTotal),
          }
        : product,
    );

    setProducts(refrashDiscont);
  };

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearch(value);
    getProducts(priceListId, {
      page: currentPage,
      search: value,
    });
  };

  const handleBlurPrice = async (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    productId: string | number,
    index: number,
  ) => {
    const { value } = event.target;
    const parsedPriceStringToNumber = currencyToFloat(value) || 0;

    setFieldPrice(prevState =>
      prevState.map((product, _index) =>
        _index === index
          ? {
              ...product,
              value: parsedPriceStringToNumber ? floatToCurrency(parsedPriceStringToNumber) : '',
            }
          : product,
      ),
    );

    const payload: CreatePrice = {
      price: parsedPriceStringToNumber,
      price_list_id: Number(priceListId),
      product_id: Number(productId),
    };

    await postPrice(payload);
  };

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const columns: Column[] = [
    {
      field: 'name',
      headerName: tPage('create.table.combo.name'),
      _style: {
        width: '100%',
      },
    },
    {
      field: 'comboTotal',
      headerName: tPage('create.table.combo.totalValue'),
    },
    {
      field: 'comboValue',
      headerName: tPage('create.table.combo.comboValue'),
      _style: {
        minWidth: '15rem',
      },
    },
    {
      field: 'discont',
      headerName: tPage('create.table.combo.discount'),
      _style: {
        minWidth: '15rem',
      },
    },
    {
      field: 'warn',
      headerName: '',
    },
  ];

  const _rows = products.map((product, index) => ({
    name: (
      <Tooltip title={product.name}>
        <span>{truncateString(product.name, 30)}</span>
      </Tooltip>
    ),
    comboTotal: (
      <Styles.Warn isActive={product?.haveUnpricedProducts}>
        {t('currency', { value: product?.comboTotal })}
      </Styles.Warn>
    ),
    comboValue: (
      <Flex width={10}>
        <MaskInput
          mask="currency"
          placeholderAlternative={t('currency', { value: 0 })}
          clearHelperTextSpacing
          value={fieldsPrice[index]?.value}
          onChange={event => handleChangePrice(event, index)}
          onBlur={event => handleBlurPrice(event, product.id, index)}
        />
      </Flex>
    ),
    discont: (
      <Styles.Warn isActive={product?.haveUnpricedProducts}>
        {t('currency', { value: product?.discont })}
      </Styles.Warn>
    ),
    warn: product?.haveUnpricedProducts && (
      <Tooltip title={`${t('pages/priceList:create.table.haveUnpricedProducts')}`}>
        <Styles.WarnContainer>
          <Icon name="errorOutline" size={1.5} color="red" />
        </Styles.WarnContainer>
      </Tooltip>
    ),
  }));

  useEffect(() => {
    getProducts(priceListId, {
      search,
      page: currentPage,
    });
  }, [currentPage]);

  return (
    <Grid container gap="1rem">
      <Grid item xs={12} sm={3}>
        <SearchFilter onChange={debounce(handleSearch)} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <SearchProductByNameFilter
          types={['DIGITAL', 'PHYSICAL']}
          queryKey="productId"
          onChange={product => {
            getProducts(priceListId, {
              search,
              productId: product.id,
              page: currentPage,
            });
          }}
          queryValue="id"
          placeholder="pages/priceList:create.filters.contains"
        />
      </Grid>
      <PaginatedTable
        tableVariant="secondary"
        count={totalPages}
        columns={columns}
        rows={_rows}
        onPageChange={(event, _page) => setCurrentPage(_page)}
      />
    </Grid>
  );
}

export const Combo = memo(BaseCombo);

import { Grid } from '@mui/material';
import { FormHandles, SubmitHandler } from '@unform/core';
import {
  Button,
  Unform,
  UnInputFIeld,
  UnMaskInput,
  UnOperationArray,
  UnUnCardFlagArray,
} from 'components';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Flex } from 'styles/Flex';
import { Legend } from 'styles/Legend';
import { SimpleOperationList } from 'types/operation';
import { createBaseAcquire } from 'yupSchemas/acquire';
import { useFormContext } from '..';

import * as Styles from '../../styles';

export interface DetailsBaseForm {
  card_flags: {
    id: number;
    name: string;
  }[];
  operations: SimpleOperationList[];
  cnpj: string;
  name: string;
}

interface BaseFormProps {
  onFoward: () => void;
  data?: Partial<DetailsBaseForm>;
}

function BaseBaseForm({ onFoward, data }: BaseFormProps) {
  const [tPage] = useTranslation('pages/acquire');

  const { id } = useParams<{ id: string }>();

  const formRef = useRef<FormHandles>(null);

  const context = useFormContext();

  const [dedtails, setDetails] = useState<Partial<DetailsBaseForm>>(data || {});

  const handleSubmit: SubmitHandler<DetailsBaseForm> = payload => {
    context?.saveForm('base', payload);
    onFoward();
  };

  const handleRemoveCardFlagFromDeleteList = (option: any) => {
    if (id) return;

    context?.setListCardFlagDeleted?.(prevState =>
      prevState.includes(option.id) ? prevState.filter(value => value !== option.id) : prevState,
    );
  };

  const handleRemoveOperationFromDeleteList = (option: any) => {
    if (id) return;

    context?.setListOperationDeleted?.(prevState =>
      prevState.includes(option.id) ? prevState.filter(value => value !== option.id) : prevState,
    );
  };

  useEffect(() => {
    if (!context?.data?.base) return;

    setDetails(context.data.base);
  }, [context?.data]);

  return (
    <Unform
      ref={formRef}
      initialData={dedtails}
      validationSchema={createBaseAcquire}
      onSubmit={handleSubmit}
    >
      <Styles.Container>
        <Legend>{tPage('create.fieldset.acquire.title')}</Legend>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <UnMaskInput
              mask="cnpj"
              name="cnpj"
              label="pages/acquire:create.fieldset.acquire.cnpj"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UnInputFIeld name="name" label="pages/acquire:create.fieldset.acquire.acquireName" />
          </Grid>
        </Grid>
      </Styles.Container>
      <hr />
      <Styles.Container>
        <Legend>{tPage('create.fieldset.flagLink.title')}</Legend>
        <UnUnCardFlagArray
          name="card_flags"
          label="pages/acquire:create.fieldset.flagLink.flagSelect"
          onRemoveRow={option => {
            context?.setListCardFlagDeleted?.(prevState => [...prevState, Number(option.id)]);
          }}
          onSelect={handleRemoveCardFlagFromDeleteList}
        />
      </Styles.Container>
      <hr />
      <Styles.Container>
        <Legend>{tPage('create.fieldset.operationSelect.title')}</Legend>
        <UnOperationArray
          name="operations"
          onRemoveRow={option => {
            context?.setListOperationDeleted?.(prevState => [...prevState, Number(option.id)]);
          }}
          onSelect={handleRemoveOperationFromDeleteList}
        />
      </Styles.Container>
      <hr />
      <Styles.Container>
        <Flex flex={1} justifyContent="flex-end" gap={2}>
          <Button type="submit">{tPage('create.next')}</Button>
        </Flex>
      </Styles.Container>
    </Unform>
  );
}

export const BaseForm = memo(BaseBaseForm);

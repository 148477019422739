import { ComponentProps, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import * as Styles from 'styles/Details';
import { Flex } from 'styles/Flex';

import { Button, IconButton, LoadingMessage, TabOption, Tabs } from 'components';

import { Base, Tax } from './components';

import { getAcquirerById } from 'services/acquire';

import { GetAcquirerById } from 'types/acquire';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { resolvePath, routePaths } from 'constants/routes';

type DataBase = Partial<Pick<ComponentProps<typeof Base>, 'data'>['data']>;

function BaseDetailAcquirer(props: RouteComponentProps<{ id: string }>) {
  const [tPage] = useTranslation('pages/acquire');

  const [details, setDetails] = useState<Partial<GetAcquirerById>>();

  const [isLoading, toggleIsLoading] = useBooleanToggle(true);

  const id = props.match.params.id;

  const getInitialData = async (id: number | string) => {
    try {
      const { data } = await getAcquirerById(id);
      setDetails(data);
    } finally {
      toggleIsLoading();
    }
  };

  const baseData: DataBase = {
    cnpj: details?.cnpj,
    name: details?.name,
    operations: details?.operations?.map(value => ({
      name: value?.name,
      id: value?.id,
    })),
    card_flags: details?.card_flags,
  };

  const taxData =
    details?.acquirer_vincule && details?.acquirer_vincule.length > 0
      ? details?.acquirer_vincule?.map(acquirerVincule => {
          const operation = details?.operations?.find(
            operation => operation.id === Number(acquirerVincule.operation_id),
          );

          const cardFlags = acquirerVincule.card_flags
            ?.map(cardFlag => cardFlag.installments)
            ?.reduce((prev, next) => [...prev, ...next])
            ?.map(value => ({
              fee_value: value.fee_value,
              installment: value.installment,
              type: value.type,
            }));

          return {
            operation_name: operation?.name || '',
            installments: cardFlags,
          };
        })
      : [];

  const tabs: TabOption[] = [
    {
      label: tPage('details.tabs.acquirer'),
    },
    {
      label: tPage('details.tabs.tax'),
    },
  ];

  useEffect(() => {
    if (!id) return;

    getInitialData(id);
  }, [id]);

  if (isLoading) return <LoadingMessage text="Carregando..." />;

  return (
    <Styles.Container>
      <Flex alignItems="center" wrap gap={1} justifyContent="space-between">
        <Flex alignItems="center" gap={1}>
          <IconButton
            icon={{ name: 'arrowBack', size: 1.4 }}
            onClick={() => props.history.goBack()}
          />
          <h1 style={{ margin: 0 }}>{tPage('details.title')}</h1>
        </Flex>
        <Button onClick={() => props.history.push(resolvePath(routePaths.acquire.edit, { id }))}>
          {tPage('details.editButton')}
        </Button>
      </Flex>
      <Styles.Wrapper>
        {details && (
          <Tabs tabs={tabs}>
            <Base data={baseData} />
            <Tax data={taxData || []} />
          </Tabs>
        )}
      </Styles.Wrapper>
    </Styles.Container>
  );
}

export const DetailAcquirer = memo(BaseDetailAcquirer);

// onClick={() => props.history.push(resolvePath(routePaths.goal.edit, { id }))}

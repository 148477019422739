import {
  Autocomplete as MUIAutocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
} from '@mui/material';
import { LoadingMessage, TextField, TextFieldProps } from 'components';
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';

interface IProps
  extends Omit<
    AutocompleteProps<any, boolean, boolean, boolean, 'div'>,
    'renderInput' | 'options'
  > {
  optionValue?: string;
  optionLabel?: string;
  options?: any[];
  label?: string;
  error?: boolean;
  noOptionsText?: string;
  loadingText?: string;
  helperText?: TextFieldProps['helperText'];
  clearHelperTextSpacing?: TextFieldProps['clearHelperTextSpacing'];
  contained?: boolean;
}

// eslint-disable-next-line react/display-name
const AutocompleteComponent = forwardRef<unknown, IProps>(
  (
    {
      options = [],
      optionLabel = 'label',
      label,
      noOptionsText = 'autocomplete.defaultNoOptionsText',
      loadingText = 'autocomplete.defaultLoadingText',
      error,
      helperText,
      loading,
      clearHelperTextSpacing,
      contained,
      defaultValue,
      optionValue = 'value',
      onChange,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const _defaultValue = () => {
      if (defaultValue) {
        return (
          options?.find(option => `${option[optionValue]}` === `${defaultValue}`) ||
          defaultValue ||
          null
        );
      }
      return defaultValue || null;
    };

    const [value, setValue] = useState<Record<string, any>>(props?.value || _defaultValue());

    useEffect(() => {
      if (props?.value !== undefined) {
        setValue(props.value);
      }
    }, [props?.value]);

    const handleChange = (
      event: React.SyntheticEvent<Element, Event>,
      value: any,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<any> | undefined,
    ) => {
      onChange?.(event, value, reason, details);
      setValue(value);
    };

    const handleSetValue = useCallback(
      newValue => {
        if (typeof newValue === 'object') {
          setValue(newValue);
          return;
        }

        const newObjValue = options?.find(option => option[optionValue] === newValue);

        newObjValue && setValue(newObjValue);
      },
      [options],
    );

    useImperativeHandle(ref, () => ({ value, setValue: handleSetValue }), [value]);

    return (
      <Styles.Container contained={contained}>
        <MUIAutocomplete
          {...props}
          value={value}
          onChange={handleChange}
          defaultValue={_defaultValue()}
          noOptionsText={t(noOptionsText)}
          loadingText={t(loadingText)}
          ref={ref}
          isOptionEqualToValue={
            optionValue || !props?.isOptionEqualToValue
              ? option => {
                  return (
                    option[optionValue as string] ===
                    (typeof value[optionValue as string] !== 'undefined'
                      ? value[optionValue as string]
                      : value.value)
                  );
                }
              : props.isOptionEqualToValue
          }
          renderInput={params => (
            <TextField
              {...params}
              helperText={helperText}
              clearHelperTextSpacing={clearHelperTextSpacing}
              label={label}
              error={error}
              placeholder={props.placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <LoadingMessage size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          getOptionLabel={option => props.getOptionLabel?.(option) || option[optionLabel] || ''}
          options={options}
        />
      </Styles.Container>
    );
  },
);

export const Autocomplete = memo(AutocompleteComponent);

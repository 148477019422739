import styled from 'styled-components';

export const Container = styled.div<{ contained?: boolean }>`
  width: 100%;

  .MuiOutlinedInput-root {
    padding-top: 0;
    padding-bottom: 0;
    background-color: ${({ theme, contained }) => contained && theme.colors.foreground};
  }
`;

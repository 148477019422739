import {
  memo,
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
  SetStateAction,
  useCallback,
} from 'react';

import { getGoalById, getSubTypesGoal, postGoal, putGoal } from 'services/goal';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { CreateGoalRequest, EntityGoal, SubTypeGoal } from 'types/goal';
import { DetailsProduct } from './Monetize/components/ProductsGoal';

import { DetailsFormBase } from './BaseParameters';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GOAL_OPERATION_TYPE } from 'constants/goal';
import { openNotification } from 'utils/notification';
import { routePaths } from 'constants/routes';
import { LoadingMessage } from 'components';

interface ProductDelete {
  user_id?: number | string;
  operation_id?: number | string;
  id: number | string;
}

interface GoalProviderProps {
  children: ReactNode;
}

interface GoalContextData {
  base?: Partial<DetailsFormBase>;
  billing?: Partial<EntityGoal>;
  capita_billing?: Partial<EntityGoal>;
  capture_sale?: Partial<EntityGoal>;
  products_goal?: Partial<DetailsProduct>;
}

export type GoalKeys = keyof GoalContextData;

export interface GoalContextProps {
  data: Partial<GoalContextData>;
  selectedSubTypes: number[];
  subTypes: SubTypeGoal[];
  canSubmit?: boolean;
  isLoading?: boolean;
  onSubmit: (key: GoalKeys, payload: any) => Promise<void>;
  toggleSelectedSubType: (id: number) => void;
  setListUserDeleted: (value: SetStateAction<number[]>) => void;
  setListOperationDeleted: (value: SetStateAction<number[]>) => void;
  setType: (type: string) => void;
  saveData: (key: GoalKeys, payload: GoalContextData[GoalKeys]) => void;
  setListProductDeleted: (value: SetStateAction<ProductDelete[]>) => void;
}

type PayloadRequest = Omit<GoalContextData, 'base'> & {
  base: DetailsFormBase;
};

const GoalContext = createContext<GoalContextProps | undefined>(undefined!);

function BaseGoalProvider({ children }: GoalProviderProps) {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  const [data, setData] = useState<GoalContextData>({});

  const [selectedSubTypes, setSelectedSubTypes] = useState<number[]>([]);
  const [subTypes, setSubTypes] = useState<SubTypeGoal[]>([]);

  const [listUserDeleted, setListUserDeleted] = useState<number[]>([]);
  const [listOperationDeleted, setListOperationDeleted] = useState<number[]>([]);
  const [listProductDeleted, setListProductDeleted] = useState<ProductDelete[]>([]);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [isInitialLoading, toggleIsInitialLoading] = useBooleanToggle(true);

  const tabsLength = [
    data?.base?.isCapitaBilling,
    data?.base?.isCaptureSale,
    data?.base?.isBilling,
    data?.base?.isProductsGoal,
  ].filter(tab => !tab).length;

  const canSubmit =
    [
      data?.base?.isBilling && !!data?.billing,
      data?.base?.isCapitaBilling && !!data?.capita_billing,
      data?.base?.isCaptureSale && !!data?.capture_sale,
      data?.base?.isProductsGoal && !!data?.products_goal,
    ].filter(tab => !tab).length === tabsLength;

  const handleSetType = (type: string) => {
    setData(prevState => ({
      ...prevState,
      base: {
        ...prevState.base,
        type,
      },
    }));
  };

  const toggleSelectedSubType = (id: number) => {
    setSelectedSubTypes(prevState =>
      prevState.includes(id) ? prevState.filter(value => value !== id) : [...prevState, id],
    );
  };

  const handleSaveState = (key: GoalKeys, payload: GoalContextData[GoalKeys]) => {
    setData(prevState => ({
      ...prevState,
      [key]: payload,
    }));
  };

  const handleSubmit = useCallback(
    async (key: GoalKeys, payload: any) => {
      const _data = {
        ...data,
        [key]: payload,
      } as PayloadRequest;

      try {
        toggleIsLoading();

        const payloadRequest: CreateGoalRequest = {
          type: _data.base.type,
          final_date: new Date(_data.base.final_validity).toISOString(),
          initial_date: new Date(_data.base.initial_validity).toISOString(),
          operation_id:
            _data.base.type === GOAL_OPERATION_TYPE.PROFESSIONAL
              ? (_data.base.operation?.id as number)
              : undefined,
          operations_deleted:
            listOperationDeleted.length > 0 && id ? listOperationDeleted : undefined,
          users_deleted: listUserDeleted.length > 0 && id ? listUserDeleted : undefined,
          products_deleted: listProductDeleted.length > 0 && id ? listProductDeleted : undefined,
          goals: {
            billing: _data.base.isBilling
              ? {
                  award_type: _data?.base.billing_award_type as string,
                  operations:
                    _data.base.type === GOAL_OPERATION_TYPE.OPERATION && _data.billing?.operations
                      ? _data.billing?.operations.map((operation, indexOperation) => ({
                          ...operation,
                          operation_id: (_data?.base?.operations &&
                            _data?.base?.operations[indexOperation].id) as number,
                        }))
                      : undefined,
                  users:
                    _data.base.type === GOAL_OPERATION_TYPE.PROFESSIONAL && _data.billing?.users
                      ? _data.billing.users?.map((user, indexUser) => ({
                          ...user,
                          user_id: (_data.base.users && _data.base.users[indexUser].id) as number,
                        }))
                      : undefined,
                }
              : undefined,
            capita_billing: _data.base.isCapitaBilling
              ? {
                  award_type: _data?.base.capita_billing_award_type as string,
                  operations:
                    _data.base.type === GOAL_OPERATION_TYPE.OPERATION &&
                    _data.capita_billing?.operations
                      ? _data.capita_billing?.operations.map((operation, indexOperation) => ({
                          ...operation,
                          operation_id: (_data?.base?.operations &&
                            _data?.base?.operations[indexOperation].id) as number,
                        }))
                      : undefined,
                  users:
                    _data.base.type === GOAL_OPERATION_TYPE.PROFESSIONAL &&
                    _data.capita_billing?.users
                      ? _data.capita_billing.users?.map((user, indexUser) => ({
                          ...user,
                          user_id: (_data.base.users && _data.base.users[indexUser].id) as number,
                        }))
                      : undefined,
                }
              : undefined,
            capture_sale: _data.base.isCaptureSale
              ? {
                  award_type: _data?.base.capture_sale_award_type as string,
                  operations:
                    _data.base.type === GOAL_OPERATION_TYPE.OPERATION &&
                    _data.capture_sale?.operations
                      ? _data.capture_sale?.operations.map((operation, indexOperation) => ({
                          ...operation,
                          operation_id: (_data?.base?.operations &&
                            _data?.base?.operations[indexOperation].id) as number,
                        }))
                      : undefined,
                  users:
                    _data.base.type === GOAL_OPERATION_TYPE.PROFESSIONAL &&
                    _data.capture_sale?.users
                      ? _data.capture_sale.users?.map((user, indexUser) => ({
                          ...user,
                          user_id: (_data.base.users && _data.base.users[indexUser].id) as number,
                        }))
                      : undefined,
                }
              : undefined,
            products_goal: _data.base.isProductsGoal
              ? {
                  award_type: _data?.base?.products_goal_award_type as string,
                  operations:
                    _data.base.type === GOAL_OPERATION_TYPE.OPERATION &&
                    _data.products_goal?.operations
                      ? _data.products_goal?.operations?.map((operation, indexOperation) => ({
                          operation_id: (_data.base.operations &&
                            _data.base.operations[indexOperation].id) as number,
                          products: operation.products,
                        }))
                      : undefined,
                  users:
                    _data.base.type === GOAL_OPERATION_TYPE.PROFESSIONAL &&
                    _data.products_goal?.users
                      ? _data.products_goal?.users?.map((user, indexUser) => ({
                          user_id: (_data.base.users && _data.base.users[indexUser].id) as number,
                          products: user.products,
                        }))
                      : undefined,
                }
              : undefined,
          },
        };

        !id ? await postGoal(payloadRequest) : await putGoal(id, payloadRequest);

        !id
          ? openNotification(t('commons:createMessage'))
          : openNotification(t('commons:editMessage'));

        history.push(routePaths.goal.list);
      } catch (err) {
      } finally {
        toggleIsLoading();
      }
    },
    [data],
  );

  const handleGetSubTypes = async () => {
    const { data } = await getSubTypesGoal();

    setSubTypes(data);
  };

  const handleGetGoalById = async (id: string | number, subTypes: SubTypeGoal[]) => {
    try {
      toggleIsInitialLoading();
      const { data } = await getGoalById(id);
      const goals = Object.keys(data.goals)?.map(key => key);
      const parsedSubTypesIds = subTypes
        .filter(value => goals.includes(value.name))
        .map(value => value.id);

      setSelectedSubTypes(parsedSubTypesIds);

      const userParsed = data.users as any[];

      const productsUser = data?.goals?.products_goal?.users
        ? data?.goals?.products_goal?.users
            ?.map(value => value.products)
            .reduce((prev, next) => [...prev, ...next])
            .map(value => ({ name: value.product_name, id: value.product_id }))
        : [];

      const productsOperation = data?.goals?.products_goal?.operations
        ? data?.goals?.products_goal?.operations
            ?.map(value => value.products)
            .reduce((prev, next) => [...prev, ...next])
            .map(value => ({ name: value.product_name, id: value.product_id }))
        : [];

      const products =
        data.type === GOAL_OPERATION_TYPE.PROFESSIONAL ? productsUser : productsOperation;

      const parsedData: GoalContextData = {
        base: {
          type: data?.type,
          final_validity: data?.final_date,
          initial_validity: data?.final_date,
          isBilling: !!data?.goals?.billing,
          isCapitaBilling: !!data?.goals?.capita_billing,
          isCaptureSale: !!data?.goals?.capture_sale,
          isProductsGoal: !!data?.goals?.products_goal,
          billing_award_type: data?.goals?.billing?.award_type,
          capita_billing_award_type: data?.goals?.capita_billing?.award_type,
          capture_sale_award_type: data?.goals?.capture_sale?.award_type,
          products_goal_award_type: data?.goals?.products_goal?.award_type,
          operations: data?.operations,
          operation: data?.operation,
          users: userParsed
            ? userParsed?.map(value => ({ fullName: value.name, ...value }))
            : undefined,
        },
        billing: data?.goals?.billing,
        capita_billing: data?.goals?.capita_billing,
        capture_sale: data?.goals?.capture_sale,
        products_goal: {
          operations: data?.goals?.products_goal?.operations,
          users: data?.goals?.products_goal?.users,
          products,
        },
      };

      setData(parsedData);
    } catch (err) {
    } finally {
      toggleIsInitialLoading();
    }
  };

  useEffect(() => {
    if (!id) toggleIsInitialLoading();

    handleGetSubTypes();
  }, []);

  useEffect(() => {
    if (subTypes.length === 0 || !id) return toggleIsInitialLoading();

    handleGetGoalById(id, subTypes);
  }, [id, subTypes]);

  if (isInitialLoading) return <LoadingMessage text="Carregando..." />;

  return (
    <GoalContext.Provider
      value={{
        data,
        selectedSubTypes,
        subTypes,
        canSubmit,
        isLoading,
        toggleSelectedSubType,
        setType: handleSetType,
        setListUserDeleted,
        setListOperationDeleted,
        setListProductDeleted,
        onSubmit: handleSubmit,
        saveData: handleSaveState,
      }}
    >
      {children}
    </GoalContext.Provider>
  );
}

export const useGoalContext = () => useContext(GoalContext);

export const GoalProvider = memo(BaseGoalProvider);

import React from 'react';

import { Navbar } from './components';
import * as Styles from './styles';

interface IProps {}

export const Admin: React.FC<IProps> = props => {
  return (
    <Styles.Container>
      <Navbar />
      <Styles.Main>
        <Styles.Section>{props.children}</Styles.Section>
      </Styles.Main>
    </Styles.Container>
  );
};

import { Grid } from '@mui/material';
import { SubmitHandler } from '@unform/core';
import { Button, ConfirmModal, IconButton, Unform } from 'components';
import { INVENTORY_TYPES } from 'constants/inventory';
import { useBooleanObj } from 'hooks/useBooleanObj';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { postInventory } from 'services/inventory';
import { Flex } from 'styles/Flex';
import { Hr } from 'styles/Hr';
import { PageContainer } from 'styles/PageContainer';
import { PageContent } from 'styles/PageContent';
import { PostCreateInventoryJson } from 'types/inventory';
import { openNotification } from 'utils/notification';
import { queryToObj } from 'utils/query';
import {
  createInventoryTransfefrValidationSchema,
  createInventoryValidationSchema,
} from 'yupSchemas/inventory';
import { GeneralData, AddProducts } from './components';

interface BooleansObj {
  cancelModal: boolean;
  haveProduct: boolean;
  isSaving: boolean;
}

export interface SelectedOperation {
  id: string;
  name: string;
}

export interface OperationIds {
  origin: string | number | null;
  destination: string | number | null;
}

interface FormData extends Omit<PostCreateInventoryJson, 'invoiceDate'> {
  invoiceDate?: Date;
  files?: { name: string; file: File }[];
}

export const CreateInventory = ({ history, location }: RouteComponentProps): JSX.Element => {
  const [tPage] = useTranslation('pages/inventory');
  const { t } = useTranslation();
  const selectedOperation = useMemo<SelectedOperation | undefined>(() => {
    if (!location.search) return undefined;

    const obj = queryToObj(location.search);

    if (!obj.operationName || !obj.operationId) return undefined;

    return {
      id: obj.operationId as string,
      name: obj.operationName as string,
    };
  }, []);
  const [toggles, setToggle] = useBooleanObj<BooleansObj>({
    cancelModal: false,
    haveProduct: !!selectedOperation,
    isSaving: false,
  });
  const [operationIds, setOperationIds] = useState<OperationIds>({
    origin: null,
    destination: selectedOperation?.id || null,
  });
  const [type, setType] = useState<{ label: string; value: string }>({
    value: INVENTORY_TYPES.entry,
    label: tPage(`types.${INVENTORY_TYPES.entry}`),
  });

  const handleRedirect = useCallback(() => {
    setToggle('cancelModal', true);
  }, []);

  const handleToggleHaveProducts = useCallback((newHaveProduct: boolean) => {
    setToggle('haveProduct', newHaveProduct);
  }, []);

  const handleToggleHaveOperation = useCallback(
    (field: 'origin' | 'destination', id?: string | number) => {
      setOperationIds(prev => ({ ...prev, [field]: id || null }));
    },
    [],
  );

  const handleSetType = useCallback((typeParam: { label: string; value: string }) => {
    setType(typeParam);
  }, []);

  const handleSubmit = useCallback<SubmitHandler<FormData>>(async data => {
    setToggle('isSaving', true);

    try {
      const sendData = {
        ...data,
        invoiceDate: data?.invoiceDate?.toISOString(),
      };

      delete sendData.files;

      await postInventory(sendData, data.files);

      openNotification(tPage('create.successCreate'));

      history.goBack();
    } finally {
      setToggle('isSaving', false);
    }
  }, []);

  return (
    <PageContainer gap={1}>
      <Flex gap={1} alignItems="center" marginBottom={0.5}>
        <IconButton
          onClick={handleRedirect}
          icon={{ name: 'arrowBack', size: 1.4, color: 'text' }}
        />

        <h1>{tPage('create.title')}</h1>
      </Flex>

      <PageContent>
        <Unform
          onSubmit={handleSubmit}
          validationSchema={
            type.value === INVENTORY_TYPES.entry
              ? createInventoryValidationSchema
              : createInventoryTransfefrValidationSchema
          }
          initialData={{
            type: {
              value: INVENTORY_TYPES.entry,
              label: tPage(`types.${INVENTORY_TYPES.entry}`),
            },
            destinationOperationId: selectedOperation,
          }}
        >
          <Grid container columnSpacing={3}>
            <GeneralData
              selectedOperation={selectedOperation}
              haveProduct={toggles.haveProduct}
              type={type}
              onToggleHaveOperation={handleToggleHaveOperation}
              onSetType={handleSetType}
              operationIds={operationIds}
            />

            <Grid item xs={12}>
              <Hr margin="2rem -1.5rem" />
            </Grid>

            <AddProducts
              onToggleHaveProduct={handleToggleHaveProducts}
              selectedOperation={selectedOperation}
              operationIds={operationIds}
              type={type}
            />

            <Grid item xs={12}>
              <Flex justifyContent="flex-end" marginTop={1} gap={1}>
                <Button variant="cancel" onClick={handleRedirect}>
                  {tPage('create.cancel')}
                </Button>

                <Button type="submit" isLoading={toggles.isSaving}>
                  {tPage('create.save')}
                </Button>
              </Flex>
            </Grid>
          </Grid>
        </Unform>
      </PageContent>

      <ConfirmModal
        open={toggles.cancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={() => setToggle('cancelModal', false)}
        onConfirm={() => history.goBack()}
      />
    </PageContainer>
  );
};

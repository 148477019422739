import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ColorSquare = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: calc(100% - 0.8rem);
  width: 2rem;
  right: 0.4rem;
  bottom: 0.4rem;
  top: 0.4rem;
  position: absolute;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

export const PickerContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
`;

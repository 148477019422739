import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { Modal } from 'components/Modal';
import { Flex } from 'styles/Flex';
import { openNotification } from 'utils/notification';
import { deleteProductOnOperation } from 'services/operation';

interface IProps {
  open: boolean;
  onClose(newOpen: boolean): void;
  updateCreate(): void;
  title: string;
  bodyText: string;
  requestParams: Array<string | number>;
}

const ModalComponent = ({
  open,
  onClose,
  bodyText,
  title,
  updateCreate,
  requestParams,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [tPage] = useTranslation('pages/operations');
  const deleteRequest = async () => {
    try {
      setIsSaving(true);
      const [operationID, productID] = requestParams;
      await deleteProductOnOperation(operationID, productID);
      openNotification(tPage('details.inventoryTab.table.removedSuccess'));
      updateCreate();
    } catch (err) {
      updateCreate();
    }
  };
  return (
    <Modal
      title={title}
      open={open}
      PaperProps={{ style: { maxWidth: '25rem', width: '100%' } }}
      onClose={onClose}
    >
      <Flex marginVertical={3}>{bodyText}</Flex>

      <Flex justifyContent="flex-end" gap={1}>
        <Button variant="cancel" onClick={() => onClose(false)}>
          {t('button.cancel')}
        </Button>

        <Button onClick={deleteRequest} isLoading={isSaving}>
          {t('button.confirm')}
        </Button>
      </Flex>
    </Modal>
  );
};

export const RemoveModal = memo(ModalComponent);

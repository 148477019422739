import { ComponentProps, memo, ChangeEvent } from 'react';
import { Pagination } from '@mui/material';

import { Table } from './Table';
import * as Styles from './styles';

type TableProps = ComponentProps<typeof Table>;

type PaginationProps = Pick<ComponentProps<typeof Pagination>, 'count' | 'shape' | 'variant'> & {};

interface PaginatedTableProps extends TableProps, PaginationProps {
  onPageChange?: (event: ChangeEvent<unknown>, page: number) => void;
  defaultPage?: number | string;
}

function BasePaginatedTable({ count, onPageChange, defaultPage, ...props }: PaginatedTableProps) {
  return (
    <Styles.Container>
      <Table {...props} />
      <Styles.PaginationContainer>
        <Pagination
          count={count}
          onChange={onPageChange}
          defaultPage={
            defaultPage
              ? typeof defaultPage === 'string'
                ? Number(defaultPage)
                : defaultPage
              : undefined
          }
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </Styles.PaginationContainer>
    </Styles.Container>
  );
}

export const PaginatedTable = memo(BasePaginatedTable);

import { FormHandles, SubmitHandler } from '@unform/core';
import { Button, Unform } from 'components';
import { usePax } from 'hooks/store';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Flex } from 'styles/Flex';
import { CreatePax } from 'types/pax';
import { openNotification } from 'utils/notification';
import { throwPaxValidationSchema } from 'yupSchemas/pax';
import { Filter, ThrowTable } from './components';
import * as Styles from './styles';

interface Props {
  history: RouteComponentProps['history'];
}

const ThrowPAXComponent = ({ history }: Props): JSX.Element => {
  const [tPage] = useTranslation('pages/pax');
  const formRef = useRef<FormHandles>(null);
  const searchFormRef = useRef<FormHandles>(null);
  const { throwPax, loadings, setDisableFilter, loadPaxList } = usePax();

  const handleSubmit = useCallback<SubmitHandler<{ paxList: CreatePax[] }>>(
    async (data, { reset }) => {
      const serializedPax = data.paxList.filter(pax => !!pax.visitors);

      const success = await throwPax(serializedPax);

      if (!success) return;

      searchFormRef.current?.reset();
      openNotification(tPage('throw.successMessage'));
      setDisableFilter(false);
      reset();
    },
    [],
  );

  useEffect(() => {
    loadPaxList();
  }, []);

  return (
    <Styles.Container>
      <Unform ref={searchFormRef}>
        <Filter formRef={searchFormRef} />
      </Unform>

      <Unform ref={formRef} onSubmit={handleSubmit} validationSchema={throwPaxValidationSchema}>
        <ThrowTable formRef={formRef} />

        <Flex justifyContent="flex-end" gap={1} marginTop={2}>
          <Button variant="cancel" onClick={() => history.goBack()}>
            {tPage('throw.cancelButton')}
          </Button>

          <Button type="submit" isLoading={loadings.throw}>
            {tPage('throw.throwButton')}
          </Button>
        </Flex>
      </Unform>
    </Styles.Container>
  );
};

export const ThrowPAX = memo(ThrowPAXComponent);

import * as yup from 'yup';

import { GOAL_OPERATION_TYPE } from 'constants/goal';

import { dateValidation } from './date';

import { minOneOperation, minOneProduct, oneOf, requiredField } from './utils/messages';
import { getIndexOfArraysInPathObject } from 'utils/regex';

const dateSchema = dateValidation('initial_validity', 'final_validity', true);

const goalsValidationSchema = {
  isBilling: yup.boolean().when(['isCapitaBilling', 'isProductsGoal', 'isCaptureSale'], {
    is: (isCapitaBilling: boolean, isProductsGoal: boolean, isCaptureSale: boolean) =>
      !isCapitaBilling && !isProductsGoal && !isCaptureSale,
    then: schema => schema.oneOf([true], oneOf),
  }),
  isCapitaBilling: yup.boolean().when(['isBilling', 'isProductsGoal', 'isCaptureSale'], {
    is: (isBilling: boolean, isProductsGoal: boolean, isCaptureSale: boolean) =>
      !isBilling && !isProductsGoal && !isCaptureSale,
    then: schema => schema.oneOf([true], oneOf),
  }),
  isProductsGoal: yup.boolean().when(['isBilling', 'isCapitaBilling', 'isCaptureSale'], {
    is: (isBilling: boolean, isCapitaBilling: boolean, isCaptureSale: boolean) =>
      !isBilling && !isCapitaBilling && !isCaptureSale,
    then: schema => schema.oneOf([true], oneOf),
  }),
  isCaptureSale: yup.boolean().when(['isBilling', 'isCapitaBilling', 'isProductsGoal'], {
    is: (isBilling: boolean, isCapitaBilling: boolean, isProductsGoal: boolean) =>
      !isBilling && !isCapitaBilling && !isProductsGoal,
    then: schema => schema.oneOf([true], oneOf),
  }),
};

export const createBaseGoalValidationSchema = yup.object().shape(
  {
    ...dateSchema,
    ...goalsValidationSchema,
    type: yup.string().required(requiredField),

    billing_award_type: yup.string().when('isBilling', {
      is: true,
      then: schema => schema.required(requiredField),
    }),

    capita_billing_award_type: yup.string().when('isCapitaBilling', {
      is: true,
      then: schema => schema.required(requiredField),
    }),

    products_goal_award_type: yup.string().when('isProductsGoal', {
      is: true,
      then: schema => schema.required(requiredField),
    }),
    capture_sale_award_type: yup.string().when('isCaptureSale', {
      is: true,
      then: schema => schema.required(requiredField),
    }),
    operation: yup.mixed().when('type', {
      is: GOAL_OPERATION_TYPE.PROFESSIONAL,
      then: schema => schema.required(requiredField),
    }),
    users: yup.array().when('type', {
      is: GOAL_OPERATION_TYPE.PROFESSIONAL,
      then: schema => schema.min(1, minOneProduct),
    }),
    operations: yup.array().when('type', {
      is: GOAL_OPERATION_TYPE.OPERATION,
      then: schema => schema.min(1, minOneOperation),
    }),
  },
  [
    ['isBilling', 'isCapitaBilling'],
    ['isBilling', 'isProductsGoal'],
    ['isBilling', 'isCaptureSale'],
    ['isCapitaBilling', 'isBilling'],
    ['isCapitaBilling', 'isProductsGoal'],
    ['isCapitaBilling', 'isCaptureSale'],
    ['isProductsGoal', 'isBilling'],
    ['isProductsGoal', 'isCaptureSale'],
    ['isProductsGoal', 'isCaptureSale'],
    ['isCaptureSale', 'isBilling'],
    ['isCaptureSale', 'isProductsGoal'],
    ['isCaptureSale', 'isCapitaBilling'],
  ],
);

function getGoalItemValidationSchema(message: string, entity: string, field?: string) {
  const goalItemValidationSchema = yup.object({
    award: yup.string().required(requiredField),
    target: yup
      .string()
      .test('formErrors', message, function (data) {
        if (!field) return true;

        const options = this.options as any;

        const refFieldValue = options.context.formData[entity][options.index][field].target;

        return Number(data) < Number(refFieldValue);
      })
      .required(requiredField),
  });

  return goalItemValidationSchema;
}

const goalValidationSchema = (entity: string) => {
  return yup.object().shape({
    minimal: getGoalItemValidationSchema('mustBeLowerThanIdeal', entity, 'ideal'),
    ideal: getGoalItemValidationSchema('mustBeLowerThanSuper', entity, 'super'),
    super: getGoalItemValidationSchema('', entity),
  });
};

export const createDefaultGoalValidationSchema = (entity: string) => {
  return yup.object({
    [entity]: yup.array().of(goalValidationSchema(entity)),
  });
};

function getGoalOperationItemValidationSchema(message: string, entity: string, field?: string) {
  const goalItemValidationSchema = yup.object({
    award: yup.string().required(requiredField),
    target: yup
      .string()
      .test('formErrors', message, function (data) {
        if (!field) return true;

        const options = this.options as any;
        const path = options.path as string;

        const indexMatch = getIndexOfArraysInPathObject(path);
        const indexEntity = indexMatch ? Number(indexMatch[0]) : 0;

        const refFieldValue =
          options.context.formData[entity][indexEntity].products[options.index][field].target;

        return Number(data) < Number(refFieldValue);
      })
      .required(requiredField),
  });

  return goalItemValidationSchema;
}

const goalOperationValidationSchema = (entity: string) =>
  yup.object().shape({
    minimal: getGoalOperationItemValidationSchema('mustBeLowerThanIdeal', entity, 'ideal'),
    ideal: getGoalOperationItemValidationSchema('mustBeLowerThanSuper', entity, 'super'),
    super: getGoalOperationItemValidationSchema('', entity),
  });

export const createProductGoalValidationSchema = (fieldName: string) => {
  return yup.object({
    [fieldName]: yup
      .array()
      .of(
        yup.object({
          products: yup.array().of(goalOperationValidationSchema(fieldName)),
        }),
      )
      .min(1, minOneProduct),
  });
};

import { memo, useCallback, useMemo, useState } from 'react';

import Swipable from 'react-swipeable-views';
import { Flex } from 'styles/Flex';

import * as Styles from './styles';

interface CarrouselProps {
  src: string[];
}

function BaseCarrousel({ src }: CarrouselProps) {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = src.length;

  const handleNext = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, []);

  const handleStepChange = useCallback((step: number) => {
    setActiveStep(step);
  }, []);

  const renderSteps = useMemo(
    () =>
      src.map((value, index) => (
        <Styles.ImgContainer key={index}>
          <Styles.Img src={value} alt="" />
        </Styles.ImgContainer>
      )),
    [src],
  );

  const renderDots = useMemo(
    () =>
      Array(maxSteps)
        .fill(0)
        .map((value, index) => <Styles.Dot isActive={index === activeStep} key={index} />),
    [maxSteps, activeStep],
  );

  return (
    <Styles.Wrapper>
      <Styles.Container>
        <Swipable axis="x" index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
          {renderSteps}
        </Swipable>
        <Styles.Controlls>
          <Styles.ButtonIcon
            disabled={activeStep === 0}
            onClick={handleBack}
            icon={{
              name: 'arrowBack',
              size: 1,
            }}
          />
          <Styles.ButtonIcon
            disabled={activeStep === maxSteps - 1}
            onClick={handleNext}
            icon={{
              name: 'arrowForward',
              size: 1,
            }}
          />
        </Styles.Controlls>
      </Styles.Container>
      <Flex gap={0.6}>{renderDots}</Flex>
    </Styles.Wrapper>
  );
}

export const Carrousel = memo(BaseCarrousel);

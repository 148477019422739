import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
`;

export const divBotao2 = styled.div`
  position: fixed;
  bottom: 6px;
  font-family: 'Montserrat', sans-serif;
  right: 6px;

  .btn-help {
    text-transform: lowercase;
  }

  .btn-help span::first-letter {
    text-transform: uppercase;
  }
`;

export const headerText = styled.div`
  text-align: center;
  width: 550px;
  font-family: 'Montserrat', sans-serif;
  z-index: 2;
  margin-bottom: 40%;
  display: block;
`;

export const cardScan = styled.div`
  margin-left: 10%;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  .scan-card {
    float: left;
    width: 80%;
    border-left: 20px;
    box-sizing: border-box;
    height: 65px;
    background: #f1f1f1;
    border: 1px solid #848484;
    border-right: none;
    border-radius: 10px 0 0 10px;
    padding: 10px 45px 0px 10px;
    font-size: 15px;
  }

  .scan-card:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  .scan-card-act {
    float: left;
    height: 65px;
    width: 20%;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(90deg, rgba(30, 70, 163, 1) 0%, rgba(7, 101, 171, 1) 100%);
    border: none;
    border-radius: 35px;
    margin-left: -40px;
    font-size: 25px;
  }

  @media only screen and (max-width: 300px) {
    .scan-card-act {
      width: 30%;
    }
`;

export const sliderStyles = styled.div`
  #slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
  }

  #slider a {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  #slider a:not(.selected) {
    pointer-events: none;
  }

  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #slider img:not(.magicPass) {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 3500ms;
    transform-origin: 50%, 50%;
  }

  #slider img.selected {
    opacity: 1;
  }
`;

export const loaderDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  justify-content: center;
  max-width: 15rem;
  position: absolute;
  text-align: center;
  top: 40%;
  width: 100%;
`;

export const clockCloud = styled.div`
  height: 80px;
  left: 50%;
  margin-top: 4rem;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  transform: translate(-50%, -50%);
  width: 240px;
`;

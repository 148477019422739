import { Column, Table } from 'components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import * as DetailStyles from 'styles/Details';
import { Flex } from 'styles/Flex';
import { GetAcquirerById } from 'types/acquire';
import * as Styles from './styles';

type AcquirerByIdData = Pick<GetAcquirerById, 'cnpj' | 'name' | 'card_flags'>;

interface Data extends AcquirerByIdData {
  operations: {
    name: string;
    id: string | number;
  }[];
}
interface BaseProps {
  data?: Partial<Data>;
}

const FIELDS = {
  flag: 'flag',
  operation: 'operation',
};

function RootBase({ data }: BaseProps) {
  const [tPage] = useTranslation('pages/acquire');

  const columnsFlag: Column[] = [
    {
      field: FIELDS.flag,
      headerName: tPage('create.table.flag'),
    },
  ];

  const columnsOperation: Column[] = [
    {
      field: FIELDS.operation,
      headerName: tPage('create.table.operation'),
    },
  ];

  const renderFlags = data?.card_flags?.map(value => ({
    [FIELDS.flag]: value.name,
  }));

  const renderOperations = data?.operations?.map(value => ({
    [FIELDS.operation]: value.name,
  }));

  return (
    <Styles.Container>
      <Flex column gap={2}>
        <Flex gap={2}>
          <Flex column gap={1}>
            <DetailStyles.Label>{tPage('create.fieldset.acquire.cnpj')}</DetailStyles.Label>
            <DetailStyles.Value>{data?.cnpj}</DetailStyles.Value>
          </Flex>
          <Flex column gap={1}>
            <DetailStyles.Label>{tPage('create.fieldset.acquire.acquireName')}</DetailStyles.Label>
            <DetailStyles.Value>{data?.name}</DetailStyles.Value>
          </Flex>
        </Flex>
        <Flex column gap={1}>
          <Table tableVariant="secondary" columns={columnsFlag} rows={renderFlags || []} />
          <Table
            tableVariant="secondary"
            columns={columnsOperation}
            rows={renderOperations || []}
          />
        </Flex>
      </Flex>
    </Styles.Container>
  );
}

export const Base = memo(RootBase);

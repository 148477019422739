import { SyntheticEvent, memo, ComponentProps, useState } from 'react';

import { debounce } from 'utils';

import { UnFieldArray } from '..';

import * as Styles from './styles';
import { GetUserData } from 'types/user';
import { isOdd } from 'utils/help';
import { getUsersByOperationId } from 'services/user';

type RootAutoCompletoProps = Pick<
  ComponentProps<typeof UnFieldArray>,
  'label' | 'placeholder' | 'disabled' | 'onSelect' | 'value' | 'onChangeValue'
>;

interface DefaultValue {
  name: string;
  id: any;
}

interface UnUserProps extends RootAutoCompletoProps {
  defaultValue?: DefaultValue;
  name: string;
  onValidate?: (user: { id: number }) => Promise<void>;
  operationId?: number | null;
  onRemoveRow?: (user: { id: number }) => void;
}

function BaseUnUser({ onValidate, operationId, onRemoveRow, ...props }: UnUserProps) {
  const [users, setUsers] = useState<any[]>([]);

  const getUsers = async (event: SyntheticEvent, value: string) => {
    if (!value || !operationId) return;

    const { data } = await getUsersByOperationId(operationId, {
      search: value,
    });

    setUsers(data);
  };

  return (
    <UnFieldArray
      {...props}
      optionLabel="fullName"
      optionValue="id"
      unicKeyFilter="id"
      onValidate={onValidate}
      options={users}
      onInputChange={debounce(getUsers)}
      render={({ remove }, _users: GetUserData[]) =>
        _users.map((user, index) => (
          <Styles.Item isOdd={isOdd(index)} key={index}>
            <span>{user.fullName}</span>
            <Styles.RemoveButton
              onClick={() => {
                onRemoveRow?.({ id: user.id });
                remove(index);
              }}
              icon={{
                name: 'close',
                color: 'text100',
              }}
            />
          </Styles.Item>
        ))
      }
    />
  );
}

export const UnUser = memo(BaseUnUser);

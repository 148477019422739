import { CreateRecuperation } from 'types/recuperation';
import api from './apis/api';

export async function postRecuperation(
  payload: CreateRecuperation,
  clientPhoto: File | undefined,
  qrCodePhoto: File | undefined,
) {
  const formData = new FormData();
  formData.append('client_photo', clientPhoto!);
  formData.append('qr_code_photo', qrCodePhoto!);
  formData.append('form', JSON.stringify(payload));
  return await api.post<CreateRecuperation>('/recuperation', formData);
}

import { states } from 'constants/states';
import { addressApi } from './apis/addressApi';

interface AddressByCepResponse {
  cep: string;
  bairro: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siaf: string;
  uf: string;
  erro?: boolean;
}
export const getAddressByCep = async (cep: string) => {
  const { data } = await addressApi.get<AddressByCepResponse>(`${cep}/json`);

  if (data?.erro) {
    throw new Error('API error');
  }

  return {
    cep: data.cep,
    street: data.logradouro,
    complement: data.complemento,
    district: data.bairro,
    city: data.localidade,
    state: states.find(state => state.label === data.uf),
    uf: data.uf,
  };
};

import styled from 'styled-components';

import { tag } from 'theme/tag';

export interface TagProps {
  variante?: keyof typeof tag;
}

export const Tag = styled.div<TagProps>`
  padding: 0.5rem 0;
  min-width: 6.5rem;
  max-width: max-content;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  background: ${({ theme, variante = 'success' }) => theme.tag[variante || 'success'].background};
`;

import { IconButton, Tabs } from 'components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import * as DefaultStyles from 'styles/Details';
import { Flex } from 'styles/Flex';
import { Info } from './components';

export function DetailPromotion(props: RouteComponentProps<{ id: string }>) {
  const { t } = useTranslation('pages/promotion');

  const promotionId = props.match.params.id;

  return (
    <DefaultStyles.Container>
      <Flex alignItems="center" wrap gap={1} justifyContent="space-between">
        <Flex alignItems="center" gap={1}>
          <IconButton
            icon={{ name: 'arrowBack', size: 1.4 }}
            onClick={() => props.history.goBack()}
          />
          <h1 style={{ margin: 0 }}>{t('detail.title')}</h1>
        </Flex>
      </Flex>
      <DefaultStyles.Wrapper>
        <Tabs tabs={[{ label: 'Dados do produto' }, { label: 'Log de alteração' }]}>
          <Info id={promotionId} />
        </Tabs>
      </DefaultStyles.Wrapper>
    </DefaultStyles.Container>
  );
}

import { TextField } from 'components';
import { useClickOutside } from 'hooks/useClickOutside';
import React, {
  memo,
  useCallback,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  ComponentProps,
  ChangeEventHandler,
  SetStateAction,
  Dispatch,
} from 'react';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import { theme } from 'theme';

import * as Styles from './styles';

export interface ColorPickerRef {
  color: string;
  setColor: Dispatch<SetStateAction<string>>;
}

interface IProps extends Omit<ComponentProps<typeof TextField>, 'onChange'> {
  label?: string;
  defaultValue?: string;
  onChange?(color: string): void;
}

// eslint-disable-next-line react/display-name
const ColorPickerComponent = forwardRef<ColorPickerRef, IProps>(
  ({ defaultValue, onChange, ...props }, ref) => {
    // Color is in hex
    const [color, setColor] = useState(defaultValue || theme.colors.primary.toLowerCase());
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({ color, setColor }), [color]);

    const handleChangeComplete = useCallback<ColorChangeHandler>(data => {
      setColor(data.hex);
    }, []);

    const handleChange = useCallback<ColorChangeHandler>(data => {
      setColor(data.hex);
      onChange?.(data.hex);
    }, []);

    const handleToggle = useCallback(
      (newValue?: boolean) =>
        setOpen(prevState => (typeof newValue === 'undefined' ? !prevState : newValue)),
      [],
    );

    const handleChangeInput = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
      setColor(e.target.value);
    }, []);

    useClickOutside(containerRef, () => handleToggle(false));

    return (
      <Styles.Container ref={containerRef}>
        <TextField
          {...props}
          value={color}
          onChange={handleChangeInput}
          InputProps={{ endAdornment: <Styles.ColorSquare backgroundColor={color} /> }}
          onFocus={() => handleToggle()}
          focused={open}
        />

        {open && (
          <Styles.PickerContainer id="pickerContainer">
            <ChromePicker
              disableAlpha
              color={color}
              onChange={handleChange}
              onChangeComplete={handleChangeComplete}
              styles={{ default: { picker: { width: '100%' } } }}
            />
          </Styles.PickerContainer>
        )}
      </Styles.Container>
    );
  },
);

export const ColorPicker = memo(ColorPickerComponent);

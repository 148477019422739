import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  strong {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }

  legend {
    color: ${({ theme }) => theme.colors.text700};
    font-size: ${({ theme }) => theme.fonts.sizes.xs};
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.text200};
  position: relative;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

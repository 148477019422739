import { Column, Table } from 'components';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'styles/Flex';
import { ProductInventoryOperation } from 'types/product';
import { Menu } from './components/Menu';

interface IProps {
  products: ProductInventoryOperation[];
  isLoading: boolean;
  operationId: string | number;
  updateCreate: () => void;
}

const ProductsTableComponent = ({
  isLoading,
  products,
  operationId,
  updateCreate,
}: IProps): JSX.Element => {
  const [tPage] = useTranslation('pages/operations', { keyPrefix: 'details.inventoryTab' });
  const { t } = useTranslation();

  const columns = useMemo<Column[]>(
    () => [
      {
        field: 'name',
        headerName: tPage('table.product'),
      },
      {
        field: 'type',
        headerName: tPage('table.type'),
      },
      {
        field: 'min',
        headerName: tPage('table.min'),
      },
      {
        field: 'max',
        headerName: tPage('table.max'),
      },
      {
        field: 'lastEntry',
        headerName: tPage('table.lastEntry'),
      },
      {
        field: 'lastCoast',
        headerName: tPage('table.lastEntryCoast'),
      },
      {
        field: 'currentInventory',
        headerName: tPage('table.currentInventory'),
      },
      {
        field: 'menu',
        headerName: '',
      },
    ],
    [],
  );

  return (
    <>
      <Table
        tableVariant="secondary"
        columns={columns}
        isLoading={isLoading}
        rows={products.map(product => ({
          name: (
            <Flex marginTop={0.5} marginBottom={0.5}>
              {`${product.id} - ${product.name}`}
            </Flex>
          ),
          type: t(`product.types.${product.type}`),
          min: product.min || '-',
          max: product.max || '-',
          lastEntry: product.lastEntry ? t('date', { date: new Date(product.lastEntry) }) : '-',
          lastCoast: product.lastEntryCost ? t('currency', { value: product.lastEntryCost }) : '-',
          currentInventory: product.currentInventory || '-',
          menu: <Menu product={product} operationId={operationId} updateCreate={updateCreate} />,
        }))}
      />
    </>
  );
};

export const ProductsTable = memo(ProductsTableComponent);

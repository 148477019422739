import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Table } from 'components';

import { Flex } from 'styles/Flex';

import * as DefaultStyles from 'styles/Details';
import { removeDuplicateObjectFroArray } from 'utils/help';

interface BaseProps {
  initialDate?: string;
  finalDate?: string;
  operation?: { name: string; id: number | string };
  operations: { operation: string }[];
  users: { user: string }[];
  goals: {
    label: string;
    award_type: string;
  }[];
}

function BaseComponent({ operations, users, goals, initialDate, finalDate, operation }: BaseProps) {
  const [tPage] = useTranslation('pages/goal');
  const { t } = useTranslation();

  const columnGoal: Column[] = [
    {
      field: 'label',
      headerName: tPage('detail.table.goal'),
    },
    {
      field: 'award_type',
      headerName: tPage('detail.table.award'),
    },
  ];

  const columnOperations: Column[] = [
    {
      field: 'operation',
      headerName: tPage('detail.table.operation'),
    },
  ];

  const columnUsers: Column[] = [
    {
      field: 'user',
      headerName: tPage('detail.table.user'),
    },
  ];

  console.log(operation);

  return (
    <Flex column gap={2}>
      <Flex gap={2}>
        <Flex column gap={1}>
          <DefaultStyles.Label>Tipo de meta</DefaultStyles.Label>
          <DefaultStyles.Value>
            {t(`pages/goal:create.types.${operations.length > 0 ? 'OPERATIONS' : 'USERS'}`)}
          </DefaultStyles.Value>
        </Flex>
        <Flex column gap={1}>
          <DefaultStyles.Label>Periodo</DefaultStyles.Label>
          <DefaultStyles.Value>{`${
            initialDate
              ? t('date', {
                  date: new Date(initialDate),
                })
              : ''
          } - ${
            finalDate
              ? t('date', {
                  date: new Date(finalDate),
                })
              : ''
          } `}</DefaultStyles.Value>
        </Flex>
      </Flex>

      <Flex column gap={2}>
        <Table tableVariant="secondary" columns={columnGoal} rows={goals} />
        <hr />
        {operations.length > 0 && (
          <Table
            tableVariant="secondary"
            columns={columnOperations}
            rows={removeDuplicateObjectFroArray(operations, 'id', 'id')}
          />
        )}
        {users.length > 0 && (
          <>
            <Table
              tableVariant="secondary"
              columns={[
                {
                  field: 'operation',
                  headerName: tPage('detail.table.operation'),
                },
              ]}
              rows={[
                {
                  operation: operation?.name,
                },
              ]}
            />
            <Table
              tableVariant="secondary"
              columns={columnUsers}
              rows={removeDuplicateObjectFroArray(users, 'id', 'id')}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
}

export const Base = memo(BaseComponent);

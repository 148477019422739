import api from './apis/api';

export async function getFile(id: string | number) {
  const response = await api.get(`/file/${id}`, {
    responseType: 'arraybuffer',
  });

  const image = btoa(
    new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
  );
  return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
}

export async function deleteFile(id: string | number) {
  return await api.delete(`/file/${id}`);
}

export const getFileBlob = (id: string | number) =>
  api.get<Blob>(`/file/${id}`, { responseType: 'blob' });

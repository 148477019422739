import { memo, SyntheticEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Autocomplete } from 'components';

import { debounce } from 'utils';

import { pushHistoryQuery } from 'utils/query';

import { getOperationUserAccesSelectsList } from 'services/operation';

interface DefaultValue {
  name: string;
  id: any;
}

interface Operation {
  name: string;
  id: number | string;
}

interface OperationFilterProps {
  defaultValue?: DefaultValue;
  onChange?: (operation: Operation) => void;
  placeholder?: string;
  removePageOnFilter?: boolean;
}

function BaseOperationFilter({
  defaultValue,
  placeholder,
  removePageOnFilter,
  onChange,
}: OperationFilterProps) {
  const history = useHistory();

  const [operations, setOperations] = useState<Operation[]>([]);

  const handleSelectOperation = (event: SyntheticEvent, operation?: Operation) => {
    if (!operation) return;

    onChange?.({
      id: operation?.id,
      name: operation?.name,
    });

    const query = {
      operation_id: operation?.id,
      operation_name: operation.name,
    };

    !onChange &&
      pushHistoryQuery(query, history, { removedProps: removePageOnFilter ? ['page'] : [] });
  };

  const getOperations = async (event: SyntheticEvent, value: string) => {
    try {
      if (value.length === 0) pushHistoryQuery({ operation_id: '', operation_name: '' }, history);
      const { data } = await getOperationUserAccesSelectsList({ search: value });
      setOperations(
        data.map((value: any) => ({
          name: value.name,
          id: value.id,
        })),
      );
    } catch (err) {}
  };

  useEffect(() => {
    debounce(getOperations);
  }, []);

  return (
    <Autocomplete
      placeholder={placeholder || 'labels:input.operation'}
      optionLabel="name"
      optionValue="id"
      options={operations}
      defaultValue={defaultValue}
      onInputChange={debounce(getOperations)}
      onChange={handleSelectOperation}
      contained
    />
  );
}

export const OperationFilter = memo(BaseOperationFilter);

export function cepMask(cep: string) {
  // const cepParse = cep.replace(/[.-]/g, '')
  return cep
    .replace(/\D/g, '')
    .replace(/(\d{5})/, '$1')
    .replace(/(\d{5})(\d{3})/, ' $1-$2');
}

export function cnpjMask(cnpj: string) {
  return cnpj
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
}

export function cpfMask(cpf: string) {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export function telefoneMask(telefone: string) {
  return telefone
    .replace(/\D/g, '')
    .replace(/(\d{2})/, '($1) ')
    .replace(/(\d{1})(\d{4})/, '$1 $2')
    .replace(/(\d{4})(\d{4})/, '$1-$2');
}

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPhotographerToReport } from 'services/report';
import { SellerToReport } from 'types/report';
import { openNotification } from 'utils/notification';
interface UseSellersToReportProps {
  operationId?: string | number;
  typeId?: string | number;
  updateOnChangeData?: boolean;
}
export const usePhotographersToReport = ({
  operationId,
  typeId,
  updateOnChangeData = true,
}: UseSellersToReportProps) => {
  const [tPage] = useTranslation('pages/report');
  const [photographer, setPhotographer] = useState<SellerToReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const handleLoadPhotographers = useCallback(async () => {
    try {
      if (!operationId || !typeId) return;
      setLoading(true);
      const response = await getPhotographerToReport({
        operationId: operationId,
        typeId: typeId,
      });
      setPhotographer(response.data);
    } catch (error) {
      openNotification(tPage('notGetPhotographersError'), 'error');
    } finally {
      setLoading(false);
    }
  }, [operationId, typeId]);
  useEffect(() => {
    if (updateOnChangeData) {
      handleLoadPhotographers();
    }
  }, [updateOnChangeData, handleLoadPhotographers]);
  return {
    photographers: photographer,
    isLoading: loading,
    handleLoadPhotographers: handleLoadPhotographers,
  };
};

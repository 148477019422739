/* eslint-disable prettier/prettier */
import { Button, Column } from 'components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Credit } from 'types/cashDesk';
import { Accordion } from '..';
import { ListModal } from './components';

const _columns = {
  total: 'total',
  acquire: 'acquire',
  orderId: 'orderId',
  flag: 'flag',
  transaction_type: 'transaction_type',
  installment: 'installment',
  button: 'button',
};

interface CardPayProps {
  data: Credit[];
  title: string;
  disabled: boolean;
  operationId: string;
}

function BaseCardPay({ data, title, disabled, operationId }: CardPayProps) {
  const [tPage] = useTranslation('pages/cashDesk');
  const { t } = useTranslation();

  const cardFlags = data?.map(value => value.cardFlags);
  const cardFlagsReduced =
    cardFlags.length > 0 ? cardFlags.reduce((prev, next) => [...prev, ...next]) : [];

  const installments = cardFlagsReduced.map(value => value.installments);
  const installmentsReduced =
    installments.length > 0 ? installments.reduce((prev, next) => [...prev, ...next]) : [];

  const value = installmentsReduced
    ?.map(value => value.total)
    ?.reduce((prev, next) => prev + next, 0);

  const groupedDataByAcquire =
    data.length > 0
      ? data
          ?.map(acquire => {
            const cardFlags = acquire.cardFlags
              ?.map(cardFlag => {
                const installments = cardFlag.installments?.map(installment => ({
                  [_columns.acquire]: acquire.name,
                  [_columns.orderId]: installment.fees[0].order_id,
                  [_columns.flag]: cardFlag.name,
                  [_columns.total]: t('currency', { value: installment.total }),
                  [_columns.installment]: installment.installment,
                  [_columns.transaction_type]:
                    installment.installment <= 1 ? 'À vista' : 'Parcelado',
                  [_columns.button]: (
                    <ListModal
                      disabled={disabled}
                      operationId={operationId}
                      data={{
                        fess: installment.fees,
                        acquire: acquire.name,
                        flag: cardFlag.name,
                        installment: installment.installment,
                        total: installment.total,
                      }}
                      title={title}
                    >
                      <Button variant="base">Ver mais</Button>
                    </ListModal>
                  ),
                }));

                return installments;
              })
              ?.reduce((prev, next) => [...prev, ...next]);

            return cardFlags;
          })
          ?.reduce((prev, next) => [...prev, ...next])
      : [];

  const columns: Column[] = [
    {
      field: _columns.acquire,
      headerName: tPage('detail.table.acquire'),
      _style: {
        width: '100%',
      },
    },
    {
      field: _columns.orderId,
      headerName: tPage('detail.table.order_id'),
    },
    {
      field: _columns.transaction_type,
      headerName: tPage('detail.table.transaction_type'),
    },
    {
      field: _columns.flag,
      headerName: tPage('detail.table.flag'),
    },
    {
      field: _columns.installment,
      headerName: tPage('detail.table.installment'),
    },
    {
      field: _columns.total,
      headerName: tPage('detail.table.total'),
    },
    {
      field: _columns.button,
      headerName: '',
    },
  ];

  const currency = t('currency', { value });

  return (
    <Accordion
      columns={columns}
      rows={groupedDataByAcquire}
      value={currency}
      title={title}
    ></Accordion>
  );
}

export const CardPay = memo(BaseCardPay);

import { Grid } from '@mui/material';
import { Table } from 'components';
import { useTranslation } from 'react-i18next';
import { Label, Value } from 'styles/Details';
import { Flex } from 'styles/Flex';
import { GetTouristGuideDetails } from 'types/touristGuide';
import { cpfMask } from 'utils/masks';

interface Props {
  data: GetTouristGuideDetails;
}

export const Info = ({ data }: Props) => {
  const [tPage] = useTranslation('pages/touristGuide');

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h3>{data.fullName}</h3>
      </Grid>

      <Grid item xs={12}>
        <Flex marginBottom={1}>
          <Label>
            <Value>{cpfMask(data.cpf)}</Value>
          </Label>
        </Flex>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Flex column gap={1}>
          <Label>{tPage('details.operator')}</Label>

          <Value>{data.operator.name}</Value>
        </Flex>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Flex column gap={1}>
          <Label>{tPage('details.phone')}</Label>

          <Value>{data.phone}</Value>
        </Flex>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Flex column gap={1}>
          <Label>{tPage('details.email')}</Label>

          <Value>{data.email}</Value>
        </Flex>
      </Grid>

      <Grid item xs={12}>
        <Flex marginTop={1} marginBottom={0.5}>
          <Label>{tPage('details.bankData')}</Label>
        </Flex>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Flex column gap={1}>
          <Label>{tPage('details.pix')}</Label>

          <Value>{data.pix || '-'}</Value>
        </Flex>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Flex column gap={1}>
          <Label>{tPage('details.bank')}</Label>

          <Value>{data.bank || '-'}</Value>
        </Flex>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Flex column gap={1}>
          <Label>{tPage('details.agency')}</Label>

          <Value>{data.agency || '-'}</Value>
        </Flex>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Flex column gap={1}>
          <Label>{tPage('details.account')}</Label>

          <Value>{data.account || '-'}</Value>
        </Flex>
      </Grid>

      <Grid item xs={12}>
        <Flex marginTop={1}>
          <Label>{tPage('details.linkedOperations')}</Label>
        </Flex>
      </Grid>

      <Grid item xs={12}>
        <Table
          rows={data.operationsAccess}
          tableVariant="secondary"
          columns={[
            {
              field: 'name',
              headerName: '',
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

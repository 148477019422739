import { FormHandles, SubmitHandler } from '@unform/core';
import {
  AuthorizeView,
  Button,
  Icon,
  IconButton,
  LoadingMessage,
  Tag,
  Tooltip,
  Unform,
  UnMaskInput,
} from 'components';
import { CASH_DESK_STATUS, CASH_DESK_STATUS_VARIANT_MAP } from 'constants/cashDesk';
import { routePaths } from 'constants/routes';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { checkCashDesk, getCashdeskById } from 'services/cashDesk';
import * as DefaultStyles from 'styles/Details';
import { Flex } from 'styles/Flex';
import { Monetize } from 'styles/Monetize';
import { PageContainer } from 'styles/PageContainer';
import { CheckCashdesk, GetCashdeskById } from 'types/cashDesk';
import { currencyToFloat } from 'utils/help';
import { checkCashdesk } from 'yupSchemas/cashDesk';
import { Bleed, CardPay, Money, Pix } from './components';
import * as Styles from './styles';

export function DetailCashDesk({
  history,
  match,
}: RouteComponentProps<{ id: string; operationId: string }>) {
  const formRef = useRef<FormHandles>(null);

  const [details, setDetails] = useState<Partial<GetCashdeskById>>({});

  const [initialForm, setInitialForm] = useState<CheckCashdesk>();

  const [isLoading, toggleIsLoading] = useBooleanToggle(true);
  const [isInitialLoading, toggleIsInitialLoading] = useBooleanToggle(false);

  const [valueChecked, setValueChecked] = useState('');

  const id = match.params.id;
  const operationId = match.params.operationId;

  const disabledConference = useMemo(() => {
    return (
      details?.status === CASH_DESK_STATUS.checked || details?.status === CASH_DESK_STATUS.opened
    );
  }, [details]);

  const disabledButtonFinalizeConference = useMemo(() => {
    return (
      details?.status === CASH_DESK_STATUS.checked ||
      details?.status === CASH_DESK_STATUS.opened ||
      valueChecked === ''
    );
  }, [details, valueChecked]);

  const cashDeskDiffValue = useMemo<number>(
    () => (details?.finalCashFund || 0) - (details.cashFund || 0),
    [details],
  );

  const cashShortage = useMemo<number>(
    () => currencyToFloat(valueChecked || '0') - (details.totalMoneySold || 0) + cashDeskDiffValue,
    [details, valueChecked],
  );

  const [tPage] = useTranslation('pages/cashDesk');
  const { t } = useTranslation();

  const handleSubmit: SubmitHandler<CheckCashdesk> = async payload => {
    try {
      toggleIsInitialLoading();
      await checkCashDesk(id, operationId, payload);
      formRef.current?.reset();

      history.push(routePaths.cashDesk.list);
    } finally {
      toggleIsInitialLoading();
    }
  };

  const handleGetDetails = async (id: number | string) => {
    try {
      const { data } = await getCashdeskById(id, operationId);
      setDetails(data);

      if (data?.value_checked) {
        setInitialForm({
          value_checked: data.value_checked,
        });
      }

      setValueChecked(data.value_checked === null ? '' : String(data?.value_checked));
    } finally {
      toggleIsLoading();
    }
  };

  useEffect(() => {
    if (!id) return;
    handleGetDetails(id);
  }, [id]);

  if (isLoading) return <LoadingMessage text="Caregando..." />;

  return (
    <PageContainer md>
      <Flex gap={1} column>
        <Flex alignItems="center" gap={1}>
          <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={() => history.goBack()} />
          <h1>{tPage('detail.title')}</h1>
        </Flex>
        <DefaultStyles.Wrapper>
          <Styles.Container>
            <Flex column gap={1.83}>
              <Flex fullWidth justifyContent="space-between">
                <Flex column gap={0.5}>
                  <Styles.User>{details?.user?.fullName}</Styles.User>
                  <Styles.Operation>{details?.operation?.name}</Styles.Operation>
                </Flex>
                <Tag
                  label={tPage(`listing.status.${details?.status}`)}
                  variante={CASH_DESK_STATUS_VARIANT_MAP[details?.status || 'default'] as any}
                />
              </Flex>
              <Flex gap={2} column>
                <Flex gap={2}>
                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.opening')}</DefaultStyles.Label>

                    <DefaultStyles.Value>
                      {details?.opened_at
                        ? t('dateHours', { date: new Date(details?.opened_at) })
                        : '-'}
                    </DefaultStyles.Value>
                  </Flex>

                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.closing')}</DefaultStyles.Label>

                    <DefaultStyles.Value>
                      {details?.closed_at
                        ? t('dateHours', { date: new Date(details.closed_at) })
                        : '-'}
                    </DefaultStyles.Value>
                  </Flex>
                </Flex>

                <Flex gap={2}>
                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.initialCashFound')}</DefaultStyles.Label>

                    <Tooltip
                      title={tPage('detail.initialCashFundDiff') as String}
                      disableHoverListener={!details.hasDiffLastCashDeskFund}
                    >
                      <Flex gap={0.5} alignItems="center">
                        <DefaultStyles.Value>
                          {t('currency', { value: details.cashFund })}
                        </DefaultStyles.Value>

                        {details.hasDiffLastCashDeskFund && (
                          <Icon name="errorOutline" color="danger" size={1.3} />
                        )}
                      </Flex>
                    </Tooltip>
                  </Flex>

                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.finalCashFound')}</DefaultStyles.Label>

                    <DefaultStyles.Value>
                      {details.finalCashFund !== null
                        ? t('currency', { value: details.finalCashFund })
                        : '-'}
                    </DefaultStyles.Value>
                  </Flex>

                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.diffCashFound')}</DefaultStyles.Label>

                    <DefaultStyles.Value bold={!!cashDeskDiffValue} error={!!cashDeskDiffValue}>
                      {details.finalCashFund !== null
                        ? t('currency', { value: cashDeskDiffValue })
                        : '-'}
                    </DefaultStyles.Value>
                  </Flex>

                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.totalSold')}</DefaultStyles.Label>

                    <DefaultStyles.Value>
                      {t('currency', { value: details.total_sold })}
                    </DefaultStyles.Value>
                  </Flex>

                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.declaredValue')}</DefaultStyles.Label>

                    <DefaultStyles.Value>
                      {details.status !== CASH_DESK_STATUS.opened
                        ? t('currency', { value: details.declared_value })
                        : '-'}
                    </DefaultStyles.Value>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Styles.Container>
        </DefaultStyles.Wrapper>
        <DefaultStyles.Wrapper>
          <Styles.Container>
            <Flex gap={1} column>
              <Flex gap={2}>
                <AuthorizeView permission="CASH_DESK_MANAGE_CHECK_CASH_DESK">
                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.conferredValue')}</DefaultStyles.Label>
                    <Unform
                      validationSchema={checkCashdesk}
                      ref={formRef}
                      initialData={initialForm}
                      onSubmit={handleSubmit}
                    >
                      <UnMaskInput
                        disabled={disabledConference}
                        mask="currency"
                        helperText="Dinheiro+Sangria"
                        onChange={event => setValueChecked(event.target.value)}
                        name="value_checked"
                        parseToFloat
                        cleanValue
                      />
                    </Unform>
                  </Flex>

                  <Flex column gap={0.5}>
                    <DefaultStyles.Label>{tPage('detail.cashBroken')}</DefaultStyles.Label>
                    <Monetize isNegative={cashShortage < 0} isPositive={cashShortage > 0}>
                      {details.status !== CASH_DESK_STATUS.opened && valueChecked
                        ? t('currency', {
                            value: cashShortage,
                          })
                        : '-'}
                    </Monetize>
                  </Flex>
                </AuthorizeView>
              </Flex>
              <Bleed data={details?.cashDeskDepletion || []} />
              <Money
                data={details?.transactions?.money || []}
                disabled={disabledConference}
                operationId={operationId}
                value={
                  details?.transactions?.money
                    ? details?.transactions?.money
                        .filter(value => !value.isReversed)
                        .map(value => value.value)
                        .reduce((prev, next) => prev + next, 0)
                    : 0
                }
              />
              <Pix
                data={details?.transactions?.pix || []}
                operationId={operationId}
                disabled={disabledConference}
                value={
                  details?.transactions?.pix
                    ? details.transactions.pix
                        .filter(value => !value.isReversed)
                        .map(value => value.value)
                        .reduce((prev, next) => prev + next, 0)
                    : 0
                }
              />
              {/* <CardPay
                title="Credito"
                disabled={disabled}
                data={details?.transactions?.credit || []}
              />
              <CardPay
                title="Débito"
                disabled={disabled}
                data={details?.transactions?.debit || []}
              /> */}
              <CardPay
                title="Crédito POS"
                disabled={disabledConference}
                data={details?.transactions?.credit_pos || []}
                operationId={operationId}
              />
              <CardPay
                title="Débito POS"
                disabled={disabledConference}
                data={details?.transactions?.debit_pos || []}
                operationId={operationId}
              />
            </Flex>
            <Flex justifyContent="flex-end" marginVertical={2}>
              <Styles.TotalSale>
                Total vendido: <strong>{t('currency', { value: details?.total_sold })}</strong>
              </Styles.TotalSale>
            </Flex>
          </Styles.Container>

          {details.status !== CASH_DESK_STATUS.opened && (
            <>
              <hr />
              <Styles.Container>
                <Flex column gap={1}>
                  <DefaultStyles.Label>{tPage('detail.observations')}</DefaultStyles.Label>
                  <DefaultStyles.Value>{details?.observation || '-'}</DefaultStyles.Value>
                </Flex>
              </Styles.Container>
            </>
          )}

          <hr />
          <Styles.Container>
            <Flex justifyContent="flex-end">
              <Button
                isLoading={isInitialLoading}
                disabled={disabledButtonFinalizeConference}
                onClick={() => formRef?.current?.submitForm()}
              >
                Confirmar
              </Button>
            </Flex>
          </Styles.Container>
        </DefaultStyles.Wrapper>
      </Flex>
    </PageContainer>
  );
}

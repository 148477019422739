import { FormHandles } from '@unform/core';
import { UnAutocomplete, UnInputFIeld } from 'components';
import { usePax } from 'hooks/store';
import { useOperationDistricts } from 'hooks/useOperationDistricts';
import { memo, RefObject, useCallback } from 'react';
import { Flex } from 'styles/Flex';
import { debounce } from 'utils';
import { objToQuery } from 'utils/query';

interface Props {
  formRef: RefObject<FormHandles>;
}

const FilterComponent = ({ formRef }: Props): JSX.Element => {
  const { districts, handleLoadDistricts, isLoading: isLoadingDistricts } = useOperationDistricts();
  const {
    loadPaxList,
    throwPage: { disableFilter },
  } = usePax();

  const handleSearch = useCallback(() => {
    const searchData = formRef.current?.getData();

    if (searchData) {
      const queryObj = {
        search: searchData.search,
        district: searchData?.district?.value,
      };

      loadPaxList({ query: objToQuery(queryObj) });
    }
  }, []);

  return (
    <Flex maxWidth={50} gap={2}>
      <Flex flex={1}>
        <UnInputFIeld
          startIcon="search"
          name="search"
          placeholder="textField.searchPlaceholder"
          onChange={debounce(handleSearch)}
          disabled={disableFilter}
        />
      </Flex>

      <Flex flex={1}>
        <UnAutocomplete
          name="district"
          placeholder="pages/pax:throw.regionLabel"
          onOpen={handleLoadDistricts}
          loading={isLoadingDistricts}
          options={districts.map(districtStr => ({ label: districtStr, value: districtStr }))}
          onChange={debounce(handleSearch, 50)}
          returnAllOption
          disabled={disableFilter}
        />
      </Flex>
    </Flex>
  );
};

export const Filter = memo(FilterComponent);

import { Modal, Table } from 'components';
import { useTouristOperators } from 'hooks/useTouristOperators';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetTourisOperator } from 'types/touristGuide';
import { CreateOperatorForm, TableRow } from './components';
import * as Styles from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ManageOperatorsModal = ({ onClose, open }: Props) => {
  const [tPage] = useTranslation('pages/touristGuide');
  const { operators, isLoading, handleGetOperators } = useTouristOperators();

  useEffect(() => {
    if (open) {
      handleGetOperators();
    }
  }, [open]);

  return (
    <Modal maxWidth="sm" fullWidth title="Gerenciar operadoras" open={open} onClose={onClose}>
      <CreateOperatorForm afterCreate={handleGetOperators} />

      <Styles.TableContainer>
        <Table
          tableVariant="secondary"
          isLoading={isLoading}
          loadingMessage={'pages/touristGuide:operator.isLoadingOperators'}
          noDataMessage={tPage('operator.noOperatorsFound')}
          columns={[
            {
              field: 'name',
              headerName: tPage('operator.table.name'),
              colSpan: 1,
            },
            {
              field: 'commission',
              headerName: tPage('operator.table.commission'),
            },
            {
              field: 'edit',
              headerName: '',
            },
          ]}
          rows={operators}
          renderTds={(operator: GetTourisOperator) => <TableRow operator={operator} />}
        />
      </Styles.TableContainer>
    </Modal>
  );
};

import { routePaths } from 'constants/routes';
import { userKey, tokenKey, tokenTypeKey } from 'constants/storage';
import { apiLogout } from 'services/auth';
import { PostLoginUser } from 'types/auth';
import { history } from './history';
import { clearStorage, getItemStorage } from './storage';

export const getUserToken = () =>
  getItemStorage<string>(tokenKey) || getItemStorage<string>(tokenKey, true);

export const geUserTokenType = () =>
  getItemStorage<string>(tokenTypeKey) || getItemStorage<string>(tokenTypeKey, true);

export const geUserStorage = () =>
  getItemStorage<PostLoginUser>(userKey) || getItemStorage<PostLoginUser>(userKey, true);

export const logout = async (doRequest: boolean = true) => {
  doRequest && apiLogout();

  setTimeout(() => {
    clearStorage();
    clearStorage(true);

    history.push(routePaths.login);
  }, 100);
};

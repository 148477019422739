import { Button, UnInputFIeld, UnSwitch, Unform, UnAutocomplete } from 'components';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'styles/Flex';
import * as Styles from './styles';
import { addIntegrationValidationSchema } from 'yupSchemas/operation';
import { openNotification } from 'utils/notification';
import { getIntegrationOnOperation, postIntegrationOnOperation } from 'services/operation';
import { IntegrationOnOperation } from 'types/operation';
import { FormHandles } from '@unform/core';
interface Props {
  id: string;
}

const IntegrationComponent = ({ id }: Props): JSX.Element => {
  const [tPage] = useTranslation('pages/operations', { keyPrefix: 'details.integrationTab' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<Partial<IntegrationOnOperation>>({});
  const [isActive, setIsActive] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);

  const integrations = [
    { label: 'PWI', value: 'PWI' },
    { label: 'YUZER', value: 'YUZER' },
  ];

  const handleChangeIsActive = async (checked: boolean) => {
    setIsActive(checked);
  };

  const getIntegration = async (operationId: string) => {
    const { data } = await getIntegrationOnOperation(operationId);
    if (data) {
      setDataForm(data);
      formRef?.current?.setData(data);
      setIsActive(data.active);
    }
  };

  const handleSubmit = useCallback(async data => {
    setIsLoading(true);
    try {
      await postIntegrationOnOperation(id, data);
      openNotification(tPage('formErrors'), 'success');
      getIntegration(id);
    } catch (error) {
      openNotification(tPage('formErrors'), 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getIntegration(id);
  }, []);

  return (
    <Flex column gap={2}>
      <Styles.Container>
        <legend>{tPage('addIntegration')}</legend>

        <Unform
          ref={formRef}
          onSubmit={handleSubmit}
          validationSchema={addIntegrationValidationSchema}
          initialData={dataForm}
        >
          <Flex gap={1} marginTop={1.5}>
            <Flex flex={1} marginBottom={1.6}>
              <UnSwitch
                name="active"
                checked={isActive}
                label={isActive ? 'labels:input.active' : 'labels:input.disabled'}
                onChange={(e, checked) => handleChangeIsActive(checked)}
              />
            </Flex>
            <Flex flex={1}>
              <UnAutocomplete
                disabled={!isActive}
                name="integration_type"
                label="integration"
                defaultValue={integrations[0]}
                options={integrations}
              />
            </Flex>
            <Flex flex={1}>
              <UnInputFIeld disabled={!isActive} name="company" label="labels:input.company" />
            </Flex>

            <Flex flex={1}>
              <UnInputFIeld
                disabled={!isActive}
                name="cost_center"
                label="labels:input.costCenter"
              />
            </Flex>

            <Flex flex={1}>
              <UnInputFIeld
                disabled={!isActive}
                type="number"
                name="family"
                label="labels:input.family"
              />
            </Flex>

            <Flex column>
              <Button type="submit" isLoading={isLoading}>
                {tPage('addButton')}
              </Button>
            </Flex>
          </Flex>
        </Unform>
      </Styles.Container>
    </Flex>
  );
};

export const Integration = memo(IntegrationComponent);

import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  width: 100vw;
  max-width: 30rem;
  padding: 1rem;
`;

export const Title = styled.strong`
  font-size: 1.1rem;
  display: inline-block;
`;

export const Li = styled.li`
  list-style: circle;
  padding: 0.5rem 0;
`;

export const Ul = styled.ul`
  padding: 1rem;
`;

import { useField } from '@unform/core';
import { MaskInput } from 'components';
import React, { ComponentProps, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyToFloat } from 'utils/help';
import { cleanString } from 'utils/string';

interface IProps extends Omit<ComponentProps<typeof MaskInput>, 'defaultValue'> {
  name: string;
  cleanValue?: boolean;
  parseToFloat?: boolean;
  notDisplayError?: boolean;
  parseDefaultValue?(value: any): any;
}

const UnMaskInputComponent: React.FC<IProps> = ({
  name,
  helperText,
  cleanValue,
  onChange,
  parseToFloat,
  notDisplayError,
  onFocus,
  onBlur,
  parseDefaultValue,
  ...props
}) => {
  const [tErros] = useTranslation('formErrors');
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name);
  const [shrinkLabel, setShrinkLabel] = useState(!!defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        let value = ref.current.value;

        if (cleanValue) {
          value = cleanString(value);
        }

        if (parseToFloat) {
          value = value ? currencyToFloat(value) : value;
        }

        return value;
      },
      setValue: (ref, value) => (ref.current.value = value),
      clearValue: ref => {
        ref.current.value = '';
        setShrinkLabel(false);
      },
    });
  }, [fieldName, registerField, inputRef, cleanValue, parseToFloat, defaultValue]);

  useEffect(() => {
    if (defaultValue && parseDefaultValue && inputRef.current) {
      const parsedDefaultValue = parseDefaultValue(defaultValue);
      inputRef.current.value = parsedDefaultValue;
    }
  }, [defaultValue, parseDefaultValue]);

  return (
    <MaskInput
      ref={inputRef}
      error={!!error}
      defaultValue={parseDefaultValue?.(defaultValue) || defaultValue}
      helperText={!notDisplayError ? (error ? tErros(error) : helperText) : helperText}
      onFocus={e => {
        onFocus?.(e);
        setShrinkLabel(true);
      }}
      onBlur={e => {
        onBlur?.(e);

        if (e.target.value) return;

        setShrinkLabel(false);
      }}
      onChange={e => {
        onChange?.(e);
        error && clearError();

        if (e.target.value) setShrinkLabel(true);
      }}
      InputLabelProps={{ shrink: shrinkLabel }}
      {...props}
    />
  );
};

export const UnMaskInput = memo(UnMaskInputComponent);

import { memo, ReactNode } from 'react';

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function BaseTabPainel({ value, index, children, ...props }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...props}
    >
      {value === index && children}
    </div>
  );
}

export const TabPainel = memo(BaseTabPainel);

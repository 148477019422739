import { TableVariant } from 'components';
import styled, { css } from 'styled-components';

export interface RowStyle {
  alignItems?: 'middle' | 'bottom' | 'top';
  textAlign?: 'center' | 'left' | 'right';
  width?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
}

interface RowProps {
  _style?: RowStyle;
  transparent?: boolean;
}

export const TableHead = styled.thead``;

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  min-width: 10rem;
  position: relative;
`;

export const Th = styled.th`
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  font-size: ${({ theme }) => theme.fonts.sizes.xs};
  padding: 0.3rem 1rem;
  text-align: left;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  white-space: nowrap;
`;

export const Td = styled.td<RowProps>`
  padding: 0.3rem 1rem;
  text-align: left;
  background: ${props => (props.transparent ? 'transparent' : props.theme.colors.foreground)};
  vertical-align: ${({ _style }) => _style?.alignItems || 'auto'};
  white-space: nowrap;
  width: ${({ _style }) =>
    _style?.width
      ? typeof _style?.width === 'number'
        ? `${_style?.width}rem`
        : _style?.width
      : 'max-content'};
  max-width: ${({ _style }) =>
    _style?.maxWidth
      ? typeof _style?.maxWidth === 'number'
        ? `${_style?.maxWidth}rem`
        : _style?.maxWidth
      : 'auto'};
  }
  min-width: ${({ _style }) =>
    _style?.minWidth
      ? typeof _style?.minWidth === 'number'
        ? `${_style?.minWidth}rem`
        : _style?.minWidth
      : 'auto'};
  }
  :first-child {
    border-radius: ${({ theme }) => theme.borderRadius.md} 0px 0px
      ${({ theme }) => theme.borderRadius.md};
  }

  :last-child {
    border-radius: 0px ${({ theme }) => theme.borderRadius.md}
      ${({ theme }) => theme.borderRadius.md} 0px;
  }
`;

export const Tr = styled.tr<{ clickable?: boolean }>`
  overflow: hidden;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

export const TableBody = styled.tbody`
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const TFooter = styled.tfoot``;

export const Table = styled.table<{ variant?: TableVariant; noData?: boolean }>`
  width: 100%;
  min-width: 10rem;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  font-size: ${({ theme }) => theme.fonts.sizes.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  ${TableBody},
  ${TableHead} {
    white-space: nowrap;
  }

  ${({ theme, variant, noData }) =>
    variant === 'secondary' &&
    !noData &&
    css`
      ${TableBody} {
        tr {
          :not(:nth-of-type(odd)) {
            background-color: ${theme.colors.background};
          }
        }
      }

      ${Td} {
        background-color: transparent;
      }
    `}
`;

export const OrderContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding-left: 0.5rem;

  svg {
    :first-of-type {
      margin-bottom: -0.6rem;
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoDataMessage = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.sm};
  color: ${({ theme }) => theme.colors.text600};
  font-style: italic;
`;

export const TdContainer = styled.div`
  height: max-content;
  width: 100%;
`;

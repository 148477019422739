import { PaxState } from './type';
import { paxActions } from './actions';
import { IModule } from '../../types';

const state: PaxState = {
  loadings: {
    getPaxList: true,
    getHistoricList: true,
    throw: false,
  },
  throwPage: {
    headers: [
      {
        field: 'name',
        headerName: 'throw.headersTable.operation',
      },
      {
        field: 'district',
        headerName: 'throw.headersTable.city',
      },
      {
        field: 'errorIcon',
        headerName: '',
      },
      {
        field: 'date',
        headerName: 'throw.headersTable.date',
      },
      {
        field: 'input',
        headerName: 'throw.headersTable.throw',
      },
    ],
    paxList: [],
    filter: {},
    disableFilter: false,
  },
  historicPage: {
    headers: [
      {
        headerName: 'historic.headersTable.operation',
        field: 'operationName',
      },
      {
        headerName: 'historic.headersTable.region',
        field: 'region',
      },
      {
        headerName: 'historic.headersTable.user',
        field: 'user',
      },
      {
        headerName: 'historic.headersTable.dateHour',
        field: 'date',
      },
      {
        headerName: 'historic.headersTable.visitors',
        field: 'visitors',
      },
      {
        headerName: '',
        field: 'tooltip',
      },
    ],
    query: '',
    historicList: [],
  },
};

export const paxModule: IModule<PaxState, ReturnType<typeof paxActions>> = set => ({
  state,
  actions: paxActions(set),
});

// @ts-nocheck
import { forwardRef, memo, useImperativeHandle, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { useTranslation } from 'react-i18next';

import { TextField } from 'components/TextField/styles';

type RootDatePickerProps = Omit<MuiDatePickerProps<any, any>, 'renderInput'>;

interface DatePickerProps extends Omit<RootDatePickerProps, 'value'> {
  label?: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  defaultValue?: any;
  contained?: boolean;
  clearHelperTextSpacing?: boolean;
}

const BaseDatePicker = forwardRef<any, DatePickerProps>(
  (
    {
      label,
      name,
      error,
      helperText,
      defaultValue,
      clearHelperTextSpacing = true,
      onChange,
      contained,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(defaultValue || null);

    const handleChange = (date: unknown, keyboardInputValue?: string) => {
      setDate(date);
      onChange?.(date, keyboardInputValue);
    };

    useImperativeHandle(ref, () => ({ value: date }), [date]);

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBrLocale}>
        <MuiDatePicker
          {...props}
          value={date}
          label={label && t(label)}
          onChange={handleChange}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder && t(props.placeholder),
              }}
              contained={contained}
              error={error}
              helperText={helperText || (clearHelperTextSpacing ? undefined : ' ')}
              name={name}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
    );
  },
);

export const DatePicker = memo(BaseDatePicker);

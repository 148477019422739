import { forwardRef, memo, useState, ChangeEvent, useImperativeHandle } from 'react';

import { Checkbox } from 'components/Checkbox';

import * as Styles from './styles';
import { Flex } from 'styles/Flex';
import { UnCheckbox } from 'components';

interface CustomCheckboxProps {
  label?: string;
  disabled?: boolean;
  name: string;
  checked?: boolean;
  hidden?: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

type ValueRef = Record<string, any>;

interface CheckboxGroupProps {
  options: CustomCheckboxProps[];
  clearHelperTextSpacing?: boolean;
  helperText?: string;
  getBoolean?: boolean;
  defaultValue?: any;
  row?: boolean;
  asUnform?: boolean;
  disabled?: boolean;
}

const BaseCheckboxGroup = forwardRef<ValueRef, CheckboxGroupProps>(
  (
    {
      clearHelperTextSpacing,
      helperText,
      options,
      getBoolean,
      row,
      defaultValue = {},
      asUnform,
      disabled: defaultDisabled,
    },
    ref,
  ) => {
    const [values, setValues] = useState<ValueRef>(defaultValue);

    useImperativeHandle(ref, () => ({ values }), [values]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked } = event.target;

      setValues(prevState => ({
        ...prevState,
        [name]: getBoolean ? checked : value,
      }));
    };

    const renderCheckbox = options.map(
      ({ hidden, disabled, ...value }, index) =>
        !hidden && (
          <Checkbox
            key={index}
            {...value}
            disabled={defaultDisabled || disabled}
            onChange={event => {
              handleChange(event);
              value?.onChange?.(event);
            }}
          />
        ),
    );

    const renderUnCheckbox = options.map(
      ({ hidden, disabled, ...value }, index) =>
        !hidden && (
          <UnCheckbox
            disabled={defaultDisabled || disabled}
            key={index}
            {...value}
            onChange={event => {
              handleChange(event);
              value?.onChange?.(event);
            }}
          />
        ),
    );

    return (
      <Flex column gap={1}>
        <Flex column={!row} gap={1}>
          {asUnform ? renderUnCheckbox : renderCheckbox}
        </Flex>
        {!clearHelperTextSpacing && <Styles.HelperText>{helperText}</Styles.HelperText>}
      </Flex>
    );
  },
);

export const CheckboxGroup = memo(BaseCheckboxGroup);

import { useEffect, useState } from 'react';
import { getOperationUserAccesSelectsList } from 'services/operation';
import { EntityStatus } from 'types/common';
import { SimpleOperationList } from 'types/operation';

interface LoadParams {
  search?: string;
  status?: EntityStatus;
}

interface Params {
  loadOnInit?: boolean;
  validate?: (operations: SimpleOperationList[]) => Promise<void>;
}

export const useOperationOptions = ({ loadOnInit, validate }: Params = {}) => {
  const [operations, setOperations] = useState<SimpleOperationList[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadOperations = async (params: LoadParams = {}) => {
    setIsLoading(true);

    try {
      const response = await getOperationUserAccesSelectsList(params);

      await validate?.(response.data);

      setOperations(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadOnInit && handleLoadOperations();
  }, []);

  return {
    operations,
    isLoading,
    onLoadOperations: handleLoadOperations,
  };
};

import { FormHandles, SubmitHandler } from '@unform/core';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import {
  Button,
  ConfirmModal,
  FieldArray,
  IconButton,
  LoadingMessage,
  Unform,
  UnInputFIeld,
  UnMaskInput,
} from 'components';

import * as DetailStyles from 'styles/Details';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import * as Styles from './styles';

import { Grid } from '@mui/material';
import { CASH_DESK_PAYMENT_METHOD } from 'constants/cashDesk';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getOrder, putOrder } from 'services/order';
import { Legend } from 'styles/Legend';
import { Monetize } from 'styles/Monetize';
import { GetAcquire } from 'types/acquire';
import { GetCardFlag } from 'types/cardFlag';
import { UpdateOrder } from 'types/order';
import { currencyToFloat } from 'utils/help';
import { openNotification } from 'utils/notification';
import { paymentSchemaValidation } from 'yupSchemas/cashDesk';
import { Payment } from './components';

export interface PaymentType {
  unicKey: number;
  installment?: number;
  value: number;
  type: string;
  acquirer?: Partial<Pick<GetAcquire, 'name' | 'id'>>;
  card_flag?: Partial<Pick<GetCardFlag, 'name' | 'id'>>;
  order_id?: number;
  fee?: number;
  id?: number;
  authorization?: string;
  hidden?: boolean;
}

export interface Details extends Omit<UpdateOrder, 'payments'> {
  payments: Partial<PaymentType>[];
}

const basePayment: Partial<PaymentType> = {
  unicKey: new Date().getTime(),
  type: CASH_DESK_PAYMENT_METHOD.MONEY,
  installment: 1,
  acquirer: {},
  card_flag: {
    name: '',
    id: 0,
  },
};

const baseDetails: Partial<Details> = {
  payments: [basePayment],
};

export function EditCashdeskPayment({
  history,
  match,
}: RouteComponentProps<{ id: string; operationId: string }>) {
  const [tPage] = useTranslation('pages/cashDesk');
  const [t] = useTranslation();

  const formRef = useRef<FormHandles>(null);

  const id = useMemo<string>(() => match.params.id, [match.params.id]);
  const operationId = useMemo<string>(() => match.params.operationId, [match.params.operationId]);

  const [isCancelModal, toggleCancelModal] = useBooleanToggle(false);
  const [isInitialLoading, toggleIsInitialLoading] = useBooleanToggle(true);
  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const [paymentDeleted, setPaymentDeleted] = useState<number[]>([]);
  const [valueCurrentList, setValueCurrentList] = useState<{ value: number; unique: number }[]>([]);

  const [details, setDetails] = useState<Partial<Details>>(baseDetails);

  const valueSold = details?.value_sold || 0;
  const valueCurrent = valueCurrentList.reduce((prev, next) => prev + next.value, 0);
  const valueToPay = valueCurrent - valueSold;

  const canAddPaymentMethod = valueCurrent - valueSold < 0;

  const canSubmit = valueCurrent === valueSold;

  /** machine integration (acquirer) */
  const tefTypes = [CASH_DESK_PAYMENT_METHOD.DEBIT, CASH_DESK_PAYMENT_METHOD.CREDIT];

  const withoutIntegrationTypes = [CASH_DESK_PAYMENT_METHOD.PIX, CASH_DESK_PAYMENT_METHOD.MONEY];

  const installmentTypes = [CASH_DESK_PAYMENT_METHOD.CREDIT, CASH_DESK_PAYMENT_METHOD.CREDIT_POS];

  const canEditPayment = (type: string, isEdit: boolean) => tefTypes.includes(type) && isEdit;

  const getDetails = async (id: number | string) => {
    try {
      const { data } = await getOrder(id, operationId);
      const { payments, ...rest } = data;
      // ...(value.acquirer_name && { acquirer: { name: value.acquirer_name, id: 0 } }),
      // ...(value.card_flag_name && { card_flag: { name: value.card_flag_name, id: 0 } }),
      const paymentsParsed: PaymentType[] = payments.map(value => {
        return {
          unicKey: value.id,
          ...value,
          acquirer: {
            name: value.acquirer_name || '',
          },
          card_flag: {
            name: value.card_flag_name || '',
          },
        };
      });

      const payload = {
        ...rest,
        payments: paymentsParsed,
      };

      setDetails({ ...payload });

      const values = data.payments.map((value, index) => ({
        value: value.value,
        unique: new Date().getTime() + index,
      }));

      setValueCurrentList(values);
    } finally {
      toggleIsInitialLoading();
    }
  };

  const handleSubmit: SubmitHandler = async data => {
    try {
      if (!id) return;

      const payments = data.payments as PaymentType[];

      const paymentsPayload: UpdateOrder['payments'] = payments.map(value => ({
        ...(value.id && { id: value.id }),
        fee: value.fee || 0,
        type: value.type,
        value: value.value,
        authorization: value.authorization || null,
        ...(value.acquirer?.id && { acquirer_id: value.acquirer?.id }),
        ...(value.acquirer?.name && { acquirer_name: value?.acquirer?.name }),
        ...(value.card_flag?.id && { card_flag_id: value.card_flag?.id }),
        ...(value.card_flag?.name && { card_flag_name: value?.card_flag?.name }),
        installment: value.installment || 1,
      }));

      toggleIsLoading();

      const putData = {
        ...data,
        value_sold: details?.value_sold as number,
        payments: paymentsPayload,
        payments_deleted: paymentDeleted,
      };

      await putOrder(id, operationId, putData);

      openNotification(t('editMessage'));
      history.goBack();
    } finally {
      toggleIsLoading();
    }
  };

  const parseDataAtSubmit = (data: any) => {
    if (!id) return;

    const payments = data.payments as PaymentType[];

    return {
      ...data,
      payments: payments.filter(payment => !payment.hidden),
    };
  };

  useEffect(() => {
    if (!id) return toggleIsInitialLoading();

    getDetails(id);
  }, [id]);

  if (isInitialLoading) return <LoadingMessage text="Carregando..." />;

  return (
    <PageContainer md>
      <ConfirmModal
        open={isCancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={toggleCancelModal}
        onConfirm={() => history.goBack()}
      />
      <Flex column gap={1.62}>
        <Flex alignItems="center" gap={1}>
          <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={toggleCancelModal} />
          <h1>{tPage('create.title')}</h1>
        </Flex>
        <Styles.Card>
          <Unform
            ref={formRef}
            validationSchema={paymentSchemaValidation}
            initialData={details}
            onSubmit={handleSubmit}
            parseDataAtSubmit={parseDataAtSubmit}
          >
            <Styles.Container>
              <Flex column gap={1}>
                <Legend>{tPage('create.fieldset.client.title')}</Legend>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <UnInputFIeld
                      name="client_name"
                      label="pages/cashDesk:create.fieldset.client.name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <UnMaskInput
                      mask="cellphone"
                      name="client_phone"
                      label="pages/cashDesk:create.fieldset.client.phone"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <UnInputFIeld
                      name="client_email"
                      label="pages/cashDesk:create.fieldset.client.email"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <UnMaskInput
                      mask="cpf"
                      name="client_cpf"
                      label="pages/cashDesk:create.fieldset.client.cpf"
                    />
                  </Grid>
                </Grid>
              </Flex>
              <FieldArray
                initialData={details.payments || []}
                name="payments"
                searhcField={false}
                render={({ push, remove }, values) => {
                  return (
                    <Flex column alignItems="flex-start" gap={2}>
                      <Legend>{tPage('create.fieldset.payment.title')}</Legend>
                      {useMemo(
                        () =>
                          values?.map((value: PaymentType, index) => {
                            return (
                              <>
                                <Payment
                                  formRef={formRef}
                                  payment={value}
                                  type={value.type}
                                  isEdit={!!value.order_id}
                                  tefTypes={tefTypes}
                                  installmentTypes={installmentTypes}
                                  canEdit={canEditPayment(value.type, !!value.order_id)}
                                  withoutIntegration={withoutIntegrationTypes}
                                  onChangeUnique={valueCurrentList?.[index]?.unique || 0}
                                  onChangeValue={(event, onChangeUnique) => {
                                    setValueCurrentList(prevState =>
                                      prevState.map(value =>
                                        value.unique === onChangeUnique
                                          ? { ...value, value: currencyToFloat(event.target.value) }
                                          : value,
                                      ),
                                    );
                                  }}
                                  name={`payments[${index}]`}
                                  disableRemove={canEditPayment(value.type, !!value.order_id)}
                                  details={details as Details}
                                  onClickRemove={onChangeUnique => {
                                    !value?.id && remove(index);

                                    value.id &&
                                      setPaymentDeleted(prevState => [
                                        ...prevState,
                                        value.id as number,
                                      ]);

                                    setValueCurrentList(prevState => {
                                      if (value?.id) {
                                        return prevState.map(prevValue =>
                                          prevValue.unique === onChangeUnique
                                            ? { ...prevValue, value: 0 }
                                            : prevValue,
                                        );
                                      }

                                      return prevState.filter(
                                        prevValue => prevValue.unique !== onChangeUnique,
                                      );
                                    });
                                  }}
                                />

                                {index === values.length - 1 && (
                                  <Button
                                    variant="base"
                                    disabled={!canAddPaymentMethod}
                                    onClick={() => {
                                      setValueCurrentList(prevState => [
                                        ...prevState,
                                        {
                                          value: 0,
                                          unique: new Date().getTime(),
                                        },
                                      ]);
                                      push(basePayment);
                                    }}
                                    icon={{
                                      name: 'add',
                                    }}
                                  >
                                    {tPage('create.fieldset.buttons.add')}
                                  </Button>
                                )}
                              </>
                            );
                          }),
                        [values, canAddPaymentMethod, basePayment, valueCurrentList],
                      )}
                    </Flex>
                  );
                }}
              />
            </Styles.Container>
            <hr />
            <Styles.Container>
              <Flex justifyContent="space-between" alignItems="flex-start">
                <Flex column gap={2}>
                  <Flex gap={2} alignItems="center">
                    <Flex column gap={1}>
                      <DetailStyles.Label>{tPage('create.paidValue')}</DetailStyles.Label>
                      <Monetize>{t('currency', { value: valueCurrent })}</Monetize>
                    </Flex>
                    <Flex column gap={1}>
                      <DetailStyles.Label>{tPage('create.missing')}</DetailStyles.Label>
                      <Monetize isPositive={valueToPay > 1} isNegative={valueToPay < 0}>
                        {t('currency', { value: valueToPay })}
                      </Monetize>
                    </Flex>
                    <Flex column gap={1}>
                      <DetailStyles.Label>{tPage('create.totalPayed')}</DetailStyles.Label>
                      <Monetize isPositive={valueSold > 1} isNegative={valueSold < 0}>
                        {t('currency', { value: valueSold })}
                      </Monetize>
                    </Flex>
                  </Flex>
                </Flex>

                <Button isLoading={isLoading} disabled={!canSubmit} variant="green" type="submit">
                  {t('button.save')}
                </Button>
              </Flex>
            </Styles.Container>
          </Unform>
        </Styles.Card>
      </Flex>
    </PageContainer>
  );
}

import { useField } from '@unform/core';
import { Autocomplete } from 'components';
import React, { ComponentProps, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps extends ComponentProps<typeof Autocomplete> {
  name: string;
  returnAllOption?: boolean;
  onValidate?: (option: any) => Promise<void>;
}

const UnAutocompleteComponent: React.FC<IProps> = ({
  optionValue = 'value',
  name,
  returnAllOption,
  onChange,
  helperText,
  onValidate,
  ...props
}) => {
  const inputRef = useRef<unknown>(null);
  const [tErros] = useTranslation('formErrors');
  const { registerField, clearError, defaultValue, error, fieldName } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        if (returnAllOption) return ref.current.value || null;

        return ref.current.value?.[optionValue] || null;
      },
      setValue: (ref, value) => {
        ref.current.setValue(value);
      },
    });
  }, [fieldName, registerField, inputRef]);

  return (
    <Autocomplete
      {...props}
      optionValue={optionValue}
      defaultValue={defaultValue}
      ref={inputRef}
      error={!!error}
      helperText={error ? tErros(error) : helperText}
      onChange={async (ev, option, details) => {
        await onValidate?.(option);
        onChange?.(ev, option, details);
        error && clearError();
      }}
    />
  );
};

export const UnAutocomplete = memo(UnAutocompleteComponent);

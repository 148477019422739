export const colors = {
  primary: '#0b91f5',
  white: '#fff',
  primaryLight: 'rgba(237, 142, 59, 0.2)',
  primary600: '#07233c',
  primary700: '#07233c',
  background: '#F8F8F8',
  background600: '#F3F3F3',
  text: '#333333',
  text200: '#DADAE1',
  text300: '#C0C0C8',
  text400: '#C4C4C4',
  text500: '#a3a3a3',
  text600: '#717174',
  text700: '#737D86',
  text1000: '#000000',
  text100: '#FFFFFF',
  foreground: '#FFFFFF',
  hightLight: '#717174',
  valid: '#3E9C3C',
  danger: '#e74c3c',
  red: '#e74c3c',
  greenLight: '#B1F2C3',
  orangeLight: '#ffbe76',
  transparent: 'transparent',
};

import * as yup from 'yup';

import { invalidDateBiggerThanCurrentDate, requiredField } from './utils/messages';

export const dateValidation = (initial_key: string, final_key: string, requred?: boolean) => {
  return {
    [initial_key]: requred ? yup.date().nullable().required(requiredField) : yup.date(),
    [final_key]: yup
      .date()
      .nullable()
      .when(initial_key, (date, schema) =>
        date
          ? schema.min(new Date(date), invalidDateBiggerThanCurrentDate).required(requiredField)
          : schema,
      ),
  };
};

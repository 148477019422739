import React, { useState, useRef, useEffect, useCallback, CSSProperties } from 'react';
import {
  BaseTextFieldProps,
  InputAdornment,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  useMediaQuery,
} from '@mui/material';
import 'dayjs/locale/pt-br';
import {
  CountryIso2,
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import 'react-international-phone/style.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { postRecuperation } from 'services/recuperation';
import { CreateRecuperation } from 'types/recuperation';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'constants/routes';
import { getStorageUrl } from 'utils/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import nuvemVyoo from '../../../assets/NuvemPNG.png';
import CloseIcon from '@mui/icons-material/Close';
import DotLoader from 'react-spinners/DotLoader';
import Button2 from '@mui/material/Button';
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga4';
import * as Styles from './styles';

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
}

export const Recover: React.FC<MUIPhoneProps> = ({ value, onChange, ...restProps }) => {
  const [fotoSuaSelected, setFotoSuaSelected] = useState(false);
  const [qrCodeSelected, setQrCodeSelected] = useState(false);

  ReactGA.initialize('G-E2W8W5XZE6');

  // Input de telefone
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'br',
    value,
    countries: defaultCountries,
    onChange:
      typeof onChange === 'function'
        ? data => {
            onChange(data.phone);
          }
        : undefined,
  });

  // Input das fotos

  const [selectedFotoSua, setSelectedFotoSua] = useState<File | null>(null);

  const [selectedFotoQRCode, setSelectedFotoQRCode] = useState<File | null>(null);

  const fileFotoSua = useRef<HTMLInputElement>(null);

  const fileFotoQRCode = useRef<HTMLInputElement>(null);

  function handleFotoSuaChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFotoSua = event.target.files[0];
      setFotoSuaSelected(true);
      setSelectedFotoSua(selectedFotoSua);
    }
  }

  function handleFotoQRCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFotoQRCode = event.target.files[0];
      setQrCodeSelected(true);
      setSelectedFotoQRCode(selectedFotoQRCode);
    }
  }

  // Input do DateTimePicker
  const [dataVisita, setDataVisita] = useState(new Date());

  const handleDateChange = (date: Date | null) => {
    setDataVisita(date || new Date());
  };

  // Salvar valores dos fields em váriaveis
  const [nome, setNome] = useState('');

  const [email, setEmail] = useState('');

  const [clientQRCode, setClientQRCode] = useState('');

  const [observacoes, setObservacoes] = useState('');

  const [atracaoVisitada, setatracaoVisitada] = useState('');

  // Validação de fields

  function handleNomeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNome(event.target.value);
  }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handleChange(event: SelectChangeEvent<string>) {
    setatracaoVisitada(event.target.value);
  }

  function handleClientQrCodeChange(value: string) {
    const qrCodeValue = value.toUpperCase();
    setClientQRCode(qrCodeValue);
  }

  function handleObservacoesChange(event: React.ChangeEvent<HTMLInputElement>) {
    setObservacoes(event.target.value);
  }

  const initialNome = '';
  const initialEmail = '';
  const initialAtracaoVisitada = '';
  const initialDataVisita = new Date();
  const initialClientQRCode = '';
  const initialObservacoes = '';

  function sendData() {
    const data: CreateRecuperation = {
      client_name: nome,
      client_email: email,
      client_phone: phone,
      local: atracaoVisitada,
      date: dataVisita,
      client_qrcode: clientQRCode,
      observations: observacoes,
    };

    const clientPhoto = fileFotoSua.current?.files?.[0];

    const qrCodePhoto = fileFotoQRCode.current?.files?.[0];

    postRecuperation(data, clientPhoto, qrCodePhoto);

    setNome(initialNome);
    setEmail(initialEmail);
    setCountry('br');
    setatracaoVisitada(initialAtracaoVisitada);
    setDataVisita(initialDataVisita);
    setClientQRCode(initialClientQRCode);
    setObservacoes(initialObservacoes);
    setFotoSuaSelected(false);
    setQrCodeSelected(false);
    fileFotoSua.current!.value = '';
    fileFotoQRCode.current!.value = '';
  }

  // Bloquear ação padrão do form
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  // Validação de campos
  const formRef = React.useRef<HTMLFormElement>(null);

  const history = useHistory();
  const redirectHome = useCallback(() => history.push(routePaths.home), []);

  // Carregamento

  const override: CSSProperties = {
    left: '85px',
    bottom: '85px',
  };

  const [showTabsBox, setShowTabsBox] = useState(true);

  const [showLoader, setShowLoader] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormValidity = () => {
    const isValid =
      nome !== '' && email !== '' && phone !== '' && atracaoVisitada !== '' && observacoes !== '';
    setIsFormValid(isValid);
  };

  useEffect(() => {
    checkFormValidity();
    const time = 6000;
    let currentImageIndex = 0;
    const images = document.querySelectorAll<HTMLImageElement>('#slider img');
    const max = images.length - 1;

    function nextImage() {
      images[currentImageIndex].classList.remove('selected');
      currentImageIndex++;

      if (currentImageIndex >= max) currentImageIndex = 0;

      images[currentImageIndex].classList.add('selected');
    }

    function start() {
      setInterval(() => {
        // troca de imagem
        nextImage();
      }, time);
    }

    start();
  }, [nome, email, phone, atracaoVisitada, observacoes]);

  const handleDisabledButtonClick = () => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Botão Enviar Desabilitado Click',
    });
  };

  const handleActiveButtonClick = () => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Botão Enviar Click',
    });
  };

  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Styles.LayoutPrincipal>
      <Styles.BackgroundImage>
        <Styles.sliderStyles>
          <div id="slider">
            <img className="selected" src={getStorageUrl('/carrossel01.jpg')} alt="Image1" />

            <img src={getStorageUrl('/carrossel02.jpg')} alt="Image2" />

            <img src={getStorageUrl('/carrossel03.jpg')} alt="Image3" />

            <img src={getStorageUrl('/carrossel04.jpg')} alt="Image4" />

            <img src={getStorageUrl('/carrossel05.jpg')} alt="Image5" />

            {showTabsBox && (
              <Styles.TabsBox>
                <div>
                  <Styles.HeaderDiv>
                    <Styles.H1>Insira as informações para o SAC</Styles.H1>
                    {isMobile ? (
                      <Styles.closeButtonDiv>
                        <CloseIcon
                          onClick={redirectHome}
                          sx={{
                            '  & > :not(style)': {
                              color: 'black',
                              fontWeight: '500',
                            },
                          }}
                        ></CloseIcon>
                      </Styles.closeButtonDiv>
                    ) : (
                      <Styles.divBotaoVoltar>
                        <div className="button-mui">
                          <Button2
                            className="btn-back"
                            style={{
                              borderRadius: 15,
                              fontFamily: 'Montserrat',
                              fontSize: '18px',
                              fontWeight: 'bold',
                              padding: '0.8vh 1.2vw',
                              textTransform: 'lowercase',
                            }}
                            variant="contained"
                            startIcon={<ArrowBackIcon />}
                            onClick={redirectHome}
                            sx={{
                              '  & > :not(style)': {
                                borderRadius: '60px',
                                color: 'white',
                                fontSize: '15px',
                                fontWeight: '500',
                              },
                            }}
                          >
                            <span style={{ fontSize: '15px', fontWeight: 'bold' }}>Voltar</span>
                          </Button2>
                        </div>
                      </Styles.divBotaoVoltar>
                    )}
                  </Styles.HeaderDiv>
                  <form ref={formRef}>
                    <TextField
                      required
                      fullWidth
                      id="nome"
                      size="small"
                      label="Nome do solicitante"
                      variant="outlined"
                      value={nome}
                      onChange={handleNomeChange}
                      onKeyDown={handleKeyDown}
                      inputProps={{ maxLength: 200 }}
                      sx={{
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#0b91f5',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#0b91f5',
                          top: '-1px',
                        },
                        '& .MuiInputLabel-root': {
                          top: '-5px',
                        },
                        '  & > :not(style)': {
                          borderRadius: '10px',
                          color: '#333333',
                          fontSize: '12px',
                          marginBottom: 1.2,
                          padding: '2px',
                          fontFamily: 'Montserrat',
                        },
                      }}
                    />
                    <TextField
                      required
                      fullWidth
                      id="email"
                      size="small"
                      label="Email"
                      variant="outlined"
                      value={email}
                      onChange={handleEmailChange}
                      onKeyDown={handleKeyDown}
                      inputProps={{ maxLength: 320 }}
                      sx={{
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#0b91f5',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#0b91f5',
                          top: '-1px',
                        },
                        '& .MuiInputLabel-root': {
                          top: '-5px',
                        },
                        '& > :not(style)': {
                          borderRadius: '10px',
                          color: '#333333',
                          fontSize: '12px',
                          marginBottom: 1.2,
                          padding: '2px',
                          fontFamily: 'Montserrat',
                        },
                      }}
                    />
                    <br />
                    <TextField
                      required
                      fullWidth
                      id="telefone"
                      size="small"
                      label="Número de telefone"
                      variant="outlined"
                      value={phone}
                      onChange={handlePhoneValueChange}
                      onKeyDown={handleKeyDown}
                      inputRef={inputRef}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginRight: '2px', marginLeft: '-6px', zIndex: '1' }}
                          >
                            <Select
                              MenuProps={{
                                style: {
                                  height: '300px',
                                  width: '360px',
                                  top: '10px',
                                  left: '-34px',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                              }}
                              sx={{
                                width: 'max-content',
                                fieldset: {
                                  display: 'none',
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                  fieldset: {
                                    display: 'block',
                                  },
                                },
                                '.MuiSelect-select': {
                                  padding: '8px',
                                  paddingRight: '24px !important',
                                },
                                svg: {
                                  right: 0,
                                },
                              }}
                              value={country}
                              onChange={e => setCountry(e.target.value as CountryIso2)}
                              renderValue={value => (
                                <FlagEmoji
                                  className="regularImages"
                                  iso2={value}
                                  style={{ display: 'flex' }}
                                />
                              )}
                            >
                              {defaultCountries.map(c => {
                                const country = parseCountry(c);
                                return (
                                  <MenuItem key={country.iso2} value={country.iso2}>
                                    <FlagEmoji iso2={country.iso2} style={{ marginRight: '8px' }} />
                                    <Typography marginRight="8px">{country.name}</Typography>
                                    <Typography color="gray">+{country.dialCode}</Typography>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                      {...restProps}
                      sx={{
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#0b91f5',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#0b91f5',
                        },
                        '& > :not(style)': {
                          borderRadius: '10px',
                          color: '#333333',
                          fontSize: '12px',
                          marginBottom: 1.2,
                          padding: '2px',
                          fontFamily: 'Montserrat',
                        },
                      }}
                    />
                    <FormControl fullWidth>
                      <InputLabel
                        required
                        htmlFor="grouped-native-select"
                        sx={{
                          color: '#333333',
                          fontSize: '12px',
                          fontFamily: 'Montserrat',
                          top: '-3px',
                          '&.Mui-focused': {
                            top: '-0px',
                          },
                        }}
                      >
                        Atração Visitada
                      </InputLabel>
                      <Select
                        native
                        required
                        onKeyDown={handleKeyDown}
                        defaultValue=""
                        id="grouped-native-select"
                        label="Atração Visita"
                        sx={{
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#0b91f5',
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#0b91f5',
                            top: '-1px',
                          },
                          ...{
                            borderRadius: '10px',
                            color: '#333333',
                            fontSize: '12px',
                            height: '40px',
                            marginBottom: 1.2,
                            padding: '2px',
                            fontFamily: 'Montserrat',
                          },
                        }}
                        onChange={handleChange}
                      >
                        <option aria-label="None" value="" />
                        <optgroup label="Paraná">
                          <option value="Dreams Ice Bar - Paraná">Dreams Ice Bar</option>
                          <option value="Dreams Motor Show - Paraná">Dreams Motor Show</option>
                          <option value="Maravilhas do Mundo - Paraná">Maravilhas do Mundo</option>
                          <option value="Museu de Cera Dreamland - Paraná">
                            Museu de Cera Dreamland
                          </option>
                        </optgroup>
                        <optgroup label="Rio de Janeiro">
                          <option value="Museu de Cera Dreamland - Rio de Janeiro">
                            Museu de Cera Dreamland
                          </option>
                          <option value="Roda Gigante Yup Star - Rio de Janeiro">
                            Roda Gigante Yup Star
                          </option>
                        </optgroup>
                        <optgroup label="Rio Grande do Sul">
                          <option value="Dreams Motor Show - Rio Grande do Sul">
                            Dreams Motor Show
                          </option>
                          <option value="Hollywood Dream Cars - Rio Grande do Sul">
                            Hollywood Dream Cars
                          </option>
                          <option value="Jardim do Amor - Rio Grande do Sul">Jardim do Amor</option>
                          <option value="Museu de Cera Dreamland - Rio Grande do Sul">
                            Museu de Cera Dreamland
                          </option>
                          <option value="Selfie - Rio Grande do Sul">Selfie</option>
                        </optgroup>
                        <optgroup label="Santa Catarina">
                          <option value="Alles Park - Santa Catarina">Alles Park</option>
                        </optgroup>
                        <optgroup label="São Paulo">
                          <option value="Museu de Cera Dreamland - São Paulo">
                            Museu de Cera Dreamland
                          </option>
                          <option value="Roda Gigante Roda Rico - São Paulo">
                            Roda Gigante Roda Rico
                          </option>
                          <option value="Vale dos Dinossauros - São Paulo">
                            Vale dos Dinossauros
                          </option>
                        </optgroup>
                      </Select>
                    </FormControl>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-BR">
                      <MobileDateTimePicker
                        label="Data e horário de visita"
                        inputFormat="DD/MM/YYYY - HH:mm"
                        disableFuture
                        ampm={false}
                        value={dataVisita}
                        onChange={handleDateChange}
                        renderInput={params => (
                          <TextField
                            required
                            fullWidth
                            size="small"
                            {...params}
                            onKeyDown={handleKeyDown}
                            sx={{
                              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                  borderColor: '#0b91f5',
                                },
                              '& .MuiInputLabel-root.Mui-focused': {
                                color: '#0b91f5',
                              },
                              '  & > :not(style)': {
                                borderRadius: '10px',
                                color: '#333333',
                                fontSize: '12px',
                                marginBottom: 1.2,
                                padding: '2px',
                                fontFamily: 'Montserrat',
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <br />
                    <InputMask
                      mask="aaaa-*******"
                      className="scan-card"
                      value={clientQRCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleClientQrCodeChange(e.target.value)
                      }
                    >
                      <TextField
                        fullWidth
                        id="QRCode"
                        size="small"
                        label="QRCode (Opcional)"
                        variant="outlined"
                        onKeyDown={handleKeyDown}
                        inputProps={{ maxLength: 15 }}
                        sx={{
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#0b91f5',
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#0b91f5',
                            top: '-1px',
                          },
                          '& .MuiInputLabel-root': {
                            top: '-5px',
                          },
                          '& > :not(style)': {
                            borderRadius: '10px',
                            color: '#333333',
                            fontSize: '12px',
                            marginBottom: 1.2,
                            padding: '2px',
                            fontFamily: 'Montserrat',
                          },
                        }}
                      />
                    </InputMask>
                    <br />
                    <Styles.DivObservacoes>
                      <TextField
                        required
                        multiline
                        fullWidth
                        id="TextObservacoes"
                        size="small"
                        label="Observações (Descreva o seu problema)"
                        variant="outlined"
                        value={observacoes}
                        onChange={handleObservacoesChange}
                        onKeyDown={handleKeyDown}
                        inputProps={{ maxLength: 400 }}
                        sx={{
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#0b91f5',
                          },
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#0b91f5',
                            top: '-5px',
                          },
                          '& .MuiInputLabel-root': {
                            left: '-8px',
                            top: '-14px',
                          },
                          '& > :not(style)': {
                            borderRadius: '10px',
                            color: '#333333',
                            fontSize: '12px',
                            height: '100%',
                            padding: '10px',
                            fontFamily: 'Montserrat',
                          },
                        }}
                      />
                    </Styles.DivObservacoes>
                    <br />
                    <div>
                      <Styles.inputUploadPhotoSua
                        type="file"
                        id="botao-foto-sua"
                        ref={fileFotoSua}
                        accept="image/*"
                        onChange={handleFotoSuaChange}
                      />
                      <Styles.ButtonUploadPhotoSua
                        onClick={event => {
                          event.preventDefault();
                          fileFotoSua.current && fileFotoSua.current.click();
                        }}
                      >
                        <Styles.TextUpload>
                          {fotoSuaSelected
                            ? 'Imagem selecionada: (' + (selectedFotoSua?.name || '') + ')'
                            : 'Insira uma foto sua no local (Opcional)'}
                        </Styles.TextUpload>
                      </Styles.ButtonUploadPhotoSua>
                    </div>
                    <div>
                      <Styles.inputUploadPhotoQRCode
                        type="file"
                        id="botao-foto-qrcode"
                        ref={fileFotoQRCode}
                        accept="image/*"
                        onChange={handleFotoQRCodeChange}
                      />
                      <Styles.ButtonUploadPhotoQRCode
                        onClick={event => {
                          event.preventDefault();
                          fileFotoQRCode.current && fileFotoQRCode.current.click();
                        }}
                      >
                        <Styles.TextUpload>
                          {qrCodeSelected
                            ? 'Imagem selecionada: (' + (selectedFotoQRCode?.name || '') + ')'
                            : 'Insira uma foto do QRCode (Opcional)'}
                        </Styles.TextUpload>
                      </Styles.ButtonUploadPhotoQRCode>
                    </div>
                    {isFormValid ? (
                      <Styles.ButtonSend
                        onClick={event => {
                          event.preventDefault();
                          formRef.current?.reportValidity();
                          if (formRef.current?.reportValidity() === true) {
                            handleActiveButtonClick();
                            setShowLoader(true);
                            setShowTabsBox(false);
                            sendData();
                            setTimeout(() => {
                              redirectHome();
                            }, 10000);
                          }
                        }}
                      >
                        Enviar Dados
                      </Styles.ButtonSend>
                    ) : (
                      <Styles.DisabledButton type="button" onClick={handleDisabledButtonClick}>
                        Enviar Dados
                      </Styles.DisabledButton>
                    )}
                  </form>
                </div>
              </Styles.TabsBox>
            )}
            {showLoader && (
              <Styles.loaderDiv>
                <Styles.H1Loading>
                  Recebemos a sua mensagem e nossa equipe entrará em contato em até 72 horas.
                </Styles.H1Loading>
                <Styles.clockCloud>
                  <img
                    className="regularImages"
                    src={nuvemVyoo}
                    style={{ width: '170px', height: '101px' }}
                  />
                  <DotLoader
                    loading={true}
                    color="#0b91f5"
                    cssOverride={override}
                    size={40}
                    speedMultiplier={1.2}
                  />
                </Styles.clockCloud>
              </Styles.loaderDiv>
            )}
          </div>
        </Styles.sliderStyles>
      </Styles.BackgroundImage>
    </Styles.LayoutPrincipal>
  );
};

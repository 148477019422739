import { INVENTORY_TYPES } from 'constants/inventory';
import * as Yup from 'yup';
import { requiredField } from './utils/messages';

const validateUnitCost = (
  value?: number | string,
  params?: Yup.TestContext<Record<string, any>>,
) => {
  const type = params?.parent.type;

  if (type === INVENTORY_TYPES.entry) {
    return value === 0 || !!value;
  }

  return true;
};

export const addProductValidationSchema = Yup.object().shape({
  product: Yup.mixed().required(requiredField),
  amount: Yup.number().required(requiredField).typeError(requiredField),
  unit_cost: Yup.mixed().test('validate-unit-cost', requiredField, validateUnitCost),
});

export const createInventoryValidationSchema = Yup.object().shape({
  type: Yup.mixed().required(requiredField).typeError(requiredField),
  destinationOperationId: Yup.mixed().required(requiredField).typeError(requiredField),
  nameProvider: Yup.string().required(requiredField),
  invoiceNumber: Yup.string().required(requiredField),
  invoiceDate: Yup.date().required(requiredField),
  totalValueInvoice: Yup.number().required(requiredField).typeError(requiredField),
  products: Yup.array().required(requiredField).min(1, requiredField),
});

export const createInventoryTransfefrValidationSchema = Yup.object().shape({
  type: Yup.mixed().required(requiredField).typeError(requiredField),
  originOperationId: Yup.mixed().required(requiredField).typeError(requiredField),
  destinationOperationId: Yup.mixed().required(requiredField).typeError(requiredField),
  products: Yup.array().required(requiredField).min(1, requiredField),
});

import React, { memo, ComponentProps, ButtonHTMLAttributes } from 'react';

import { Icon, LoadingMessage } from 'components';

import { StyledButton, StyledButtonProps } from './styles';

type RootButtonProps = Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;

interface IProps extends StyledButtonProps, RootButtonProps {
  isLoading?: boolean;
  icon?: ComponentProps<typeof Icon>;
}

const ButtonComponent: React.FC<IProps> = ({ type = 'button', isLoading, icon, ...props }) => {
  return (
    <StyledButton type={type} {...props}>
      {isLoading ? (
        <LoadingMessage color="inherit" size={25} />
      ) : (
        <>
          {icon && <Icon {...icon} size={icon.size || 1} />}
          {props.children}
        </>
      )}
    </StyledButton>
  );
};

export const Button = memo(ButtonComponent);

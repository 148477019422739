import { IHomeState } from './type';
import { homeActions } from './actions';
import { IModule } from '../../types';

const state: IHomeState = {
  count: 0,
};

export const homeModule: IModule<IHomeState, ReturnType<typeof homeActions>> = set => ({
  state,
  actions: homeActions(set),
});

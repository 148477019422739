import {
  CardFlagQueries,
  CreateCardFlag,
  GetCardFlag,
  GetCardFlagResponse,
  UpdateCardFlag,
} from 'types/cardFlag';

import { objToQuery } from 'utils/query';

import api from './apis/api';

export function createCardFlag(payload: CreateCardFlag) {
  return api.post('/card-flag', payload);
}

export function getCardFlag(queries?: CardFlagQueries) {
  return api.get<GetCardFlagResponse>(`/card-flag${queries ? `?${objToQuery(queries)}` : ''}`);
}

export function getCardFlagById(id: string | number) {
  return api.get<GetCardFlag>(`/card-flag/${id}`);
}

export function putCardFlagById(id: string | number, payload: CreateCardFlag) {
  return api.put<UpdateCardFlag>(`/card-flag/${id}`, payload);
}

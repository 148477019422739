import { Icon, PaginatedTable, Popover } from 'components';
import { usePax } from 'hooks/store';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'styles/Flex';
import { HistoricPax } from 'types/pax';
import { objToQuery } from 'utils/query';

interface Props {
  onClickEdit(historic: HistoricPax): void;
  onClickDelete(historic: HistoricPax): void;
}

const HistoricTableComponent = ({ onClickEdit, onClickDelete }: Props): JSX.Element => {
  const [tPage] = useTranslation('pages/pax');
  const { t } = useTranslation();

  const {
    historicPage: { headers, historicList, meta },
    loadings,
    loadHistoricList,
  } = usePax();

  const handleChangePage = useCallback(async (e, newPage: number) => {
    await loadHistoricList({ query: objToQuery({ page: newPage }) });
  }, []);

  useEffect(() => {
    loadHistoricList();
  }, []);

  return (
    <PaginatedTable
      tableVariant="secondary"
      isLoading={loadings.getHistoricList}
      defaultOrder="date"
      count={meta?.last_page || 1}
      onPageChange={handleChangePage}
      columns={headers.map(header => ({
        ...header,
        headerName: header.headerName && tPage(header.headerName),
      }))}
      rows={historicList.map(historic => ({
        ...historic,
        operationName: historic.operation.name,
        region: `${historic.operation.address.city} / ${historic.operation.address.uf}`,
        visitors: (
          <Flex gap={0.5}>
            <Icon name="people" color="text500" />
            {historic.visitors}
          </Flex>
        ),
        date: t('dateHours', { date: new Date(historic.created_at) }),
        user: historic.user.fullName,
        tooltip: (
          <Flex justifyContent="flex-end">
            <Popover
              options={[
                {
                  label: 'pages/pax:historic.editPopoverLabel',
                  onClick: () => onClickEdit(historic),
                },
                {
                  label: 'pages/pax:historic.deletePopoverLabel',
                  onClick: () => onClickDelete(historic),
                },
              ]}
            />
          </Flex>
        ),
      }))}
    />
  );
};

export const HistoricTable = memo(HistoricTableComponent);

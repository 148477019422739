import { ButtonBase } from '@mui/material';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.nav`
  grid-area: sidebar;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 2rem 0rem;
  min-width: 15rem;
  height: 5000px;
  font-size: ${({ theme }) => theme.fonts.sizes.xs};
`;

export const Ul = styled.ul`
  margin-bottom: 0.5rem;
`;

export const Li = styled.li`
  font-size: ${({ theme }) => theme.fonts.sizes.xs};
  color: ${({ theme }) => theme.colors.primary700};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const LinkButton = styled(ButtonBase)<{ selected?: boolean; section?: boolean }>`
  padding: ${({ section }) => (section ? '.5rem 2rem' : '.8rem 2rem')};
  color: ${({ theme, section }) => (section ? theme.colors.primary700 : theme.colors.text100)};
  font-weight: ${({ theme, section }) =>
    section ? theme.fonts.weight.semiBold : theme.fonts.weight.regular};
  pointer-events: ${({ section }) => (section ? 'none' : 'all')};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  transition: background-color 0.3s;
`;

export const NavLink = styled(RouterNavLink)`
  &.active {
    ${LinkButton} {
      background-color: ${({ theme }) => theme.colors.primary600};
      font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
    }
  }
`;

import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { Tabs, Button, IconButton, LoadingMessage } from 'components';

import { OperationInfo } from './OperationInfo';
import { Logs } from './Logs';
import { Inventory } from './Inventory';

import * as Styles from 'styles/Details';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';
import { useEffect, useState } from 'react';
import { ListOperation } from 'types/operation';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { getFile } from 'services/file';
import { getOperationById as getOperationByIdApi } from 'services/operation';
import { verifyAuthentication } from 'utils/authorization';
import { Integration } from './Integration';

export function DetailOperation(props: RouteComponentProps<{ id: string }>) {
  const { t } = useTranslation('pages/details');
  const operationId = props.match.params.id;
  const [operation, setOperation] = useState<Partial<ListOperation>>({});
  const [isLoading, toggle] = useBooleanToggle(false);
  const [file, setFile] = useState<string>();

  const handleGetOperationById = async (id: string) => {
    try {
      toggle();
      const { data } = await getOperationByIdApi(id);

      setOperation(data);

      if (data.operation?.background?.id) {
        const response = await getFile(data.operation?.background?.id);
        setFile(response || '');
      }
    } catch (err) {
    } finally {
      toggle();
    }
  };

  useEffect(() => {
    if (!operationId) return;
    handleGetOperationById(operationId);
  }, [operationId]);

  return (
    <Styles.Container>
      <Flex alignItems="center" wrap gap={1} justifyContent="space-between">
        <Flex alignItems="center" gap={1}>
          <IconButton
            icon={{ name: 'arrowBack', size: 1.4 }}
            onClick={() => props.history.goBack()}
          />
          <h1 style={{ margin: 0 }}>{t('operations.title')}</h1>
        </Flex>
        <Button
          onClick={() =>
            props.history.push(resolvePath(routePaths.operation.edit, { id: operationId }))
          }
        >
          {t('pages/details:editButton')}
        </Button>
      </Flex>
      <Styles.Wrapper>
        {isLoading ? (
          <LoadingMessage text="Carregando informações" />
        ) : (
          <Tabs
            tabs={[
              { label: t('pages/operations:details.tabs.operationData') },
              { label: t('pages/operations:details.tabs.logs') },
              { label: t('pages/operations:details.tabs.integration') },
              {
                label: t('pages/operations:details.tabs.inventory'),
                disabled: !verifyAuthentication('INVENTORY_MANAGE_MANAGE_ALL'),
              },
            ]}
          >
            <OperationInfo id={operationId} operation={operation} file={file} />
            <Logs id={operationId} />
            <Integration id={operationId} />
            <Inventory id={operationId} operation={operation} history={props.history} />
          </Tabs>
        )}
      </Styles.Wrapper>
    </Styles.Container>
  );
}

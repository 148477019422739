import { DashBase } from 'types/dash';
import { resolveQueryStart } from 'utils/query';
import api from './apis/api';

export const getDashOperations = (query: string) =>
  api.get<DashBase>(`/dash/operation${query[0] === '?' ? query : `?${query}`}`);

export const getDashPDV = (query: string) =>
  api.get<DashBase>(`/dash/pdv${query[0] === '?' ? query : `?${query}`}`);

export const getDashProducts = (query?: string) =>
  api.get<DashBase>(`/dash/product${resolveQueryStart(query)}`);

import styled from 'styled-components';

export const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.foreground};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const SendIcon = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`;

import styled from 'styled-components';

import { Item as OddItem, List as OddList, RemoveButton as OddRemoveButton } from 'styles/OddList';

export const Item = styled(OddItem)`
  span {
    display: flex;
    flex: 1;
  }
`;

export const List = styled(OddList)``;

export const RemoveButton = styled(OddRemoveButton)``;

import React, { ComponentProps, memo, useState } from 'react';

import { Popover, Icon, ConfirmModal } from 'components';

import { deletePdv, patchStatus } from 'services/pdv';

import useBooleanToggle from 'hooks/useBooleanToggle';
import { RouteComponentProps } from 'react-router';
import { resolvePath, routePaths } from 'constants/routes';

type PopoverProps = ComponentProps<typeof Popover>;

interface MenuProps {
  id?: string | number;
  status?: number;
  onDelete?: () => void;
  onEdit?: () => void;
  history: RouteComponentProps['history'];
}

function BaseMenu(props: MenuProps) {
  const [isVisibleDeleteModal, toggleDeleteModal] = useBooleanToggle(false);
  const [isVisibleEditModal, toggleEditModal] = useBooleanToggle(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleToggleStatus = async (id: string | number) => {
    try {
      await patchStatus(id);
      props?.onEdit?.();
    } catch (err) {
    } finally {
      toggleEditModal();
    }
  };

  const handleDelete = async (id: string | number) => {
    setIsLoading(true);
    try {
      await deletePdv(id);
      toggleDeleteModal();
      props?.onDelete?.();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const options: PopoverProps['options'] = [
    {
      label: 'commons:popoverList.edit',
      onClick: () => props.history?.push(resolvePath(routePaths.pdv.edit, { id: props.id })),
    },
    {
      label: props.status !== 1 ? 'commons:popoverList.active' : 'commons:popoverList.inactive',
      onClick: toggleEditModal,
    },
    { label: 'commons:popoverList.delete', variante: 'error', onClick: toggleDeleteModal },
  ];

  return (
    <div onClick={event => event.stopPropagation()}>
      <ConfirmModal
        title="Atenção"
        bodyText="Deseja realmente excluir este PDV?"
        open={isVisibleDeleteModal}
        onClose={toggleDeleteModal}
        disabled={isLoading}
        onConfirm={() => !!props?.id && handleDelete(props?.id)}
      />
      <ConfirmModal
        open={isVisibleEditModal}
        title="Atenção"
        bodyText="Deseja realmente alterar o status dessa operação?"
        onClose={toggleEditModal}
        onConfirm={() => props.id && handleToggleStatus(props.id)}
      />
      <Popover options={options} id="simple">
        <Icon name="moreVert" size={1.3} />
      </Popover>
    </div>
  );
}

export const Menu = memo(BaseMenu);

import { Button } from 'components';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllPhotosToDownload } from 'services/qrcode';
import { GetQRCodeDigitalPhotos } from 'types/QRCode';
import { downloadFile } from 'utils/api';
import { openNotification } from 'utils/notification';
import { ReactComponent as VyooLogo } from '../../../../../assets/vyoo-logo-novo.svg';

import * as Styles from './styles';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'constants/routes';

interface Props {
  qrCode: string;
  photos: GetQRCodeDigitalPhotos[];
}

export const Header = ({ photos, qrCode }: Props) => {
  const [tPage] = useTranslation('pages/gallery');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownloadPhotos = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAllPhotosToDownload(qrCode);

      if (response.status === 200) {
        downloadFile(response.data, `${qrCode}.zip`);
      }
    } catch (error) {
      openNotification(tPage('errorOnDownloadPhotos'), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [photos]);

  const history = useHistory();
  const redirectHome = useCallback(() => history.push(routePaths.home), []);

  return (
    <Styles.Header>
      <Styles.DivPonteiroMouse>
        <VyooLogo onClick={redirectHome} />
      </Styles.DivPonteiroMouse>

      <Styles.LeftContainer>
        <p>{qrCode}</p>

        <span>{tPage('photos', { count: photos.length })}</span>
      </Styles.LeftContainer>

      <Styles.ButtonContainer>
        <Button variant="green" fullWidth isLoading={isLoading} onClick={handleDownloadPhotos}>
          {tPage('downloadZip')}
        </Button>
        <a
          href="https://vyoofotos.com.br/recover"
          rel="noopener noreferrer"
          style={{
            color: '#0A91F7',
            textDecoration: 'underline',
            display: 'block',
            textAlign: 'center',
            marginTop: '5px',
            cursor: 'pointer',
          }}
        >
          Comprar mais fotos
        </a>
      </Styles.ButtonContainer>
    </Styles.Header>
  );
};

import api from './apis/api';

import {
  CreateQRCode,
  CreateQRCodeResponse,
  GetQRCodeDigitalPhotos,
  GetQRCodeQueries,
  GetQRCodeResponse,
} from 'types/QRCode';

import { objToQuery } from 'utils/query';

export async function getQrcode(query?: GetQRCodeQueries) {
  return await api.get<GetQRCodeResponse>(
    `/operation/qr-code${query ? `?${objToQuery(query)}` : ''}`,
  );
}

export async function patchQrCode(payload: CreateQRCode) {
  return await api.patch<CreateQRCodeResponse>('/operation/qr-code/gen', payload);
}

export async function getDownloadQRCode(qrCodeId: string | number) {
  return await api.get<Blob>(`/operation/qr-code/${qrCodeId}/download`, {
    responseType: 'blob',
  });
}

export const getVerifyQRCodeDigitalPhoto = async (qrCode: string) =>
  api.get(`/operation/qr-code/${qrCode}/photo/check`);

export const getQRCodeDigitalPhotos = (qrCode: string) =>
  api.get<GetQRCodeDigitalPhotos[]>(`/operation/qr-code/${qrCode}/photo/digital`);

export const getPhotoToDownload = async (qrCode: string, photoId: string | number) =>
  api.get<Blob>(`/operation/qr-code/${qrCode}/photo/${photoId}`, { responseType: 'blob' });

export const getAllPhotosToDownload = async (qrCode: string) =>
  api.get<Blob>(`/operation/qr-code/${qrCode}/photo/all`, { responseType: 'blob' });

import { ComponentProps } from 'react';

import styled, { css } from 'styled-components';
import { PageContainer } from 'styles/PageContainer';

type PageContainerProps = ComponentProps<typeof PageContainer>;

export const Container = styled(PageContainer).attrs<PageContainerProps>(
  ({ md = true, ...props }) => ({ md, ...props }),
)`
  gap: 1.18rem;
`;

export const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const SectionTitle = styled.h3`
  font-size: 1.13rem;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.foreground};
  border-radius: ${props => props.theme.borderRadius.md};
`;

export const Card = styled.div``;

export const Label = styled.strong`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.hightLight};
`;
export const Value = styled.p<{ error?: boolean; bold?: boolean }>`
  font-size: 0.87rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  ${({ error }) =>
    error &&
    css`
      color: ${props => props.theme.colors.danger};
    `};
`;

export const SubValue = styled.span`
  font-size: 0.75rem;
`;

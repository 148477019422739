import { LoadingMessage, Tag } from 'components';
import format from 'date-fns/format';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPdvById as getPdvByIdApi } from 'services/pdv';
import * as Styles from 'styles/Details';
import { Flex } from 'styles/Flex';
import { PDV } from 'types/pdv';

interface InfoProps {
  id: string;
}

function BasePdvInfo({ id }: InfoProps) {
  const { t } = useTranslation('pages/pdv');
  const [pdv, setPdv] = useState<Partial<PDV>>({});

  const [isLoading, toggle] = useBooleanToggle();

  const getPdvById = async (id: string) => {
    try {
      toggle();

      const { data } = await getPdvByIdApi(id);

      setPdv(data);
    } catch (err) {
    } finally {
      toggle();
    }
  };

  useEffect(() => {
    getPdvById(id);
  }, [id]);

  if (isLoading) return <LoadingMessage text="Carregando informações" />;

  return (
    <Styles.Card>
      <Styles.Title>{pdv?.name}</Styles.Title>
      <Flex flex={1} gap={4} justifyContent="space-between" wrap>
        <Flex column gap={1}>
          <Styles.Value>ID {pdv?.custom_id}</Styles.Value>
          <Styles.Value>{pdv?.operation?.name}</Styles.Value>
        </Flex>
        <Flex column gap={1}>
          <Styles.Value>Tipo totem</Styles.Value>
          <Styles.Value>{t(`log.totem.${!!pdv?.is_totem}`)}</Styles.Value>
        </Flex>
        <Flex column gap={1}>
          <Styles.Label>Data de cadastro</Styles.Label>
          <Styles.Value>
            {pdv?.created_at && format(new Date(pdv?.created_at), 'dd/MM/yyyy')}
          </Styles.Value>
        </Flex>
        <Flex column gap={1}>
          <Styles.Label>Status</Styles.Label>
          <Tag
            label={pdv?.status === 0 ? 'Inativo' : 'Ativo'}
            variante={pdv.status === 0 ? 'inactive' : 'success'}
          />
        </Flex>
      </Flex>
    </Styles.Card>
  );
}

export const PdvInfo = memo(BasePdvInfo);

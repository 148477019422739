import styled from 'styled-components';
import { IconButton, ButtonBase } from '@mui/material';

export const Container = styled.div`
  position: relative;
  width: max-content;
`;

export const List = styled.ul`
  box-shadow: 0px 3px 20px #00000029;
  background: ${({ theme }) => theme.colors.foreground};
  min-width: 8rem;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  position: absolute;
  top: 100%;
  right: 100%;
  z-index: 100;
`;

export const Item = styled.li`
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const Toggle = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemPress = styled(ButtonBase)`
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  justify-content: flex-start;
`;

import {
  Autocomplete,
  Button,
  Icon,
  PaginatedTable,
  StatusFilter,
  Tag,
  TextField,
} from 'components';
import { resolvePath, routePaths } from 'constants/routes';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { useTouristOperators } from 'hooks/useTouristOperators';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { getTouristGuideList } from 'services/touristGuide';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { Meta } from 'types/api';
import { GetTouristGuideList, GetTouristGuideListParams } from 'types/touristGuide';
import { debounce } from 'utils';
import { pushHistoryQuery, queryToObj } from 'utils/query';
import { ManageOperatorsModal, Menu } from './components';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export const ListTouristGuidePage = ({ history }: RouteComponentProps) => {
  const [tPage] = useTranslation('pages/touristGuide');
  const [openManageOperatorModal, toggleOpenManageOperatorModal] = useBooleanToggle();

  const [guides, setGuides] = useState<GetTouristGuideList[]>([]);
  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleGetOperators, isLoading: isLoadingOperators, operators } = useTouristOperators();

  const defaultQueries = useMemo(() => queryToObj(history.location.search), []);

  const totalPages = meta?.last_page || 0;

  const getGuides = async (params?: GetTouristGuideListParams) => {
    try {
      setIsLoading(true);
      const { data } = await getTouristGuideList(params);

      setGuides(data.data);
      setMeta(data.meta);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateList = async () => {
    const query = queryToObj(location.search) as GetTouristGuideListParams;

    getGuides(query);
  };

  const columns = [
    {
      field: 'fullName',
      headerName: tPage('list.name'),
    },
    {
      field: 'operatorName',
      headerName: tPage('list.operatorName'),
    },
    {
      field: 'email',
      headerName: tPage('list.email'),
    },
    {
      field: 'status',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const rows = useMemo(
    () =>
      guides.map(guide => ({
        ...guide,
        operatorName: guide.operator.name,
        status: (
          <Tag
            label={guide?.status === 0 ? 'Inativo' : 'Ativo'}
            variante={guide?.status === 0 ? 'inactive' : 'success'}
          />
        ),
        menu: <Menu touristGuide={guide} onChangeStatus={handleUpdateList} />,
      })),
    [guides],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as GetTouristGuideListParams;

      getGuides(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    const query = queryToObj(location.search) as GetTouristGuideListParams;

    getGuides(query);
  }, []);

  return (
    <PageContainer>
      <ManageOperatorsModal
        open={openManageOperatorModal}
        onClose={toggleOpenManageOperatorModal}
      />

      <Flex column>
        <Flex>
          <h1>{tPage('title')}</h1>
        </Flex>

        <Flex marginVertical={0.5} justifyContent="space-between" alignItems="flex-start" gap={1}>
          <Flex maxWidth={50} flex={1} gap={1}>
            <Flex flex={2}>
              <TextField
                fullWidth
                clearHelperTextSpacing
                label="pages/touristGuide:list.filters.name"
                contained
                defaultValue={defaultQueries?.search}
                onChange={debounce((e: ChangeEvent<HTMLInputElement>) =>
                  pushHistoryQuery({ search: e.target.value }, history, { removedProps: ['page'] }),
                )}
              />
            </Flex>

            <Flex flex={2}>
              <Autocomplete
                options={operators}
                fullWidth
                optionLabel="name"
                optionValue="id"
                label="pages/touristGuide:list.filters.operator"
                contained
                loading={isLoadingOperators}
                filterOptions={x => x}
                defaultValue={
                  defaultQueries
                    ? {
                        id: defaultQueries?.operatorId,
                        name: defaultQueries?.operatorName,
                      }
                    : undefined
                }
                onInputChange={debounce((e: ChangeEvent, value: string) => {
                  handleGetOperators({ search: value });
                })}
                onChange={(e, value) =>
                  pushHistoryQuery(
                    { operatorId: value?.id, operatorName: value?.id ? value?.name : '' },
                    history,
                    {
                      removedProps: ['page'],
                    },
                  )
                }
              />
            </Flex>

            <Flex flex={1}>
              <StatusFilter
                sendBooleanToQuery
                removePageOnFilter
                fullWidth
                defaultValue={defaultQueries?.status}
              />
            </Flex>
          </Flex>

          <Flex gap={1}>
            <Button variant="primaryTransparent" outline onClick={toggleOpenManageOperatorModal}>
              {tPage('manageOperators')}
            </Button>

            <Button onClick={() => history.push(routePaths.touristGuide.create)}>
              <Icon name="add" size={1.3} />

              {tPage('createTourist')}
            </Button>
          </Flex>
        </Flex>

        <Flex>
          <PaginatedTable
            count={totalPages}
            defaultPage={(queryToObj(history.location.search)?.page as string) || '1'}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={guide => {
              console.log('LINE');
              history.push(resolvePath(routePaths.touristGuide.details, { id: guide.id }));
            }}
          />
        </Flex>
      </Flex>
    </PageContainer>
  );
};

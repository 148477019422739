import { Login, PostLogin, PostRecoverPasswordEmail, ResetPassword } from 'types/auth';
import api from '../services/apis/api';

export const resetPassword = (data: ResetPassword) => api.post('auth/reset-password', data);

export const postLogin = (data: Login) => api.post<PostLogin>('/auth/login', data);

export const endpointLogout = '/auth/logout';

export const apiLogout = () => api.delete(endpointLogout);

export const postRecoverPasswordEmail = (data: PostRecoverPasswordEmail) =>
  api.post('/auth/reset-password/email', data);

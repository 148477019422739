import api from './apis/api';

import {
  CreateProduct,
  GetProduct,
  GetProductAllList,
  GetProductAllListQuery,
  GetProductByIdResponse,
  GetProductCategory,
  LogProductResponse,
  LogQueries,
  ProductById,
} from 'types/product';

import { IImage } from 'components';

import { ApiQueriesProduct } from 'types/api';

import { DimensionsPrinter } from 'types/dimensionsPrinter';
import { objToQuery, resolveQueryStart } from 'utils/query';

export async function postProduct(data: Partial<CreateProduct>) {
  return await api.post<ProductById>('/product', data);
}

export async function getProducts(query?: ApiQueriesProduct) {
  return await api.get<GetProduct>(`/product${query ? `?${objToQuery(query)}` : ''}`);
}

export async function getProductById(id: string | number) {
  return await api.get<GetProductByIdResponse>(`/product/${id}`);
}

export async function putProduct(id: number | string, data: Partial<CreateProduct>) {
  return await api.put<ProductById>(`product/${id}`, data);
}

export const patchStatus = async (id: number | string) =>
  await api.patch(`product/${id}/updateStatus`);

export const deleteProduct = async (id: number | string) => await api.delete(`/product/${id}`);

export async function putImage(productId: string | number, file: FormData) {
  return await api.put(`product/${productId}/image`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function handleUploadImage(id: string | number, files: Omit<IImage, 'name'>[]) {
  for (const file of files) {
    if (!file.isFromServer && file.file) {
      const formData = new FormData();
      formData.append('image', file.file);
      await putImage(id, formData);
    }
  }
}

export const getProductList = (params?: string) =>
  api.get<GetProductAllList[]>(`/product/all${resolveQueryStart(params)}`);

export async function getLogProduct(id: string | number, query?: LogQueries) {
  return await api.get<LogProductResponse>(
    `/log/product/${id}${query ? `?${objToQuery(query)}` : ''}`,
  );
}

export async function getAllProducts(query?: GetProductAllListQuery) {
  return api.get<GetProductAllList[]>(`/product/all${query ? `?${objToQuery(query)}` : ''}`);
}

export async function getProductCategory() {
  return await api.get<GetProductCategory[]>('/product/category');
}

export const getDimensionsPrinter = () =>
  api.get<DimensionsPrinter[]>('/product/dimensions-printer');

import { Icon, MaskInput, TextField } from 'components';
import * as TableStyles from 'components/PaginatedTable/Table/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { putTouristOperator } from 'services/touristGuide';
import { GetTourisOperator } from 'types/touristGuide';
import { currencyToFloat } from 'utils/help';
import { openNotification } from 'utils/notification';

interface Props {
  operator: GetTourisOperator;
}

export const TableRow = ({ operator }: Props) => {
  const [tPage] = useTranslation('pages/touristGuide');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [name, setName] = useState<string>(operator.name);
  const [commission, setCommission] = useState<number>(operator.commissionPercentage);

  const handleEdit = async () => {
    try {
      const payload = {
        name,
        commissionPercentage: commission,
      };

      const response = await putTouristOperator(operator.id, payload);

      if (response.status === 200) {
        openNotification(tPage('editOperator.successMessage'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableStyles.Td colSpan={1}>
        <TextField
          size="small"
          defaultValue={operator.name}
          clearHelperTextSpacing
          disabled={!isEdit}
          onChange={e => setName(e.target.value)}
        />
      </TableStyles.Td>

      <TableStyles.Td>
        <MaskInput
          size="small"
          mask="decimalPercent"
          defaultValue={operator.commissionPercentage}
          clearHelperTextSpacing
          disabled={!isEdit}
          onChange={e => setCommission(currencyToFloat(e.target.value))}
        />
      </TableStyles.Td>

      <TableStyles.Td>
        {isEdit ? (
          <Icon
            name="done"
            clickable
            color="valid"
            onClick={() => {
              handleEdit();
              setIsEdit(false);
            }}
          />
        ) : (
          <Icon name="edit" clickable color="text500" onClick={() => setIsEdit(true)} />
        )}
      </TableStyles.Td>
    </>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1.5rem 2rem 1.5rem;
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.foreground};
`;

export const OperationName = styled.strong`
  font-size: 1rem;
`;

import { Locale } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';

export const supportedLanguages = {
  ptBr: 'pt-BR',
};

export const locales: {
  [key: string]: Locale;
} = {
  'pt-BR': ptBrLocale,
};

// The first index is default name space
export const namespaces = [
  'commons',
  'labels',
  'formErrors',
  'apiErrorMessages',
  'routes',
  'pages/home',
  'pages/operations',
  'pages/promotion',
  'pages/listing',
  'pages/pdv',
  'pages/qrCode',
  'pages/create',
  'pages/products',
  'pages/priceList',
  'pages/pax',
  'pages/user',
  'pages/recoveryPassword',
  'pages/login',
  'pages/inventory',
  'pages/goal',
  'pages/cashDesk',
  'pages/acquire',
];

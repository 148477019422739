import React, { useState, useEffect, useCallback } from 'react';
import { ReactComponent as Logo } from '../../../assets/vyoo-logo-novo.svg';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'constants/routes';
import ReactGA from 'react-ga4';
import * as Styles from './styles';

export const PrimaryClient: React.FC = ({ children }) => {
  const history = useHistory();
  const [showFaq, setShowFac] = useState(false);
  const redirectHome = useCallback(() => history.push(routePaths.home), []);

  ReactGA.initialize('G-E2W8W5XZE6');

  useEffect(() => {
    if (React.isValidElement(children)) {
      const childElement = children as React.ReactElement<any>;

      if (childElement.props.location?.pathname === '/') {
        setShowFac(true);
      } else {
        setShowFac(false);
      }
    }
  }, [children]);

  const handleFaqButtonClick = () => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Botão FAQ Click',
    });
    history.push(routePaths.client.faq);
  };

  return (
    <Styles.Container>
      <Styles.Main>{children}</Styles.Main>
      <Styles.Navbar>
        <Logo onClick={redirectHome} cursor={'pointer'} />
        {showFaq === true && (
          <Styles.divBotao>
            <Styles.botaoFaq onClick={handleFaqButtonClick}>FAQ</Styles.botaoFaq>
          </Styles.divBotao>
        )}
      </Styles.Navbar>
    </Styles.Container>
  );
};

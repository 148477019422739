import api from './apis/api';

import { ApiQueries } from 'types/api';
import { PdvResponse, PDV, CreatePDV, LogPDVResponse, LogPDVQueries } from 'types/pdv';

import { objToQuery } from 'utils/query';

export const getPdvs = async (query?: ApiQueries) =>
  await api.get<PdvResponse>(`pdv${query ? `?${objToQuery(query)}` : ''}`);

export const getPdvById = async (id: number | string) => await api.get<PDV>(`pdv/${id}`);

export const deletePdv = async (id: number | string) => await api.delete(`pdv/${id}`);

export const postPDV = (data: CreatePDV) => api.post('/pdv', data);

export const patchStatus = async (id: string | number) => await api.patch(`pdv/${id}/updateStatus`);

export const putPDV = (idPdv: number | string, data: CreatePDV) => api.put(`/pdv/${idPdv}`, data);

export async function getLogPdv(id: string | number, query?: LogPDVQueries) {
  return await api.get<LogPDVResponse>(`/log/pdv/${id}${query ? `?${objToQuery(query)}` : ''}`);
}

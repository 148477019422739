import { ComponentProps, memo } from 'react';

import { Accordion as RootAccordion } from 'components/Accordion';

import * as Styles from './styles';
import { Flex } from 'styles/Flex';
import { Table } from 'components';

type TableProps = Pick<ComponentProps<typeof Table>, 'columns' | 'rows'>;

interface AccordionProps extends TableProps {
  title: string;
  value: number | string;
}

function BaseAccordion({ title, value, columns, rows }: AccordionProps) {
  return (
    <Styles.Container>
      <RootAccordion.Container>
        <RootAccordion.Header>
          <Styles.Header>
            <Flex gap={0.5} alignItems="center" flex={1}>
              <Styles.Title>{title}</Styles.Title>
            </Flex>
            <Styles.Title>{value}</Styles.Title>
          </Styles.Header>
        </RootAccordion.Header>
        <RootAccordion.Body>
          <Styles.Body>
            <Table tableVariant="secondary" columns={columns} rows={rows} />
          </Styles.Body>
        </RootAccordion.Body>
      </RootAccordion.Container>
    </Styles.Container>
  );
}

export const Accordion = memo(BaseAccordion);

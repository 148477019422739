import { Grid } from '@mui/material';
import { UnAutocomplete, UnInputFIeld, UnMaskInput } from 'components';
import { useTouristOperators } from 'hooks/useTouristOperators';
import { ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Legend } from 'styles/Legend';
import { debounce } from 'utils';

const GuideDataPartBase = (): JSX.Element => {
  const [tPage] = useTranslation('pages/touristGuide');
  const { handleGetOperators, isLoading: isLoadingOperators, operators } = useTouristOperators();

  return (
    <>
      <Grid item xs={12}>
        <Legend>{tPage('createGuide.guideData')}</Legend>
      </Grid>

      <Grid item xs={12} sm={12} md={8}>
        <UnInputFIeld name="fullName" label="pages/touristGuide:createGuide.form.name" />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <UnMaskInput name="cpf" mask="cpf" label="pages/touristGuide:createGuide.form.cpf" />
      </Grid>

      <Grid item xs={12} md={4}>
        <UnInputFIeld name="email" label="pages/touristGuide:createGuide.form.email" />
      </Grid>

      <Grid item xs={12} md={4}>
        <UnMaskInput
          name="phone"
          mask="cellphone"
          label="pages/touristGuide:createGuide.form.phone"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <UnAutocomplete
          options={operators}
          optionLabel="name"
          optionValue="id"
          name="operatorId"
          label="pages/touristGuide:createGuide.form.operator"
          loading={isLoadingOperators}
          filterOptions={x => x}
          onInputChange={debounce((e: ChangeEvent, value: string) => {
            handleGetOperators({ search: value });
          })}
        />
      </Grid>
    </>
  );
};

export const GuideDataPart = memo(GuideDataPartBase);

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Table } from 'components';

import { Goal } from 'types/goal';

import { AWARD_TYPE } from 'constants/goal';
import { removeDuplicateObjectFroArray } from 'utils/help';

interface BaseProps {
  operations: (Goal & {
    operation_name: string;
  })[];
  type?: string;
}

function BaseCapitaBilling({ type, operations }: BaseProps) {
  const [tPage] = useTranslation('pages/goal');
  const { t } = useTranslation();

  const columns: Column[] = [
    {
      field: 'minimal',
      headerName: tPage('detail.table.minimal'),
    },
    {
      field: 'minimal_award',
      headerName: tPage('detail.table.award'),
    },
    {
      field: 'ideal',
      headerName: tPage('detail.table.ideal'),
    },
    {
      field: 'ideal_award',
      headerName: tPage('detail.table.award'),
    },
    {
      field: 'super',
      headerName: tPage('detail.table.super'),
    },
    {
      field: 'super_award',
      headerName: tPage('detail.table.award'),
    },
  ];

  const _operations = removeDuplicateObjectFroArray(operations, 'operation_id', 'operation_id');

  const renderOperations = _operations?.map(value => ({
    name: value.operation_name,
    minimal:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.minimal.target} %`
        : t('currency', { value: value.minimal.target }),
    minimal_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.minimal.award} %`
        : t('currency', { value: value.minimal.award }),
    ideal:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.ideal.target} %`
        : t('currency', { value: value.ideal.target }),
    ideal_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.ideal.target} %`
        : t('currency', { value: value.ideal.award }),
    super:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.super.target} %`
        : t('currency', { value: value.super.target }),
    super_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.super.target} %`
        : t('currency', { value: value.super.award }),
  }));
  return (
    <Table
      tableVariant="secondary"
      columns={[
        {
          field: 'name',
          headerName: tPage('detail.table.operation'),
        },
        ...columns,
      ]}
      rows={renderOperations}
    />
  );
}

export const CapitaBilling = memo(BaseCapitaBilling);

import { createNumberMask } from 'text-mask-addons';

export const masks = {
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  cellphone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  cnpj: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  freeNumber: createNumberMask({
    includeThousandsSeparator: false,
    prefix: '',
  }),
  number: createNumberMask({
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    prefix: '',
  }),
  percent: createNumberMask({
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    suffix: ' %',
    prefix: '',
  }),
  decimalPercent: createNumberMask({
    includeThousandsSeparator: true,
    allowDecimal: true,
    allowLeadingZeroes: true,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    suffix: ' %',
    prefix: '',
  }),
  currency: createNumberMask({
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    prefix: 'R$ ',
    allowLeadingZeroes: false,
  }),
  decimal: createNumberMask({
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    prefix: '',
    allowLeadingZeroes: false,
  }),
};

import { useTranslation } from 'react-i18next';

import { TabOption, Tabs } from 'components';

import { GOAL_OPERATION_TYPE } from 'constants/goal';

import { useGoalContext } from '../GoalProvider';

import * as DefaultStyles from '../../styles';
import { memo } from 'react';
import { DefaultGoal, ProductsGoal } from './components';

export const masks = {
  percent: 'percent',
  number: 'number',
  currency: 'currency',
};

export type Masks = keyof typeof masks;

interface MonetizeProps {
  onBackward: () => void;
}

function BaseMonetize({ onBackward }: MonetizeProps) {
  const [tPage] = useTranslation('pages/goal');

  const context = useGoalContext();

  const tabs: TabOption[] = [
    {
      label: tPage('create.goals.revenues'),
      hidden: !context?.data?.base?.isBilling,
      value: 0,
    },
    {
      label: tPage('create.goals.revenuesPerCapita'),
      hidden:
        context?.data?.base?.type === GOAL_OPERATION_TYPE.PROFESSIONAL ||
        !context?.data?.base?.isCapitaBilling,
      value: 1,
    },
    {
      label: tPage('create.goals.productQuantity'),
      hidden: !context?.data?.base?.isProductsGoal,
      value: 2,
    },
    {
      label: tPage('create.goals.captureSale'),
      hidden: !context?.data?.base?.isCaptureSale,
      value: 3,
    },
  ];

  const enableTabs = tabs.filter(tab => !tab.hidden);

  const lastTab = enableTabs[enableTabs.length - 1];

  return (
    <DefaultStyles.Container>
      <Tabs tabs={tabs}>
        <DefaultGoal
          mask="currency"
          initialData={context?.data?.billing}
          type="billing"
          isTheLast={lastTab.value === 0}
          onBackward={onBackward}
        />
        <DefaultGoal
          mask="currency"
          initialData={context?.data?.capita_billing}
          type="capita_billing"
          isTheLast={lastTab.value === 1}
          onBackward={onBackward}
        />
        <ProductsGoal
          mask="number"
          initialData={context?.data?.products_goal}
          isTheLast={lastTab.value === 2}
          onBackward={onBackward}
        />
        <DefaultGoal
          mask="percent"
          initialData={context?.data?.capture_sale}
          type="capture_sale"
          isTheLast={lastTab.value === 3}
          onBackward={onBackward}
        />
      </Tabs>
    </DefaultStyles.Container>
  );
}

export const Monetize = memo(BaseMonetize);

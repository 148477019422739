import { useTranslation } from 'react-i18next';
import { memo } from 'react';

import { Column, Table, UnMaskInput } from 'components';
import { CorrectMaskInput } from '../CorrectMaskInput';

import { TABLE_FIELDS } from 'constants/goal';

import * as DefaultStyles from '../../styles';

import { GetUserData } from 'types/user';

import { Masks, masks } from '../Monetize';

interface RenderUsersProps {
  data?: Pick<GetUserData, 'fullName' | 'id'>[];
  mask?: Masks;
  opeartionName?: string;
  type?: string;
}

function BaseRenderUsers({ data = [], type, opeartionName, mask = 'currency' }: RenderUsersProps) {
  const [tPage] = useTranslation('pages/goal');
  const [t] = useTranslation();

  const maskPlaceholders = {
    [masks.percent]: '0 %',
    [masks.number]: '0',
    [masks.currency]: t('currency', { value: 0 }),
  };

  const columns: Column[] = [
    {
      headerName: tPage('create.table.users'),
      field: TABLE_FIELDS.user,
      _style: {
        width: '100%',
      },
    },
    {
      headerName: tPage('create.table.minimumGoal'),
      field: TABLE_FIELDS.minimal,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.minimal_award,
    },
    {
      headerName: tPage('create.table.idealGoal'),
      field: TABLE_FIELDS.ideal,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.ideal_award,
    },
    {
      headerName: tPage('create.table.superGoal'),
      field: TABLE_FIELDS.super,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.super_award,
    },
  ];

  const rows =
    data?.map((user, index) => ({
      [TABLE_FIELDS.user]: user.fullName,
      [TABLE_FIELDS.minimal]: (
        <DefaultStyles.InputField size={8}>
          <UnMaskInput
            name={`users[${index}].minimal.target`}
            mask={mask}
            placeholderAlternative={maskPlaceholders[mask]}
            cleanValue
            parseToFloat
          />
        </DefaultStyles.InputField>
      ),
      [TABLE_FIELDS.minimal_award]: (
        <DefaultStyles.InputField size={5}>
          <CorrectMaskInput name={`users[${index}].minimal.award`} type={type} />
        </DefaultStyles.InputField>
      ),
      [TABLE_FIELDS.ideal]: (
        <DefaultStyles.InputField size={8}>
          <UnMaskInput
            name={`users[${index}].ideal.target`}
            mask={mask}
            placeholderAlternative={maskPlaceholders[mask]}
            cleanValue
            parseToFloat
          />
        </DefaultStyles.InputField>
      ),
      [TABLE_FIELDS.ideal_award]: (
        <DefaultStyles.InputField size={5}>
          <CorrectMaskInput name={`users[${index}].ideal.award`} type={type} />
        </DefaultStyles.InputField>
      ),
      [TABLE_FIELDS.super]: (
        <DefaultStyles.InputField size={8}>
          <UnMaskInput
            name={`users[${index}].super.target`}
            mask={mask}
            placeholderAlternative={maskPlaceholders[mask]}
            cleanValue
            parseToFloat
          />
        </DefaultStyles.InputField>
      ),
      [TABLE_FIELDS.super_award]: (
        <DefaultStyles.InputField size={5}>
          <CorrectMaskInput name={`users[${index}].super.award`} type={type} />
        </DefaultStyles.InputField>
      ),
    })) || [];

  if (data.length === 0) return null;

  return (
    <>
      <DefaultStyles.Title>{opeartionName}</DefaultStyles.Title>
      <Table tableVariant="secondary" columns={columns} rows={rows} />
    </>
  );
}

export const RenderUsers = memo(BaseRenderUsers);

import { useCallback } from 'react';
import { getPax, getPaxHistoric, postPax } from 'services/pax';
import { useStore } from 'store';
import { CreatePax } from 'types/pax';

interface LoadPaxListParams {
  query?: string;
}

interface LoadHistoricParams {
  query?: string;
  onlyUpdate?: boolean;
}

export const usePax = () => {
  const { state, actions } = useStore(useCallback(state => state.pax, []));

  const handleLoadPaxList = useCallback(async ({ query }: LoadPaxListParams = {}) => {
    actions.setLoading('getPaxList', true);

    try {
      const response = await getPax(query);

      actions.setPaxList(response.data);
    } catch (error) {
    } finally {
      actions.setLoading('getPaxList', false);
    }
  }, []);

  const saveFilter = useCallback((filters: Record<string, any>) => {
    actions.setFilter(filters);
  }, []);

  const throwPax = useCallback(async (data: CreatePax[]) => {
    actions.setLoading('throw', true);
    try {
      await postPax(data);

      handleLoadPaxList();

      return true;
    } catch (error) {
      return false;
    } finally {
      actions.setLoading('throw', false);
    }
  }, []);

  const handleLoadHistoric = useCallback(
    async ({ query, onlyUpdate }: LoadHistoricParams = {}) => {
      actions.setLoading('getHistoricList', true);

      actions.setQuery(query || '');

      try {
        const response = await getPaxHistoric(onlyUpdate ? state.historicPage.query : query);

        actions.setHistoricList(response.data.data);
        actions.setHistoricMeta(response.data.meta);
      } catch (error) {
      } finally {
        actions.setLoading('getHistoricList', false);
      }
    },
    [state.historicPage.query],
  );

  return {
    ...state,
    loadPaxList: handleLoadPaxList,
    loadHistoricList: handleLoadHistoric,
    setDisableFilter: actions.setDisableFilter,
    saveFilter,
    throwPax,
  };
};

import { ButtonHTMLAttributes, memo, ComponentProps } from 'react';

import { Icon } from 'components';

import * as Styles from './styles';

type IconProps = ComponentProps<typeof Icon>;

type RootButtonAttrs = Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick' | 'disabled' | 'type'
>;

interface IconButtonProps extends RootButtonAttrs {
  icon: IconProps;
}

function BaseIconButton({ icon, ...props }: IconButtonProps) {
  return (
    <Styles.Button {...props}>
      <Icon {...icon} />
    </Styles.Button>
  );
}

export const IconButton = memo(BaseIconButton);

import * as yup from 'yup';

import { dateValidation } from './date';
import { DISCOUNT_TYPE } from 'constants/promotion';

import { minOneOperation, requiredField, minOneProduct } from './utils/messages';

const dateSchema = dateValidation('initial_validity', 'final_validity', true);

export const createPromotionValidateSchema = yup.object().shape({
  name: yup.string().required(requiredField),
  ...dateSchema,
  discountType: yup.string().required(requiredField),
  fixedValue: yup.string().when('discountType', {
    is: DISCOUNT_TYPE.FIXED_VALUE,
    then: schema => schema.required(requiredField),
  }),
  percentage: yup.string().when('discountType', {
    is: DISCOUNT_TYPE.PERCENTAGE,
    then: schema => schema.required(requiredField),
  }),
  amount: yup.string().when('discountType', {
    is: DISCOUNT_TYPE.PRODUCT_REQUIRED,
    then: schema => schema.min(1, minOneProduct),
  }),
  isMinimumValueGenerate: yup.boolean(),
  isRequiredProductGenerate: yup.boolean(),
  minimumValueGenerate: yup.string().when('isMinimumValueGenerate', {
    is: true,
    then: schema => schema.required(requiredField),
  }),
  productsGenerate: yup.array().when('isRequiredProductGenerate', {
    is: true,
    then: schema => schema.min(1, minOneProduct),
  }),
  operationGenerate: yup.array().min(1, minOneOperation),
  validDays: yup.string().required(requiredField),
  operationsUse: yup.array().min(1, minOneOperation),
  isMinimumValueUse: yup.boolean(),
  minimumValueUse: yup.string().when('isMinimumValueUse', {
    is: true,
    then: schema => schema.required(requiredField),
  }),
  productsUse: yup.array().when('minimumValueUse', {
    is: null || '',
    then: schema => schema.min(1, minOneProduct),
  }),
});

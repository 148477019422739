import { memo } from 'react';

import { RootModal, IconButton } from 'components';

import useBooleanToggle from 'hooks/useBooleanToggle';

import * as Styles from './styles';
import { Flex } from 'styles/Flex';

interface Data {
  name: string;
}

interface ModalInfoProps {
  data: Data[];
  label: string | number;
  title: string;
}

function BaseModalInfo({ label, data, title }: ModalInfoProps) {
  const [isOpen, toggoIsOpen] = useBooleanToggle(false);

  const handleClick = () => {
    toggoIsOpen();
  };

  const renderData = data.map((value, index) => <li key={index}>{value.name}</li>);

  return (
    <div onClick={event => event.stopPropagation()}>
      {renderData.length > 0 && (
        <RootModal onClose={handleClick} open={isOpen}>
          <Styles.ModalContainer>
            <Flex column gap={1.5}>
              <Flex justifyContent="space-between">
                <Styles.Title>{title}</Styles.Title>
                <IconButton
                  onClick={handleClick}
                  icon={{
                    name: 'close',
                  }}
                />
              </Flex>
              <Styles.List>{renderData}</Styles.List>
            </Flex>
          </Styles.ModalContainer>
        </RootModal>
      )}
      <div onClick={handleClick}>
        <span>{label}</span>
      </div>
    </div>
  );
}

export const ModalInfo = memo(BaseModalInfo);

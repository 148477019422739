import { FormHandles, FormProps, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import {
  ComponentProps,
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { AnyObjectSchema, ValidationError } from 'yup';

interface IProps extends Omit<ComponentProps<typeof Form>, 'onSubmit' | 'children' | 'ref'> {
  parseDataAtSubmit?: FormProps['onSubmit'];
  validationSchema?: AnyObjectSchema;
  schemaParams?: any;
  onSubmit?: FormProps['onSubmit'];
  children: ReactNode;
}
// eslint-disable-next-line react/display-name
const UnformComponent = forwardRef<FormHandles, IProps>(
  ({ parseDataAtSubmit, onSubmit = () => {}, validationSchema, schemaParams, ...props }, ref) => {
    const formRef = useRef<FormHandles>(null);

    useImperativeHandle(ref, () => formRef.current as FormHandles, [formRef]);

    const handleSubmit = useCallback<SubmitHandler>(
      async (data, ...restParams) => {
        try {
          let validateData = data;
          if (parseDataAtSubmit) {
            const parsedData = parseDataAtSubmit(data, ...restParams);
            if (parsedData !== undefined) {
              validateData = parsedData;
            }
          }

          if (validationSchema) {
            await validationSchema.validate(validateData, {
              abortEarly: false,
              context: {
                formData: validateData,
                schemaParams,
              },
            });
          }

          formRef.current?.setErrors({});

          await onSubmit?.(validateData, ...restParams);
        } catch (err: any) {
          if (err?.data?.errors) {
            formRef.current?.setErrors(err.data.errors);
          }

          if (err instanceof ValidationError) {
            const errorMessages: { [key: string]: string } = {};

            err.inner.forEach(error => {
              errorMessages[error.path || ''] = error.message;
            });

            formRef.current?.setErrors(errorMessages);
          } else {
            console.error(err);
          }
        }
      },
      [validationSchema, onSubmit, props, formRef],
    );

    return (
      <Form
        style={{ width: '100%' }}
        onSubmit={handleSubmit}
        ref={formRef}
        autoComplete="off"
        {...props}
      >
        {props.children}
      </Form>
    );
  },
);

export const Unform = memo(UnformComponent);

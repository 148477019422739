import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const H1 = styled.h1`
  margin-top: 5px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
`;

export const FaqPage = styled.div`
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  font-family: 'Montserrat', sans-serif;
  height: 65vh;
  padding: 2rem;
  width: 80vw;
  z-index: 2;
`;

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat', sans-serif;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const FaqPageContent = styled.div`
  background-color: rgba(255, 255, 255, 1);
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
  height: 48vh;
  width: 100%;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 0;
    font-family: 'Montserrat', sans-serif;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    font-family: 'Montserrat', sans-serif;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    font-family: 'Montserrat', sans-serif;
  }

  .MuiAccordion-root {
    &:focus {
      border-left: 4px solid #0b91f5;
      background-color: rgba(255, 255, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-family: 'Montserrat', sans-serif;
    }
  }

  .MuiAccordion-root:last-child {
    box-shadow: none;
    font-family: 'Montserrat', sans-serif;
  }

  .MuiAccordionSummary-root:hover {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    ::before {
      content: '';
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background-color: #0b91f5;
    }
  }

  .MuiAccordionSummary-root:focus {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    ::before {
      content: '';
      position: absolute;
      font-family: 'Montserrat', sans-serif;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background-color: #0b91f5;
    }

    a {
      color: #0b91f5;
      text-decoration: underline;
      cursor: pointer;
      margin-right: 5px;
      font-weight: bold;
    }

    a:hover {
      color: #004080;
    }

    a:visited {
      color: #6c757d;
    }
  }
`;

export const sliderStyles = styled.div`
  #slider {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }

  #slider a {
    cursor: pointer;
    position: absolute;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
  }

  #slider a:not(.selected) {
    font-family: 'Montserrat', sans-serif;
  }

  .slide {
    position: absolute;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
  }

  #slider img:not(.magicPass) {
    height: 100%;
    object-fit: cover;
    font-family: 'Montserrat', sans-serif;
    opacity: 0;
    position: absolute;
    transition: opacity 3500ms;
    transform-origin: 50%, 50%;
    width: 100%;
  }

  #slider img.selected {
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const divBotao = styled.div`
  .btn-sac span {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const divBotaoVoltar = styled.div`
  bottom: 6px;
  left: 6px;
  font-family: 'Montserrat', sans-serif;
  position: fixed;

  .btn-back {
    text-transform: lowercase;
    font-family: 'Montserrat', sans-serif;
  }

  .btn-back span::first-letter {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const closeButtonDiv = styled.div`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
`;

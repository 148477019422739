import { Route } from 'react-router-dom';
import { Secondary } from 'layouts/Secondary';
import { secondaryLayoutRoutes } from 'routes/routes';
import React from 'react';

export const SecondaryLayoutRoutes = () => {
  const renderRoutes = secondaryLayoutRoutes.map(({ component: Component, ...route }, index) => (
    <Route
      key={`route-${index}`}
      render={props => (
        <Secondary>
          <Component {...props} />
        </Secondary>
      )}
      {...route}
    />
  ));

  return renderRoutes;
};

import { Tabs } from 'components';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { ThrowPAX, Historic } from './components';

import * as Styles from './styles';

const PAXComponent = ({ history }: RouteComponentProps): JSX.Element => {
  const [tPage] = useTranslation('pages/pax');

  return (
    <PageContainer>
      <Flex marginBottom={1}>
        <h1>{tPage('title')}</h1>
      </Flex>

      <Styles.Wrapper>
        <Tabs tabs={[{ label: tPage('throwTab') }, { label: tPage('historicTab') }]}>
          <ThrowPAX history={history} />

          <Historic />
        </Tabs>
      </Styles.Wrapper>
    </PageContainer>
  );
};

export const PAX = memo(PAXComponent);

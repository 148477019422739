export const GOAL_OPERATION_TYPE = {
  PROFESSIONAL: 'USERS',
  OPERATION: 'OPERATIONS',
};

export const AWARD_TYPE = {
  VALUE: 'VALUE',
  PERCENTAGE: 'PERCENTAGE',
};

export const SUB_TYPES = {
  isProductsGoal: 'products_goal',
  isCaptureSale: 'capiture_sale',
  isCapitaBilling: 'capita_billing',
  isBilling: 'billing',
};

export const TABLE_FIELDS = {
  product: 'product',
  operation: 'operation',
  minimal: 'minimal',
  minimal_award: 'minimal_award',
  ideal: 'ideal',
  ideal_award: 'ideal_award',
  super: 'super',
  super_award: 'super_award',
  user: 'user',
};

import create, { GetState, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';
import { homeModule } from './modules/home';
import { paxModule } from './modules/pax';
import { getModule } from './utils';

export interface IAplicationState {
  home: ReturnType<typeof homeModule>;
  pax: ReturnType<typeof paxModule>;
}

const combineStore = (
  set: SetState<IAplicationState>,
  get: GetState<IAplicationState>,
): IAplicationState => ({
  home: getModule(homeModule, 'home', set, get),
  pax: getModule(paxModule, 'pax', set, get),
});

export const useStore = create(devtools(combineStore));

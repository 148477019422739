import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Column, Icon } from 'components';

import { Accordion } from '..';

import { resolvePath, routePaths } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { PIX } from 'types/cashDesk';
import * as Styles from '../../styles';

const _columns = {
  created_at: 'created_at',
  id: 'id',
  pdv: 'pdv',
  button: 'button',
  total: 'total',
  reversed: 'reversed',
};

interface PixProps {
  data: PIX[];
  value: number;
  operationId: string;
  disabled: boolean;
}

function BasePix({ data, value, operationId, disabled }: PixProps) {
  const [tPage] = useTranslation('pages/cashDesk');
  const { t } = useTranslation();
  const history = useHistory();

  const columns: Column[] = [
    {
      field: _columns.created_at,
      headerName: tPage('detail.table.created_at'),
      _style: {
        width: '100%',
      },
    },
    {
      field: _columns.id,
      headerName: tPage('detail.table.order_id'),
    },
    {
      field: _columns.pdv,
      headerName: tPage('detail.table.pdv_name'),
    },
    {
      field: _columns.reversed,
      headerName: tPage('detail.table.reversed'),
    },
    {
      field: _columns.total,
      headerName: tPage('detail.table.total'),
    },
    {
      field: _columns.button,
      headerName: '',
    },
  ];

  const rows = data
    .sort((a, b) => (a.isReversed && !b.isReversed ? 1 : -1))
    .map(value => ({
      [_columns.id]: value.order_id,
      [_columns.created_at]: (
        <Styles.TableValue isReversed={value.isReversed}>
          {t('dateHours', { date: new Date(value.created_at) })}
        </Styles.TableValue>
      ),
      [_columns.pdv]: (
        <Styles.TableValue isReversed={value.isReversed}>{value?.pdv?.name}</Styles.TableValue>
      ),
      [_columns.total]: (
        <Styles.TableValue isReversed={value.isReversed} isCurrency>
          {t('currency', { value: value.value })}
        </Styles.TableValue>
      ),
      [_columns.reversed]: !value?.isReversed ? null : <Icon name="done" color="danger" />,
      [_columns.button]: (
        <Button
          onClick={() => {
            history?.push(
              resolvePath(routePaths.cashDesk.edit, { id: value.order_id, operationId }),
            );
          }}
          disabled={disabled || value.isReversed}
          variant="base"
        >
          Editar
        </Button>
      ),
    }));

  const currency = t('currency', { value });

  return (
    <Accordion columns={columns} rows={rows} value={currency} title={tPage('detail.groups.pix')} />
  );
}

export const Pix = memo(BasePix);

import { Grid } from '@mui/material';
import { Checkbox, Column, MaskInput, PaginatedTable, SearchFilter } from 'components';
import { ChangeEvent, FocusEvent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPhysicalPriceList, postPrice } from 'services/priceList';
import { Flex } from 'styles/Flex';
import { Meta } from 'types/api';
import { CreatePrice, GetPhysicalPriceList, GetProductPriceListQueries } from 'types/priceList';
import { debounce } from 'utils';
import { currencyToFloat, floatToCurrency } from 'utils/help';
import { openNotification } from 'utils/notification';

interface PhysicalProps {
  priceListId?: string | number;
}

interface FieldPrice {
  value: string;
  additionalComboPrice?: string;
  disabled?: boolean;
}

function BasePhysical({ priceListId }: PhysicalProps) {
  const { t } = useTranslation('');
  const [tpage] = useTranslation('pages/priceList');

  const [fieldsPrice, setFieldPrice] = useState<FieldPrice[]>([]);

  const [physicalProducts, setPhysicalProducts] = useState<GetPhysicalPriceList[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>();
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState('');

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getProducts = async (_priceListId: string | number, query?: GetProductPriceListQueries) => {
    const { data } = await getPhysicalPriceList(_priceListId, query);

    setPhysicalProducts(data.data);
    setMeta(data.meta);

    setFieldPrice(
      data.data.map(value =>
        value.priceList
          ? {
              disabled: !!value?.priceList?.no_cost,
              value: value?.priceList?.price ? floatToCurrency(value?.priceList?.price) : '',
              additionalComboPrice: value?.priceList?.additional_combo_price
                ? floatToCurrency(value?.priceList?.additional_combo_price)
                : '',
            }
          : {
              disabled: false,
              value: '',
              additionalComboPrice: '',
            },
      ),
    );
  };

  const handleChangePrice = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    isAdditionalCombo?: boolean,
  ) => {
    const { value } = event.target;

    setFieldPrice(prevState =>
      prevState.map((product, _index) =>
        _index === index
          ? { ...product, [isAdditionalCombo ? 'additionalComboPrice' : 'value']: value }
          : product,
      ),
    );
  };

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!priceListId) return;

    const { value } = event.target;

    setSearch(value);
    getProducts(priceListId, {
      page: currentPage,
      search: event.target.value,
    });
  };

  const handleToggleNoCost = async (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    productId: string | number,
  ) => {
    const { checked } = event.target;

    setFieldPrice(prevState =>
      prevState.map((product, _index) =>
        _index === index
          ? {
              value: checked ? t('currency', { value: 0 }) : product.value,
              additionalComboPrice: checked
                ? t('currency', { value: 0 })
                : product.additionalComboPrice,
              disabled: checked,
            }
          : product,
      ),
    );

    const payload: CreatePrice = {
      price: 0,
      additional_combo_price: 0,
      price_list_id: Number(priceListId),
      product_id: Number(productId),
      no_cost: checked,
    };

    await postPrice(payload);
  };

  const handleBlurPrice = async (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    productId: string | number,
    index: number,
    isAdditionalCombo?: boolean,
  ) => {
    const { value } = event.target;
    const parsedPriceStringToNumber = currencyToFloat(value) || 0;

    setFieldPrice(prevState =>
      prevState.map((product, _index) =>
        _index === index
          ? {
              ...product,
              [isAdditionalCombo ? 'additionalComboPrice' : 'value']: parsedPriceStringToNumber
                ? floatToCurrency(parsedPriceStringToNumber)
                : '',
            }
          : product,
      ),
    );

    const comboPrice = isAdditionalCombo
      ? parsedPriceStringToNumber
      : fieldsPrice[index].additionalComboPrice;

    const price = !isAdditionalCombo ? parsedPriceStringToNumber : fieldsPrice[index].value;

    const payload: CreatePrice = {
      price: typeof price === 'number' ? price : currencyToFloat(`${price}` || '0'),
      additional_combo_price:
        typeof comboPrice === 'number' ? comboPrice : currencyToFloat(`${comboPrice}` || '0'),
      price_list_id: Number(priceListId),
      product_id: Number(productId),
    };

    if (!!payload?.additional_combo_price && payload?.additional_combo_price > payload.price) {
      openNotification(t('apiErrorMessages:additionalComboPriceCannotMoreThanPrice'), 'error');

      return;
    }

    const response = await postPrice(payload);

    if (response.data?.message) {
      openNotification(t(`apiErrorMessages:${response.data.message}`), 'warn', undefined, {
        toastId: productId,
      });
    }
  };

  const columns: Column[] = [
    {
      field: 'product',
      headerName: tpage('create.table.product'),
      _style: {
        width: '100%',
      },
    },
    {
      field: 'value',
      headerName: tpage('create.table.value'),
      _style: {
        minWidth: '15rem',
      },
    },
    {
      field: 'additionalComboPrice',
      headerName: tpage('create.table.additionalComboPrice'),
      _style: {
        minWidth: '15rem',
      },
    },
    {
      field: 'noCost',
      headerName: tpage('create.table.noCost'),
    },
  ];

  const _rows = physicalProducts.map((product, index) => ({
    product: product?.name,
    value: (
      <Flex width={10}>
        <MaskInput
          clearHelperTextSpacing
          mask="currency"
          value={fieldsPrice?.[index]?.value}
          disabled={fieldsPrice?.[index]?.disabled}
          placeholderAlternative={t('currency', { value: 0 })}
          onChange={event => handleChangePrice(event, index)}
          onBlur={event => handleBlurPrice(event, product.id, index)}
        />
      </Flex>
    ),
    additionalComboPrice: (
      <Flex width={10}>
        <MaskInput
          clearHelperTextSpacing
          mask="currency"
          value={fieldsPrice?.[index]?.additionalComboPrice}
          disabled={fieldsPrice?.[index]?.disabled}
          placeholderAlternative={t('currency', { value: 0 })}
          onChange={event => handleChangePrice(event, index, true)}
          onBlur={event => handleBlurPrice(event, product.id, index, true)}
        />
      </Flex>
    ),
    noCost: (
      <Checkbox
        value={fieldsPrice?.[index]?.disabled}
        defaultChecked={!!product?.priceList?.no_cost}
        onChange={event => handleToggleNoCost(event, index, product.id)}
      />
    ),
  }));

  useEffect(() => {
    if (!priceListId) return;

    getProducts(priceListId, {
      page: currentPage,
      search,
    });
  }, [currentPage, priceListId]);

  return (
    <Grid container gap="1rem">
      <Grid item xs={12} sm={3}>
        <SearchFilter onChange={debounce(handleSearch)} />
      </Grid>
      <PaginatedTable
        count={totalPages}
        columns={columns}
        rows={_rows}
        tableVariant="secondary"
        onPageChange={(event, _page) => setCurrentPage(_page)}
      />
    </Grid>
  );
}

export const Physical = memo(BasePhysical);

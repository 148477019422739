import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1.5rem 1rem 1.5rem;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.foreground};
  border-radius: ${({ theme }) => theme.borderRadius.md};

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const Topic = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.12rem;
`;

export const FieldLegend = styled.strong`
  font-size: 0.87rem;
`;

export const SubmitContainer = styled.div`
  padding: 3rem 1.5rem;
`;

import { memo, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  LoadingMessage,
  UnDatePicker,
  Unform,
  UnOperation,
  UnOperationArray,
  UnRadioGroup,
  UnUser,
} from 'components';

import { Awards } from './AwardTypes';

import * as Styles from '../../styles';
import { Legend } from 'styles/Legend';
import { Flex } from 'styles/Flex';

import { GOAL_OPERATION_TYPE } from 'constants/goal';
import { routePaths } from 'constants/routes';

import { useGoalContext } from '../GoalProvider';

import { SimpleOperationList } from 'types/operation';
import { openNotification } from 'utils/notification';
import { validateOperationGoal, validateUserGoal } from 'services/goal';
import { createBaseGoalValidationSchema } from 'yupSchemas/goal';
import { GetUserData } from 'types/user';
import useBooleanToggle from 'hooks/useBooleanToggle';

export interface DetailsFormBase {
  type: string;
  billing_award_type?: string;
  capita_billing_award_type?: string;
  capture_sale_award_type?: string;
  products_goal_award_type?: string;
  isBilling?: boolean;
  isCapitaBilling?: boolean;
  isCaptureSale?: boolean;
  isProductsGoal?: boolean;
  operation?: SimpleOperationList;
  operations?: SimpleOperationList[];
  initial_validity: string;
  final_validity: string;
  users?: Pick<GetUserData, 'fullName' | 'id'>[];
}

interface ParametersProps {
  onFoward: () => void;
}

function BaseParameters({ onFoward }: ParametersProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();

  const context = useGoalContext();

  const formRef = useRef<FormHandles>(null);

  const [details, setDetails] = useState<Partial<DetailsFormBase>>({});

  const [initialValidity, setInitialValidity] = useState<string | undefined>(
    context?.data?.base?.initial_validity,
  );

  const [selectedOperation, setSelectedOperation] = useState<
    SimpleOperationList | undefined | null
  >(null);

  const [selectedOperations, setSelectedOperations] = useState<SimpleOperationList[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const [isInitialLoading, toggleInitialLoading] = useBooleanToggle(true);

  const handleSubmit: SubmitHandler<DetailsFormBase> = async payload => {
    context?.saveData('base', payload);

    onFoward();
  };

  const handleValidateUsers = async (user: { id: string | number }) => {
    try {
      if (!initialValidity || !context?.selectedSubTypes) return;

      const initial_validity_parsed = new Date(initialValidity);

      initial_validity_parsed.setHours(0, 0, 0, 0);

      await validateUserGoal({
        goal_id: id,
        user_id: user.id,
        initial_date: initial_validity_parsed.toISOString(),
        subtypes: context?.selectedSubTypes,
      });
    } catch (err: any) {
      for (const error in err.data.errors) {
        openNotification(t(`apiErrorMessages:${error}`), 'error');
      }
      throw err;
    }
  };

  const handleValidateOperations = async (operation: { id: string | number }) => {
    try {
      if (!initialValidity || !context?.selectedSubTypes) return;

      const initial_validity_parsed = new Date(initialValidity);

      initial_validity_parsed.setHours(0, 0, 0, 0);

      await validateOperationGoal({
        goal_id: id,
        operation_id: operation.id,
        initial_date: initial_validity_parsed.toISOString(),
        subtypes: context?.selectedSubTypes,
      });
    } catch (err: any) {
      for (const error in err.data.errors) {
        openNotification(t(`apiErrorMessages:${error}`), 'error');
      }
      throw err;
    }
  };

  const handleRemoveUserFromDeleteList = (option: any) => {
    if (id) return;

    context?.setListUserDeleted?.(prevState =>
      prevState.includes(option.id) ? prevState.filter(value => value !== option.id) : prevState,
    );
  };

  const handleRemoveOperationsFromDeleteList = (option: any) => {
    if (id) return;

    context?.setListOperationDeleted?.(prevState =>
      prevState.includes(option.id) ? prevState.filter(value => value !== option.id) : prevState,
    );
  };

  useEffect(() => {
    if (context?.data?.base) setDetails(context?.data?.base);

    toggleInitialLoading();
  }, []);

  useEffect(() => {
    setSelectedOperation(null);
    setSelectedOperations([]);
    setSelectedUsers([]);
  }, [context?.data?.base?.type]);

  useEffect(() => {
    setSelectedOperation(context?.data?.base?.operation);
  }, [context?.data?.base?.operation]);

  if (isInitialLoading) return <LoadingMessage text="Carregando..." />;

  return (
    <Unform
      onSubmit={handleSubmit}
      ref={formRef}
      validationSchema={createBaseGoalValidationSchema}
      initialData={details}
    >
      <Styles.Container>
        <Flex column gap={2}>
          <Flex column gap={1}>
            <Grid>
              <Legend>Tipo de meta</Legend>
              <UnRadioGroup
                disabled={!!id}
                row
                name="type"
                onChange={event => context?.setType?.(event.target.value)}
                options={[
                  {
                    label: 'Operação',
                    value: GOAL_OPERATION_TYPE.OPERATION,
                  },
                  {
                    label: 'Profissional',
                    value: GOAL_OPERATION_TYPE.PROFESSIONAL,
                  },
                ]}
              />
            </Grid>
          </Flex>
          <Flex column gap={1}>
            <Legend>Período</Legend>
            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={6} md={4}>
                <UnDatePicker
                  disabled={!!id}
                  label="commons:datepicker.initial_validity"
                  name="initial_validity"
                  onChange={date => setInitialValidity(date as any)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <UnDatePicker
                  disabled={!!id}
                  minDate={initialValidity}
                  label="commons:datepicker.final_validity"
                  name="final_validity"
                />
              </Grid>
            </Grid>
          </Flex>
          <Awards
            type={context?.data?.base?.type}
            subTypes={context?.subTypes}
            onChangeSubType={context?.toggleSelectedSubType}
            isBilling={context?.data?.base?.isBilling}
            isCapitaBilling={context?.data?.base?.isCapitaBilling}
            isCaptureSale={context?.data?.base?.isCaptureSale}
            isProductsGoal={context?.data?.base?.isProductsGoal}
          />
        </Flex>
      </Styles.Container>
      <hr />
      <Styles.Container>
        {context?.data?.base?.type === GOAL_OPERATION_TYPE.OPERATION && (
          <UnOperationArray
            disabled={!initialValidity || context?.selectedSubTypes?.length === 0}
            label="pages/goal:create.operationArray"
            name="operations"
            value={selectedOperations}
            onChangeValue={setSelectedOperations}
            onValidate={handleValidateOperations}
            onRemoveRow={option => {
              context?.setListOperationDeleted?.(prevState => [...prevState, option.id]);
            }}
            onSelect={handleRemoveOperationsFromDeleteList}
          />
        )}
        {context?.data?.base?.type === GOAL_OPERATION_TYPE.PROFESSIONAL && (
          <Flex column gap={2}>
            <UnOperation
              value={selectedOperation}
              disabled={!!id}
              label="pages/goal:create.operationArray"
              name="operation"
              returnAllOption
              onSelect={operation => {
                setSelectedOperation(operation);
              }}
            />
            <UnUser
              value={selectedUsers}
              disabled={
                !initialValidity || context?.selectedSubTypes?.length === 0 || !selectedOperation
              }
              onValidate={handleValidateUsers}
              name="users"
              label="pages/goal:create.professionalArray"
              operationId={selectedOperation?.id}
              onChangeValue={setSelectedUsers}
              onRemoveRow={option =>
                context?.setListUserDeleted?.(prevState => [...prevState, option.id])
              }
              onSelect={handleRemoveUserFromDeleteList}
            />
          </Flex>
        )}
      </Styles.Container>
      <hr />
      <Styles.Container>
        <Flex justifyContent="flex-end" gap={1}>
          <Button variant="cancel" onClick={() => history.push(routePaths.goal.list)}>
            Cancelar
          </Button>
          <Button type="submit">Próximo</Button>
        </Flex>
      </Styles.Container>
    </Unform>
  );
}

export const Parameters = memo(BaseParameters);

import { Grid } from '@mui/material';
import { UnCounter, UnDatePicker, UnFieldArray } from 'components';
import { memo, SyntheticEvent, useState } from 'react';
import { getProducts } from 'services/product';
import { debounce } from 'utils/debounce';
import { isOdd } from 'utils/help';
import * as Styles from './styles';

interface Product {
  label: string;
  id: number;
  name: string;
}

function BaseCombo() {
  const [productsDigital, setProductsDigital] = useState<Product[]>([]);
  const [productsPhysical, setProductsPhysical] = useState<Product[]>([]);

  const [initialValidate, setInitialValidate] = useState(null);

  const handleSearchProductsDigital = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    const { data } = await getProducts({ search: value, type: 'DIGITAL' });
    setProductsDigital(
      data.data.map(value => ({
        label: value.name,
        name: value.name,
        id: value.id,
      })),
    );
  };

  const hanldeSearchProductsPhysical = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    const { data } = await getProducts({ search: value, type: 'PHYSICAL' });
    setProductsPhysical(
      data.data.map(value => ({
        label: value.name,
        name: value.name,
        id: value.id,
      })),
    );
  };

  return (
    <Styles.Fieldset>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={6}>
          <UnDatePicker
            label="pages/create:product.filters.initialValidity"
            name="initial_validity"
            onChange={date => setInitialValidate(date as any)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UnDatePicker
            minDate={initialValidate}
            label="pages/create:product.filters.finalValidity"
            name="final_validity"
          />
        </Grid>
      </Grid>
      <legend>Selecionar Produtos</legend>
      <div style={{ width: '100%' }}>
        <UnFieldArray
          label="pages/create:combo.autoCompleteProductPhysical"
          onInputChange={debounce(hanldeSearchProductsPhysical)}
          name="products-physical"
          options={productsPhysical}
          unicKeyFilter="id"
          render={({ remove }, products) => (
            <Styles.List>
              {products.map((product, index) => (
                <Styles.Item isOdd={isOdd(index)} key={index}>
                  <span>{product.id}</span>
                  <span>{product.name}</span>
                  <UnCounter name={`products-physical[${index}].amount`} min={1} defaultValue={1} />
                  <Styles.RemoveButton
                    onClick={() => remove(index)}
                    icon={{
                      name: 'close',
                      size: 0.8,
                      color: 'text100',
                    }}
                  />
                </Styles.Item>
              ))}
            </Styles.List>
          )}
        />
      </div>
      <div style={{ width: '100%' }}>
        <UnFieldArray
          label="pages/create:combo.autoCompleteProductDigital"
          onInputChange={debounce(handleSearchProductsDigital)}
          placeholder="commons:autocomplete.select"
          name="products-digital"
          options={productsDigital}
          unicKeyFilter="id"
          render={({ remove }, products) => (
            <Styles.List>
              {products.map((product, index) => (
                <Styles.Item isOdd={isOdd(index)} key={index}>
                  <span>{product.id}</span>
                  <span>{product.name}</span>
                  <UnCounter name={`products-digital[${index}].amount`} min={1} defaultValue={1} />
                  <Styles.RemoveButton
                    onClick={() => remove(index)}
                    icon={{
                      name: 'close',
                      size: 0.8,
                      color: 'text100',
                    }}
                  />
                </Styles.Item>
              ))}
            </Styles.List>
          )}
        />
      </div>
    </Styles.Fieldset>
  );
}

export const Combo = memo(BaseCombo);

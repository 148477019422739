import { memo, SyntheticEvent } from 'react';

import { useHistory } from 'react-router';

import { Autocomplete } from 'components';

import { pushHistoryQuery } from 'utils/query';
import { useTranslation } from 'react-i18next';
import { PRODUCT_TYPES } from 'constants/product';

interface StatusFilterProps {
  defaultValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

function BaseSelectProductType({ defaultValue, placeholder, ...props }: StatusFilterProps) {
  const [tPage] = useTranslation('pages/listing');

  const history = useHistory();

  const handleSelectProduct = (event: SyntheticEvent, option?: { value: string }) => {
    if (!option) return;

    props?.onChange?.(option.value);

    const query = {
      type: option.value,
    };

    !props?.onChange && pushHistoryQuery(query, history);
  };

  return (
    <Autocomplete
      contained
      onInputChange={(event, value) =>
        value.length === 0 && pushHistoryQuery({ type: '' }, history)
      }
      defaultValue={{ label: defaultValue }}
      placeholder={placeholder}
      options={[
        {
          value: PRODUCT_TYPES.digital,
          label: tPage(`product.types.${PRODUCT_TYPES.digital}`),
        },
        {
          value: PRODUCT_TYPES.physical,
          label: tPage(`product.types.${PRODUCT_TYPES.physical}`),
        },
        {
          value: PRODUCT_TYPES.combo,
          label: tPage(`product.types.${PRODUCT_TYPES.combo}`),
        },
      ]}
      onChange={handleSelectProduct}
    />
  );
}

export const SelectProductType = memo(BaseSelectProductType);

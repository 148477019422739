import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Table } from 'components';

import { Flex } from 'styles/Flex';

import { Goal } from 'types/goal';

import { AWARD_TYPE } from 'constants/goal';
import { removeDuplicateObjectFroArray } from 'utils/help';

interface BaseProps {
  users: (Goal & {
    user_name: string;
  })[];
  operations: (Goal & {
    operation_name: string;
  })[];
  type?: string;
}

function BaseBilling({ users, type, operations }: BaseProps) {
  const [tPage] = useTranslation('pages/goal');
  const { t } = useTranslation();

  const columns: Column[] = [
    {
      field: 'minimal',
      headerName: tPage('detail.table.minimal'),
    },
    {
      field: 'minimal_award',
      headerName: tPage('detail.table.award'),
    },
    {
      field: 'ideal',
      headerName: tPage('detail.table.ideal'),
    },
    {
      field: 'ideal_award',
      headerName: tPage('detail.table.award'),
    },
    {
      field: 'super',
      headerName: tPage('detail.table.super'),
    },
    {
      field: 'super_award',
      headerName: tPage('detail.table.award'),
    },
  ];

  const _users = removeDuplicateObjectFroArray(users, 'user_id', 'user_id');
  const _operations = removeDuplicateObjectFroArray(operations, 'operation_id', 'operation_id');

  const renderUsers = _users?.map(value => ({
    name: value.user_name,
    minimal: t('currency', { value: value.minimal.target }),
    minimal_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.minimal.award} %`
        : t('currency', { value: value.minimal.award }),
    ideal: t('currency', { value: value.ideal.target }),
    ideal_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.ideal.target} %`
        : t('currency', { value: value.ideal.award }),
    super: t('currency', { value: value.super.target }),
    super_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.super.target} %`
        : t('currency', { value: value.super.award }),
  }));

  const renderOperations = _operations?.map(value => ({
    name: value.operation_name,
    minimal:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.minimal.target} %`
        : t('currency', { value: value.minimal.target }),
    minimal_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.minimal.award} %`
        : t('currency', { value: value.minimal.award }),
    ideal:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.ideal.target} %`
        : t('currency', { value: value.ideal.target }),
    ideal_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.ideal.target} %`
        : t('currency', { value: value.ideal.award }),
    super:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.super.target} %`
        : t('currency', { value: value.super.target }),
    super_award:
      type === AWARD_TYPE.PERCENTAGE
        ? `${value.super.target} %`
        : t('currency', { value: value.super.award }),
  }));
  return (
    <Flex column gap={2}>
      {renderUsers.length > 0 && (
        <Table
          tableVariant="secondary"
          columns={[
            {
              field: 'name',
              headerName: tPage('detail.table.user'),
            },
            ...columns,
          ]}
          rows={renderUsers}
        />
      )}
      {renderOperations.length > 0 && (
        <Table
          tableVariant="secondary"
          columns={[
            {
              field: 'name',
              headerName: tPage('detail.table.operation'),
            },
            ...columns,
          ]}
          rows={renderOperations}
        />
      )}
    </Flex>
  );
}

export const Billing = memo(BaseBilling);

import { Autocomplete } from 'components';

import { GOAL_OPERATION_TYPE } from 'constants/goal';
import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { pushHistoryQuery } from 'utils/query';

function BaseTypeFilter() {
  const { t } = useTranslation();

  const history = useHistory();

  const handleChange = (type: { value: number }) => {
    if (!type) return;

    const query = { type: type.value };

    pushHistoryQuery(query, history);
  };

  return (
    <Autocomplete
      placeholder="pages/goal:listing.filters.type"
      contained
      onChange={(event, value) => handleChange(value)}
      onInputChange={(event, value) =>
        value.length === 0 && pushHistoryQuery({ type: '' }, history)
      }
      options={[
        {
          label: t('pages/goal:create.types.USERS'),
          value: GOAL_OPERATION_TYPE.PROFESSIONAL,
        },
        { label: t('pages/goal:create.types.OPERATIONS'), value: GOAL_OPERATION_TYPE.OPERATION },
      ]}
    />
  );
}

export const TypeFilter = memo(BaseTypeFilter);

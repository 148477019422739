import { memo } from 'react';

import { UnSwitch } from 'components';

import { Flex } from 'styles/Flex';

interface DigitalProps {
  toggleOverlay?: () => void;
  isOverlay?: boolean;
  disabled?: boolean;
}

function BaseDigital(props: DigitalProps) {
  return (
    <>
      <UnSwitch
        label="labels:input.product.overlay"
        name="overlay"
        disabled={props?.disabled}
        onChange={props?.toggleOverlay}
      />
      <Flex column gap={1}>
        <label>Imagens do produto</label>
      </Flex>
    </>
  );
}

export const Digital = memo(BaseDigital);

const groups = {
  create: 'create',
  inventoryManage: 'inventoryManage',
  cashDeskManage: 'cashDeskManage',
  generateReport: 'generateReport',
  generateClosing: 'generateClosing',
  dashboard: 'dashboard',
};

const modules = {
  operation: 'operation',
  PDV: 'PDV',
  user: 'user',
  product: 'product',
  PAX: 'PAX',
  price_table: 'price_table',
  manage_all_inventory: 'manage_all_inventory',
  generate_qr_codes: 'generate_qr_codes',
  goal: 'goal',
  cash_desk: 'cash_desk',
  consult_cash_desk: 'consult_cash_desk',
  check_cash_desk: 'check_cash_desk',
  alter_payment_methods: 'alter_payment_methods',
  sales_report: 'sales_report',
  closing_report: 'closing_report',
  acquire: 'acquire',
  tourist_guide: 'tourist_guide',
  access_dashboard: 'access_dashboard',
};

export const permissions = {
  groups,
  modules,
};

interface PermissionKey {
  group: keyof typeof groups;
  module: keyof typeof modules;
}

type Keys =
  | 'CREATE_OPERATION'
  | 'CREATE_PDV'
  | 'CREATE_PRODUCT'
  | 'CREATE_USER'
  | 'CREATE_PAX'
  | 'CREATE_PRICE_TABLE'
  | 'CREATE_TOURIST_GUIDE'
  | 'CREATE_ACQUIRE'
  | 'INVENTORY_MANAGE_MANAGE_ALL'
  | 'CREATE_QR_CODE'
  | 'CREATE_GOAL'
  | 'CREATE_CASH_DESK'
  | 'CASH_DESK_MANAGE_CONSULT_CASH_DESK'
  | 'CASH_DESK_MANAGE_CHECK_CASH_DESK'
  | 'CASH_DESK_MANAGE_ALTER_PAYMENT_METHODS'
  | 'REPORT_SALES_REPORT'
  | 'REPORT_CLOSING'
  | 'ACCESS_DASHBOARD';

export const permissionKeys: Record<Keys, PermissionKey> = {
  CREATE_OPERATION: {
    group: 'create',
    module: 'operation',
  },
  CREATE_PDV: {
    group: 'create',
    module: 'PDV',
  },
  CREATE_PRODUCT: {
    group: 'create',
    module: 'product',
  },
  CREATE_USER: {
    group: 'create',
    module: 'user',
  },
  CREATE_PAX: {
    group: 'create',
    module: 'PAX',
  },
  CREATE_PRICE_TABLE: {
    group: 'create',
    module: 'price_table',
  },
  INVENTORY_MANAGE_MANAGE_ALL: {
    group: 'inventoryManage',
    module: 'manage_all_inventory',
  },
  CREATE_QR_CODE: {
    module: 'generate_qr_codes',
    group: 'create',
  },
  CREATE_GOAL: {
    module: 'goal',
    group: 'create',
  },
  CREATE_ACQUIRE: {
    module: 'acquire',
    group: 'create',
  },
  CREATE_TOURIST_GUIDE: {
    module: 'tourist_guide',
    group: 'create',
  },
  CREATE_CASH_DESK: {
    module: 'cash_desk',
    group: 'create',
  },
  CASH_DESK_MANAGE_ALTER_PAYMENT_METHODS: {
    module: 'alter_payment_methods',
    group: 'cashDeskManage',
  },
  CASH_DESK_MANAGE_CHECK_CASH_DESK: {
    module: 'check_cash_desk',
    group: 'cashDeskManage',
  },
  CASH_DESK_MANAGE_CONSULT_CASH_DESK: {
    module: 'consult_cash_desk',
    group: 'cashDeskManage',
  },
  REPORT_SALES_REPORT: {
    module: 'sales_report',
    group: 'generateReport',
  },
  REPORT_CLOSING: {
    module: 'closing_report',
    group: 'generateClosing',
  },
  ACCESS_DASHBOARD: {
    module: 'access_dashboard',
    group: 'dashboard',
  },
};

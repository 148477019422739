import { SubmitHandler } from '@unform/core';
import { Button, Modal, Unform, UnMaskInput } from 'components';
import { usePax } from 'hooks/store';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { patchPaxHistoric } from 'services/pax';
import { Flex } from 'styles/Flex';
import { HistoricPax } from 'types/pax';
import { openNotification } from 'utils/notification';

interface IProps {
  open: boolean;
  onClose(): void;
  historic?: HistoricPax;
}

const EditModalComponent = ({ open, onClose, historic }: IProps): JSX.Element => {
  const [tPage] = useTranslation('pages/pax');
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useBooleanToggle(false);
  const { loadHistoricList } = usePax();

  const handleSubmit = useCallback<SubmitHandler<{ visitors: number }>>(
    async data => {
      if (!historic) return;

      setIsLoading();

      try {
        await patchPaxHistoric(historic.id, data);

        loadHistoricList({ onlyUpdate: true });
        onClose();
        openNotification(tPage('historic.updateSuccess'));
      } finally {
        setIsLoading();
      }
    },
    [historic],
  );

  return (
    <Modal
      maxWidth="xs"
      fullWidth
      title={tPage('historic.editModalTitle')}
      open={open}
      onClose={onClose}
    >
      <Unform onSubmit={handleSubmit} initialData={historic}>
        <Flex fullWidth column gap={1.5} marginTop={1}>
          <Flex gap={1} column>
            <p>{historic?.operation.name}</p>

            <Flex gap={1}>
              <p>
                {t('dateHours', {
                  date: historic?.created_at ? new Date(historic?.created_at) : '',
                })}
              </p>
            </Flex>
          </Flex>

          <Flex maxWidth={13}>
            <UnMaskInput
              name="visitors"
              contained
              startIcon={{ name: 'people', color: 'text', size: 1.2 }}
              placeholder="pages/pax:visitorsPlaceholder"
              parseToFloat
            />
          </Flex>

          <Flex justifyContent="flex-end" gap={1} marginTop={2}>
            <Button variant="cancel" onClick={onClose}>
              {t('button.cancel')}
            </Button>

            <Button type="submit" isLoading={isLoading}>
              {t('button.save')}
            </Button>
          </Flex>
        </Flex>
      </Unform>
    </Modal>
  );
};

export const EditModal = memo(EditModalComponent);

import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import {
  PaginatedTable,
  Button,
  Tag,
  StatusFilter,
  OperationFilter,
  SearchProductByNameFilter,
  UserFilter,
  DateFilter,
} from 'components';
import { Menu } from '../components/Menu';
import { TypeFilter, ModalInfo, GoalFilter } from './Components';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';

import { queryToObj, pushHistoryQuery } from 'utils/query';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { PRODUCT_TYPES } from 'constants/product';

import { getGoal } from 'services/goal';

import { GetGoal, GoalQuery } from 'types/goal';
import { Meta } from 'types/api';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListGoal({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/goal');
  const { t } = useTranslation();

  const [goals, setGoals] = useState<GetGoal[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [isVisibleContainFilter, setIsVisibleContainFilter] = useState(false);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getGoals = async (query?: GoalQuery) => {
    toggleIsLoading();

    try {
      const { data } = await getGoal(query);
      setGoals(data.data);
      setMeta(data.meta);
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const columns = [
    {
      field: 'type',
      headerName: tPage('listing.table.type'),
    },
    {
      field: 'goal',
      headerName: tPage('listing.table.goal'),
    },
    {
      field: 'operation',
      headerName: tPage('listing.table.operation'),
    },
    {
      field: 'professionals',
      headerName: tPage('listing.table.professionals'),
    },
    {
      field: 'period',
      headerName: tPage('listing.table.period'),
    },
    {
      field: 'status',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const rows = useMemo(
    () =>
      goals.map(goal => ({
        id: goal?.id,
        type: tPage(`create.types.${goal.type}`),
        goal: (
          <ModalInfo
            title="Metas"
            label={goal.typeGoal.length}
            data={goal.typeGoal.map(value => ({ name: tPage(`create.typeGoal.${value.name}`) }))}
          />
        ),
        created_at: t('date', { date: new Date(goal?.created_at || '') }),
        operation: (
          <ModalInfo title="Operações" label={goal.operations.length} data={goal.operations} />
        ),
        professionals: (
          <ModalInfo
            title="Profissionais"
            label={goal.users.length}
            data={goal.users.map(value => ({ name: value.fullName }))}
          />
        ),
        period: `${t('date', { date: new Date(goal.initial_date) })} - ${t('date', {
          date: new Date(goal.final_date),
        })}`,
        menu: (
          <Menu
            id={goal?.id}
            status={goal?.status}
            onDelete={() => getGoals(historyQuery)}
            onEdit={() => getGoals(historyQuery)}
            history={history}
          />
        ),
        status: (
          <Tag
            label={goal?.status === 0 ? 'Inativo' : 'Ativo'}
            variante={goal.status === 0 ? 'inactive' : 'success'}
          />
        ),
      })),
    [goals],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as GoalQuery;

      getGoals(query);

      if (query.type === PRODUCT_TYPES.combo) {
        setIsVisibleContainFilter(true);
        return;
      }
      setIsVisibleContainFilter(false);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getGoals(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <h1>{tPage('listing.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <TypeFilter />
              <DefaultStyles.SelectContainer>
                <GoalFilter />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <DateFilter
                  keyQuery="initial_date"
                  placeholder="pages/goal:listing.filters.initial_date"
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <DateFilter
                  keyQuery="final_date"
                  placeholder="pages/goal:listing.filters.final_date"
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <UserFilter placeholder="pages/goal:listing.filters.users" />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <OperationFilter />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <StatusFilter defaultValue={historyQuery?.status as any} />
              </DefaultStyles.SelectContainer>
              {isVisibleContainFilter && (
                <DefaultStyles.SelectContainer>
                  <SearchProductByNameFilter
                    types={['PHYSICAL', 'DIGITAL']}
                    placeholder="pages/products:listing.filters.product"
                    queryValue="id"
                    queryKey="productId"
                  />
                </DefaultStyles.SelectContainer>
              )}
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => history.push(routePaths.goal.create)}
            >
              {tPage('listing.actionButton')}
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.goal.details, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

import { memo, useState, forwardRef, useImperativeHandle } from 'react';

import { Icon } from 'components';

import * as Styles from './styles';

export interface CountRef {
  count: number;
}

interface CountProps {
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  name: string;
  defaultValue?: number;
}

// eslint-disable-next-line react/display-name
const baseCounter = forwardRef<CountRef, CountProps>(
  ({ min = 0, max, defaultValue, ...props }, ref) => {
    const [count, setCount] = useState(defaultValue ?? 1);

    useImperativeHandle(ref, () => ({
      count,
    }));

    const handleChange = (value: number) => {
      if (value < min || (max && value > max)) return;

      setCount(prevState => prevState + value);
    };

    return (
      <Styles.Container>
        <Styles.Button
          onClick={() => setCount(prevState => (prevState > min ? prevState - 1 : prevState))}
        >
          <Icon name="remove" size={1} />
        </Styles.Button>
        <Styles.Display
          min={min}
          max={max}
          type="number"
          name={props.name}
          value={count}
          onChange={event => handleChange(Number(event.target.value))}
        />
        <Styles.Button onClick={() => handleChange(1)}>
          <Icon name="add" size={1} />
        </Styles.Button>
      </Styles.Container>
    );
  },
);

export const Counter = memo(baseCounter);

import {
  memo,
  useState,
  SyntheticEvent,
  ComponentProps,
  ReactNode,
  useMemo,
  Children,
} from 'react';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import { TabPainel } from './TabPainel';

import * as Styles from './styles';

export interface TabOption extends ComponentProps<typeof MuiTab> {
  hidden?: boolean;
}
interface TabsProps {
  tabs: TabOption[];
  header?: ReactNode;
  children: ReactNode;
  disabled?: boolean;
  currentTab?: number;
  onChangeTab?: (index: number) => void;
}

function BaseTabs(props: TabsProps) {
  const [currentTab, setCurrentTab] = useState(
    props.tabs.findIndex(element => !element.hidden) || 0,
  );

  const handleChangeCurrentTab = (event: SyntheticEvent, newValue: number) => {
    !props?.currentTab && setCurrentTab(newValue);

    props?.onChangeTab?.(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setCurrentTab(index);
  };

  const renderTabs = useMemo(
    () =>
      props.tabs.map(
        ({ hidden, ...value }, index) => !hidden && <Styles.Tab {...value} key={index} />,
      ),
    [props.tabs],
  );

  const renderTabPainels = useMemo(
    () =>
      Children.map(props.children, (child, index) =>
        !props.tabs[index].hidden ? (
          <TabPainel value={currentTab} index={index}>
            {child}
          </TabPainel>
        ) : (
          <TabPainel value={currentTab} index={index}></TabPainel>
        ),
      ),
    [currentTab],
  );

  return (
    <Styles.Container>
      <MuiTabs value={currentTab} onChange={handleChangeCurrentTab}>
        {renderTabs}
      </MuiTabs>
      {!props.disabled && props.header && <Styles.Header>{props.header}</Styles.Header>}
      <SwipeableViews
        disabled={props?.disabled}
        axis="x"
        index={props?.currentTab || currentTab}
        onChangeIndex={handleChangeIndex}
      >
        {renderTabPainels}
      </SwipeableViews>
    </Styles.Container>
  );
}

export const Tabs = memo(BaseTabs);

import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
`;

export const Info = styled.div`
  width: 100%;
  margin: 2rem 0;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.background};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const Container = styled.div`
  padding: 1rem;
`;

export const Title = styled.strong`
  font-size: 1.1rem;
  display: inline-block;
`;

export const Total = styled.strong`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
`;

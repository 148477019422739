import { Button, IconButton, LoadingMessage } from 'components';
import { resolvePath, routePaths } from 'constants/routes';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { getTouristGuideDetails } from 'services/touristGuide';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { PageContent } from 'styles/PageContent';
import { GetTouristGuideDetails } from 'types/touristGuide';
import { Info } from './components';

export const TouristGuideDetailsPage = ({
  history,
  match,
}: RouteComponentProps<{ id: string }>) => {
  const [tPage] = useTranslation('pages/touristGuide');
  const guideId = useMemo(() => match.params.id, []);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [guide, setGuide] = useState<GetTouristGuideDetails>();

  const handleLoadGuide = async () => {
    try {
      setIsLoading(true);

      const response = await getTouristGuideDetails(guideId);

      setGuide(response.data);
    } catch (error) {
      history.goBack();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleLoadGuide();
  }, []);

  return (
    <PageContainer md>
      <Flex alignItems="center" marginBottom={1} wrap gap={1} justifyContent="space-between">
        <Flex alignItems="center" gap={1}>
          <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={() => history.goBack()} />

          <h1 style={{ margin: 0 }}>{tPage('details.title')}</h1>
        </Flex>

        <Button
          onClick={() => history.push(resolvePath(routePaths.touristGuide.edit, { id: guideId }))}
        >
          {tPage('details.editButton')}
        </Button>
      </Flex>

      <PageContent>
        {isLoading || !guide ? (
          <Flex justifyContent="center">
            <LoadingMessage text={tPage('details.loadingMessage')} />
          </Flex>
        ) : (
          <Info data={guide} />
        )}
      </PageContent>
    </PageContainer>
  );
};

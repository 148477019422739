import { SubmitHandler } from '@unform/core';
import { Button, UnAutocomplete, UnDatePicker, Unform } from 'components';
import { format } from 'date-fns';
import { useOperationOptions } from 'hooks/useOperationOptions';
import { useSellersToReport } from 'hooks/useSellersToReport';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGenerateSellerReport } from 'services/report';
import { Flex } from 'styles/Flex';
import { SimpleOperationList } from 'types/operation';
import { ReportTypes, SellerToReport } from 'types/report';
import { downloadFile } from 'utils/api';
import { openNotification } from 'utils/notification';
import { saleReportValidationSchema } from 'yupSchemas/report';
import { usePhotographersToReport } from '../../../../../../hooks/usePhotographersToReport';
import { reportTypes } from '../../../../../../constants/report';

interface FormData {
  final_date: Date;
  initial_date: Date;
  operation_id: number;
  user_id: number;
  file_format: number;
}

interface Props {
  type: ReportTypes;
}

export const SaleReport = ({ type }: Props) => {
  const [tPage] = useTranslation('pages/report');
  const { isLoading: isLoadingOperations, operations } = useOperationOptions({ loadOnInit: true });
  const [operation, setOperation] = useState<SimpleOperationList>();
  const [loadingButton, setLoadingButton] = useState<number | null>(null);
  const [selectedFileFormat, setSelectedFileFormat] = useState(0);
  const [period, setPeriod] = useState<{ initialDate: Date; finalDate: Date }>({
    initialDate: new Date(),
    finalDate: new Date(),
  });
  const isPhotographerReport = type === reportTypes.photoproduction;
  let users: SellerToReport[], handleLoad: () => Promise<void>, isLoading: boolean;
  const userLabel = isPhotographerReport
    ? 'pages/report:form.photographer'
    : 'pages/report:form.user';
  if (isPhotographerReport) {
    ({
      photographers: users,
      handleLoadPhotographers: handleLoad,
      isLoading,
    } = usePhotographersToReport({
      operationId: operation?.id,
      typeId: 6,
    }));
  } else {
    ({
      sellers: users,
      handleLoadSellers: handleLoad,
      isLoading,
    } = useSellersToReport({
      operationId: operation?.id,
      initialDate: period.initialDate,
      finalDate: period.finalDate,
      fileFormat: selectedFileFormat,
    }));
  }
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const disableUser = !operation;

  const handleSetPeriod = useCallback((field: keyof typeof period, value: Date) => {
    setPeriod(prev => ({ ...prev, [field]: value }));
  }, []);

  const handleSubmit = useCallback<SubmitHandler<FormData>>(
    async data => {
      try {
        setLoadingButton(selectedFileFormat);
        setIsLoadingReport(true);
        const initialDate = format(data.initial_date, 'yyyy-MM-dd');
        const finalDate = format(data.final_date, 'yyyy-MM-dd');

        const response = await getGenerateSellerReport({
          ...data,
          initial_date: initialDate,
          final_date: finalDate,
          file_format: selectedFileFormat,
          type,
        });

        downloadFile(response.data, tPage(`fullNameReportTypes.${type}`));

        openNotification(tPage('generatedReport'));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingReport(false);
        setLoadingButton(null);
      }
    },
    [type, selectedFileFormat],
  );

  useEffect(() => {
    if (!disableUser) {
      handleLoad();
    }
  }, [disableUser]);

  return (
    <Unform onSubmit={handleSubmit} validationSchema={saleReportValidationSchema}>
      <Flex column gap={0.2}>
        <Flex>
          <UnAutocomplete
            label="pages/report:form.operation"
            name="operation_id"
            optionLabel="name"
            optionValue="id"
            options={operations}
            loading={isLoadingOperations}
            onChange={(_, opt) => setOperation(opt)}
            value={operation}
          />
        </Flex>

        <Flex gap={1}>
          <UnDatePicker
            onChange={date => handleSetPeriod('initialDate', date as Date)}
            defaultValue={period.initialDate}
            label="pages/report:form.initialDate"
            name="initial_date"
            maxDate={new Date()}
          />

          <UnDatePicker
            clearHelperTextSpacing={false}
            defaultValue={period.finalDate}
            label="pages/report:form.finalDate"
            name="final_date"
            onChange={date => handleSetPeriod('finalDate', date as Date)}
            maxDate={new Date()}
          />
        </Flex>

        <Flex>
          <UnAutocomplete
            name="user_id"
            label={userLabel}
            options={users}
            optionLabel="full_name"
            optionValue="id"
            disabled={disableUser}
            loading={isLoading}
          />
        </Flex>

        <Flex>
          <Button
            type="submit"
            fullWidth
            isLoading={isLoadingReport && loadingButton === 2}
            style={{ marginRight: '10px' }}
            onClick={() => {
              setSelectedFileFormat(2);
            }}
          >
            Emitir relatório Excel
          </Button>
          <Button
            type="submit"
            fullWidth
            isLoading={isLoadingReport && loadingButton === 1}
            onClick={() => {
              setSelectedFileFormat(1);
            }}
          >
            Emitir relatório PDF
          </Button>
        </Flex>
      </Flex>
    </Unform>
  );
};

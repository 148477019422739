import { useField } from '@unform/core';
import { DropImage, ImagesRef } from 'components';
import { ComponentProps, memo, useEffect, useRef } from 'react';
import { Flex } from 'styles/Flex';

interface IProps extends ComponentProps<typeof DropImage> {
  name: string;
}

const UnDropImageComponent = ({ name, maxFiles, ...props }: IProps): JSX.Element => {
  const imageRef = useRef<ImagesRef>(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: imageRef,
      getValue: ref => ref.current?.images,
    });
  }, [imageRef, fieldName, defaultValue]);

  return (
    <Flex>
      <DropImage
        ref={imageRef}
        maxFiles={maxFiles}
        initialValue={defaultValue && (Array.isArray(defaultValue) ? defaultValue : [defaultValue])}
        {...props}
      />
    </Flex>
  );
};

export const UnDropImage = memo(UnDropImageComponent);

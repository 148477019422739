import { ConfirmModal, Popover } from 'components';
import { INVENTORY_STATUS, INVENTORY_TYPES } from 'constants/inventory';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { ComponentProps, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { patchCancelInventory, patchCompleteInventory } from 'services/inventory';
import { InventoryList } from 'types/inventory';
import { openNotification } from 'utils/notification';

type PopoverProps = ComponentProps<typeof Popover>;

interface MenuProps {
  inventory: InventoryList;
  updateList: () => void;
  history?: RouteComponentProps['history'];
}

function BaseMenu({ inventory, updateList }: MenuProps) {
  const [isCancelEntry, toggleCancelEntry] = useBooleanToggle(false);
  const [loadingCancelEntry, toggleLoadingCancelEntry] = useBooleanToggle(false);
  const [isCompleteTransfer, toggleCompleteTransfer] = useBooleanToggle(false);
  const [loadingCompleteTransfer, toggleLoadingCompleteTransfer] = useBooleanToggle(false);
  const [tPage] = useTranslation('pages/inventory', { keyPrefix: 'listing' });

  const handleCancelEntry = useCallback(
    async (id: string | number, type: InventoryList['type']) => {
      try {
        toggleLoadingCancelEntry();

        await patchCancelInventory(id);

        openNotification(
          tPage(`${type === INVENTORY_TYPES.entry ? 'cancelEntry' : 'cancelTransfer'}.success`),
        );
        updateList();
      } catch (err) {
      } finally {
        toggleCancelEntry();
        toggleLoadingCancelEntry();
      }
    },
    [],
  );

  const handleCompleteTransfer = useCallback(async (id: string | number) => {
    try {
      toggleLoadingCompleteTransfer();

      await patchCompleteInventory(id);

      openNotification(tPage('completeTransfer.success'));
      updateList();
    } catch (err) {
    } finally {
      toggleCancelEntry();
      toggleLoadingCompleteTransfer();
    }
  }, []);

  const options: PopoverProps['options'] = [
    {
      label: `pages/inventory:listing.popover.${
        inventory.type === INVENTORY_TYPES.entry ? 'cancelEntry' : 'cancelTransfer'
      }`,
      onClick: toggleCancelEntry,
    },
    {
      label: `pages/inventory:listing.popover.completeTransfer`,
      onClick: toggleCompleteTransfer,
      disabled:
        inventory.type === INVENTORY_TYPES.entry || inventory.status === INVENTORY_STATUS.completed,
    },
  ];

  if (inventory.status === INVENTORY_STATUS.canceled) return null;

  return (
    <div onClick={event => event.stopPropagation()}>
      <ConfirmModal
        open={isCancelEntry}
        title={tPage(
          `${inventory.type === INVENTORY_TYPES.entry ? 'cancelEntry' : 'cancelTransfer'}.title`,
        )}
        bodyText={tPage(
          `${inventory.type === INVENTORY_TYPES.entry ? 'cancelEntry' : 'cancelTransfer'}.body`,
        )}
        onClose={toggleCancelEntry}
        onConfirm={() => handleCancelEntry(inventory.id, inventory.type)}
        isLoading={loadingCancelEntry}
      />

      <ConfirmModal
        open={isCompleteTransfer}
        title={tPage('completeTransfer.title')}
        bodyText={tPage('completeTransfer.body')}
        onClose={toggleCompleteTransfer}
        onConfirm={() => handleCompleteTransfer(inventory.id)}
        isLoading={loadingCompleteTransfer}
      />

      <Popover options={options} id="simple" />
    </div>
  );
}

export const Menu = memo(BaseMenu);

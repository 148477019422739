import { AutocompleteInputChangeReason } from '@mui/core';
import { SubmitHandler } from '@unform/core';
import { Button, UnAutocomplete, Unform, UnMaskInput } from 'components';
import { PRODUCT_TYPES } from 'constants/product';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postProductOnOperation } from 'services/operation';
import { getAllProducts } from 'services/product';
import { Flex } from 'styles/Flex';
import { GetProductAllList } from 'types/product';
import { debounce } from 'utils';
import { addProductInventoryValidationSchema } from 'yupSchemas/operation';
import * as Styles from './styles';

interface Props {
  postCreate(product: any): void;
  id: string | number;
}

interface FormData {
  product: GetProductAllList;
  min: number;
  max: number;
}

const ManageProductsComponent = ({ postCreate, id }: Props): JSX.Element => {
  const [tPage] = useTranslation('pages/operations', { keyPrefix: 'details.inventoryTab' });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [products, setProducts] = useState<GetProductAllList[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<GetProductAllList>();

  const handleSubmit = useCallback<SubmitHandler<FormData>>(async (data, { reset }) => {
    setIsSaving(true);
    try {
      const productId = data.product.id;

      const response = await postProductOnOperation(id, productId, data);

      postCreate(response.data);

      reset();
    } finally {
      setIsSaving(false);
    }
  }, []);

  const handleSearchProduct = useCallback(
    async (e, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason !== 'input' || !value) return;

      try {
        const response = await getAllProducts({ search: value });

        setProducts(response.data);
      } catch (error) {}
    },
    [],
  );

  return (
    <Styles.Container>
      <legend>{tPage('addProducts')}</legend>

      <Unform onSubmit={handleSubmit} validationSchema={addProductInventoryValidationSchema}>
        <Flex gap={1} marginTop={1.5}>
          <Flex flex={2}>
            <UnAutocomplete
              name="product"
              contained
              label="labels:input.productName"
              filterOptions={opt => opt}
              onInputChange={debounce(handleSearchProduct)}
              optionLabel="name"
              optionValue="id"
              getOptionLabel={(option: GetProductAllList) =>
                option?.id ? `${option?.id} - ${option?.name}` : ''
              }
              options={products}
              returnAllOption
              onChange={(e, value: GetProductAllList) => setSelectedProduct(value)}
            />
          </Flex>

          <Flex flex={1}>
            <UnMaskInput
              contained
              parseToFloat
              name="min"
              label="labels:input.min"
              disabled={selectedProduct?.type !== PRODUCT_TYPES.physical}
            />
          </Flex>

          <Flex flex={1}>
            <UnMaskInput
              contained
              parseToFloat
              name="max"
              label="labels:input.max"
              disabled={selectedProduct?.type !== PRODUCT_TYPES.physical}
            />
          </Flex>

          <Flex column>
            <Button type="submit" isLoading={isSaving}>
              {tPage('addButton')}
            </Button>
          </Flex>
        </Flex>
      </Unform>
    </Styles.Container>
  );
};

export const ManageProducts = memo(ManageProductsComponent);

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { IconButton, LoadingMessage, Tabs } from 'components';

import { Info, Logs } from './components';

import { getInventoryDetails } from 'services/inventory';

import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { PageContent } from 'styles/PageContent';

import { InventoryDetails as IInventoryDetails } from 'types/inventory';

export const InventoryDetails = ({
  history,
  match,
}: RouteComponentProps<{ id: string }>): JSX.Element => {
  const id = useMemo<string>(() => match.params.id, []);

  const [tPage] = useTranslation('pages/inventory', { keyPrefix: 'details' });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inventory, setInventory] = useState<IInventoryDetails>();

  const getDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getInventoryDetails(id);

      setInventory(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <PageContainer>
      <Flex gap={1} marginBottom={0.5} alignItems="center">
        <IconButton
          icon={{ name: 'arrowBack', size: 1.4, color: 'text1000' }}
          onClick={() => history.goBack()}
        />

        <h1>{tPage('title')}</h1>
      </Flex>

      <PageContent>
        {isLoading && (
          <Flex marginHorizontal={1} marginVertical={1} justifyContent="center" alignItems="center">
            <LoadingMessage text={tPage('loadingMessage')} />
          </Flex>
        )}

        {!!inventory && id && (
          <>
            <Tabs tabs={[{ label: tPage('tabs.info') }, { label: tPage('tabs.log') }]}>
              <Info inventory={inventory} />
              <Logs id={id} />
            </Tabs>
          </>
        )}
      </PageContent>
    </PageContainer>
  );
};

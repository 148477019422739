import { Autocomplete } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getSubTypesGoal } from 'services/goal';
import { SubTypeGoal } from 'types/goal';
import { pushHistoryQuery } from 'utils/query';

interface Option {
  value: number;
  label: string;
}

function BaseGoalFilter() {
  const filter = 'meta_type_id';

  const history = useHistory();
  const { t } = useTranslation();

  const [subTypes, setSubTypes] = useState<SubTypeGoal[]>([]);

  const handleChange = (type: { value: number }) => {
    if (!type) return;

    const query = { [filter]: type.value };

    pushHistoryQuery(query, history);
  };

  const handleGetSubTypes = async () => {
    const { data } = await getSubTypesGoal();

    setSubTypes(data);
  };

  useEffect(() => {
    handleGetSubTypes();
  }, []);

  const options: Option[] = subTypes.map(value => ({
    label: t(`pages/goal:create.typeGoal.${value.name}`),
    value: value.id,
  }));

  return (
    <Autocomplete
      placeholder="pages/goal:listing.filters.goal"
      contained
      onChange={(event, value) => handleChange(value)}
      onInputChange={(event, value) =>
        value.length === 0 && pushHistoryQuery({ [filter]: '' }, history)
      }
      options={options}
    />
  );
}

export const GoalFilter = BaseGoalFilter;

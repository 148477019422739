import { useField } from '@unform/core';
import { FileInput, FileInputRef } from 'components';
import React, { ComponentProps, memo, useEffect, useRef } from 'react';

interface IProps extends ComponentProps<typeof FileInput> {
  name: string;
}

const UnFileInputComponent: React.FC<IProps> = ({ name, ...props }) => {
  const { fieldName, registerField } = useField(name);
  const fileRef = useRef<FileInputRef>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: fileRef,
      getValue: ref => ref.current.files,
      clearValue: ref => ref.current.clearFiles(),
    });
  }, [fieldName, registerField]);

  return <FileInput ref={fileRef} {...props} />;
};

export const UnFileInput = memo(UnFileInputComponent);

import React, { useState, useEffect, CSSProperties } from 'react';
import { Button, UnInputFIeldQrCode, Unform } from 'components';
import { getVerifyQRCodeDigitalPhoto } from 'services/qrcode';
import { loadQrCodeValidationSchema } from 'yupSchemas/loadQrCode';
import { RouteComponentProps } from 'react-router-dom';
import { openNotification } from 'utils/notification';
import { qrCodeParamKey } from 'constants/client';
import { useTranslation } from 'react-i18next';
import { SubmitHandler } from '@unform/core';
import { getStorageUrl } from 'utils/api';
import { routePaths } from 'constants/routes';
import { objToQuery } from 'utils/query';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonMaterial from '@mui/material/Button';
import nuvemVyoo from '../../../assets/NuvemPNG.png';
import DotLoader from 'react-spinners/DotLoader';
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga4';
import * as Styles from './styles';

type Props = RouteComponentProps;

export const LoadQrCode = ({ history }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showBody, setShowBody] = useState(true);
  const [qrCode, setQrCode] = useState('');

  const handleQrCodeChange = (event: { target: { value: string } }) => {
    const uppercaseValue = event.target.value.toUpperCase();
    setQrCode(uppercaseValue);
  };

  ReactGA.initialize('G-E2W8W5XZE6');

  useEffect(() => {
    const time = 6000;
    let currentImageIndex = 0;
    const images = document.querySelectorAll<HTMLImageElement>('#slider img');
    const max = images.length - 1;

    function nextImage() {
      images[currentImageIndex].classList.remove('selected');
      currentImageIndex++;

      if (currentImageIndex >= max) currentImageIndex = 0;

      images[currentImageIndex].classList.add('selected');
    }

    function start() {
      setInterval(() => {
        // troca de imagem
        nextImage();
      }, time);
    }

    start();
  }, []);

  const handleSubmit: SubmitHandler<{ qrCode: string }> = async ({ qrCode }) => {
    setIsLoading(true);
    setShowBody(false);

    try {
      const response = await getVerifyQRCodeDigitalPhoto(qrCode);

      console.log(objToQuery({ [qrCodeParamKey]: qrCode }));

      if (response.status === 200) {
        history.push(`${routePaths.client.gallery}?${objToQuery({ [qrCodeParamKey]: qrCode })}`);
        ReactGA.event({
          category: 'Page Visit',
          action: 'Acessou a Galeria',
          label: 'Gallery Page',
        });
      }
    } catch {
      openNotification(t('commons:invalidQRCode'), 'error');
    } finally {
      setIsLoading(false);
      setShowBody(true);
    }
  };

  const override: CSSProperties = {
    left: '85px',
    bottom: '85px',
  };

  const handleAjudaButtonClick = () => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Botão Ajuda Click',
    });
    history.push(routePaths.client.recover);
  };

  return (
    <Styles.Container>
      <Styles.sliderStyles>
        <div id="slider">
          <img className="selected" src={getStorageUrl('/carrossel01.jpg')} alt="Image1" />

          <img src={getStorageUrl('/carrossel02.jpg')} alt="Image2" />

          <img src={getStorageUrl('/carrossel03.jpg')} alt="Image3" />

          <img src={getStorageUrl('/carrossel04.jpg')} alt="Image4" />

          <img src={getStorageUrl('/carrossel05.jpg')} alt="Image5" />
          {showBody && (
            <Styles.headerText>
              <img
                className="magicPass"
                src={getStorageUrl('/magicpass.jpg')}
                alt="MagicPass"
                style={{ width: '70%' }}
              />
              <Styles.cardScan>
                <Unform onSubmit={handleSubmit} validationSchema={loadQrCodeValidationSchema}>
                  <InputMask
                    mask="aaaa-*******"
                    className="scan-card"
                    value={qrCode}
                    onChange={handleQrCodeChange}
                  >
                    <UnInputFIeldQrCode
                      inputProps={{
                        placeholder: 'Informe seu QR Code',
                        border: 'none',
                        outline: 'none',
                        style: {
                          fontFamily: 'Montserrat',
                        },
                      }}
                      type="text"
                      className="scan-card"
                      name="qrCode"
                    />
                  </InputMask>
                  <Button type="submit" className="scan-card-act" fullWidth isLoading={isLoading}>
                    Ir
                  </Button>
                </Unform>
              </Styles.cardScan>
            </Styles.headerText>
          )}
        </div>
      </Styles.sliderStyles>
      {isLoading && (
        <Styles.loaderDiv>
          <Styles.clockCloud>
            <img src={nuvemVyoo} style={{ width: '170px', height: '101px' }} />
            <DotLoader
              loading={true}
              color="#0b91f5"
              cssOverride={override}
              size={40}
              speedMultiplier={1.2}
            />
          </Styles.clockCloud>
        </Styles.loaderDiv>
      )}
      <Styles.divBotao2>
        <ButtonMaterial
          className="btn-help"
          style={{
            borderRadius: 15,
            fontFamily: 'Montserrat',
            fontSize: '15px',
            fontWeight: 'bold',
            padding: '0.8vh 1.2vw',
            textTransform: 'lowercase',
          }}
          variant="contained"
          onClick={handleAjudaButtonClick}
        >
          <HelpOutlineIcon fontSize="medium" />
          <span style={{ fontSize: '15px', fontWeight: 'bold', marginLeft: '5px' }}>Ajuda</span>
        </ButtonMaterial>
      </Styles.divBotao2>
    </Styles.Container>
  );
};

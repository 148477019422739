import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  max-width: 30rem;
  background: ${({ theme }) => theme.colors.foreground};
  padding: 1rem;
`;

export const Title = styled.strong`
  font-size: 1rem;
  font-weight: bold;
`;

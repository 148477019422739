import styled from 'styled-components';

export interface Props {
  fullWidth?: boolean;
  contained?: boolean;
}

export const Container = styled.div<Props>`
  min-width: 10rem;
  width: ${props => (props.fullWidth ? '100%' : 'inherit')};

  .MuiInputBase-input {
    border-radius: ${props => props.theme.borderRadius.md} !important;
    padding: 0.719rem;
    background-color: ${({ theme, contained }) => contained && theme.colors.foreground};
  }
`;

import styled from 'styled-components';

import { IconButton } from 'components';
export const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
`;

export const ImgContainer = styled.figure`
  width: 18rem;
  height: 18rem;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius.md};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.4rem;
  width: 18rem;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 18rem;
  width: 18rem;
  position: relative;
`;

export const Controlls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  position: absolute;
`;
export const ButtonIcon = styled(IconButton)<{ isActive?: boolean }>`
  background: #fff;

  &:hover {
    background: #fff;
    filter: brightness(0.9);
  }
`;

export const Dot = styled.div<{ isActive?: boolean }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: ${({ isActive, theme }) => (isActive ? theme.colors.primary : '#ccc')};
`;

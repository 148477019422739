import { PrimaryClient } from 'layouts/Client/Primary';
import { Route } from 'react-router-dom';
import { primaryClientRoutes } from 'routes/routes';

export const PrimaryClientLayoutRoutes = () => {
  const renderRoutes = primaryClientRoutes.map(({ component: Component, ...route }, index) => (
    <Route
      key={`route-${index}`}
      render={props =>
        route.removeLayout ? (
          <Component {...props} />
        ) : (
          <PrimaryClient>
            <Component {...props} />
          </PrimaryClient>
        )
      }
      {...route}
    />
  ));

  return renderRoutes;
};

import { SubmitHandler } from '@unform/core';
import { Button, Unform, UnInputFIeld, UnMaskInput } from 'components';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { postTouristOperator } from 'services/touristGuide';
import { Flex } from 'styles/Flex';
import { openNotification } from 'utils/notification';
import { operatorValidationSchema } from 'yupSchemas/touristGuide';

interface FormData {
  name: string;
  commissionPercentage: number;
}
interface Props {
  afterCreate: () => void;
}

export const CreateOperatorForm = ({ afterCreate }: Props) => {
  const [tPage] = useTranslation('pages/touristGuide');
  const [isLoading, toggleIsLoading] = useBooleanToggle();

  const handleSubmit = useCallback<SubmitHandler<FormData>>(async (data, { reset }) => {
    toggleIsLoading();

    try {
      const response = await postTouristOperator(data);

      if (response.status === 200) {
        openNotification(tPage('createOperator.successMessage'));
        reset();

        afterCreate();
      }
      toggleIsLoading();
    } catch (error) {
      console.log(error);

      toggleIsLoading();
      throw error;
    }
  }, []);

  return (
    <Unform onSubmit={handleSubmit} validationSchema={operatorValidationSchema}>
      <Flex alignItems="flex-start" fullWidth gap={0.5}>
        <Flex flex={2}>
          <UnInputFIeld name="name" label="pages/touristGuide:createOperator.name" />
        </Flex>

        <Flex flex={1}>
          <UnMaskInput
            name="commissionPercentage"
            mask="decimalPercent"
            label="pages/touristGuide:createOperator.commission"
            parseToFloat
          />
        </Flex>

        <Flex flex={1} marginLeft={1}>
          <Button type="submit" isLoading={isLoading}>
            {tPage('createOperator.create')}
          </Button>
        </Flex>
      </Flex>
    </Unform>
  );
};

import React from 'react';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';
import { Button, Icon } from 'components';
import { routePaths } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IProps {}

const ResponseComp: React.FC<IProps> = (): JSX.Element => {
  const [tPage] = useTranslation('pages/forgotPassword');
  return (
    <Styles.Body>
      <Styles.SendIcon>
        <Icon name="send" size={3} />
      </Styles.SendIcon>
      <Styles.Title>{tPage('sent')}</Styles.Title>
      <Link to={routePaths.login}>
        <Flex column fullWidth marginTop={2.2}>
          <Button>{tPage('backHome')}</Button>
        </Flex>
      </Link>
    </Styles.Body>
  );
};

export const Response = React.memo(ResponseComp);

import styled, { css } from 'styled-components';
import { Value as ValueDefault } from 'styles/Details';

interface ValueProps {
  isPositive?: boolean;
  isNegative?: boolean;
}

export const Monetize = styled(ValueDefault)<ValueProps>`
  font-weight: 700;
  ${({ isPositive }) =>
    isPositive &&
    css`
      color: ${({ theme }) => theme.colors.valid};
    `};

  ${({ isNegative }) =>
    isNegative &&
    css`
      color: ${({ theme }) => theme.colors.red};
    `};
`;

import { PermissionKey } from 'types/auth';

export const routePaths = {
  main: '/',
  login: '/login',
  home: '/home',
  recoveryPassword: '/recovery-password',
  forgotPassword: '/forgot-password',
  inventory: {
    list: '/inventory/list',
    details: '/inventory/details/:id',
    create: '/inventory/create',
    edit: '/inventory/edit/:id',
  },
  operation: {
    list: '/operation/list',
    details: '/operation/details/:id',
    create: '/operation/create',
    edit: '/operation/edit/:id',
  },
  pdv: {
    list: '/pdv/list',
    details: '/pdv/details/:id',
    create: '/pdv/create',
    edit: '/pdv/edit/:id',
  },
  products: {
    list: '/product/list',
    details: '/product/details/:id',
    create: '/product/create',
    edit: '/product/edit/:id',
  },
  pax: {
    index: '/pax',
  },
  user: {
    create: '/user/create',
    list: '/user/list',
    details: '/user/details/:id',
    edit: '/user/edit/:id',
  },
  priceList: {
    create: '/price-list/create',
    list: '/price-list/list',
    edit: '/price-list/edit/:id',
  },
  goal: {
    create: '/goal/create',
    list: '/goal/list',
    edit: '/goal/edit/:id',
    details: '/goal/details/:id',
  },
  promotion: {
    create: '/promotion/create',
    list: '/promotion/list',
    edit: '/promotion/edit/:id',
    details: '/promotion/details/:id',
  },
  qrCode: {
    create: '/qr-code/create',
    list: '/qr-code/list',
    edit: '/qr-code/edit/:id',
    details: '/qr-code/details/:id',
  },
  cashDesk: {
    create: '/cash-desk/create',
    list: '/cash-desk/list',
    edit: '/cash-desk/edit/:id/:operationId',
    details: '/cash-desk/details/:id/:operationId',
  },
  acquire: {
    create: '/acquire/create',
    list: '/acquire/list',
    edit: '/acquire/edit/:id',
    details: '/acquire/details/:id',
  },
  client: {
    loadQrCode: '/',
    gallery: '/gallery',
    recover: '/recover',
    galery: '/galery',
    faq: '/faq',
  },
  report: {
    index: '/report',
  },
  dashboard: {
    index: '/dashboard',
  },
  closing: {
    index: '/closing',
  },
  touristGuide: {
    list: '/tourist-guide',
    create: '/tourist-guide/create',
    details: '/tourist-guide/:id',
    edit: '/tourist-guide/edit/:id',
  },
};

export const publicRoutes = {
  login: '/login',
  home: '/home',
  recoveryPassword: '/recovery-password',
};

export const resolvePath = (path: string, obj: Record<string, any>) => {
  let tempPath = path;

  Object.keys(obj).map(key => (tempPath = tempPath.replace(`:${key}`, obj[key])));

  return tempPath;
};

interface IDrawerRoute {
  i18nLabel: string;
  routes: {
    i18nLabel: string;
    path: string;
    permission?: PermissionKey;
  }[];
}

export const adminDrawerRoutes: IDrawerRoute[] = [
  {
    i18nLabel: 'routes:drawerRoutes.menu',
    routes: [
      {
        i18nLabel: 'routes:drawerRoutes.home',
        path: routePaths.home,
      },
      {
        i18nLabel: 'routes:drawerRoutes.throwPax',
        path: routePaths.pax.index,
        permission: 'CREATE_PAX',
      },
      {
        i18nLabel: 'routes:drawerRoutes.inventory',
        path: routePaths.inventory.list,
        permission: 'INVENTORY_MANAGE_MANAGE_ALL',
      },
      {
        i18nLabel: 'routes:drawerRoutes.promotion',
        path: routePaths.promotion.list,
        permission: 'CREATE_OPERATION',
      },
      {
        i18nLabel: 'routes:drawerRoutes.cashDesk',
        path: routePaths.cashDesk.list,
        permission: 'CASH_DESK_MANAGE_CONSULT_CASH_DESK',
      },
      {
        i18nLabel: 'routes:drawerRoutes.report',
        path: routePaths.report.index,
      },
      {
        i18nLabel: 'routes:drawerRoutes.closing',
        path: routePaths.closing.index,
      },
      {
        i18nLabel: 'routes:drawerRoutes.dashboard',
        path: routePaths.dashboard.index,
        permission: 'ACCESS_DASHBOARD',
      },
    ],
  },
  {
    i18nLabel: 'routes:drawerRoutes.photos',
    routes: [
      {
        i18nLabel: 'routes:drawerRoutes.qrcode',
        path: routePaths.qrCode.list,
        permission: 'CREATE_QR_CODE',
      },
    ],
  },
  {
    i18nLabel: 'routes:drawerRoutes.registrations',
    routes: [
      {
        i18nLabel: 'routes:drawerRoutes.operation',
        path: routePaths.operation.list,
        permission: 'CREATE_OPERATION',
      },
      {
        i18nLabel: 'routes:drawerRoutes.pdv',
        path: routePaths.pdv.list,
        permission: 'CREATE_PDV',
      },
      {
        i18nLabel: 'routes:drawerRoutes.product',
        path: routePaths.products.list,
        permission: 'CREATE_PRODUCT',
      },
      {
        i18nLabel: 'routes:drawerRoutes.user',
        path: routePaths.user.list,
        permission: 'CREATE_USER',
      },
      {
        i18nLabel: 'routes:drawerRoutes.priceList',
        path: routePaths.priceList.list,
        permission: 'CREATE_PRICE_TABLE',
      },
      {
        i18nLabel: 'routes:drawerRoutes.goal',
        path: routePaths.goal.list,
        permission: 'CREATE_GOAL',
      },
      {
        i18nLabel: 'routes:drawerRoutes.acquire',
        path: routePaths.acquire.list,
        permission: 'CREATE_ACQUIRE',
      },
      {
        i18nLabel: 'routes:drawerRoutes.touristGuide',
        path: routePaths.touristGuide.list,
        permission: 'CREATE_TOURIST_GUIDE',
      },
    ],
  },
];

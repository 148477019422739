import { permissionKeys, permissions } from 'constants/permission';
import { PermissionKey } from 'types/auth';
import { getUserToken, geUserStorage } from './auth';

export const userIsLogged = () => !!getUserToken();

export const checkPermission = (key: PermissionKey) => {
  const permission = permissionKeys[key];
  const group = permissions.groups[permission.group];
  const module = permissions.modules[permission.module];
  const user = geUserStorage();

  if (!user) return false;

  const userPermissions = user.permissions;

  const userGroup = userPermissions.find(userGroup => userGroup.group === group);

  return !!userGroup?.modules.find(userModule => userModule === module);
};

export const verifyAuthentication = (permission?: PermissionKey) =>
  userIsLogged() && (permission ? checkPermission(permission) : true);

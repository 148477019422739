import { storagePrefixKey } from 'constants/storage';

/** Function to save data on `local` / `session` storage */
export const setItemStorage = (key: string, value: any, inSessionStorage: boolean = false) => {
  const newKey = `${storagePrefixKey}${key}`;
  const jsonValue = JSON.stringify(value);

  if (inSessionStorage) {
    sessionStorage.setItem(newKey, jsonValue);
  } else {
    localStorage.setItem(newKey, jsonValue);
  }

  return true;
};

export function getItemStorage<T = any>(key: string, inSessionStorage: boolean = false): T | null {
  const newKey = `${storagePrefixKey}${key}`;
  let value = null;

  if (inSessionStorage) {
    value = sessionStorage.getItem(newKey);
  } else {
    value = localStorage.getItem(newKey);
  }

  if (!value) return null;

  try {
    return JSON.parse(value);
  } catch (error) {
    return value as unknown as T;
  }
}

export const clearStorage = (inSessionStorage: boolean = false) =>
  inSessionStorage ? sessionStorage.clear() : localStorage.clear();

import styled from 'styled-components';

export const tableDiv = styled.div`
  margin-top: 20px;
  width: 400px;
  margin-bottom: 30px;
  position: absolute;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  thead {
    background-color: #f0f0f0;
  }

  th {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
    font-weight: 500;
  }

  tbody {
    border: 1px solid #ccc;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
`;

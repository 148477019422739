import api from 'services/apis/api';

import {
  CreatePrice,
  CreatePriceList,
  GetComboPriceListResponse,
  GetDigitalPriceListResponse,
  GetOperationRelatedPriceList,
  GetPhysicalPriceListResponse,
  GetPriceListById,
  GetPriceListResponse,
  GetProductPriceListQueries,
  PriceListQueries,
} from 'types/priceList';

import { objToQuery } from 'utils/query';

export async function getPriceList(query?: PriceListQueries) {
  return await api.get<GetPriceListResponse>(`/price-list?${query ? objToQuery(query) : ''}`);
}

export async function getPriceListById(id: string | number) {
  return await api.get<GetPriceListById>(`/price-list/${id}`);
}

export async function getDigitalPriceList(
  priceListId: string | number,
  query?: GetProductPriceListQueries,
) {
  return await api.get<GetDigitalPriceListResponse>(
    `/price-list/${priceListId}/product/digital?${
      query
        ? objToQuery({
            ...query,
            per_page: 10,
          })
        : ''
    }`,
  );
}

export async function getPhysicalPriceList(
  priceListId: string | number,
  query?: GetProductPriceListQueries,
) {
  return await api.get<GetPhysicalPriceListResponse>(
    `/price-list/${priceListId}/product/physical?${query ? objToQuery(query) : ''}`,
  );
}

export async function getComboPriceList(
  priceListId: string | number,
  query?: GetProductPriceListQueries,
) {
  return await api.get<GetComboPriceListResponse>(
    `/price-list/${priceListId}/product/combo?${query ? objToQuery(query) : ''}`,
  );
}

export async function getProductRelatedPriceList(priceListId: string | number) {
  return await api.get<GetOperationRelatedPriceList[]>(`/price-list/${priceListId}/operations`);
}

export async function postProductList(payload: CreatePriceList) {
  return await api.post('/price-list', payload);
}

export async function postPrice(payload: CreatePrice) {
  return await api.post<{ message: string } | undefined>('/price-list/product/price', payload);
}

export async function putRelateOperationToPriceList(
  priceListId: string | number,
  operationsId: (string | number)[],
) {
  return await api.put(`/price-list/${priceListId}/operations`, {
    operationsId,
  });
}

export async function patchStatusPriceList(id: string | number) {
  return await api.patch(`/price-list/${id}/update-status`);
}

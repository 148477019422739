import { ComponentProps, memo, SyntheticEvent, useMemo } from 'react';

import { UnAutocomplete } from 'components';

import { AcquirerType, GetAllAcquirerByOperationResponse } from 'types/acquire';

type RootAutoCompletoProps = Pick<
  ComponentProps<typeof UnAutocomplete>,
  | 'label'
  | 'placeholder'
  | 'returnAllOption'
  | 'disabled'
  | 'value'
  | 'optionLabel'
  | 'optionValue'
  | 'returnAllOption'
  | 'hidden'
  | 'isOptionEqualToValue'
>;

interface DefaultValue {
  name: string;
  id: any;
}

interface UnAcquirerProps extends RootAutoCompletoProps {
  defaultValue?: DefaultValue;
  onChange?: (value: AcquirerType | null) => void;
  name: string;
  operationId: number;
  acquirers?: GetAllAcquirerByOperationResponse;
}

function BaseUnAcquirer({
  label,
  name,
  value,
  optionValue = 'id',
  optionLabel = 'name',
  returnAllOption,
  onChange,
  acquirers,
  ...props
}: UnAcquirerProps) {
  const options = useMemo(() => acquirers?.acquirers || [], [acquirers]);

  const handleSelect = async (event: SyntheticEvent, value?: AcquirerType) => {
    onChange?.(value || null);
  };

  return (
    <UnAutocomplete
      {...props}
      name={name}
      label={label || 'labels:input.acquirer'}
      optionLabel={optionLabel}
      optionValue={optionValue}
      returnAllOption={returnAllOption}
      options={value?.id || !value ? options : []}
      onChange={handleSelect}
      contained
      value={value}
    />
  );
}

export const UnAcquirer = memo(BaseUnAcquirer);

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

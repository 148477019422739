import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { format } from 'date-fns';

import {
  Column,
  DateFilter,
  Icon,
  OperationFilter,
  PaginatedTable,
  StatusFilter,
  Tag,
  Tooltip,
  UserFilter,
} from 'components';
import { Menu } from '../components/Menu';

import { Flex } from 'styles/Flex';
import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';

import { getQueryParams, pushHistoryQuery, queryToObj } from 'utils/query';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { getCashDesk } from 'services/cashDesk';

import { Meta } from 'types/api';
import { CashDesk, GetCashDeskQueries } from 'types/cashDesk';

import { CASH_COLUMNS_LISTING, CASH_DESK_STATUS } from 'constants/cashDesk';
import { resolvePath, routePaths } from 'constants/routes';

const baseMeta = {
  total: 0,
  last_page: 0,
};

type StatusFilterProps = Pick<ComponentProps<typeof Tag>, 'variante'>;

export function ListCashDesk({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/cashDesk');
  const { t } = useTranslation();

  const [details, setDetails] = useState<CashDesk[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  type SearchResult = {
    id: string;
    name: string;
  };
  function searchItem(queryObj: any, key: string, nameKey: string): SearchResult[] {
    const results: SearchResult[] = [];
    if (queryObj[key]) {
      results.push({
        id: queryObj[key],
        name: queryObj[nameKey] || '',
      });
    }
    return results;
  }
  const queryObj = queryToObj(history.location.search);
  const results: SearchResult[] = [
    ...searchItem(queryObj, 'operation_id', 'operation_name'),
    ...searchItem(queryObj, 'client_id', 'client_name'),
    ...searchItem(queryObj, 'supplier_id', 'supplier_name'),
  ];
  let defaultOperationFilter: SearchResult | undefined;
  if (results.length > 1) {
    defaultOperationFilter = results[0];
  }
  const defaultUserFilter = getQueryParams(['user_id', 'user_name'])?.user_id
    ? {
        name: getQueryParams(['user_id', 'user_name'])?.user_name as string,
        id: getQueryParams(['user_id', 'user_name'])?.user_id as string,
      }
    : undefined;

  const defaultStatusFilter = queryToObj(history.location.search)?.status;
  const current = new Date();
  const defaultOpenedAtFilter = format(current, 'MM/dd/yyyy');
  const getDetails = async (query?: GetCashDeskQueries) => {
    toggleIsLoading();

    try {
      const { data } = await getCashDesk(query);

      setDetails(data.data);
      setMeta(data.meta);
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const columns: Column[] = [
    {
      field: CASH_COLUMNS_LISTING.user,
      headerName: tPage('listing.table.user'),
    },
    {
      field: CASH_COLUMNS_LISTING.operation,
      headerName: tPage('listing.table.operation'),
    },
    {
      field: CASH_COLUMNS_LISTING.opened,
      headerName: tPage('listing.table.opened'),
    },
    {
      field: 'status',
      headerName: '',
    },
    {
      field: CASH_COLUMNS_LISTING.hasDiffLastCashDeskFund,
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const statusVariantMap = {
    [CASH_DESK_STATUS.opened]: 'success',
    [CASH_DESK_STATUS.closed]: 'error',
    [CASH_DESK_STATUS.checked]: 'blue',
    default: 'success',
  };

  const rows = useMemo(
    () =>
      details.map(detail => ({
        id: detail?.id,
        operationId: detail?.operation.id,
        [CASH_COLUMNS_LISTING.user]: detail.user.fullName,
        [CASH_COLUMNS_LISTING.operation]: detail.operation.name,
        [CASH_COLUMNS_LISTING.opened]: t('dateHours', { date: new Date(detail?.opened_at || '') }),
        [CASH_COLUMNS_LISTING.hasDiffLastCashDeskFund]: !detail.hasDiffLastCashDeskFund ? null : (
          <Tooltip title={tPage('detail.initialCashFundDiff') as String}>
            <Flex justifyContent="center">
              <Icon name="infoOutline" color="danger" size={1.3} />
            </Flex>
          </Tooltip>
        ),
        menu: (
          <Flex justifyContent="flex-end">
            <Menu
              id={detail?.id}
              operationId={detail?.operation_id}
              status={detail?.status}
              onDelete={() => getDetails(historyQuery)}
              onEdit={() => getDetails(historyQuery)}
              history={history}
            />
          </Flex>
        ),
        status: (
          <Tag
            label={tPage(`listing.status.${detail.status}`)}
            variante={
              (statusVariantMap[detail.status] ||
                statusVariantMap.default) as StatusFilterProps['variante']
            }
          />
        ),
      })),
    [details],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as GetCashDeskQueries;

      getDetails(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getDetails(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <h1>{tPage('listing.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <DefaultStyles.SelectContainer>
                <OperationFilter defaultValue={defaultOperationFilter} />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <UserFilter
                  placeholder="pages/cashDesk:listing.filter.user"
                  defaultValue={defaultUserFilter}
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <DateFilter
                  defaultValue={defaultOpenedAtFilter}
                  placeholder="pages/cashDesk:listing.filter.date"
                  keyQuery="opened_at"
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <StatusFilter
                  defaultValue={defaultStatusFilter}
                  options={[
                    { label: t('commons:conferred'), value: CASH_DESK_STATUS.checked },
                    { label: t('commons:open'), value: CASH_DESK_STATUS.opened },
                    { label: t('commons:close'), value: CASH_DESK_STATUS.closed },
                  ]}
                />
              </DefaultStyles.SelectContainer>
            </Styles.InputFields>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) => {
              console.log(row);

              history.push(
                resolvePath(routePaths.cashDesk.details, {
                  id: row.id,
                  operationId: row.operationId,
                }),
              );
            }}
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  height: 2.25rem;
  width: 4.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: filter 0.2s;

  :hover {
    filter: brightness(95%);
  }
`;

export const pBox = styled.p`
  color: #0b91f5;
  font-weight: 700;
  font-family: Montserrat, '--apple-system', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

import { useCallback, useEffect, useState } from 'react';
import { getInventoryProductsOperation } from 'services/operation';
import { getProductList } from 'services/product';
import { EntityStatus } from 'types/common';
import { GetProductAllList, ProductInventoryOperation, ProductType } from 'types/product';
import { objToQuery } from 'utils/query';

interface Props {
  getOnInit?: boolean;
  operationId?: number | string;
}

interface SearchParams {
  search?: string;
  types?: ProductType[];
  status?: EntityStatus;
}

export const useProducts = ({ getOnInit, operationId }: Props = {}) => {
  const [products, setProducts] = useState<GetProductAllList[] | ProductInventoryOperation[]>([]);
  const [excludedIds, setExcludedIds] = useState<(number | string)[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(!!getOnInit);

  const searchProducts = useCallback(
    async (params?: SearchParams) => {
      setIsLoading(true);
      try {
        const paramsToQuery = params
          ? objToQuery({ ...params, type: params.types ? params.types.join(',') : '' })
          : '';

        const response = operationId
          ? await getInventoryProductsOperation(operationId, paramsToQuery)
          : await getProductList(paramsToQuery);

        setProducts(response.data.filter(product => !excludedIds.includes(product.id)));
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [operationId, excludedIds],
  );

  const cleanProducts = useCallback(() => {
    setProducts([]);
  }, []);

  useEffect(() => {
    getOnInit && searchProducts();
  }, []);

  useEffect(() => {
    products.length > 0 &&
      setProducts(prev => prev.filter(product => !excludedIds.includes(product.id)));
  }, [excludedIds]);

  return {
    products,
    isLoading,
    searchProducts,
    cleanProducts,
    setExcludedIds,
  };
};

import { memo, ReactNode } from 'react';

import { IconButton, RootModal } from 'components';

import * as Styles from './styles';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { Flex } from 'styles/Flex';

interface ListModalProps {
  title: string;
  children: ReactNode;
  data: string[];
}

function BaseListModal({ title, data, children }: ListModalProps) {
  const [isOpen, toggleIsOpen] = useBooleanToggle(false);

  const renderItem = data?.map((value, index) => <Styles.Li key={index}>{value}</Styles.Li>);

  return (
    <div onClick={event => event.stopPropagation()}>
      <RootModal open={isOpen} onClose={toggleIsOpen}>
        <Styles.Container>
          <Flex justifyContent="space-between" alignItems="center">
            <Styles.Title>{title}</Styles.Title>
            <IconButton onClick={toggleIsOpen} icon={{ name: 'close' }} />
          </Flex>
          <Styles.Ul>{renderItem}</Styles.Ul>
        </Styles.Container>
      </RootModal>
      <Styles.Content onClick={toggleIsOpen}>{children}</Styles.Content>
    </div>
  );
}

export const ListModal = memo(BaseListModal);

import { isInstallment, isNotIntegrationPayment } from 'constants/cashDesk';
import * as Yup from 'yup';
import { invalidCpf, minOnePayment, requiredField } from './utils/messages';
import { validateCpf } from './utils/validateCpf';

export const checkCashdesk = Yup.object({
  value_checked: Yup.string().required(requiredField),
});

const payment = Yup.object({
  value: Yup.string().required(requiredField),
  type: Yup.string().required(requiredField),
  card_flag: Yup.mixed().when('type', (value, schema) =>
    !isNotIntegrationPayment.includes(value) ? schema.required(requiredField) : schema,
  ),
  acquirer: Yup.mixed().when('type', (value, schema) => {
    return !isNotIntegrationPayment.includes(value) ? schema.required(requiredField) : schema;
  }),
  authorization: Yup.string().when('type', (value, schema) =>
    !isNotIntegrationPayment.includes(value) ? schema.required(requiredField) : schema,
  ),
  installment: Yup.string()
    .nullable()
    .when('type', (value, schema) =>
      isInstallment.includes(value) ? schema.required(requiredField) : schema,
    ),
});

export const paymentSchemaValidation = Yup.object({
  client_name: Yup.string().required(requiredField),
  client_phone: Yup.string().required(requiredField),
  client_email: Yup.string(),
  client_cpf: Yup.string()
    .nullable()
    .test('cpfClient', invalidCpf, value =>
      value ? validateCpf(value.replace(/\D/g, '') || '') : true,
    ),
  payments: Yup.array(payment).min(1, minOnePayment),
});

import { AutocompleteInputChangeReason } from '@mui/core';
import { Autocomplete, Button, Icon } from 'components';
import { routePaths } from 'constants/routes';
import { useToggle } from 'hooks/useToggle';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getDashPDV } from 'services/dash';
import { getOperationsList } from 'services/operation';
import { DashBase } from 'types/dash';
import { SimpleOperationList } from 'types/operation';
import { debounce } from 'utils';
import { objToQuery } from 'utils/query';
import { LoadingMessage } from '..';

import * as HomeStyles from '../../styles';

interface IProps {}

const PDVComponent: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [toggles, setToggles] = useToggle<'loadingData' | 'loadingOperations'>({
    loadingData: true,
    loadingOperations: false,
  });
  const [data, setData] = useState<DashBase>({
    actives: 0,
    inatives: 0,
    total: 0,
  });
  const [operations, setOperations] = useState<SimpleOperationList[]>([]);
  const [selectedOperation, setSelectedOperation] = useState<SimpleOperationList>();

  const handleRedirect = useCallback(
    (route: string) => {
      if (route === routePaths.pdv.list) {
        const path = `${routePaths.pdv.list}?${objToQuery({
          operation_name: selectedOperation?.name,
          operation_id: selectedOperation?.id,
        })}`;

        history.push(path);

        return;
      }

      history.push(route);
    },
    [selectedOperation],
  );

  const handleGetData = useCallback(async (operation?: SimpleOperationList) => {
    setSelectedOperation(operation);

    try {
      setToggles('loadingData', true);

      const response = await getDashPDV(objToQuery({ operation_id: operation?.id }));

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
    } finally {
      setToggles('loadingData', false);
    }
  }, []);

  const handleSearchOperations = useCallback(
    async (e, value: string, reason: AutocompleteInputChangeReason) => {
      setToggles('loadingOperations', true);
      try {
        if (reason === 'input') {
          const response = await getOperationsList({ search: value });

          setOperations(response.data);
        }
      } catch (error) {
      } finally {
        setToggles('loadingOperations', false);
      }
    },
    [],
  );

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <HomeStyles.CardContainer>
      <h2>{t('pages/home:pdv.title')}</h2>

      {toggles.loadingData && <LoadingMessage title={t('pages/home:pdv.title')} />}

      <Autocomplete
        label="labels:input.operation"
        noOptionsText="autocomplete.typeToSearch"
        optionLabel="name"
        optionValue="id"
        clearHelperTextSpacing
        onInputChange={debounce(handleSearchOperations)}
        filterOptions={x => x}
        options={operations}
        onChange={(ev, newOption) => {
          newOption && handleGetData(newOption);
        }}
      />

      <HomeStyles.StatusContainer>
        <HomeStyles.StatusCard>
          <h3>{data.total}</h3>

          <span>{t('pages/home:labels.total')}</span>
        </HomeStyles.StatusCard>

        <HomeStyles.StatusCard>
          <h3>{data.actives}</h3>

          <span>{t('pages/home:labels.active')}</span>
        </HomeStyles.StatusCard>

        <HomeStyles.StatusCard>
          <h3>{data.inatives}</h3>

          <span>{t('pages/home:labels.inactive')}</span>
        </HomeStyles.StatusCard>
      </HomeStyles.StatusContainer>

      <HomeStyles.ButtonsContainer>
        <Button onClick={() => handleRedirect(routePaths.pdv.create)}>
          <Icon name="add" size={1.5} marginRight={0.5} marginLeft={-0.5} />

          {t('pages/home:buttons.register')}
        </Button>

        <Button outline onClick={() => handleRedirect(routePaths.pdv.list)}>
          {t('pages/home:buttons.detail')}
        </Button>
      </HomeStyles.ButtonsContainer>
    </HomeStyles.CardContainer>
  );
};

export const PDV = memo(PDVComponent);

import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import {
  PaginatedTable,
  Button,
  Tag,
  StatusFilter,
  SearchFilter,
  SelectProductType,
  SearchProductByNameFilter,
} from 'components';
import { Menu } from '../components/Menu';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';

import { getProducts as getProductsApi } from 'services/product';

import { queryToObj, pushHistoryQuery } from 'utils/query';

import { ApiQueriesProduct, Meta } from 'types/api';
import { GetProductData } from 'types/product';

import useBooleanToggle from 'hooks/useBooleanToggle';
import { PRODUCT_TYPES } from 'constants/product';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListProduct({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/listing');
  const { t } = useTranslation();

  const [products, setProducts] = useState<GetProductData[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [isVisibleContainFilter, setIsVisibleContainFilter] = useState(false);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getProducts = async (query?: ApiQueriesProduct) => {
    toggleIsLoading();

    try {
      const { data } = await getProductsApi(query);
      setProducts(data.data);
      setMeta(data.meta);
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const columns = [
    {
      field: 'id',
      orderable: true,
      headerName: tPage('product.table.id'),
    },
    {
      field: 'name',
      orderable: true,
      headerName: tPage('product.table.name'),
    },
    {
      field: 'type',
      headerName: tPage('product.table.type'),
    },
    {
      field: 'created_at',
      headerName: tPage('product.table.createdAt'),
      orderable: true,
    },
    {
      field: 'status',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const rows = useMemo(
    () =>
      products.map(product => ({
        id: product?.id,
        name: product?.name,
        type: tPage(`product.types.${product.type}`),
        created_at: t('date', { date: new Date(product?.created_at || '') }),
        menu: (
          <Menu
            id={product?.id}
            status={product?.status}
            onDelete={() => getProducts(historyQuery)}
            onEdit={() => getProducts(historyQuery)}
            history={history}
          />
        ),
        status: (
          <Tag
            label={product?.status === 0 ? 'Inativo' : 'Ativo'}
            variante={product.status === 0 ? 'inactive' : 'success'}
          />
        ),
      })),
    [products],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as ApiQueriesProduct;

      getProducts(query);

      if (query.type === PRODUCT_TYPES.combo) {
        setIsVisibleContainFilter(true);
        return;
      }
      setIsVisibleContainFilter(false);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getProducts(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <h1>{tPage('product.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <SearchFilter
                defaultValue={(queryToObj(history.location.search)?.search ?? '') as any}
              />
              <DefaultStyles.SelectContainer>
                <SelectProductType
                  defaultValue={historyQuery.type ? String(historyQuery.type) : undefined}
                  placeholder="pages/listing:product.filters.type"
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <StatusFilter defaultValue={historyQuery?.status} />
              </DefaultStyles.SelectContainer>
              {isVisibleContainFilter && (
                <DefaultStyles.SelectContainer>
                  <SearchProductByNameFilter
                    types={['PHYSICAL', 'DIGITAL']}
                    placeholder="pages/products:listing.filters.product"
                    queryValue="id"
                    queryKey="productId"
                  />
                </DefaultStyles.SelectContainer>
              )}
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => history.push(routePaths.products.create)}
            >
              Cadastrar
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.products.details, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

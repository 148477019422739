import { UnSwitch, UnCheckbox } from 'components';
import { Accordion } from 'components/Accordion';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'types/permission';

import * as Styles from './styles';

interface Props {
  permission: Permission & { checked?: boolean };
  disabled?: boolean;
}

const PermissionAccordionComponent = ({ permission, disabled }: Props): JSX.Element => {
  const [tPage] = useTranslation('pages/user');
  const [allowed, setAllowed] = useState(permission.checked || false);

  const handleChangeAllowed = useCallback((e, value: boolean) => {
    setAllowed(value);
  }, []);

  return (
    <Styles.Container>
      <Accordion.Container>
        <Accordion.Header>
          <Styles.Header>
            {tPage(`create.permissionsGroups.${permission.group}`)}
            <UnSwitch
              leftLabel
              name="group"
              returnValue
              value={permission.group}
              label={`pages/user:create.${allowed ? 'allowed' : 'notAllowed'}`}
              onClick={e => e.stopPropagation()}
              onChange={handleChangeAllowed}
              checked={allowed}
              disabled={disabled}
            />
          </Styles.Header>
        </Accordion.Header>

        <Accordion.Body>
          <Styles.Body>
            {permission.modules.map((module, index) => (
              <UnCheckbox
                name={`modules[${index}]`}
                key={`module-${index}`}
                label={`pages/user:create.permissionsLabels.${module}`}
                value={module}
                returnValue
                disabled={disabled || !allowed}
                clearHelperTextSpacing
              />
            ))}
          </Styles.Body>
        </Accordion.Body>
      </Accordion.Container>
    </Styles.Container>
  );
};

export const PermissionAccordion = memo(PermissionAccordionComponent);

import * as Yup from 'yup';
import { invalidCpf, requiredField } from './utils/messages';
import { validateCpf } from './utils/validateCpf';

export const createUserValidationSchema = Yup.object().shape({
  typeId: Yup.number().required(requiredField).typeError(requiredField),
  fullName: Yup.string().required(requiredField),
  phone: Yup.string().required(requiredField),
  email: Yup.string().required(requiredField),
  cpf: Yup.string()
    .test('validate-cpf', invalidCpf, value => validateCpf(value || ''))
    .required(requiredField),
});

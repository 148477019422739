import { memo } from 'react';

import useBooleanToggle from 'hooks/useBooleanToggle';
import { ConfirmModal, IImage } from 'components';

import * as Styles from './styles';

interface PreviewProps {
  onDelete: () => void | Promise<void>;
  image: IImage;
}
function BasePreview({ onDelete, image }: PreviewProps) {
  const [isDeleteImage, toggleIsDeleteImage] = useBooleanToggle(false);

  const handleDelete = async () => {
    await onDelete?.();
    toggleIsDeleteImage();
  };
  return (
    <>
      <ConfirmModal
        title="Atenção"
        bodyText="Esta operação não pode ser desfeita!"
        open={isDeleteImage}
        onClose={toggleIsDeleteImage}
        onConfirm={handleDelete}
      />
      <Styles.PreviewContainer key={image.name}>
        <img src={image.url} />
        <Styles.DeleteContainer icon={{ name: 'close' }} onClick={toggleIsDeleteImage} />
      </Styles.PreviewContainer>
    </>
  );
}

export const Preview = memo(BasePreview);

// The messages are the key of translation in `locales/[locale]/formErrors.json`

export const requiredField = 'required';

export const passwordNotMatch = 'passwordNotMatch';

export const invalidPassword = 'invalidPassword';

export const invalidFormat = 'invalidFormat';

export const invalidEmail = 'invalidEmail';

export const invalidCnpj = 'invalidCnpj';

export const invalidDateBiggerThan = 'invalidDateBiggerThan';
export const mustBeBiggerThanInitialDate = 'mustBeBiggerThanInitialDate';

export const invalidDateBiggerThanCurrentDate = 'invalidDateBiggerThanCurrentDate';

export const invalidCpf = 'invalidCpf';

export const minBiggerMax = 'minBiggerMax';

export const maxLowerMin = 'maxLowerMin';

export const oneOf = 'oneOf';

export const minOneOperation = 'minOneOperation';
export const minOneProduct = 'minOneProduct';

export const minOneCardFlag = 'minOneCardFlag';

export const mustBuLessThan100Percent = 'mustBuLessThan100Percent';

export const minOnePayment = 'minOnePayment';

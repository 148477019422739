import { AuthorizeView, Button } from 'components';
import { routePaths } from 'constants/routes';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { getInventoryProductsOperation } from 'services/operation';
import { Flex } from 'styles/Flex';
import { ListOperation } from 'types/operation';
import { ProductInventoryOperation } from 'types/product';
import { ManageProducts, ProductsTable } from './components';

interface IProps {
  id: string;
  history: RouteComponentProps['history'];
  operation: Partial<ListOperation>;
}

const InventoryComponent = ({ id, history, operation }: IProps): JSX.Element => {
  const [tPage] = useTranslation('pages/operations', { keyPrefix: 'details.inventoryTab' });
  const [products, setProducts] = useState<ProductInventoryOperation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getInventoryProductsOperation(id);

      setProducts(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  const postCreate = useCallback(() => {
    getProducts();
  }, []);

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Flex column gap={2}>
      <Flex>
        <Flex marginLeft="auto">
          <AuthorizeView permission="INVENTORY_MANAGE_MANAGE_ALL">
            <Button
              outline
              onClick={() =>
                history.push(
                  `${routePaths.inventory.create}?operationId=${id}&operationName=${operation.operation?.name}`,
                )
              }
            >
              {tPage('entry')}
            </Button>
          </AuthorizeView>
        </Flex>
      </Flex>
      <ManageProducts postCreate={postCreate} id={id} />

      <ProductsTable
        products={products}
        isLoading={isLoading}
        operationId={id}
        updateCreate={postCreate}
      />
    </Flex>
  );
};

export const Inventory = memo(InventoryComponent);

import { useCallback, useState } from 'react';

export type BooleanObj<KeyType extends keyof any = string> = Record<KeyType, boolean>;

export function useBooleanObj<T extends BooleanObj<keyof T> = BooleanObj>(
  initialState: T,
): [T, (prop: keyof T, value?: boolean) => void] {
  const [booleanObj, setBooleanObj] = useState<T>(initialState);

  const handleSetProp = useCallback<(prop: keyof T, value?: boolean) => void>((prop, value) => {
    setBooleanObj(prev => ({ ...prev, [prop]: value ?? !prev[prop] }));
  }, []);

  return [booleanObj, handleSetProp];
}

import { LoadingMessage } from 'components';
import { ACQUIRE_CARD_FLAG_TYPE } from 'constants/acquire';
import { routePaths } from 'constants/routes';
import useBooleanToggle from 'hooks/useBooleanToggle';
import {
  createContext,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { getAcquirerById, postAcquire, putAcquirer } from 'services/acquire';
import { CreateAcquirer, Installment, InstallmentById } from 'types/acquire';
import { openNotification } from 'utils/notification';
import { DetailsBaseForm, DetailTax } from '.';

interface FormProviderProps {
  children: ReactNode;
}

interface Forms {
  base: Partial<DetailsBaseForm>;
  table: Partial<DetailTax>;
}

interface Data {
  base: DetailsBaseForm;
  table: DetailTax;
}

type FormTypes = keyof Forms;

interface FormProviderData {
  data: Partial<Forms>;
  isLoading?: boolean;
  saveForm: (formName: FormTypes, data: any) => void;
  onSubmit: () => void;
  setListOperationDeleted: (value: SetStateAction<number[]>) => void;
  setListCardFlagDeleted: (value: SetStateAction<number[]>) => void;
  addTaxToDeletedList: (installmentValue: number, type: string) => void;
  removeTaxFromDeletedList: (installmentValue: number, type: string) => void;
}

const FormContext = createContext<FormProviderData | undefined>(undefined);

/**
 *
 * default values for the data form of the installment, so the array coming from the API is compared line by line by
 * type and installment, filling in the correct amount for the correct installment
 */
const baseInstallments = [
  { installment: 1, type: ACQUIRE_CARD_FLAG_TYPE.DEBIT },
  { installment: 1, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 2, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 3, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 4, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 5, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 6, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 7, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 8, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 9, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
  { installment: 10, type: ACQUIRE_CARD_FLAG_TYPE.CREDIT },
] as Installment[];

function BaseFormProvider({ children }: FormProviderProps) {
  const [t] = useTranslation();
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [isInitialLoading, toggleIsInitialLoading] = useBooleanToggle(true);

  const [listOperationDeleted, setListOperationDeleted] = useState<number[]>([]);
  const [listCardFlagDeleted, setListCardFlagDeleted] = useState<number[]>([]);
  const [listTaxDeleted, setLisTaxDeleted] = useState<number[]>([]);

  const [installments, setInstallments] = useState<InstallmentById[]>([]);

  const [data, setData] = useState<Partial<Forms>>({});

  const getInitialData = async (id: string | number) => {
    try {
      const { data } = await getAcquirerById(id);

      const mapInstallment = (
        defaultInstallment: Installment[],
        installments: InstallmentById[],
      ) => {
        const result = defaultInstallment.map(installment => {
          const result =
            installments.find(
              value =>
                Number(value.installment) === installment.installment &&
                value.type === installment.type,
            ) || installment;

          return result;
        });

        return result;
      };

      const form: Partial<Forms> = {
        base: {
          card_flags: data.card_flags,
          cnpj: data.cnpj,
          name: data.name,
          operations: data.operations?.map(value => ({
            id: value.id,
            name: value.name,
          })),
        },
        table: {
          acquirer_vincule: data?.acquirer_vincule?.map(value => ({
            operation_id: value.operation_id,
            card_flags: value.card_flags?.map(cardFlag => ({
              ...cardFlag,
              id: cardFlag.id,
              installments: mapInstallment(baseInstallments, cardFlag.installments),
            })),
          })),
          operations_value_required: data.operations?.map(value => value.value_required),
        },
      };

      const installments: InstallmentById[] = data?.acquirer_vincule
        ?.map(acquirer => {
          const installments = acquirer.card_flags.map(value => value.installments);

          return installments.reduce((prev, next) => [...prev, ...next]);
        })
        .reduce((prev, next) => [...prev, ...next]);

      setInstallments(installments);

      setData(form);
    } finally {
      toggleIsInitialLoading();
    }
  };

  const removeInstallmentFromDeletedList = (installmentValue: number, type: string) => {
    const installment = installments.find(
      value => Number(value.installment) === installmentValue && value.type === type,
    );

    if (!installment) return;

    setLisTaxDeleted(prevState => prevState.filter(value => value !== installment.id));
  };

  const addInstallmentToDeletedList = (installmentValue: number, type: string) => {
    const installment = installments.find(
      value => Number(value.installment) === installmentValue && value.type === type,
    );

    if (!installment) return;

    setLisTaxDeleted(prevState => [...prevState, installment.id]);
  };

  const handleSaveForm = useCallback((formName: FormTypes, payload: any) => {
    setData(prevState => ({
      ...prevState,
      [formName]: payload,
    }));
  }, []);

  const handleSubmit = async () => {
    try {
      toggleIsLoading();

      const _data = {
        ...data,
      } as Data;

      const payload: CreateAcquirer = {
        name: _data.base.name,
        cnpj: _data.base.cnpj,
        operations: _data.base.operations.map((value, index) => ({
          operation_id: value.id,
          value_required: _data.table.operations_value_required[index],
        })),
        acquirer_vincule: _data.table?.acquirer_vincule,
        card_flags: _data.base.card_flags.map(value => value.id),
      };

      !id
        ? await postAcquire(payload)
        : await putAcquirer(id, {
            ...payload,
            card_flags_deleted: listCardFlagDeleted.length > 0 ? listCardFlagDeleted : undefined,
            operations_deleted: listOperationDeleted.length > 0 ? listOperationDeleted : undefined,
            fees_deleted: listTaxDeleted.length > 0 ? listTaxDeleted : undefined,
          });

      id ? openNotification(t('editMessage')) : openNotification(t('createMessage'));
      history.push(routePaths.acquire.list);
    } finally {
      toggleIsLoading();
    }
  };

  useEffect(() => {
    if (!id) return toggleIsInitialLoading();

    getInitialData(id);
  }, [id]);

  if (isInitialLoading) return <LoadingMessage text="Carregando..." />;

  return (
    <FormContext.Provider
      value={{
        data,
        isLoading,
        onSubmit: handleSubmit,
        saveForm: handleSaveForm,
        setListOperationDeleted,
        setListCardFlagDeleted,
        removeTaxFromDeletedList: removeInstallmentFromDeletedList,
        addTaxToDeletedList: addInstallmentToDeletedList,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export const useFormContext = () => useContext(FormContext);

export const FormProvider = memo(BaseFormProvider);

import styled from 'styled-components';

export const LayoutPrincipal = styled.div`
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const BackgroundImage = styled.div`
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

export const TabsBox = styled.div`
border-radius: ${({ theme }) => theme.borderRadius.md};
background-color: ${({ theme }) => theme.colors.foreground};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
display: block;
max-width: 32.125rem;
max-height: 85%;
min-height: 33rem;
min-width: 30%;
padding: 1rem;
text-align: center;
width: 100%;
z-index: 2;

  > h1 {
    font-size: 1.125rem;
  }

  @media only screen and (max-width: 300px) and (max-height: 660px) {
    max-height: 80%;
  }

  @media only screen and (max-width: 300px) and (min-height: 670px) {
    max-height: 65%;
  }

  @media only screen and (min-width: 768px) {
    max-height: 40%;
  }

  @media only screen and (max-width: 1920px) and (max-height: 1080px) {
    margin-bottom: 4rem;
  })
`;

export const H1 = styled.h1`
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  width: 100%;
`;

export const DivObservacoes = styled.div`
  height: 52px;
  overflow: auto;
`;

export const inputUploadPhotoSua = styled.input`
  display: none;
`;

export const inputUploadPhotoQRCode = styled.input`
  display: none;
`;

export const ButtonUploadPhotoSua = styled.button`
  align-content: end;
  background: #0b91f5;
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: auto;
  margin-bottom: 6px;
  padding: 6px;
  width: 100%;
  &:hover {
    background-color: #07233c;
  }
`;

export const ButtonUploadPhotoQRCode = styled.button`
  align-content: end;
  background: #0b91f5;
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: auto;
  margin-bottom: 6px;
  padding: 6px;
  width: 100%;
  &:hover {
    background-color: #07233c;
  }
`;

export const TextUpload = styled.p`
  color: rgb(255, 255, 255);
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: auto;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
`;

export const ButtonSend = styled.button`
  background: #0b91f5;
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  height: auto;
  min-height: 30px;
  padding: 6px;
  width: 100%;
  &:hover {
    background-color: #07233c;
  }
`;

export const DisabledButton = styled.button`
  background: rgba(11, 145, 245, 0.5);
  border: none;
  border-radius: 10px;
  color: rgba(255, 255, 255, 1);
  cursor: not-allowed;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  height: auto;
  min-height: 25px;
  padding: 6px;
  width: 100%;
  &:hover {
    background-color: rgba(7, 35, 60, 0.5);
  }
`;

export const divBotaoVoltar = styled.div`
  bottom: 6px;
  left: 6px;
  position: fixed;
  z-index: 1;

  .btn-back {
    text-transform: lowercase;
  }

  .btn-back span::first-letter {
    text-transform: uppercase;
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const closeButtonDiv = styled.div`
  text-align: center;
`;

export const closeTab = styled.div`
  float: right;
`;

export const sliderStyles = styled.div`
  #slider {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
  }

  #slider img:not(.regularImages) {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 3500ms;
    transform-origin: 50%, 50%;
  }

  #slider img.selected {
    opacity: 1;
  }
`;

export const loaderDiv = styled.div`
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  justify-content: center;
  max-width: 28rem;
  padding: 0.8rem;
  position: absolute;
  text-align: center;
  top: 35%;
  width: 100%;
`;

export const clockCloud = styled.div`
  height: 80px;
  left: 50%;
  margin-top: 4rem;
  position: relative;
  transform: translate(-50%, -50%);
  width: 240px;
`;

export const H1Loading = styled.h1`
  color: rgba(0, 0, 0, 1);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

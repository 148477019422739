import { IconButton } from 'components';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const DropZone = styled.div<{ isDragging: boolean; disabled?: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 2px dashed
    ${({ theme, isDragging }) => (isDragging ? theme.colors.text600 : theme.colors.text400)};
  background-color: ${({ theme }) => theme.colors.background600};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  transition: border-color 0.2s;
  color: ${({ theme }) => theme.colors.text600};
  height: 9rem;
  width: 9rem;
  text-align: center;
  padding: 1rem;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  flex: none;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
`;

export const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  height: 9rem;
  width: 9rem;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  flex: none;
`;

export const DeleteContainer = styled(IconButton)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.foreground} !important;
  border-radius: 50%;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.2rem;

  :hover {
    filter: brightness(90%);
  }
`;

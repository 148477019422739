import { useState, SyntheticEvent, memo, ComponentProps } from 'react';

import { UnAutocomplete } from 'components';

import { debounce } from 'utils';

import { getOperationsList as getOperationsListApi } from 'services/operation';

type RootAutoCompletoProps = Pick<
  ComponentProps<typeof UnAutocomplete>,
  'label' | 'placeholder' | 'returnAllOption' | 'disabled' | 'value'
>;

interface DefaultValue {
  name: string;
  id: any;
}

interface Operation {
  name: string;
  id: number;
}

interface OperationFilterProps extends RootAutoCompletoProps {
  defaultValue?: DefaultValue;
  onChange?: (operation: Operation) => void;
  name: string;
  onValidate?: (operation: Operation) => Promise<void>;
  onSelect?: (operation?: Operation | null) => void;
}

function BaseUnOperation({
  defaultValue,
  placeholder,
  name,
  value,
  returnAllOption,
  onSelect,
  ...props
}: OperationFilterProps) {
  const [operations, setOperations] = useState<Operation[]>([]);

  const handleSelectOperation = async (event: SyntheticEvent, operation?: Operation) => {
    onSelect?.(operation);
    if (!operation) return;

    props?.onChange?.({
      id: operation?.id,
      name: operation?.name,
    });
  };

  const getOperations = async (event: SyntheticEvent, value: string) => {
    try {
      const { data } = await getOperationsListApi({ search: value });

      setOperations(
        data.map(value => ({
          name: value.name,
          id: value.id,
        })),
      );
    } catch (err) {}
  };

  return (
    <UnAutocomplete
      name={name}
      placeholder={placeholder || 'labels:input.operation'}
      optionLabel="name"
      returnAllOption={returnAllOption}
      optionValue="id"
      options={operations}
      defaultValue={defaultValue}
      onInputChange={debounce(getOperations)}
      onChange={handleSelectOperation}
      contained
      value={value}
    />
  );
}

export const UnOperation = memo(BaseUnOperation);

import { useField } from '@unform/core';
import { ComponentProps, memo, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { PaymentMethodGroup } from '../PaymentMethodGroup';

type RadioGroupRootProps = ComponentProps<typeof PaymentMethodGroup>;

interface UnPaymentMethodGroupProps extends RadioGroupRootProps {
  name: string;
}

function BaseUnPaymentMethodGroup({ name, helperText, ...props }: UnPaymentMethodGroupProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [tErros] = useTranslation('formErrors');

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => ref?.current?.value,
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <PaymentMethodGroup
      defaultValue={defaultValue}
      {...props}
      helperText={error ? tErros(error) : helperText}
      ref={inputRef}
    />
  );
}

export const UnPaymentMethodGroup = memo(BaseUnPaymentMethodGroup);

import { Grid } from '@mui/material';
import { SubmitHandler } from '@unform/core';
import { Button, ConfirmModal, IconButton, LoadingMessage, Unform } from 'components';
import { routePaths } from 'constants/routes';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { getTouristGuideDetails, postTouristGuide, putTouristGuide } from 'services/touristGuide';
import { Flex } from 'styles/Flex';
import { PageContainer } from 'styles/PageContainer';
import { PageContent } from 'styles/PageContent';
import { GetTouristGuideDetails, PostTouristGuide } from 'types/touristGuide';
import { openNotification } from 'utils/notification';
import { createTouristGuideValidationSchema } from 'yupSchemas/touristGuide';
import { BankDataPart, GuideDataPart, VinculeOperationPart } from './components';

export const CreateTouristGuidePage = ({
  history,
  match,
}: RouteComponentProps<{ id?: string }>) => {
  const [tPage] = useTranslation('pages/touristGuide');
  const { t } = useTranslation();
  const [openCancelModal, toggleCancelModal] = useBooleanToggle();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [details, setDetails] = useState<GetTouristGuideDetails>();
  const guideId = useMemo(() => match.params.id, []);

  const handleSubmit = useCallback<SubmitHandler<PostTouristGuide>>(async data => {
    try {
      setIsSaving(true);

      const response = await (guideId ? putTouristGuide(guideId, data) : postTouristGuide(data));

      if (response.status === 200) {
        openNotification(tPage(`createGuide.${guideId ? 'updateSuccess' : 'createSuccess'}`));
        history.push(routePaths.touristGuide.list);
      }
    } finally {
      setIsSaving(false);
    }
  }, []);

  const handleGetDetails = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await getTouristGuideDetails(guideId as string);

      setDetails(response.data);

      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [guideId]);

  useEffect(() => {
    if (guideId) {
      handleGetDetails();
    }
  }, []);

  return (
    <PageContainer md>
      <Flex alignItems="center" gap={1}>
        <IconButton icon={{ name: 'arrowBack', size: 1.4 }} onClick={toggleCancelModal} />
        <h1>{tPage(`createGuide.${guideId ? 'updateTitle' : 'title'}`)}</h1>
      </Flex>

      <PageContent>
        {guideId && isLoading ? (
          <LoadingMessage text={tPage('createGuide.loadingDetails')} />
        ) : (
          <Unform
            validationSchema={createTouristGuideValidationSchema}
            onSubmit={handleSubmit}
            initialData={{
              ...details,
              operatorId: details?.operator,
              operationsAccessId: details?.operationsAccess,
            }}
          >
            <Grid container columnSpacing={3}>
              <GuideDataPart />

              <BankDataPart />

              <VinculeOperationPart />

              <Grid item xs={12}>
                <Flex justifyContent="flex-end" gap={1} marginTop={1}>
                  <Button onClick={toggleCancelModal} variant="cancel">
                    {tPage('createGuide.cancel')}
                  </Button>

                  <Button type="submit" isLoading={isSaving}>
                    {tPage('createGuide.save')}
                  </Button>
                </Flex>
              </Grid>
            </Grid>
          </Unform>
        )}
      </PageContent>

      <ConfirmModal
        open={openCancelModal}
        title={t('confirmCancelOperation.title')}
        bodyText={t('confirmCancelOperation.body')}
        onClose={toggleCancelModal}
        onConfirm={() => history.goBack()}
      />
    </PageContainer>
  );
};

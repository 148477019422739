import styled from 'styled-components';

interface LegendProps {
  marginTop?: number | 'auto';
  marginBottom?: number | 'auto';
  marginLeft?: number | 'auto';
  marginRight?: number | 'auto';
}

export const Legend = styled.legend<LegendProps>`
  font-size: ${({ theme }) => theme.fonts.sizes.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.text600};
  margin-top: ${props =>
    props.marginTop === 'auto' ? props.marginTop : `${props.marginTop || 0}rem`};
  margin-right: ${props =>
    props.marginRight === 'auto' ? props.marginRight : `${props.marginRight || 0}rem`};
  margin-bottom: ${props =>
    props.marginBottom === 'auto' ? props.marginBottom : `${props.marginBottom || 1}rem`};
  margin-left: ${props =>
    props.marginLeft === 'auto' ? props.marginLeft : `${props.marginLeft || 0}rem`};
`;

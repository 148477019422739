export const PRODUCT_TYPES = {
  combo: 'COMBO',
  physical: 'PHYSICAL',
  digital: 'DIGITAL',
};

export const PRODUCT_CATEGORY = {
  printedPhoto: 'Printed_Photo',
  magnet: 'Magnet',
  pictureFrame: 'Picture_Frame',
  keychain: 'Keychain',
  other: 'Other',
};

import { useRef, ComponentProps, useEffect } from 'react';
import { useField } from '@unform/core';
import { useTranslation } from 'react-i18next';

import { FieldArray, FieldArrayRef } from 'components/FieldArray';

interface FieldArrayProps extends ComponentProps<typeof FieldArray> {
  onGetValue?(options: any[]): any;
}

function BaseFieldArray({ helperText, onGetValue, ...props }: FieldArrayProps) {
  const ref = useRef<FieldArrayRef>(null);
  const { fieldName, registerField, error, defaultValue } = useField(props.name);
  const { t } = useTranslation('formErrors');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref,
      getValue: ref => {
        return onGetValue?.(ref?.current?.values) || ref?.current?.values;
      },
    });
  }, [defaultValue, fieldName, registerField, ref.current, onGetValue]);

  return (
    <FieldArray
      initialData={defaultValue}
      {...props}
      error={!!error}
      helperText={error ? t(error) : helperText}
      ref={ref}
    />
  );
}

export const UnFieldArray = BaseFieldArray;

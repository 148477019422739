import styled from 'styled-components';

export const ModalContainer = styled.div`
  min-width: 300px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

export const List = styled.ul`
  padding: 1rem;

  li {
    list-style: circle;
    padding: 0.5rem 0;
  }
`;

export const Title = styled.strong`
  font-size: 1.5rem;
`;

import { memo, useRef, useState } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import { Button, Unform } from 'components';

import { DetailsFormBase, GoalKeys, useGoalContext } from '../../..';

import { EntityGoal } from 'types/goal';

import { GOAL_OPERATION_TYPE } from 'constants/goal';

import { createDefaultGoalValidationSchema } from 'yupSchemas/goal';

import { RenderOperations } from '../../../RenderOperations';

import { RenderUsers } from '../../../RenderUsers';

import * as DefaultStyles from 'pages/Private/Goal/Create/styles';

import { Flex } from 'styles/Flex';

import { Masks } from '../../index';

interface DefaultGoalProps {
  isTheLast?: boolean;
  onBackward: () => void;
  mask?: Masks;
  type: GoalKeys;
  initialData?: Partial<EntityGoal>;
}

function BaseDefaultGoal({ isTheLast, onBackward, type, initialData, mask }: DefaultGoalProps) {
  const context = useGoalContext();
  const formRef = useRef<FormHandles>(null);

  const [details] = useState<Partial<EntityGoal>>(initialData || {});
  const [isSaved, setIsSaved] = useState(false);

  const handleSubmit: SubmitHandler<EntityGoal> = async payload => {
    try {
      context?.saveData?.(type, payload);
      setIsSaved(true);

      if (isTheLast && isSaved && context?.canSubmit) await context?.onSubmit(type, payload);
    } catch (err) {
      setIsSaved(false);
    }
  };

  return (
    <Unform
      onSubmit={handleSubmit}
      ref={formRef}
      validationSchema={
        context?.data?.base?.type === GOAL_OPERATION_TYPE.OPERATION
          ? createDefaultGoalValidationSchema('operations')
          : createDefaultGoalValidationSchema('users')
      }
      initialData={details}
    >
      {context?.data?.base?.type === GOAL_OPERATION_TYPE.OPERATION ? (
        <RenderOperations
          mask={mask}
          data={context?.data?.base?.operations}
          type={context?.data?.base?.[`${type}_award_type` as keyof DetailsFormBase] as string}
        />
      ) : (
        <RenderUsers
          mask={mask}
          data={context?.data?.base?.users}
          opeartionName={context?.data?.base?.operation?.name}
          type={context?.data?.base?.products_goal_award_type as string}
        />
      )}
      <hr style={{ marginTop: '5rem' }} />
      <DefaultStyles.Container>
        <Flex justifyContent="flex-end" gap={1}>
          <Button variant="cancel" disabled={context?.isLoading} onClick={onBackward}>
            Voltar
          </Button>
          <Button
            isLoading={context?.isLoading}
            variant={isTheLast && context?.canSubmit && isSaved ? 'green' : 'primary'}
            type="submit"
          >
            {isTheLast && context?.canSubmit && isSaved ? 'Enviar' : 'Salvar'}
          </Button>
        </Flex>
      </DefaultStyles.Container>
    </Unform>
  );
}

export const DefaultGoal = memo(BaseDefaultGoal);

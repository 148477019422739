import { Autocomplete, Button, Icon } from 'components';
import { routePaths } from 'constants/routes';
import { useToggle } from 'hooks/useToggle';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getDashOperations } from 'services/dash';
import { getOperationDistricts } from 'services/districts';
import { DashBase } from 'types/dash';
import { GeneralDistricts } from 'types/districts';
import { objToQuery } from 'utils/query';
import { LoadingMessage } from '../';

import * as HomeStyles from '../../styles';

interface IProps {}

const OperationComponent: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [toggles, setToggles] = useToggle<'loadingData' | 'loadingDistricts'>({
    loadingData: true,
    loadingDistricts: false,
  });
  const [data, setData] = useState<DashBase>({
    actives: 0,
    inatives: 0,
    total: 0,
  });
  const [districts, setDistricts] = useState<GeneralDistricts>([]);
  const [noOptionsText, setNoOptionsText] = useState<string>('autocomplete.defaultLoadingText');
  const districtOptions = useMemo(
    () => districts.map(district => ({ label: district, value: district })),
    [districts],
  );
  const [selectedDistrict, setSelectedDistrict] = useState<string>();

  const handleRedirect = useCallback(
    (route: string, isDetails?: boolean) => {
      if (isDetails && selectedDistrict) {
        history.push(`${route}?district=${selectedDistrict}`);

        return;
      }

      history.push(route);
    },
    [selectedDistrict],
  );

  const handleGetDistricts = useCallback(async () => {
    setToggles('loadingDistricts', true);

    try {
      const response = await getOperationDistricts();

      if (response.status === 200) {
        setDistricts(response.data);

        setNoOptionsText('autocomplete.defaultNoOptionsText');
      }
    } catch (error) {
    } finally {
      setToggles('loadingDistricts', false);
    }
  }, []);

  const handleGetData = useCallback(async (district?: string) => {
    setSelectedDistrict(district);

    try {
      setToggles('loadingData', true);
      const response = await getDashOperations(objToQuery({ district: district }));

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
    } finally {
      setToggles('loadingData', false);
    }
  }, []);

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <HomeStyles.CardContainer>
      <h2>{t('pages/home:operation.title')}</h2>

      {toggles.loadingData && <LoadingMessage title={t('pages/home:operation.title')} />}

      <Autocomplete
        label="labels:input.region"
        clearHelperTextSpacing
        options={districtOptions}
        noOptionsText={noOptionsText}
        onOpen={handleGetDistricts}
        loading={toggles.loadingDistricts}
        onChange={(ev, newOption) => {
          handleGetData(newOption?.value);
        }}
      />

      <HomeStyles.StatusContainer>
        <HomeStyles.StatusCard>
          <h3>{data.total}</h3>

          <span>{t('pages/home:labels.total')}</span>
        </HomeStyles.StatusCard>

        <HomeStyles.StatusCard>
          <h3>{data.actives}</h3>

          <span>{t('pages/home:labels.active')}</span>
        </HomeStyles.StatusCard>

        <HomeStyles.StatusCard>
          <h3>{data.inatives}</h3>

          <span>{t('pages/home:labels.inactive')}</span>
        </HomeStyles.StatusCard>
      </HomeStyles.StatusContainer>

      <HomeStyles.ButtonsContainer>
        <Button onClick={() => handleRedirect(routePaths.operation.create)}>
          <Icon name="add" size={1.5} marginRight={0.5} marginLeft={-0.5} />

          {t('pages/home:buttons.register')}
        </Button>

        <Button outline onClick={() => handleRedirect(routePaths.operation.list, true)}>
          {t('pages/home:buttons.detail')}
        </Button>
      </HomeStyles.ButtonsContainer>
    </HomeStyles.CardContainer>
  );
};

export const Operation = memo(OperationComponent);

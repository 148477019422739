import { ReportTypes, SellerToReport } from 'types/report';
import { objToQuery } from 'utils/query';
import api from './apis/api';

export const getReportTypes = () => api.get<string[]>('/report/types');

interface GetSellerToReportParams {
  initialDate: string;
  finalDate: string;
  operationId: string | number;
  fileFormat: number;
}
export const getSellerToReport = ({
  finalDate,
  initialDate,
  operationId,
}: GetSellerToReportParams) =>
  api.get<SellerToReport[]>(
    `/report/sales/sellers?initial_date=${initialDate}&final_date=${finalDate}&operation_id=${operationId}`,
  );

interface GetGenerateSellerReportParams {
  type: ReportTypes;
  /** Pattern: 2022-01-15T08:35:00.234Z */
  initial_date: string;
  /** Pattern: 2022-01-15T08:35:00.234Z */
  final_date: string;
  operation_id: string | number;
  user_id: string | number;
  file_format: number;
}

export const getGenerateSellerReport = (params: GetGenerateSellerReportParams) =>
  api.get(`/report/sales?${objToQuery(params)}`, { responseType: 'blob' });

export const getClosingReport = ({
  finalDate,
  initialDate,
  operationId,
  fileFormat,
}: GetSellerToReportParams) =>
  api.get<ClosingReportData[]>(
    `/closing?initial_date=${initialDate}&final_date=${finalDate}&operation_id=${operationId}&file_format=${fileFormat}`,
  );

// Define a type for the response data if needed
interface ClosingReportData {
  date: string;
  payment_value_sum: number;
}

interface GetPhotographerToReportParams {
  operationId: string | number;
  typeId: string | number;
}

export const getPhotographerToReport = ({ operationId, typeId }: GetPhotographerToReportParams) =>
  api.get<SellerToReport[]>(
    `/report/sales/photographer?operation_id=${operationId}&type_id=${typeId}`,
  );

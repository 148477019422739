import { PRODUCT_TYPES } from 'constants/product';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFile } from 'services/file';
import { getProductById as getProductByIdApi } from 'services/product';
import * as DefaultStyles from 'styles/Details';
import { Flex } from 'styles/Flex';
import { GetProductByIdResponse } from 'types/product';
import { Carrousel } from '..';
import { Combo } from './Combo';
import * as Styles from './styles';

interface InfoProps {
  id: string | number;
}

function BaseInfo(props: InfoProps) {
  const { t } = useTranslation();
  const [tPage] = useTranslation('pages/products');

  const [product, setProduct] = useState<Partial<GetProductByIdResponse>>({});
  const [images, setImages] = useState<string[]>([]);

  const getProductById = async (id: string | number) => {
    const { data } = await getProductByIdApi(id);

    const imageList: string[] = [];

    for (const file of data.images) {
      const image = await getFile(file.id);
      imageList.push(image);
    }

    setImages(imageList);
    setProduct(data);
  };

  useEffect(() => {
    getProductById(props.id);
  }, []);

  const products = {
    [PRODUCT_TYPES.digital]: (
      <Flex column gap={1}>
        <DefaultStyles.Label>Produto Overlay</DefaultStyles.Label>
        <DefaultStyles.Value>
          {product?.overlay ? t('labels:input.true') : t('labels:input.false')}
        </DefaultStyles.Value>
      </Flex>
    ),
    [PRODUCT_TYPES.combo]: <Combo products={product.products} />,
    [PRODUCT_TYPES.physical]: (
      <Flex gap={1} flex={1}>
        <Flex column flex={1} gap={1}>
          <DefaultStyles.Label>{tPage('log.printedProduct')}</DefaultStyles.Label>
          <DefaultStyles.Value>
            {product?.isPrinted ? t('labels:input.true') : t('labels:input.false')}
          </DefaultStyles.Value>
        </Flex>

        {product?.printDimension && (
          <Flex column flex={1} gap={1}>
            <DefaultStyles.Label>{tPage('log.printDimension')}</DefaultStyles.Label>
            <DefaultStyles.Value>{product?.printDimension}</DefaultStyles.Value>
          </Flex>
        )}
      </Flex>
    ),
  };

  return (
    <DefaultStyles.Card>
      <Styles.Container>
        <Carrousel src={images} />
        <Flex flex={1} column gap={2}>
          <h2>{product?.name}</h2>
          <Flex gap={2} column>
            <Flex column gap={1}>
              <DefaultStyles.Label>Tipo de produto</DefaultStyles.Label>
              <DefaultStyles.Value>
                {product?.type && t(`pages/listing:product.types.${product?.type}`)}
              </DefaultStyles.Value>
            </Flex>

            {product?.category?.name && (
              <Flex column gap={1}>
                <DefaultStyles.Label>Categoria</DefaultStyles.Label>
                <DefaultStyles.Value>
                  {t(`pages/products:log.category.${product?.category?.name}`)}
                </DefaultStyles.Value>
              </Flex>
            )}
            {product?.type && products[product.type]}
          </Flex>
        </Flex>
      </Styles.Container>
    </DefaultStyles.Card>
  );
}

export const Info = memo(BaseInfo);

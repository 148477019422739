import { useTranslation } from 'react-i18next';
import {
  ChangeEvent,
  DragEventHandler,
  memo,
  useCallback,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Icon } from 'components';

import { useToggle } from 'hooks/useToggle';

import * as Styles from './styles';

interface IProps {
  label?: string;
  dropMessage?: string;
  multiple?: boolean;
  initialValue?: IFile[];
  accept?: string;
  onRemoveFile?: (file: IFile) => Promise<void>;
}

type Toggles = 'isDragging';

interface IFile {
  name: string;
  file: File;
}

export interface FileInputRef {
  files: IFile[];
}

const FileInputComponent = forwardRef<FileInputRef, IProps>(
  (
    {
      label,
      dropMessage = 'dropArchive.defaultDropMessage',
      initialValue,
      multiple = true,
      accept,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [toggles, setToggles] = useToggle<Toggles>({ isDragging: false });
    const [filesValue, setFiles] = useState<IFile[]>(initialValue || []);

    useImperativeHandle(
      ref,
      () => ({
        files: filesValue,
        clearFiles: () => setFiles([]),
      }),
      filesValue,
    );

    const verifyAccept = (acceptParam: string, file: File) => {
      const acceptArr = acceptParam.split(',');
      let accepted = false;

      for (const acceptKey of acceptArr) {
        if (acceptKey.includes('/*')) {
          accepted = file.type.includes(acceptKey.replace('/*', ''));
        } else {
          accepted = file.type.includes(acceptKey);
        }

        if (accepted) break;
      }

      return accepted;
    };

    const onChange = (fileList: FileList) => {
      let files = Array.from(fileList).map(file => ({ name: file.name, file }));

      if (accept) {
        files = files.filter(file => verifyAccept(accept, file.file));
      }

      if (multiple) {
        files = multiple ? files : [files[0]];
      }

      multiple ? setFiles(prev => [...prev, ...files]) : setFiles(files);
    };

    const handleDragEnter = useCallback<DragEventHandler<HTMLDivElement>>(e => {
      e.preventDefault();
      setToggles('isDragging', true);
    }, []);

    const handleDragLeave = useCallback<DragEventHandler<HTMLDivElement>>(e => {
      e.preventDefault();
      setToggles('isDragging', false);
    }, []);

    const handleDrop = useCallback<DragEventHandler<HTMLDivElement>>(e => {
      e.stopPropagation();
      e.preventDefault();

      const fileList = e.dataTransfer.files;
      onChange(fileList);
    }, []);

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;
      if (!fileList) return;

      onChange(fileList);
    };

    const handleClickDrag = useCallback(() => inputRef.current?.click(), []);

    const handleRemoveFile = async (index: number) => {
      try {
        setFiles(prevState => prevState.filter((value, fileIndex) => fileIndex !== index));
      } catch (err) {}
    };

    return (
      <Styles.Wrapper>
        {label && <label>{t(label)}</label>}
        <Styles.Container>
          <Styles.DropZone
            isDragging={toggles.isDragging}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClickDrag}
          >
            <Icon name="file" size={1.5} />

            <span>{t(dropMessage)}</span>
          </Styles.DropZone>
        </Styles.Container>

        {filesValue.map((file, index) => (
          <Styles.FilesContainer key={file.name}>
            <Styles.FileItem>
              <span>{file.name}</span>

              <Icon
                name="closeCircle"
                clickable
                marginLeft="auto"
                color="text500"
                size={1.3}
                onClick={() => handleRemoveFile(index)}
              />
            </Styles.FileItem>
          </Styles.FilesContainer>
        ))}

        <input
          type="file"
          accept={accept}
          hidden
          ref={inputRef}
          multiple={multiple}
          onChange={handleChangeInput}
        />
      </Styles.Wrapper>
    );
  },
);

export const FileInput = memo(FileInputComponent);

import { memo, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { PaginatedTable } from 'components';

import { Flex } from 'styles/Flex';

import { LOG_PRODUCT_TYPES } from 'constants/log';
import { getLogProduct as getLogProductApi } from 'services/product';
import { Meta } from 'types/api';
import useBooleanToggle from 'hooks/useBooleanToggle';

interface Log {
  from: string;
  to: string;
  type: string;
  field: string;
  item?: string;
}

interface LogData {
  createdAt: string;
  user: string;
  log: Log;
}

interface LogProps {
  id: string | number;
}

function BaseLogs(props: LogProps) {
  const [logs, setLogs] = useState<LogData[]>([]);
  const [meta, setMeta] = useState<Partial<Meta>>({});
  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const { t } = useTranslation();

  const getLogProduc = async (id: string | number) => {
    try {
      const { data } = await getLogProductApi(id, {
        page: currentPage,
      });

      toggleIsLoading();
      const newLogs: LogData[] = data.data.map(log => ({
        createdAt: log.created_at,
        user: log.user.fullName,
        log: {
          field: log.field,
          from: log.original,
          to: log.new,
          type: log.operation_type,
          item: log.itemUpdate,
        },
      }));

      setMeta(data.meta);
      setLogs(newLogs);
    } finally {
      toggleIsLoading();
    }
  };

  const parseLogLabel = (_log: Log) => {
    const type = {
      [LOG_PRODUCT_TYPES.CREATE]: (
        <Trans i18nKey="pages/products:log.messages.create" values={{ to: _log.to }} />
      ),
      [LOG_PRODUCT_TYPES.DELETE]: (
        <Trans
          i18nKey="pages/products:log.messages.delete"
          values={{ from: _log.from }}
          tOptions={{
            context: 'combo',
          }}
        />
      ),
      [LOG_PRODUCT_TYPES.UPDATE]: (
        <Trans
          i18nKey="pages/products:log.messages.update"
          values={{
            from:
              _log.field === 'initial_validity' || _log.field === 'final_validity'
                ? new Date(_log.from)
                : _log.from,
            to:
              _log.field === 'initial_validity' || _log.field === 'final_validity'
                ? new Date(_log.to)
                : _log.to,
            item: _log?.item,
            field: t(`pages/products:log.${_log.field}`),
          }}
          tOptions={{
            context:
              (_log?.item && 'productItem') ||
              (_log.field === 'initial_validity' && 'date') ||
              (_log.field === 'final_validity' && 'date'),
          }}
        />
      ),
      [LOG_PRODUCT_TYPES.UPDATE_STATUS]: (
        <Trans
          i18nKey="pages/products:log.messages.updateStatus"
          values={{ from: _log.from }}
          tOptions={{
            context: String(Number(_log.to) === 1),
          }}
        />
      ),
      [LOG_PRODUCT_TYPES.INSERT]: (
        <Trans i18nKey="pages/products:log.messages.insert" values={{ to: _log.to }} />
      ),
    };

    return type[_log.type];
  };

  const logParse = logs.map(log => ({
    label: parseLogLabel(log.log),
    user: log.user,
    createdAt: t('dateHours', { date: new Date(log.createdAt) }),
  }));

  const columns = [
    { field: 'label', headerName: t('pages/products:log.table.label') },
    { field: 'user', headerName: t('pages/products:log.table.user') },
    { field: 'createdAt', headerName: t('pages/products:log.table.createdAt') },
  ];

  useEffect(() => {
    getLogProduc(props.id);
  }, [currentPage]);

  return (
    <Flex column gap={2.26} alignItems="flex-start">
      <PaginatedTable
        tableVariant="secondary"
        count={totalPages}
        rows={logParse}
        columns={columns}
        isLoading={isLoading}
        onPageChange={(event, _page) => setCurrentPage(_page)}
      />
    </Flex>
  );
}

export const Logs = memo(BaseLogs);

import { Button, Column, Icon, IconButton, RootModal, Table } from 'components';
import { resolvePath, routePaths } from 'constants/routes';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as DetailsStyles from 'styles/Details';
import { Flex } from 'styles/Flex';
import { Fess } from 'types/cashDesk';
import { TableValue } from '../../../../styles';
import * as Styles from './styles';

interface ListModalProps {
  title: string;
  children: ReactNode;
  disabled: boolean;
  operationId: string;
  data: {
    acquire: string;
    flag: string;
    installment: string | number;
    total: number;
    fess: Fess[];
  };
}

const FIELDS = {
  transaction: 'transaction',
  createdAt: 'createdAt',
  total: 'total',
  button: 'button',
  reversed: 'reversed',
  authorization: 'authorization',
};

function BaseListModal({ title, children, data, disabled, operationId }: ListModalProps) {
  const [t] = useTranslation();
  const [tPage] = useTranslation('pages/cashDesk');
  const history = useHistory();

  const [isOpen, toggleIsOpen] = useBooleanToggle(false);

  const rows = data?.fess
    ?.sort((a, b) => (a.isReversed && !b.isReversed ? 1 : -1))
    ?.map(value => ({
      [FIELDS.transaction]: <TableValue isReversed={value.isReversed}>{value.id}</TableValue>,
      [FIELDS.authorization]: (
        <TableValue isReversed={value.isReversed}>{value.authorization}</TableValue>
      ),
      [FIELDS.createdAt]: (
        <TableValue isReversed={value.isReversed}>
          {t('dateHours', { date: new Date(value.created_at) })}
        </TableValue>
      ),
      [FIELDS.total]: (
        <TableValue isReversed={value.isReversed} isCurrency>
          {t('currency', { value: value.value })}
        </TableValue>
      ),
      [FIELDS.reversed]: !value?.isReversed ? null : <Icon name="done" color="danger" />,
      [FIELDS.button]: (
        <Button
          variant="base"
          onClick={() => {
            history?.push(
              resolvePath(routePaths.cashDesk.edit, { id: value.order_id, operationId }),
            );
          }}
          disabled={disabled || value.isReversed}
        >
          Editar
        </Button>
      ),
    }));

  const columns: Column[] = [
    {
      field: FIELDS.transaction,
      headerName: tPage('detail.table.transaction'),
    },
    {
      field: FIELDS.authorization,
      headerName: tPage('detail.table.authorization'),
    },
    {
      field: FIELDS.createdAt,
      headerName: tPage('detail.table.created_at'),
    },
    {
      field: FIELDS.reversed,
      headerName: tPage('detail.table.reversed'),
    },
    {
      field: FIELDS.total,
      headerName: tPage('detail.table.total'),
    },
    {
      field: FIELDS.button,
      headerName: '',
      _style: {
        width: '2rem',
      },
    },
  ];

  return (
    <div onClick={event => event.stopPropagation()}>
      <RootModal maxWidth="md" fullWidth open={isOpen} onClose={toggleIsOpen}>
        <Styles.Container>
          <Flex justifyContent="space-between" alignItems="center">
            <Styles.Title>{title}</Styles.Title>
            <IconButton onClick={toggleIsOpen} icon={{ name: 'close' }} />
          </Flex>
          <Styles.Info>
            <Flex column gap={1}>
              <DetailsStyles.Label>Rede Adquirente</DetailsStyles.Label>
              <DetailsStyles.Value>{data.acquire}</DetailsStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DetailsStyles.Label>Transação</DetailsStyles.Label>
              <DetailsStyles.Value>{data.fess[0].order_id}</DetailsStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DetailsStyles.Label>Bandeira</DetailsStyles.Label>
              <DetailsStyles.Value>{data.flag}</DetailsStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DetailsStyles.Label>Tipo de transação</DetailsStyles.Label>
              <DetailsStyles.Value>
                {data.installment <= 1 ? 'À vista' : 'Parcelado'}
              </DetailsStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DetailsStyles.Label>Parcelas</DetailsStyles.Label>
              <DetailsStyles.Value>{data.installment}</DetailsStyles.Value>
            </Flex>
            <Flex column gap={1}>
              <DetailsStyles.Label>Valor total</DetailsStyles.Label>
              <Styles.Total>{t('currency', { value: data.total })}</Styles.Total>
            </Flex>
          </Styles.Info>
          <Table tableVariant="secondary" rows={rows} columns={columns} />
        </Styles.Container>
      </RootModal>
      <Styles.Content onClick={toggleIsOpen}>{children}</Styles.Content>
    </div>
  );
}

export const ListModal = memo(BaseListModal);

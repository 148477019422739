import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Installment } from 'types/acquire';

import { Column, Table } from 'components';

import { Flex } from 'styles/Flex';
import { Legend } from 'styles/Legend';

interface Data {
  operation_name: string;
  installments: Installment[];
}

interface TaxProps {
  data?: Data[];
}

const FIELDS = {
  fee: 'fee',
  type: 'type',
  installment: 'installment',
};

function BaseTax({ data }: TaxProps) {
  const [tPage] = useTranslation('pages/acquire');
  const [t] = useTranslation();

  const columns: Column[] = [
    {
      field: FIELDS.fee,
      headerName: tPage('details.table.fee'),
    },
    {
      field: FIELDS.installment,
      headerName: tPage('details.table.installment'),
    },
    {
      field: FIELDS.type,
      headerName: tPage('details.table.type'),
    },
  ];

  const renderInstallments = data?.map((value, index) => {
    const rows = value.installments?.map(value => ({
      [FIELDS.fee]: `${value.fee_value} %`,
      [FIELDS.installment]: `${value.installment}x`,
      [FIELDS.type]: t(`labels:transactionTypes.${value.type}`),
    }));

    return (
      <Flex key={index} column gap={1}>
        <Legend>{value.operation_name}</Legend>
        <Table tableVariant="secondary" columns={columns} rows={rows || []} />
      </Flex>
    );
  });

  return <>{renderInstallments}</>;
}

export const Tax = memo(BaseTax);

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Table } from 'components';
import { GoalProductOperations } from 'types/goal';
import { Flex } from 'styles/Flex';
import { AWARD_TYPE } from 'constants/goal';
import { removeDuplicateObjectFroArray } from 'utils/help';

interface ProductGoalProps {
  operations: (GoalProductOperations & {
    operation_name: string;
  })[];
  users: (GoalProductOperations & {
    user_name: string;
  })[];
  type?: string;
}

function BaseProductsGoal({ users, operations, type }: ProductGoalProps) {
  const [tPage] = useTranslation('pages/goal');
  const { t } = useTranslation();

  const columns: Column[] = [
    {
      field: 'minimal',
      headerName: tPage('detail.table.minimal'),
    },
    {
      field: 'minimal_award',
      headerName: tPage('detail.table.award'),
    },
    {
      field: 'ideal',
      headerName: tPage('detail.table.ideal'),
    },
    {
      field: 'ideal_award',
      headerName: tPage('detail.table.award'),
    },
    {
      field: 'super',
      headerName: tPage('detail.table.super'),
    },
    {
      field: 'super_award',
      headerName: tPage('detail.table.award'),
    },
  ];

  const _users = removeDuplicateObjectFroArray(users, 'user_id', 'user_id');
  const _operations = removeDuplicateObjectFroArray(operations, 'operation_id', 'operation_id');

  const renderUserTables = _users.map((value, index) => {
    const rows = value.products?.map(product => ({
      name: product.product_name,
      minimal: product.minimal.target,
      minimal_award:
        type === AWARD_TYPE.PERCENTAGE
          ? `${product.minimal?.award} %`
          : t('currency', { value: product.minimal?.award }),

      ideal: product.ideal.target,
      ideal_award:
        type === AWARD_TYPE.PERCENTAGE
          ? `${product.ideal?.award} %`
          : t('currency', { value: product.ideal?.award }),

      super: product.super.target,
      super_award:
        type === AWARD_TYPE.PERCENTAGE
          ? `${product.super?.award} %`
          : t('currency', { value: product.super?.award }),
    }));
    return (
      <Flex key={index} column gap={2}>
        <strong>{value.user_name}</strong>
        <Table
          columns={[
            {
              field: 'name',
              headerName: tPage('detail.table.user'),
            },
            ...columns,
          ]}
          rows={rows || []}
        />
      </Flex>
    );
  });

  const renderOperationsTables = _operations.map((value, index) => {
    const rows = value.products?.map(product => ({
      name: product.product_name,
      minimal: product.minimal.target,
      minimal_award:
        type === AWARD_TYPE.PERCENTAGE
          ? `${product.minimal?.award} %`
          : t('currency', { value: product.minimal?.award }),

      ideal: product.ideal.target,
      ideal_award:
        type === AWARD_TYPE.PERCENTAGE
          ? `${product.ideal?.award} %`
          : t('currency', { value: product.ideal?.award }),

      super: product.super.target,
      super_award:
        type === AWARD_TYPE.PERCENTAGE
          ? `${product.super?.award} %`
          : t('currency', { value: product.super?.award }),
    }));

    return (
      <Flex key={index} column gap={1}>
        <strong>{value.operation_name}</strong>
        <Table
          columns={[
            {
              field: 'name',
              headerName: tPage('detail.table.operation'),
            },
            ...columns,
          ]}
          rows={rows || []}
        />
      </Flex>
    );
  });

  return (
    <Flex column gap={2}>
      {renderUserTables}
      {renderOperationsTables}
    </Flex>
  );
}

export const ProductsGoal = memo(BaseProductsGoal);

// @ts-nocheck
import { TextField as TextFieldMui } from '@mui/material';

import { styled } from '@mui/material/styles';
import { DefaultTheme } from 'styled-components';

export const TextFieldQrCode = styled(TextFieldMui)<{ contained?: boolean; theme?: DefaultTheme }>`
  width: 55% !important;
  margin-left: 9% !important;

  input,
  .MuiInputBase-multiline {
    padding: ${({ size }) => (size === 'small' ? '0.6rem' : '0.75rem')} !important;
    text-align: center !important;
  }

  .MuiInputBase-root {
    background: ${({ theme, contained }) => contained && theme.colors.foreground};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    background: ${({ theme, contained }) => contained && theme.colors.foreground};
  }

  .notranslate {
    font-size: 0.75rem;
    font-family: inherit;
  }

  .inLVpp {
    border-color: transparent;
  }

  .kQZxvn.Mui-focused .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
    border-color: transparent !important;
  }

  .kQZxvn:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }

  .kLFgQW {
    border-color: transparent !important;
  }

  .kLFgQW:focus {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
    border-color: transparent !important;
  }
`;

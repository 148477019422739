import { PrivateRoute } from '../components/PrivateRoute';

import { Admin } from 'layouts/Admin';

import { adminRoutes } from '../routes';

export const AdminLayoutRoutes = () => {
  const renderRoutes = adminRoutes.map((route, index) => (
    <PrivateRoute wrapComponent={Admin} key={`route-${index}`} {...route} />
  ));

  return renderRoutes;
};

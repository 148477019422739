import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { routePaths } from 'constants/routes';
import { getStorageUrl } from 'utils/api';
import Button2 from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ReactGA from 'react-ga4';
import * as Styles from './styles';

type Props = RouteComponentProps;

export const Faq = ({ history }: Props): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>('');
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const redirectHome = useCallback(() => history.push(routePaths.home), []);

  ReactGA.initialize('G-E2W8W5XZE6');

  useEffect(() => {
    const time = 6000;
    let currentImageIndex = 0;
    const images = document.querySelectorAll<HTMLImageElement>('#slider img');
    const max = images.length - 1;

    function nextImage() {
      images[currentImageIndex].classList.remove('selected');
      currentImageIndex++;

      if (currentImageIndex >= max) currentImageIndex = 0;

      images[currentImageIndex].classList.add('selected');
    }

    function start() {
      setInterval(() => {
        nextImage();
      }, time);
    }

    start();
  }, []);
  return (
    <Styles.Container>
      <Styles.sliderStyles>
        <div id="slider">
          <img className="selected" src={getStorageUrl('/carrossel01.jpg')} alt="Image1" />

          <img src={getStorageUrl('/carrossel02.jpg')} alt="Image2" />

          <img src={getStorageUrl('/carrossel03.jpg')} alt="Image3" />

          <img src={getStorageUrl('/carrossel04.jpg')} alt="Image4" />

          <img src={getStorageUrl('/carrossel05.jpg')} alt="Image5" />
          <Styles.FaqPage>
            <Styles.HeaderDiv>
              <Styles.H1>Dúvidas Frequentes</Styles.H1>{' '}
              {isMobile ? (
                <Styles.closeButtonDiv>
                  <CloseIcon
                    onClick={redirectHome}
                    sx={{
                      '  & > :not(style)': {
                        color: 'black',
                        fontWeight: '500',
                      },
                    }}
                  ></CloseIcon>
                </Styles.closeButtonDiv>
              ) : (
                <Styles.divBotaoVoltar>
                  <div className="button-mui">
                    <Button2
                      className="btn-back"
                      style={{
                        borderRadius: 15,
                        fontFamily: 'Montserrat',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        padding: '0.8vh 1.2vw',
                        textTransform: 'lowercase',
                      }}
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                      onClick={redirectHome}
                      sx={{
                        '  & > :not(style)': {
                          borderRadius: '60px',
                          color: 'white',
                          fontSize: '15px',
                          fontWeight: '500',
                        },
                      }}
                    >
                      <span style={{ fontSize: '15px', fontWeight: 'bold' }}>Voltar</span>
                    </Button2>
                  </div>
                </Styles.divBotaoVoltar>
              )}
            </Styles.HeaderDiv>
            <Styles.FaqPageContent>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
                sx={{
                  '& > :not(style)': {
                    color: 'rgba(0,0,0,1)',
                  },
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    Não consigo acessar minhas fotos após o prazo de 48 horas. O que devo fazer?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Caso esse cenário ocorra, será necessário entrar em contato com o nosso
                      suporte, fornecendo algumas informações essenciais para resolver o seu
                      problema. Acesse nosso formulário de atendimento através deste link:
                      <a
                        href="https://vyoofotos.com.br/recover"
                        rel="noopener noreferrer"
                        style={{ color: '#0A91F7', textDecoration: 'underline', marginLeft: '8px' }}
                      >
                        https://vyoofotos.com.br/recover
                      </a>
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
                sx={{
                  '& > :not(style)': {
                    color: 'rgba(0,0,0,1)',
                  },
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Perdi meu QR Code. Como posso recuperar minhas fotos?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Caso tenha perdido o seu QR Code, você precisará fornecer algumas informações
                      essenciais para localizar a sua compra. Acesse nosso formulário de atendimento
                      através deste link:
                      <a
                        href="https://vyoofotos.com.br/recover"
                        rel="noopener noreferrer"
                        style={{ color: '#0A91F7', textDecoration: 'underline', marginLeft: '8px' }}
                      >
                        https://vyoofotos.com.br/recover
                      </a>
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
                sx={{
                  '& > :not(style)': {
                    color: 'rgba(0,0,0,1)',
                  },
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    Desejo comprar fotos. Por quanto tempo elas ficam disponíveis e qual é o
                    procedimento?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      As fotos adquiridas estarão disponíveis no site por 15 dias, enquanto todas as
                      imagens capturadas serão armazenadas por 30 dias. Para comprar fotos, siga as
                      etapas abaixo:
                      <br />
                      <br /> 1. Acesse nosso formulário de atendimento através deste link e forneça
                      todos os dados solicitados:
                      <a
                        href="https://vyoofotos.com.br/recover"
                        rel="noopener noreferrer"
                        style={{ color: '#0A91F7', textDecoration: 'underline', marginLeft: '8px' }}
                      >
                        https://vyoofotos.com.br/recover
                      </a>
                      <br />
                      <br /> 2. Após recebermos as informações, localizaremos as suas fotos no
                      servidor e as enviaremos para que você possa visualizá-las e fazer a sua
                      escolha.
                      <br />
                      <br /> 3. Após a confirmação do pagamento, as fotos de alta qualidade serão
                      associadas a um QR-Code. Enviaremos o link para que você possa acessar e fazer
                      o download.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Styles.FaqPageContent>
          </Styles.FaqPage>
        </div>
      </Styles.sliderStyles>
    </Styles.Container>
  );
};

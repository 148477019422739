import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  max-width: 30rem;
  padding: 1rem;
  max-height: 70%;
  overflow: auto;
`;

export const Title = styled.strong`
  font-size: 1.1rem;
  display: inline-block;
`;

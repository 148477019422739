import React, { Suspense } from 'react';
import { Notification } from 'components';

import ReactGA from 'react-ga4';
import Routes from 'routes';
import { MUIThemeProvider, SCThemeProvider } from 'theme/themeProviders';

export const App = () => {
  ReactGA.initialize('G-E2W8W5XZE6');
  return (
    <Suspense fallback="">
      <SCThemeProvider>
        {/* NOTE: MUIThemeProvider ever inside SCThemeProvider */}
        <MUIThemeProvider>
          <Routes />
          <Notification />
        </MUIThemeProvider>
      </SCThemeProvider>
    </Suspense>
  );
};

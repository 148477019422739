import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, IconButton, Table, UnMaskInput } from 'components';
import { ProductArray } from '../ProductArray';

import { TABLE_FIELDS } from 'constants/goal';

import * as Styles from '../../../../styles';
import { Flex } from 'styles/Flex';
import { CorrectMaskInput } from 'pages/Private/Goal/Create/components/CorrectMaskInput';
import { DetailsProduct } from '../..';
import { useGoalContext } from 'pages/Private/Goal/Create/components';
import { GetUserData } from 'types/user';

interface RenderUserProps {
  data: Pick<GetUserData, 'fullName' | 'id'>[];
  initialData?: DetailsProduct;
  operationId?: number;
  type: string;
}

function BaseRenderUser({ data, type, operationId }: RenderUserProps) {
  const [tPage] = useTranslation('pages/goal');
  const context = useGoalContext();

  const columns: Column[] = [
    {
      headerName: tPage('create.table.users'),
      field: TABLE_FIELDS.product,
      _style: {
        width: '100%',
      },
    },
    {
      headerName: tPage('create.table.minimumGoal'),
      field: TABLE_FIELDS.minimal,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.minimal_award,
    },
    {
      headerName: tPage('create.table.idealGoal'),
      field: TABLE_FIELDS.ideal,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.ideal_award,
    },
    {
      headerName: tPage('create.table.superGoal'),
      field: TABLE_FIELDS.super,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.super_award,
    },
    {
      headerName: '',
      field: 'removeButton',
    },
  ];

  const renderUserForm = data?.map((user, indexUser) => {
    return (
      <Flex column gap={2} key={indexUser}>
        <Styles.Title>{user.fullName}</Styles.Title>
        <ProductArray
          operationId={operationId}
          name="products"
          render={({ remove }, products: any[]) => {
            const row = products?.map((product, productIndex) => {
              return {
                [TABLE_FIELDS.product]: (
                  <>
                    {product.name}
                    {/* para inserir o valor do product_id no unform */}
                    <UnMaskInput
                      parseToFloat
                      cleanValue
                      style={{ display: 'none' }}
                      name={`users[${indexUser}].products[${productIndex}].product_id`}
                      value={product.id}
                      type="hidden"
                      hidden
                    />
                  </>
                ),
                [TABLE_FIELDS.minimal]: (
                  <Styles.InputField size={6}>
                    <UnMaskInput
                      placeholderAlternative="0"
                      cleanValue
                      parseToFloat
                      name={`users[${indexUser}].products[${productIndex}].minimal.target`}
                    />
                  </Styles.InputField>
                ),
                [TABLE_FIELDS.minimal_award]: (
                  <Styles.InputField size={6}>
                    <CorrectMaskInput
                      name={`users[${indexUser}].products[${productIndex}].minimal.award`}
                      type={type}
                    />
                  </Styles.InputField>
                ),
                [TABLE_FIELDS.ideal]: (
                  <Styles.InputField size={6}>
                    <UnMaskInput
                      placeholderAlternative="0"
                      cleanValue
                      parseToFloat
                      name={`users[${indexUser}].products[${productIndex}].ideal.target`}
                    />
                  </Styles.InputField>
                ),
                [TABLE_FIELDS.ideal_award]: (
                  <Styles.InputField size={6}>
                    <CorrectMaskInput
                      name={`users[${indexUser}].products[${productIndex}].ideal.award`}
                      type={type}
                    />
                  </Styles.InputField>
                ),
                [TABLE_FIELDS.super]: (
                  <Styles.InputField size={6}>
                    <UnMaskInput
                      placeholderAlternative="0"
                      cleanValue
                      parseToFloat
                      name={`users[${indexUser}].products[${productIndex}].super.target`}
                    />
                  </Styles.InputField>
                ),
                [TABLE_FIELDS.super_award]: (
                  <Styles.InputField size={6}>
                    <CorrectMaskInput
                      name={`users[${indexUser}].products[${productIndex}].super.award`}
                      type={type}
                    />
                  </Styles.InputField>
                ),
                removeButton: (
                  <IconButton
                    icon={{ name: 'close' }}
                    disabled={products.length === 1}
                    onClick={() => {
                      context?.setListProductDeleted?.(prevState => [
                        ...prevState,
                        {
                          id: product.id,
                          user_id: user.id,
                        },
                      ]);
                      remove(productIndex);
                    }}
                  />
                ),
              };
            });

            return <Table columns={columns} rows={row} />;
          }}
        />
        {indexUser !== (data?.length as number) - 1 && <hr />}
      </Flex>
    );
  });

  return <>{renderUserForm}</>;
}

export const RenderUser = memo(BaseRenderUser);

import { useCallback, useEffect, useState } from 'react';
import { getTouristOperator } from 'services/touristGuide';
import { GetTourisOperator, GetTouristOperatorParams } from 'types/touristGuide';

interface Params {
  getOnRender?: boolean;
}

export const useTouristOperators = ({ getOnRender }: Params = {}) => {
  const [operators, setOperators] = useState<GetTourisOperator[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(!!getOnRender);

  const handleGetOperators = useCallback(async (params?: GetTouristOperatorParams) => {
    setIsLoading(true);

    try {
      const response = await getTouristOperator(params);

      setOperators(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (getOnRender) {
      handleGetOperators();
    }
  }, []);

  return {
    operators,
    isLoading,
    handleGetOperators,
  };
};

import styled from 'styled-components';

export const FileLine = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: 0.5rem 1rem;

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`;

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSellerToReport } from 'services/report';
import { SellerToReport } from 'types/report';
import { openNotification } from 'utils/notification';

interface UseSellersToReportProps {
  operationId?: string | number;
  initialDate?: Date;
  finalDate?: Date;
  fileFormat: number;
  updateOnChangeData?: boolean;
}

export const useSellersToReport = ({
  finalDate,
  initialDate,
  operationId,
  fileFormat,
  updateOnChangeData = true,
}: UseSellersToReportProps) => {
  const [tPage] = useTranslation('pages/report');

  const [sellers, setSellers] = useState<SellerToReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLoadSellers = useCallback(async () => {
    try {
      if (!operationId || !initialDate || !finalDate) return;
      setLoading(true);

      const parsedInitialDate = new Date(
        initialDate.getFullYear(),
        initialDate.getMonth(),
        initialDate.getDate(),
        0,
        0,
        0,
      );
      const parsedFinalDate = new Date(
        finalDate.getFullYear(),
        finalDate.getMonth(),
        finalDate.getDate(),
        23,
        59,
        59,
      );

      const response = await getSellerToReport({
        operationId: operationId,
        initialDate: parsedInitialDate.toISOString(),
        finalDate: parsedFinalDate.toISOString(),
        fileFormat: fileFormat,
      });

      setSellers(response.data);
    } catch (error) {
      openNotification(tPage('notGetSellersError'), 'error');
    } finally {
      setLoading(false);
    }
  }, [operationId, initialDate, finalDate, fileFormat]);

  useEffect(() => {
    if (updateOnChangeData) {
      handleLoadSellers();
    }
  }, [updateOnChangeData, handleLoadSellers]);

  return {
    sellers,
    isLoading: loading,
    handleLoadSellers,
  };
};

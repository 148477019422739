import { SetState } from 'zustand';
import { PaxState } from './type';

export const paxActions = (set: SetState<PaxState>) => ({
  setPaxList: (paxList: PaxState['throwPage']['paxList']) =>
    set(prev => ({ throwPage: { ...prev.throwPage, paxList: paxList } })),
  setFilter: (filter: PaxState['throwPage']['filter']) =>
    set(prev => ({ throwPage: { ...prev.throwPage, filter } })),
  setDisableFilter: (disableFilter: PaxState['throwPage']['disableFilter']) =>
    set(prev => ({ throwPage: { ...prev.throwPage, disableFilter } })),
  setLoading: (loading: keyof PaxState['loadings'], value: boolean) =>
    set(prev => ({ loadings: { ...prev.loadings, [loading]: value } })),
  setHistoricList: (historicList: PaxState['historicPage']['historicList']) =>
    set(prev => ({ historicPage: { ...prev.historicPage, historicList } })),
  setHistoricMeta: (meta: PaxState['historicPage']['meta']) =>
    set(prev => ({ historicPage: { ...prev.historicPage, meta } })),
  setQuery: (query: PaxState['historicPage']['query']) =>
    set(prev => ({ historicPage: { ...prev.historicPage, query } })),
});

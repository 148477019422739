import { memo, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { PaginatedTable } from 'components';

import { Flex } from 'styles/Flex';

import { getLogOperation as getLogOperationApi } from 'services/operation';

import { LOG_PRODUCT_TYPES } from 'constants/log';
import { Meta } from 'types/api';
import useBooleanToggle from 'hooks/useBooleanToggle';

interface Log {
  from: string;
  to: string;
  type: string;
  field: string;
}

interface LogData {
  createdAt: string;
  user: string;
  log: Log;
}

interface LogProps {
  id: string | number;
}

function BaseLogs(props: LogProps) {
  const [logs, setLogs] = useState<LogData[]>([]);
  const [meta, setMeta] = useState<Partial<Meta>>({});
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const { t } = useTranslation();

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getLogOperation = async (id: string | number) => {
    try {
      toggleIsLoading();

      const { data } = await getLogOperationApi(id, {
        page: currentPage,
      });

      const newLogs: LogData[] = data.data.map(log => ({
        createdAt: log.created_at,
        user: log.user.fullName,
        log: {
          field: log.field,
          from: log.original,
          to: log.new,
          type: log.operation_type,
        },
      }));

      setMeta(data.meta);
      setLogs(newLogs);
    } finally {
      toggleIsLoading();
    }
  };

  const parseLogLabel = (log: Log) => {
    const type = {
      [LOG_PRODUCT_TYPES.CREATE]: (
        <Trans i18nKey="pages/operations:log.messages.create" values={{ to: log.to }} />
      ),

      [LOG_PRODUCT_TYPES.UPDATE]: (
        <Trans
          i18nKey="pages/operations:log.messages.update"
          values={{
            to: log.to,
            from: log.from,
            field: t(`pages/operations:log.${log.field}`),
          }}
        />
      ),
      [LOG_PRODUCT_TYPES.UPDATE_STATUS]: (
        <Trans
          i18nKey="pages/operations:log.messages.updateStatus"
          values={{ from: log.from }}
          tOptions={{
            context: String(Number(log.to) === 1),
          }}
        />
      ),
    };

    return type[log.type];
  };

  const logParse = logs.map(log => ({
    label: parseLogLabel(log.log),
    user: log.user,
    createdAt: t('dateHours', { date: new Date(log.createdAt) }),
  }));

  const columns = [
    { field: 'label', headerName: t('pages/operations:log.table.title') },
    { field: 'user', headerName: t('pages/operations:log.table.user') },
    { field: 'createdAt', headerName: t('pages/operations:log.table.createdAt') },
  ];

  useEffect(() => {
    getLogOperation(props.id);
  }, [currentPage]);

  return (
    <Flex column gap={2.26} alignItems="flex-start">
      <PaginatedTable
        tableVariant="secondary"
        rows={logParse}
        columns={columns}
        count={totalPages}
        isLoading={isLoading}
        onPageChange={(event, _page) => setCurrentPage(_page)}
      />
    </Flex>
  );
}

export const Logs = memo(BaseLogs);

import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.background};

  legend {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
    font-size: ${({ theme }) => theme.fonts.sizes.sm};
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 0.5rem 1rem 0.5rem 2rem;
`;

export const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${ListItem} {
    :nth-of-type(odd) {
      background-color: ${({ theme }) => theme.colors.background};
    }
  }
`;

import * as Yup from 'yup';
import { invalidEmail, requiredField } from './utils/messages';

export const operatorValidationSchema = Yup.object().shape({
  name: Yup.string().required(requiredField),
  commissionPercentage: Yup.number().required(requiredField).typeError(requiredField),
});

export const createTouristGuideValidationSchema = Yup.object().shape({
  fullName: Yup.string().required(requiredField),
  email: Yup.string().email(invalidEmail).required(requiredField),
  phone: Yup.string().required(requiredField),
  cpf: Yup.string().required(requiredField),
  operatorId: Yup.number().required(requiredField).typeError(requiredField),
  operationsAccessId: Yup.array()
    .min(1, requiredField)
    .required(requiredField)
    .typeError(requiredField),
});

import { Button, Icon, Unform, UnInputFIeld } from 'components';
import { routePaths } from 'constants/routes';
import { useState, useCallback } from 'react';
import { SubmitHandler } from '@unform/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Flex } from 'styles/Flex';
import { emailValidationSchema } from 'yupSchemas/auth';
import * as Styles from './styles';
import { Response } from './components';
import { postRecoverPasswordEmail } from 'services/auth';

interface FormData {
  email: string;
}

export const ForgotPassword = (): JSX.Element => {
  const [tPage] = useTranslation('pages/forgotPassword');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const handleSubmit = useCallback<SubmitHandler<FormData>>(async data => {
    setIsLoading(true);
    try {
      await postRecoverPasswordEmail(data);
      setEmailSent(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Styles.Container>
      <Styles.Body>
        {emailSent ? (
          <Response />
        ) : (
          <>
            <Styles.Title>
              <Link to={routePaths.login}>
                <Icon name="arrowBack" size={1.5} />
              </Link>
              {tPage('forgotPassword.title')}
            </Styles.Title>
            <Styles.SubTitle>{tPage('forgotPassword.subtitle')}</Styles.SubTitle>
            <Unform onSubmit={handleSubmit} validationSchema={emailValidationSchema}>
              <Flex column>
                <UnInputFIeld name="email" label={'labels:input.email'} />

                <Flex fullWidth marginTop={1}>
                  <Button fullWidth type="submit" isLoading={isLoading}>
                    {tPage('send')}
                  </Button>
                </Flex>
              </Flex>
            </Unform>
          </>
        )}
      </Styles.Body>
    </Styles.Container>
  );
};

import { useEffect, useState } from 'react';
import { getCardFlag } from 'services/cardFlag';

import { CardFlagQueries, GetCardFlag } from 'types/cardFlag';

import useBooleanToggle from './useBooleanToggle';

interface Props {
  getOnInit?: boolean;
}

export function useCardFlags(props: Props = {}) {
  const [data, setData] = useState<GetCardFlag[]>([]);
  const [isLoading, toggleIsLoading] = useBooleanToggle(false);
  const [error, setError] = useState<Error | null>(null);

  const handleGetCardFlag = async (queries?: CardFlagQueries) => {
    try {
      toggleIsLoading();

      const { data } = await getCardFlag(queries);

      setData(data);
    } catch (err: any) {
      setError(new Error(err));
    } finally {
      toggleIsLoading();
    }
  };

  useEffect(() => {
    props?.getOnInit && handleGetCardFlag();
  }, []);

  return {
    error,
    isLoading,
    data,
    getData: handleGetCardFlag,
  };
}

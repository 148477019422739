import { IconButton, Unform } from 'components';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  max-width: 35rem;
  padding: 1rem;
`;

export const ScrollView = styled.div`
  max-height: 25rem;
  overflow: auto;
`;

export const Title = styled.strong`
  font-size: 1.15rem;
  font-weight: bold;
`;

export const Legend = styled.legend`
  font-size: 0.87rem;
  font-weight: 600;
`;

export const Form = styled(Unform)``;

export const List = styled.ul`
  width: 100%;
  padding-bottom: 1rem;
`;

export const RemoveButton = styled(IconButton)`
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.3rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Item = styled.li<{ isOdd?: boolean }>`
  padding: 0.5rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${({ isOdd }) => (isOdd ? 'rgba(0, 0, 0, .03)' : 'none')};

  span:nth-child(2) {
    flex: 1;
  }
`;

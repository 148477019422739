import { Drawer } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { adminDrawerRoutes } from 'constants/routes';

import * as Styles from './styles';
import { AuthorizeView } from 'components';

interface IProps {
  openDrawer: boolean;
  onClose(): void;
}

const SidebarComponent: React.FC<IProps> = ({ onClose, openDrawer }) => {
  const [t] = useTranslation();

  return (
    <Drawer open={openDrawer} onClose={onClose}>
      <Styles.Container>
        {adminDrawerRoutes.map(route => (
          <Styles.Ul key={route.i18nLabel}>
            <Styles.Li>
              <Styles.LinkButton section>{t(route.i18nLabel)}</Styles.LinkButton>
            </Styles.Li>

            <Styles.Li>
              <Styles.Ul>
                {route.routes.map(childRoute => (
                  <AuthorizeView key={childRoute.path} permission={childRoute.permission}>
                    <Styles.Li>
                      <Styles.NavLink to={childRoute.path} onClick={onClose}>
                        <Styles.LinkButton>{t(childRoute.i18nLabel)}</Styles.LinkButton>
                      </Styles.NavLink>
                    </Styles.Li>
                  </AuthorizeView>
                ))}
              </Styles.Ul>
            </Styles.Li>
          </Styles.Ul>
        ))}
      </Styles.Container>
    </Drawer>
  );
};

export const Sidebar = memo(SidebarComponent);

import React, { useEffect, useState, useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import {
  PaginatedTable,
  Button,
  Tag,
  StatusFilter,
  SearchFilter,
  OperationFilter,
} from 'components';

import { Menu } from '../components/Menu';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';

import { queryToObj, pushHistoryQuery } from 'utils/query';

import { getPdvs as getPdvsApi } from 'services/pdv';

import { ApiQueries, Meta } from 'types/api';
import { ResponseData as PdvResponseData } from 'types/pdv';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListPdv({ history }: RouteComponentProps) {
  const [pdvs, setPdvs] = useState<PdvResponseData[]>([]);

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);

  const { t } = useTranslation('pages/listing');

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const historyQuery = queryToObj(history.location.search);

  const columns = [
    {
      field: 'custom_id',
      headerName: t('pdv.table.id'),
    },
    {
      field: 'name',
      headerName: t('pdv.table.name'),
      orderable: true,
    },
    {
      field: 'operation.name',
      orderable: true,
      headerName: t('pdv.table.operationName'),
    },
    {
      field: 'created_at',
      orderable: true,
      headerName: t('pdv.table.createdAt'),
    },
    {
      field: 'tag',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const getPdvs = async (query?: ApiQueries) => {
    const { data } = await getPdvsApi(query);

    setPdvs(data.data);
    setMeta(data.meta);
  };

  const rows = useMemo(
    () =>
      pdvs.map(pdv => ({
        id: pdv.id,
        custom_id: pdv.custom_id,
        name: pdv.name,
        'operation.name': pdv.operation.name,
        created_at: format(new Date(pdv.created_at), 'dd/MM/yyyy'),
        menu: (
          <Menu
            id={pdv.id}
            onDelete={() => getPdvs(historyQuery)}
            onEdit={() => getPdvs(historyQuery)}
            status={pdv?.status}
            history={history}
          />
        ),
        tag: (
          <Tag
            label={pdv?.status === 0 ? 'Inativo' : 'Ativo'}
            variante={pdv.status === 0 ? 'inactive' : 'success'}
          />
        ),
      })),
    [pdvs],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      const query = queryToObj(location.search) as ApiQueries;

      getPdvs(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getPdvs(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <Flex column gap={0.5}>
          <h1>{t('pdv.title')}</h1>
        </Flex>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <SearchFilter
                defaultValue={(queryToObj(history.location.search)?.search ?? '') as any}
              />
              <DefaultStyles.SelectContainer>
                <OperationFilter />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <StatusFilter defaultValue={historyQuery?.status} />
              </DefaultStyles.SelectContainer>
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => history.push(routePaths.pdv.create)}
            >
              Cadastrar
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.pdv.details, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

import { ConfirmModal, Popover } from 'components';
import { useBooleanObj } from 'hooks/useBooleanObj';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { patchTouristGuideStatus } from 'services/touristGuide';
import { Flex } from 'styles/Flex';
import { GetTouristGuideList } from 'types/touristGuide';
import { openNotification } from 'utils/notification';

interface Props {
  touristGuide: GetTouristGuideList;
  onChangeStatus: () => void;
}

export const Menu = ({ touristGuide, onChangeStatus }: Props) => {
  const [tPage] = useTranslation('pages/touristGuide');
  const [openConfirmStatus, setOpenConfirmStatus] = useState<boolean>(false);
  const [loadings, setLoading] = useBooleanObj({ updateStatus: false });

  const handleUpdateStatus = async () => {
    try {
      setLoading('updateStatus', true);

      const response = await patchTouristGuideStatus(touristGuide.id);

      if (response.status === 200) {
        openNotification(tPage('list.confirmStatus.successMessage'));
        setOpenConfirmStatus(false);
        onChangeStatus();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading('updateStatus', false);
    }
  };

  return (
    <Flex onClick={event => event.stopPropagation()} fullWidth justifyContent="flex-end">
      <Popover
        options={[
          {
            label: `pages/touristGuide:list.${!touristGuide.status ? 'inactive' : 'active'}`,
            onClick: () => setOpenConfirmStatus(true),
          },
        ]}
      />

      <ConfirmModal
        title={tPage('list.confirmStatus.title')}
        bodyText={tPage('list.confirmStatus.body')}
        open={openConfirmStatus}
        onClose={() => setOpenConfirmStatus(false)}
        onConfirm={() => {
          handleUpdateStatus();
        }}
        isLoading={loadings.updateStatus}
      />
    </Flex>
  );
};

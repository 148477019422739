import * as yup from 'yup';
import {
  requiredField,
  minOneCardFlag,
  minOneOperation,
  invalidCnpj,
  mustBuLessThan100Percent,
} from './utils/messages';
import { validateCNPJ } from './utils/validateCnpj';

const installmentValidation = yup.object({
  fee_value: yup.string().test('formErrors', mustBuLessThan100Percent, function (data) {
    if (!data) return true;

    return Number(data) < 100;
  }),
});

export const createBaseAcquire = yup.object({
  cnpj: yup
    .string()
    .test('validate-cnpj', invalidCnpj, value => !!validateCNPJ(value || ''))
    .required(requiredField),
  name: yup.string().required(requiredField),
  card_flags: yup.array().min(1, minOneCardFlag),
  operations: yup.array().min(1, minOneOperation),
});

export const createTaxAcquire = yup.object({
  operations_value_required: yup.array().of(yup.string().required(requiredField)),
  acquirer_vincule: yup.array().of(
    yup.object({
      card_flags: yup.array().of(
        yup.object({
          installments: yup.array().of(installmentValidation),
        }),
      ),
    }),
  ),
});

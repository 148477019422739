import { AutocompleteInputChangeReason, Grid, IconButton } from '@mui/material';
import {
  Button,
  ConfirmModal,
  Icon,
  LoadingMessage,
  UnAutocomplete,
  Unform,
  UnInputFIeld,
  UnSwitch,
} from 'components';
import { useToggle } from 'hooks/useToggle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { getOperationsList } from 'services/operation';
import { getPdvById, postPDV, putPDV } from 'services/pdv';
import { Flex } from 'styles/Flex';
import { Hr } from 'styles/Hr';
import { PageContainer } from 'styles/PageContainer';
import { SimpleOperationList } from 'types/operation';
import { CreatePDV, PDV } from 'types/pdv';
import { debounce } from 'utils/debounce';
import { openNotification } from 'utils/notification';
import { createPdvValidationSchema } from 'yupSchemas/pdv';
import * as Styles from './styles';

type FormData = CreatePDV;

export const CreatePDVComponent = ({
  history,
  match,
}: RouteComponentProps<{ id?: string }>): JSX.Element => {
  const { t } = useTranslation();
  const [tPage] = useTranslation('pages/pdv');
  const [toggles, setToggle] = useToggle<
    'cancelModal' | 'loadingOperations' | 'saving' | 'loadingDetails'
  >({
    cancelModal: false,
    loadingOperations: false,
    saving: false,
    loadingDetails: true,
  });
  const [operations, setOperations] = useState<SimpleOperationList[]>([]);
  const [details, setDetails] = useState<PDV>();
  const id = useMemo(() => match.params.id, [match.params.id]);

  const handleRedirect = useCallback(() => {
    setToggle('cancelModal', true);
  }, []);

  const handleConfirmRedirect = useCallback(() => {
    history.goBack();
    setToggle('cancelModal', false);
  }, []);

  const handleSubmit = useCallback(async (data: FormData) => {
    setToggle('saving', true);
    try {
      id ? await putPDV(id, data) : await postPDV(data);

      openNotification(tPage(id ? 'create.updatedMessage' : 'create.createdMessage'));
      history.goBack();
    } finally {
      setToggle('saving', false);
    }
  }, []);

  const handleSearchOperations = useCallback(
    async (e: any, value: string, reason: AutocompleteInputChangeReason) => {
      setToggle('loadingOperations', true);
      try {
        if (reason === 'input') {
          const response = await getOperationsList({ search: value, status: 1, isInventory: 0 });

          setOperations(response.data);
        }
      } catch (error) {
      } finally {
        setToggle('loadingOperations', false);
      }
    },
    [],
  );

  const handleGetDetails = useCallback(async () => {
    setToggle('loadingDetails', true);
    try {
      if (id) {
        const response = await getPdvById(id);

        setDetails(response.data);
      }
    } catch (error) {
    } finally {
      setToggle('loadingDetails', false);
    }
  }, [id]);

  useEffect(() => {
    id && handleGetDetails();
  }, []);

  return (
    <PageContainer md>
      <Flex column gap={1.62}>
        <h1>
          <IconButton onClick={() => handleRedirect()}>
            <Icon name="arrowBack" size={1.4} color="text" />
          </IconButton>

          {tPage(id ? 'edit.title' : 'create.title')}
        </h1>

        <Styles.FormContainer>
          {toggles.loadingDetails && id ? (
            <LoadingMessage text={t('loadingDetails')} />
          ) : (
            <Unform
              onSubmit={handleSubmit}
              initialData={{
                name: details?.name,
                custom_id: details?.custom_id,
                operation_id: details?.operation,
                is_totem: details?.is_totem,
              }}
              validationSchema={createPdvValidationSchema}
            >
              <Grid container columnSpacing={3}>
                <Grid item xs={12} sm={3}>
                  <UnInputFIeld name="custom_id" label="labels:input.id" />
                </Grid>

                <Grid item xs={12} sm={9}>
                  <UnInputFIeld name="name" label="labels:input.pdvName" />
                </Grid>

                <Grid item xs={12} sm={9} style={{ marginBottom: '1.2rem' }}>
                  <UnSwitch name="is_totem" label="pages/pdv:log.isToten" />
                </Grid>

                <Grid item xs={12}>
                  <UnAutocomplete
                    name="operation_id"
                    label="labels:input.operation"
                    noOptionsText="autocomplete.typeToSearch"
                    onInputChange={debounce(handleSearchOperations)}
                    optionLabel="name"
                    optionValue="id"
                    options={operations}
                    filterOptions={x => x}
                    loading={toggles.loadingOperations}
                  />
                </Grid>
              </Grid>

              <Hr margin="0 -1.5rem" />

              <Flex marginTop={1.5}>
                <Flex gap={1} marginLeft="auto">
                  <Button variant="cancel" onClick={() => handleRedirect()}>
                    {t('button.cancel')}
                  </Button>

                  <Button type="submit" isLoading={toggles.saving}>
                    {t('button.save')}
                  </Button>
                </Flex>
              </Flex>
            </Unform>
          )}
        </Styles.FormContainer>

        <ConfirmModal
          open={toggles.cancelModal}
          title={t('confirmCancelOperation.title')}
          bodyText={t('confirmCancelOperation.body')}
          onClose={() => setToggle('cancelModal', false)}
          onConfirm={handleConfirmRedirect}
        />
      </Flex>
    </PageContainer>
  );
};

import { ComponentProps, useEffect, useRef, memo } from 'react';
import { useField } from '@unform/core';

import { Counter, CountRef } from 'components/Counter';

type CounterProps = ComponentProps<typeof Counter>;

function BaseUnCounter(props: CounterProps) {
  const ref = useRef<CountRef>(null);

  const { fieldName, registerField, defaultValue } = useField(props.name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref,
      getValue: ref => ref.current.count,
    });
  }, [fieldName, registerField, defaultValue]);

  return <Counter {...props} ref={ref} defaultValue={defaultValue} />;
}

export const UnCounter = memo(BaseUnCounter);

import { Grid } from '@mui/material';
import { IconButton, UnFieldArray, UnSwitch } from 'components';
import { useOperationOptions } from 'hooks/useOperationOptions';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'styles/Flex';
import { Legend } from 'styles/Legend';
import { SimpleOperationList } from 'types/operation';
import { debounce } from 'utils';
import * as Styles from './styles';

interface Props {
  accessAll?: boolean;
}

const OperationPartComponent = ({ accessAll: accessAllProp }: Props): JSX.Element => {
  const { operations, onLoadOperations, isLoading } = useOperationOptions();
  const [tPage] = useTranslation('pages/user');
  const [acceptAll, setAcceptAll] = useState<boolean>(accessAllProp || false);

  const handleLoadOperations = useCallback((e, value: string) => {
    onLoadOperations(value ? { search: value } : {});
  }, []);

  const handleChangeAll = useCallback((e, checked: boolean) => {
    setAcceptAll(checked);
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Legend>{tPage('create.linkOperations')}</Legend>
      </Grid>

      <Grid item xs={12}>
        <Flex marginBottom={0.5}>
          <UnSwitch
            name="operationAccessAll"
            label="labels:input.user.accessAll"
            onChange={handleChangeAll}
          />
        </Flex>
      </Grid>

      <Grid item xs={12}>
        <UnFieldArray
          name="operationsAccessId"
          unicKeyFilter="id"
          optionLabel="name"
          optionValue="id"
          noOptionsText="autocomplete.typeToSearch"
          placeholder={'labels:input.user.selectOperations'}
          onInputChange={debounce(handleLoadOperations)}
          filterOptions={x => x}
          options={operations}
          loading={isLoading}
          onGetValue={(values: SimpleOperationList[]) => values.map(value => value.id)}
          disabled={acceptAll}
          render={({ remove, disabled }, values) => (
            <Styles.List>
              {values.map((value, index) => (
                <Styles.ListItem key={`operation-${index}`}>
                  <Flex>{value.name}</Flex>
                  <Flex marginLeft="auto">
                    <IconButton
                      onClick={() => remove(index)}
                      disabled={disabled}
                      icon={{
                        name: 'closeCircle',
                        size: 1.2,
                        color: disabled ? 'text200' : 'text300',
                      }}
                    />
                  </Flex>
                </Styles.ListItem>
              ))}
            </Styles.List>
          )}
        />
      </Grid>
    </>
  );
};

export const OperationPart = memo(OperationPartComponent);

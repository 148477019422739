import { FunctionComponent } from 'react';

export function withContext<T = any>(
  Component: FunctionComponent<T>,
  Context: FunctionComponent<any>,
) {
  return (props: T) => (
    <Context>
      <Component {...props} />
    </Context>
  );
}

import styled, { DefaultTheme } from 'styled-components';

type Variants = keyof DefaultTheme['paymentMethodRadio'];

interface Props {
  variant?: Variants;
}

export const Container = styled.div<Props>`
  width: max-content;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 0rem 1rem;
  border: 2px solid ${({ theme, variant = 'primary' }) => theme.paymentMethodRadio[variant].border};
  background-color: ${({ theme, variant = 'primary' }) =>
    theme.paymentMethodRadio[variant].background};
`;

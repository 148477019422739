import { memo, useEffect, useRef, useState } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useTranslation } from 'react-i18next';
import { openNotification } from 'utils/notification';

import { Button, Unform, UnMaskInput } from 'components';
import { Modal } from 'components/Modal';
import { Flex } from 'styles/Flex';
import { putProductOnOperation } from 'services/operation';
import { editProductInventoryValidationSchema } from 'yupSchemas/operation';
import { ProductInventoryOperation } from 'types/product';

interface IProps {
  open: boolean;
  onClose(newOpen: boolean): void;
  title: string;
  updateCreate(): void;
  product: ProductInventoryOperation;
  operationId: number | string;
}

interface IDetails {
  min: number;
  max: number;
}
const ModalComponent = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [tPage] = useTranslation('pages/operations');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [details, setDetails] = useState<Partial<IDetails>>();
  const putRequest = async (detail: IDetails): Promise<void> => {
    try {
      const { data } = await putProductOnOperation(props.operationId, props.product.id, detail);
      setDetails(data);
    } catch (err) {}
  };
  const handleSubmit: SubmitHandler<IDetails> = async detail => {
    setIsSaving(true);
    try {
      await putRequest(detail);
      openNotification(tPage('details.inventoryTab.table.updatedSuccess'));
      props.updateCreate();
    } catch (err) {
      props.updateCreate();
    }
  };
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    setDetails({ min: props.product.min, max: props.product.max });
  }, []);
  return (
    <Modal
      title={props.title}
      open={props.open}
      PaperProps={{ style: { maxWidth: '25rem', width: '100%' } }}
      onClose={props.onClose}
    >
      <Unform
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={details}
        validationSchema={editProductInventoryValidationSchema}
      >
        <Flex gap={1} marginTop={2}>
          <UnMaskInput
            cleanValue
            parseToFloat
            name="min"
            label="pages/operations:details.inventoryTab.table.min"
          />
          <UnMaskInput
            cleanValue
            parseToFloat
            name="max"
            label="pages/operations:details.inventoryTab.table.max"
          />
        </Flex>
        <Flex justifyContent="flex-end" gap={1}>
          <Button variant="cancel" onClick={() => props.onClose(false)}>
            {t('button.cancel')}
          </Button>

          <Button type="submit" isLoading={isSaving}>
            {t('button.confirm')}
          </Button>
        </Flex>
      </Unform>
    </Modal>
  );
};

export const EditModal = memo(ModalComponent);

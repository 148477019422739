import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Operation, PDV, Products } from './components';
import { PageContainer } from 'styles/PageContainer';

import * as Styles from './styles';
import { AuthorizeView } from 'components';

interface IProps {}

export const Home: FC<IProps> = () => {
  const [tPage] = useTranslation('pages/home');

  return (
    <PageContainer>
      <h1>{tPage('title')}</h1>

      <Styles.Body>
        <AuthorizeView permission="CREATE_OPERATION">
          <Operation />
        </AuthorizeView>

        <AuthorizeView permission="CREATE_PDV">
          <PDV />
        </AuthorizeView>

        <AuthorizeView permission="CREATE_PRODUCT">
          <Products />
        </AuthorizeView>
      </Styles.Body>
    </PageContainer>
  );
};

import { Paginated } from 'types/api';
import {
  GetTourisOperator,
  GetTouristGuideDetails,
  GetTouristGuideList,
  GetTouristGuideListParams,
  GetTouristOperatorParams,
  PostTouristGuide,
  PostTouristOperator,
} from 'types/touristGuide';
import { objToQuery } from 'utils/query';
import api from '../services/apis/api';

export const postTouristOperator = (data: PostTouristOperator) =>
  api.post('/tourist-guide/operator', data);

export const getTouristOperator = (params?: GetTouristOperatorParams) =>
  api.get<GetTourisOperator[]>(`/tourist-guide/operator${params ? `?${objToQuery(params)}` : ''}`);

export const putTouristOperator = (
  touristGuideOperatorId: number | string,
  data?: PostTouristOperator,
) => api.put(`/tourist-guide/operator/${touristGuideOperatorId}`, data);

export const postTouristGuide = (data: PostTouristGuide) => api.post('/tourist-guide', data);

export const getTouristGuideList = (params?: GetTouristGuideListParams) =>
  api.get<Paginated<GetTouristGuideList>>(
    `/tourist-guide${params ? `?${objToQuery(params)}` : ''}`,
  );

export const getTouristGuideDetails = (id: number | string) =>
  api.get<GetTouristGuideDetails>(`/tourist-guide/${id}`);

export const putTouristGuide = (touristGuideId: number | string, data: PostTouristGuide) =>
  api.put(`/tourist-guide/${touristGuideId}`, data);

export const patchTouristGuideStatus = (touristGuideId: number | string) =>
  api.patch(`/tourist-guide/${touristGuideId}/updateStatus`);

import { memo } from 'react';

import * as Styles from './styles';

interface TagProps extends Styles.TagProps {
  label?: string;
}

function baseTag({ label, ...props }: TagProps) {
  return <Styles.Tag {...props}>{label}</Styles.Tag>;
}

export const Tag = memo(baseTag);

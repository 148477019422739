import { FormEvent, memo, useEffect, useRef, useState } from 'react';

import { IconButton } from 'components';

import * as Styles from './styles';
import useBooleanToggle from 'hooks/useBooleanToggle';
import { Flex } from 'styles/Flex';

interface UpdateInputProps {
  defaultValue?: string;
  onSubmit?: (value: string) => Promise<void>;
}

function BaseUpdateInput({ onSubmit, ...props }: UpdateInputProps) {
  const [value, setValue] = useState(props.defaultValue);

  const ref = useRef<HTMLInputElement>(null);

  const [isDisabled, toggleIsDisabled] = useBooleanToggle(true);

  const handleEdit = () => {
    toggleIsDisabled();
    ref?.current?.focus?.();
  };

  const handleSubmit = async (event: FormEvent) => {
    toggleIsDisabled();

    event.preventDefault();
    await onSubmit?.(value as string);
  };

  useEffect(() => {
    ref?.current?.focus();
  }, [ref?.current]);

  return (
    <Flex flex={1}>
      <Styles.Container onSubmit={handleSubmit}>
        {isDisabled ? (
          <Styles.Label>{value}</Styles.Label>
        ) : (
          <Styles.Input
            {...props}
            required
            value={value}
            autoFocus
            ref={ref}
            disabled={isDisabled}
            onChange={event => setValue(event.target.value)}
          />
        )}

        {!isDisabled && <IconButton type="submit" icon={{ name: 'done', color: 'valid' }} />}
      </Styles.Container>
      {isDisabled && <IconButton type="button" icon={{ name: 'edit' }} onClick={handleEdit} />}
    </Flex>
  );
}

export const UpdateInput = memo(BaseUpdateInput);

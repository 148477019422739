import { memo, useRef, useEffect, ComponentProps } from 'react';
import { useField } from '@unform/core';

import { RadioGroup } from 'components';
import { useTranslation } from 'react-i18next';

type RadioGroupRootProps = ComponentProps<typeof RadioGroup>;

interface UnRadioGroupProps extends RadioGroupRootProps {
  name: string;
}

function BaseUnRadioGroup({ name, helperText, ...props }: UnRadioGroupProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [tErros] = useTranslation('formErrors');

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => ref?.current?.value,
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <RadioGroup
      defaultValue={defaultValue}
      {...props}
      helperText={error ? tErros(error) : helperText}
      ref={inputRef}
    />
  );
}

export const UnRadioGroup = memo(BaseUnRadioGroup);

import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styled from 'styled-components';

const Container = styled(MuiAccordion)`
  box-shadow: none;
`;

const Header = styled(AccordionSummary)`
  padding: 0;
  min-height: 0;

  .Mui-expanded {
    margin: 0;
  }

  &.Mui-expanded {
    min-height: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const Body = styled(AccordionDetails)`
  padding: 0;
`;

export const Accordion = {
  Container,
  Header,
  Body,
};

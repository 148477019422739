import { LoadingMessage } from 'components';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPhotoToDownload } from 'services/qrcode';
import { GetQRCodeDigitalPhotos } from 'types/QRCode';
import { downloadFile } from 'utils/api';
import { openNotification } from 'utils/notification';
import * as Styles from './styles';

interface Props {
  photo: GetQRCodeDigitalPhotos;
}

const DownloadButtonBase = ({ photo }: Props): JSX.Element => {
  const [tPage] = useTranslation('pages/gallery');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownloadPhoto = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getPhotoToDownload(photo.qr_code, photo.id);

      if (response.status === 200) {
        downloadFile(response.data, photo.filename);
      }
    } catch (error) {
      openNotification(tPage('errorOnDownloadPhoto '), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [photo]);

  return (
    <Styles.Container onClick={handleDownloadPhoto}>
      {isLoading ? <LoadingMessage size={20} /> : <Styles.pBox>Baixar</Styles.pBox>}
    </Styles.Container>
  );
};

export const DownloadButton = memo(DownloadButtonBase);

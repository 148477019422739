import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { DatePicker } from 'components';

import { pushHistoryQuery } from 'utils/query';

interface DateFilterProps {
  keyQuery?: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
}

function BaseDateFilter({ keyQuery = 'date', label, placeholder, defaultValue }: DateFilterProps) {
  const history = useHistory();

  const handleChange = (date: unknown) => {
    console.log(date);
    if (!date) {
      pushHistoryQuery({ [keyQuery]: '' }, history);
      return;
    }
    const query = {
      [keyQuery]: new Date(date as any).toISOString(),
    };

    pushHistoryQuery(query, history);
  };

  return (
    <DatePicker
      onChange={handleChange}
      defaultValue={defaultValue}
      label={label}
      placeholder={placeholder}
      contained
    />
  );
}

export const DateFilter = memo(BaseDateFilter);

import { ComponentProps, memo, SyntheticEvent } from 'react';

import { UnFieldArray, UnCounter } from 'components';

import useProductOptions from 'hooks/useProductOptions';

import { debounce } from 'utils';
import { isOdd } from 'utils/help';

import * as Styles from './styles';

import { GetProductAllList } from 'types/product';

type UnFieldArrayProps = Pick<ComponentProps<typeof UnFieldArray>, 'disabled'>;

interface ProductArrayProps extends UnFieldArrayProps {
  name: string;
}

function BaseUnProductArray(props: ProductArrayProps) {
  const { onLoadProducts, products } = useProductOptions();

  const handleChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    if (!value) return;

    onLoadProducts({
      search: value,
      status: 1,
    });
  };

  return (
    <UnFieldArray
      name={props.name}
      disabled={props?.disabled}
      options={products}
      onInputChange={debounce(handleChange)}
      unicKeyFilter="id"
      optionLabel="name"
      optionValue="id"
      render={({ remove }, _products) =>
        _products.map((product: GetProductAllList, index) => (
          <Styles.Item isOdd={isOdd(index)} key={index}>
            <span>{product.name}</span>
            <UnCounter name={`${props.name}[${index}].amount`} />
            <Styles.RemoveButton
              onClick={() => remove(index)}
              icon={{
                name: 'close',
                color: 'text100',
              }}
            />
          </Styles.Item>
        ))
      }
    />
  );
}

export const UnProductArray = memo(BaseUnProductArray);

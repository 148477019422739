import { breakpoints } from 'constants/layout';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { Flex } from './Flex';

type Props = ComponentProps<typeof Flex>;

export const PageContainer = styled(Flex).attrs<Props>(({ column = true, ...props }) => ({
  column,
  ...props,
}))<{ lg?: boolean; md?: boolean }>`
  padding: 0
    ${({ md }) => {
      if (md) return '12rem';

      // lg is default
      return '5rem';
    }};

  @media (max-width: ${breakpoints.lg}) {
    padding: 0
      ${({ md }) => {
        if (md) return '7rem';

        // lg is default
        return '3rem';
      }};
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0
      ${({ md }) => {
        if (md) return '3rem';

        // lg is default
        return '0';
      }};
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 0
      ${({ md }) => {
        if (md) return '0';

        // lg is default
        return '0';
      }};
  }

  h1 {
    margin: 0;
  }
`;

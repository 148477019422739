import { useField } from '@unform/core';
import { Switch } from 'components';
import { ComponentProps, memo, useEffect, useRef } from 'react';

type SwitchRootProps = ComponentProps<typeof Switch>;

interface UnSwitchProps extends SwitchRootProps {
  name: string;
  returnValue?: boolean;
}

function BaseUnSwitch({ name, returnValue, ...props }: UnSwitchProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      setValue: (ref, value) => (ref.current.checked = value),
      getValue: ref =>
        returnValue
          ? ref.current.checked
            ? ref.current.value
            : ref.current.checked
          : ref.current.checked,
    });
  }, [fieldName, registerField]);

  return <Switch defaultChecked={!!defaultValue} {...props} ref={inputRef} />;
}

export const UnSwitch = memo(BaseUnSwitch);

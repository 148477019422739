import styled from 'styled-components';

import { IconButton } from 'components';

export const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  height: 9rem;
  width: 9rem;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  flex: none;
`;

export const DeleteContainer = styled(IconButton)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.foreground} !important;
  border-radius: 50%;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.2rem;

  :hover {
    filter: brightness(90%);
  }
`;

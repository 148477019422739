import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Table } from 'components';

import { ProductByIdItem } from 'types/product';

import { PRODUCT_TYPES } from 'constants/product';

import { Flex } from 'styles/Flex';

interface ComboProps {
  products?: ProductByIdItem[];
}

function BaseCombo({ products }: ComboProps) {
  const [tPage] = useTranslation('pages/products');

  const columnsPhysical: Column[] = [
    {
      field: 'name',
      headerName: tPage('detail.combo.table.productPhysical'),
      _style: {
        width: '100%',
      },
    },
    {
      field: 'amount',
      headerName: tPage('detail.combo.table.length'),
    },
  ];

  const columnsDigital: Column[] = [
    {
      field: 'name',
      headerName: tPage('detail.combo.table.productDigital'),
      _style: {
        width: '100%',
      },
    },
    {
      field: 'amount',
      headerName: tPage('detail.combo.table.length'),
    },
  ];

  const rowPhysical = useMemo(
    () =>
      products
        ?.filter(product => product.type === PRODUCT_TYPES.physical)
        .map(product => ({
          name: product.name,
          amount: product.amount,
        })),
    [products],
  );

  const rowDigital = useMemo(
    () =>
      products
        ?.filter(product => product.type === PRODUCT_TYPES.digital)
        .map(product => ({
          name: product.name,
          amount: product.amount,
        })),
    [products],
  );

  return (
    <Flex column gap={2}>
      <Table tableVariant="secondary" columns={columnsPhysical} rows={rowPhysical || []} />
      <Table tableVariant="secondary" columns={columnsDigital} rows={rowDigital || []} />
    </Flex>
  );
}

export const Combo = memo(BaseCombo);

import { memo, SyntheticEvent } from 'react';

import useProductOptions from 'hooks/useProductOptions';

import { Autocomplete } from 'components';
import { debounce } from 'utils';
import { pushHistoryQuery } from 'utils/query';
import { useHistory } from 'react-router';
import { GetProductAllList } from 'types/product';

interface SearchProductByNameProps {
  defaultValue?: string | null;
  placeholder?: string;
  onChange?: (value: GetProductAllList) => void;
  queryKey?: string;
  types: ('PHYSICAL' | 'DIGITAL' | 'COMBO')[];
  queryValue?: string;
}

function BaseSearchProductByName({
  defaultValue,
  placeholder,
  queryKey = 'id',
  queryValue = 'id',
  types,
  ...props
}: SearchProductByNameProps) {
  const history = useHistory();

  const { onLoadProducts, products } = useProductOptions();

  const handleSearchProduct = async (event: SyntheticEvent<Element, Event>, value: any) => {
    value.length === 0 && pushHistoryQuery({ [queryKey]: '' }, history);

    onLoadProducts({
      search: value,
      types: types.join(','),
    });
  };

  const handleSelectProduct = (event: SyntheticEvent, option?: GetProductAllList) => {
    if (!option) return;

    props?.onChange?.(option);

    const query = {
      [queryKey]: option[(queryValue || queryValue) as keyof typeof option],
    };

    !props?.onChange && pushHistoryQuery(query, history);

    localStorage.setItem('productId', option.id.toString());
  };

  return (
    <Autocomplete
      contained
      optionLabel="name"
      optionValue="id"
      onInputChange={debounce(handleSearchProduct)}
      defaultValue={{ name: defaultValue }}
      placeholder={placeholder}
      options={products}
      onChange={handleSelectProduct}
    />
  );
}

export const SearchProductByNameFilter = memo(BaseSearchProductByName);

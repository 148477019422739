import { Autocomplete, Button, Icon } from 'components';
import { PRODUCT_TYPES } from 'constants/product';
import { routePaths } from 'constants/routes';
import { useToggle } from 'hooks/useToggle';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getDashProducts } from 'services/dash';
import { DashBase } from 'types/dash';
import { ProductType } from 'types/product';
import { objToQuery } from 'utils/query';
import { LoadingMessage } from '..';

import * as HomeStyles from '../../styles';

interface IProps {}

const ProductsComponent: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [toggles, setToggles] = useToggle<'loadingData'>({ loadingData: true });
  const [data, setData] = useState<DashBase>({
    actives: 0,
    inatives: 0,
    total: 0,
  });
  const [selectedType, setSelectedType] = useState<ProductType>();

  const handleRedirect = useCallback(
    (toDetails?: boolean) => {
      if (toDetails) {
        history.push(`${routePaths.products.list}?${objToQuery({ type: selectedType })}`);
        return;
      }

      history.push(routePaths.products.create);
    },
    [selectedType],
  );

  const handleGetData = useCallback(async (productType?: ProductType) => {
    try {
      setToggles('loadingData', true);

      setSelectedType(productType);

      const response = await getDashProducts(objToQuery({ type: productType }));

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
    } finally {
      setToggles('loadingData', false);
    }
  }, []);

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <HomeStyles.CardContainer>
      <h2>{t('pages/home:products.title')}</h2>

      {toggles.loadingData && <LoadingMessage title={t('pages/home:pdv.title')} />}

      <Autocomplete
        label="labels:input.type"
        clearHelperTextSpacing
        options={[
          {
            label: t('labels:input.product.listOfType.digital'),
            value: PRODUCT_TYPES.digital,
          },
          {
            label: t('labels:input.product.listOfType.physical'),
            value: PRODUCT_TYPES.physical,
          },
          {
            label: t('labels:input.product.listOfType.combo'),
            value: PRODUCT_TYPES.combo,
          },
        ]}
        onChange={(e, option) => {
          handleGetData(option?.value);
        }}
      />

      <HomeStyles.StatusContainer>
        <HomeStyles.StatusCard>
          <h3>{data.total || 0}</h3>

          <span>{t('pages/home:labels.total')}</span>
        </HomeStyles.StatusCard>

        <HomeStyles.StatusCard>
          <h3>{data.actives || 0}</h3>

          <span>{t('pages/home:labels.active')}</span>
        </HomeStyles.StatusCard>

        <HomeStyles.StatusCard>
          <h3>{data.inatives || 0}</h3>

          <span>{t('pages/home:labels.inactive')}</span>
        </HomeStyles.StatusCard>
      </HomeStyles.StatusContainer>

      <HomeStyles.ButtonsContainer>
        <Button onClick={() => handleRedirect()}>
          <Icon name="add" size={1.5} marginRight={0.5} marginLeft={-0.5} />

          {t('pages/home:buttons.register')}
        </Button>

        <Button outline onClick={() => handleRedirect(true)}>
          {t('pages/home:buttons.detail')}
        </Button>
      </HomeStyles.ButtonsContainer>
    </HomeStyles.CardContainer>
  );
};

export const Products = memo(ProductsComponent);

import styled from 'styled-components';

export const Warn = styled.span<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.colors.red : 'auto')};
`;

export const WarnContainer = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    margin: 0 !important;
  }
`;

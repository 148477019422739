import { Button } from 'components';
import styled from 'styled-components';
import { ReactComponent as VyooLogoSVG } from '../../../../assets/vyoo-logo-novo.svg';

export const Header = styled.header`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.foreground};
  padding: 0.7rem 2rem;
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const UserIconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThrowPaxButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.valid};
  padding: 0.7rem 1rem;
  min-height: 0;
`;

export const VyooLogo = styled(VyooLogoSVG)`
  margin-left: 1rem;
  cursor: pointer;
  margin-right: auto;
`;

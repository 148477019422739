import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ComponentProps, useEffect, useState } from 'react';

import { Button, IconButton, LoadingMessage, TabOption, Tabs } from 'components';
import { Base, Billing, CapitaBilling, CaptureSale, ProductsGoal } from './components';

import { resolvePath, routePaths } from 'constants/routes';

import * as Styles from 'styles/Details';
import { Flex } from 'styles/Flex';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { getGoalById } from 'services/goal';
import { GetGoalById, GetGoals } from 'types/goal';

type BaseProps = ComponentProps<typeof Base>;

export function DetailGoal(props: RouteComponentProps<{ id: string }>) {
  const [tPage] = useTranslation('pages/goal');
  const { t } = useTranslation();

  const [isLoading, toggleIsLoading] = useBooleanToggle(false);

  const [details, setDetails] = useState<GetGoalById>();

  const id = props.match.params.id;

  const subTypes = details?.goals
    ? Object.keys(details?.goals).map(key => ({
        label: t(`pages/goal:create.typeGoal.${key}`),
        award_type: t(
          `pages/goal:create.award_types.${details?.goals?.[key as keyof GetGoals]?.award_type}`,
        ),
      }))
    : [];

  const operations = details?.goals
    ? Object.keys(details?.goals).map(key => {
        const operations = details?.goals[key as keyof GetGoals]?.operations as any[];

        return operations?.reduce((prev, next) => ({
          ...prev,
          name: next.operation_name,
        }));
      })
    : [];

  const users = details?.goals
    ? Object.keys(details?.goals).map(key => {
        const users = details?.goals[key as keyof GetGoals]?.users as any[];

        return users?.reduce((prev, next) => ({
          ...prev,
          name: next.user_name,
        }));
      })
    : [];

  const handleGetDetails = async (id: string) => {
    try {
      toggleIsLoading();
      const { data } = await getGoalById(id);

      setDetails(data);
    } catch (err) {
    } finally {
      toggleIsLoading();
    }
  };

  const tabs: TabOption[] = [
    {
      label: t('pages/goal:detail.tabs.base'),
      value: 0,
    },
    {
      label: t('pages/goal:create.goals.revenues'),
      hidden: !details?.goals?.billing,
      value: 1,
    },
    {
      label: t('pages/goal:create.goals.revenuesPerCapita'),
      hidden: !details?.goals?.capita_billing,
      value: 2,
    },
    {
      label: t('pages/goal:create.goals.productQuantity'),
      hidden: !details?.goals?.products_goal,
      value: 3,
    },
    {
      label: t('pages/goal:create.goals.captureSale'),
      hidden: !details?.goals?.capture_sale,
      value: 4,
    },
  ];

  useEffect(() => {
    if (!id) return;

    handleGetDetails(id);
  }, [id]);

  return (
    <Styles.Container>
      <Flex alignItems="center" wrap gap={1} justifyContent="space-between">
        <Flex alignItems="center" gap={1}>
          <IconButton
            icon={{ name: 'arrowBack', size: 1.4 }}
            onClick={() => props.history.goBack()}
          />
          <h1 style={{ margin: 0 }}>{tPage('detail.title')}</h1>
        </Flex>
        <Button onClick={() => props.history.push(resolvePath(routePaths.goal.edit, { id }))}>
          {tPage('detail.editButton')}
        </Button>
      </Flex>
      <Styles.Wrapper>
        {isLoading ? (
          <LoadingMessage text="Carregando informações" />
        ) : (
          <Tabs tabs={tabs}>
            <Base
              finalDate={details?.final_date}
              initialDate={details?.initial_date}
              goals={subTypes}
              operation={details?.operation}
              operations={
                operations
                  .filter(value => value)
                  .map(value => ({ operation: value?.operation_name })) as BaseProps['operations']
              }
              users={
                users
                  .filter(value => value)
                  .map(value => ({ user: value?.user_name })) as BaseProps['users']
              }
            />
            <Billing
              type={details?.goals?.billing?.award_type || 'VALUE'}
              users={details?.goals?.billing?.users || []}
              operations={details?.goals?.billing?.operations || []}
            />
            <CapitaBilling
              type={details?.goals?.capita_billing?.award_type || 'VALUE'}
              operations={details?.goals?.capita_billing?.operations || []}
            />
            <ProductsGoal
              operations={details?.goals?.products_goal?.operations || []}
              users={details?.goals?.products_goal?.users || []}
            />
            <CaptureSale
              type={details?.goals?.capture_sale?.award_type || 'VALUE'}
              users={details?.goals?.capture_sale?.users || []}
              operations={details?.goals?.capture_sale?.operations || []}
            />
          </Tabs>
        )}
      </Styles.Wrapper>
    </Styles.Container>
  );
}

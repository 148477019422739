import { useTranslation } from 'react-i18next';
import { memo } from 'react';

import { Column, Table, UnMaskInput } from 'components';

import { TABLE_FIELDS } from 'constants/goal';

import { SimpleOperationList } from 'types/operation';

import * as Styles from '../../styles';
import { CorrectMaskInput } from '../CorrectMaskInput';

import { Masks, masks } from '../Monetize';

interface RenderOperationsProps {
  data?: SimpleOperationList[];
  mask?: Masks;
  type?: string;
}

function BaseRenderOperations({ data = [], type, mask = 'currency' }: RenderOperationsProps) {
  const [tPage] = useTranslation('pages/goal');
  const { t } = useTranslation();

  const maskPlaceholders = {
    [masks.percent]: '0 %',
    [masks.number]: '0',
    [masks.currency]: t('currency', { value: 0 }),
  };

  const columns: Column[] = [
    {
      headerName: tPage('create.table.operation'),
      field: TABLE_FIELDS.operation,
    },
    {
      headerName: tPage('create.table.minimumGoal'),
      field: TABLE_FIELDS.minimal,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.minimal_award,
    },
    {
      headerName: tPage('create.table.idealGoal'),
      field: TABLE_FIELDS.ideal,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.ideal_award,
    },
    {
      headerName: tPage('create.table.superGoal'),
      field: TABLE_FIELDS.super,
    },
    {
      headerName: tPage('create.table.award'),
      field: TABLE_FIELDS.super_award,
    },
  ];

  const rows =
    data?.map((operation, index) => ({
      [TABLE_FIELDS.operation]: operation.name,
      [TABLE_FIELDS.minimal]: (
        <Styles.InputField size={8}>
          <UnMaskInput
            name={`operations[${index}].minimal.target`}
            mask={mask}
            placeholderAlternative={maskPlaceholders[mask]}
            cleanValue
            parseToFloat
          />
        </Styles.InputField>
      ),
      [TABLE_FIELDS.minimal_award]: (
        <Styles.InputField size={5}>
          <CorrectMaskInput name={`operations[${index}].minimal.award`} type={type} />
        </Styles.InputField>
      ),
      [TABLE_FIELDS.ideal]: (
        <Styles.InputField size={8}>
          <UnMaskInput
            name={`operations[${index}].ideal.target`}
            mask={mask}
            placeholderAlternative={maskPlaceholders[mask]}
            cleanValue
            parseToFloat
          />
        </Styles.InputField>
      ),
      [TABLE_FIELDS.ideal_award]: (
        <Styles.InputField size={5}>
          <CorrectMaskInput name={`operations[${index}].ideal.award`} type={type} />
        </Styles.InputField>
      ),
      [TABLE_FIELDS.super]: (
        <Styles.InputField size={8}>
          <UnMaskInput
            name={`operations[${index}].super.target`}
            mask={mask}
            placeholderAlternative={maskPlaceholders[mask]}
            cleanValue
            parseToFloat
          />
        </Styles.InputField>
      ),
      [TABLE_FIELDS.super_award]: (
        <Styles.InputField size={5}>
          <CorrectMaskInput name={`operations[${index}].super.award`} type={type} />
        </Styles.InputField>
      ),
    })) || [];

  if (data.length === 0) return null;

  return <Table tableVariant="secondary" columns={columns} rows={rows} />;
}

export const RenderOperations = memo(BaseRenderOperations);

import styled from 'styled-components';

interface IHr {
  marginTop?: number | 'auto';
  marginBottom?: number | 'auto';
  marginLeft?: number | 'auto';
  marginRight?: number | 'auto';
  margin?: string;
}

export const Hr = styled.div<IHr>`
  height: 1px;
  border-top: 1px solid ${({ theme }) => theme.colors.text200};

  margin-top: ${props =>
    props.marginTop === 'auto' ? props.marginTop : `${props.marginTop || 0}rem`};
  margin-right: ${props =>
    props.marginRight === 'auto' ? props.marginRight : `${props.marginRight || 0}rem`};
  margin-bottom: ${props =>
    props.marginBottom === 'auto' ? props.marginBottom : `${props.marginBottom || 0}rem`};
  margin-left: ${props =>
    props.marginLeft === 'auto' ? props.marginLeft : `${props.marginLeft || 0}rem`};
  margin: ${props => props.margin};
  flex: 1;
`;

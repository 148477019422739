import { FormHelperText, Grid } from '@mui/material';
import { AutocompleteInputChangeReason } from '@mui/core';
import { FormHandles, SubmitHandler, useField } from '@unform/core';
import {
  Button,
  Icon,
  Table,
  UnAutocomplete,
  Unform,
  UnMaskInput,
  UnHiddenInput,
  ConfirmModal,
} from 'components';
import { useProducts } from 'hooks/useProducts';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Legend } from 'styles/Legend';
import { GetProductAllList } from 'types/product';
import { addProductValidationSchema } from 'yupSchemas/inventory';
import { Flex } from 'styles/Flex';
import { debounce } from 'utils';
import { OperationIds, SelectedOperation } from 'pages';
import { INVENTORY_TYPES } from 'constants/inventory';
import { postValidateProductToEntry } from 'services/inventory';
import { useBooleanObj } from 'hooks/useBooleanObj';

interface IProps {
  selectedOperation?: SelectedOperation;
  operationIds: OperationIds;
  type: { label: string; value: string };
  onToggleHaveProduct(newHaveProduct: boolean): void;
}

export interface UniqueProduct {
  product: GetProductAllList;
  operations: OperationIds;
  amount: number;
  unit_cost?: number;
  type: string;
}

interface BooleansObj {
  openRemoveProduct: boolean;
}

const AddProductsComponent = ({ type, onToggleHaveProduct, operationIds }: IProps): JSX.Element => {
  const { registerField, fieldName, error, clearError } = useField('products');
  const [tPage] = useTranslation('pages/inventory', { keyPrefix: 'create' });
  const { t } = useTranslation();
  const {
    products: productOptions,
    searchProducts,
    isLoading,
    setExcludedIds,
    cleanProducts,
  } = useProducts({ operationId: operationIds?.destination || undefined });
  const [products, setProducts] = useState<UniqueProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<UniqueProduct>();
  const [toggles, setToggle] = useBooleanObj<BooleansObj>({ openRemoveProduct: false });

  const formRef = useRef<FormHandles>(null);

  const handleSearchProduct = useCallback(
    async (e, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason !== 'input' || !value) return;

      searchProducts({ search: value, types: ['PHYSICAL'] });
    },
    [searchProducts],
  );

  const handleSubmit = useCallback<SubmitHandler<UniqueProduct>>(
    async (data, { reset }) => {
      try {
        if (data.type === INVENTORY_TYPES.transfer) {
          if (!data.operations.origin) return;
          if (!data.operations.destination) return;

          await postValidateProductToEntry(data.operations.origin, {
            type: data.type,
            originOperationId: Number(data.operations.origin),
            product: {
              id: data.product.id,
              amount: data.amount,
              unit_cost: data.unit_cost,
            },
          });
        }

        setProducts(prev => [data, ...prev]);
        setExcludedIds(prev => [...prev, data.product.id]);
        reset();
        error && clearError();
      } catch (error) {}
    },
    [type],
  );

  const handleRemoveProduct = useCallback(() => {
    setProducts(prev =>
      prev.filter(productMap => productMap.product.id !== selectedProduct?.product?.id),
    );
    setExcludedIds(prev => prev.filter(id => id !== selectedProduct?.product.id));
  }, [selectedProduct]);

  useEffect(() => {
    onToggleHaveProduct(!!products.length);

    registerField({
      name: fieldName,
      getValue: () =>
        products.map(product => ({
          amount: product.amount,
          unit_cost: product?.unit_cost || null,
          id: product.product.id,
        })),
    });
  }, [products]);

  useEffect(() => {
    cleanProducts();
    setExcludedIds([]);
  }, [operationIds.destination, type]);

  useEffect(() => {
    setProducts([]);
  }, [type]);

  return (
    <Grid item xs={12}>
      <Legend>{tPage('addProducts')}</Legend>

      <Unform ref={formRef} validationSchema={addProductValidationSchema} onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <UnHiddenInput name="operations" value={operationIds} />

          <Grid item xs={type.value === INVENTORY_TYPES.entry ? 12 : 8}>
            <UnAutocomplete
              name="product"
              contained
              returnAllOption
              loading={isLoading}
              label="labels:input.productName"
              noOptionsText="autocomplete.typeToSearch"
              filterOptions={opt => opt}
              onInputChange={debounce(handleSearchProduct)}
              optionLabel="name"
              optionValue="id"
              getOptionLabel={(option: GetProductAllList) =>
                option?.id ? `${option?.id} - ${option?.name}` : ''
              }
              options={productOptions}
              disabled={
                type.value === INVENTORY_TYPES.entry
                  ? !operationIds.destination
                  : !(operationIds.destination && operationIds.origin)
              }
            />
          </Grid>

          <Grid item xs={type.value === INVENTORY_TYPES.entry ? 6 : 4}>
            <UnMaskInput
              name="amount"
              parseToFloat
              label="labels:inventory.amount"
              disabled={
                type.value === INVENTORY_TYPES.entry
                  ? !operationIds.destination
                  : !operationIds.destination && !operationIds.origin
              }
            />
          </Grid>

          {type.value === INVENTORY_TYPES.entry && (
            <Grid item xs={6}>
              <UnMaskInput
                name="unit_cost"
                parseToFloat
                mask="currency"
                label="labels:inventory.coast"
                disabled={!operationIds.destination}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              fullWidth
              outline
              onClick={() => formRef.current?.submitForm()}
              disabled={
                type.value === INVENTORY_TYPES.entry
                  ? !operationIds.destination
                  : !operationIds.destination && !operationIds.origin
              }
            >
              {tPage('add')}
            </Button>
          </Grid>
        </Grid>
        <UnHiddenInput name="type" value={type.value} />
      </Unform>

      <Table
        tableVariant="secondary"
        columns={[
          {
            headerName: tPage('productsTable.addedProducts'),
            field: 'product',
          },
          {
            headerName: tPage('productsTable.amount'),
            field: 'amount',
          },
          {
            headerName: type.value === INVENTORY_TYPES.entry ? tPage('productsTable.coast') : '',
            field: 'unit_cost',
          },
          {
            headerName: '',
            field: 'remove',
          },
        ]}
        rows={products.map(product => ({
          ...product,
          unit_cost: product.unit_cost ? t('currency', { value: product.unit_cost }) : '',
          product: `${product.product.id} - ${product.product.name}`,
          remove: (
            <Flex marginVertical={0.5}>
              <Flex marginLeft="auto">
                <Icon
                  name="closeCircle"
                  onClick={() => {
                    setToggle('openRemoveProduct', true);
                    setSelectedProduct(product);
                  }}
                  clickable
                  size={1.1}
                  color="text500"
                />
              </Flex>
            </Flex>
          ),
        }))}
      />

      <Flex>
        <FormHelperText error>{error && t(`formErrors:${error}`)}</FormHelperText>
      </Flex>

      <ConfirmModal
        open={toggles.openRemoveProduct}
        onClose={() => setToggle('openRemoveProduct', false)}
        title={tPage('removeProductTitle')}
        bodyText={tPage('removeProductBody')}
        onConfirm={() => {
          handleRemoveProduct();
          setToggle('openRemoveProduct', false);
        }}
      />
    </Grid>
  );
};

export const AddProducts = memo(AddProductsComponent);

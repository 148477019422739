import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.background};

  legend {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
    font-size: ${({ theme }) => theme.fonts.sizes.sm};
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1.5rem;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.foreground};
  border-radius: ${({ theme }) => theme.borderRadius.md};

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const List = styled.ul``;

export const Title = styled.strong`
  size: 2rem;
  display: block;
  margin-bottom: 1.2rem;
  font-weight: bold;
`;

export const InputField = styled.div<{ size: number }>`
  min-width: ${({ size }) => size}rem;
`;

import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import {
  PaginatedTable,
  Button,
  Tag,
  Autocomplete,
  StatusFilter,
  SearchFilter,
  Column,
} from 'components';
import { Menu } from '../components/Menu';

import * as DefaultStyles from 'styles/Listing';
import * as Styles from './styles';
import { Flex } from 'styles/Flex';

import { resolvePath, routePaths } from 'constants/routes';

import { queryToObj, pushHistoryQuery } from 'utils/query';
import { debounce } from 'utils/debounce';

import { getPriceList as getPriceListApi } from 'services/priceList';

import { ApiQueriesProduct, Meta } from 'types/api';
import { GetPriceList, PriceListQueries } from 'types/priceList';

import useBooleanToggle from 'hooks/useBooleanToggle';

import { getOperationsList } from 'services/operation';
import { SimpleOperationList } from 'types/operation';

const baseMeta = {
  total: 0,
  last_page: 0,
};

export function ListPriceList({ history }: RouteComponentProps) {
  const [tPage] = useTranslation('pages/priceList');
  const { t } = useTranslation();

  const [meta, setMeta] = useState<Partial<Meta>>(baseMeta);
  const [priceList, setPriceList] = useState<GetPriceList[]>([]);
  const [operations, setOperations] = useState<SimpleOperationList[]>([]);

  const [isLoading] = useBooleanToggle(false);

  const historyQuery = queryToObj(history.location.search);

  const totalPages = meta?.last_page ? meta?.last_page : 0;

  const getPriceList = async (query?: PriceListQueries) => {
    const { data } = await getPriceListApi(query);

    setPriceList(data.data);
    setMeta(data.meta);
  };

  const getOperations = async (event: React.SyntheticEvent<Element, Event>, search: string) => {
    const { data } = await getOperationsList({ search, status: 1 });

    setOperations(data);
  };

  const handleChangeOperationFilter = (operation: { value: number | string }) => {
    if (!operation) {
      pushHistoryQuery({ operationId: '' }, history);
      return;
    }

    const query = { operationId: operation.value };
    pushHistoryQuery(query, history);
  };

  const columns: Column[] = [
    {
      field: 'name',
      headerName: tPage('listing.table.name'),
    },
    {
      field: 'linkedOperations',
      headerName: tPage('listing.table.linkedOperations'),
    },

    {
      field: 'updated_at',
      headerName: tPage('listing.table.updated_at'),
    },
    {
      field: 'status',
      headerName: '',
    },
    {
      field: 'menu',
      headerName: '',
    },
  ];

  const rows = useMemo(
    () =>
      priceList.map(price => ({
        id: price?.id,
        name: price.name,
        linkedOperations: price.linkedOperations,
        updated_at: t('date', { date: new Date(price.updated_at) }),
        menu: (
          <Menu
            id={price?.id}
            relateTitle={price.name}
            status={price?.status}
            onDelete={() => getPriceList(historyQuery)}
            onEdit={() => getPriceList(historyQuery)}
            onRelate={() => getPriceList(historyQuery)}
          />
        ),
        status: (
          <Tag
            label={price?.status === 0 ? t('labels:tag.inactive') : t('labels:tag.active')}
            variante={price?.status === 0 ? 'inactive' : 'success'}
          />
        ),
      })),
    [priceList],
  );

  useEffect(() => {
    const unListen = history.listen(location => {
      if (location.pathname.includes(routePaths.login)) return;

      const query = queryToObj(location.search) as ApiQueriesProduct;

      getPriceList(query);
    });

    return () => unListen();
  }, []);

  useEffect(() => {
    getPriceList(historyQuery);
  }, []);

  return (
    <DefaultStyles.Container>
      <Flex column gap={1.5}>
        <h1>{tPage('listing.title')}</h1>
        <DefaultStyles.Wrapper>
          <Styles.FilterContainer>
            <Styles.InputFields>
              <SearchFilter
                defaultValue={(queryToObj(history.location.search)?.search ?? '') as any}
              />
              <DefaultStyles.SelectContainer>
                <Autocomplete
                  contained
                  onInputChange={debounce(getOperations)}
                  defaultValue={
                    historyQuery.type && {
                      label: historyQuery.type,
                    }
                  }
                  placeholder="pages/priceList:listing.filters.operation"
                  options={operations.map(operation => ({
                    label: operation.name,
                    value: operation.id,
                  }))}
                  onChange={(event, value) => handleChangeOperationFilter(value)}
                />
              </DefaultStyles.SelectContainer>
              <DefaultStyles.SelectContainer>
                <StatusFilter defaultValue={historyQuery?.status} />
              </DefaultStyles.SelectContainer>
            </Styles.InputFields>
            <Button
              icon={{
                name: 'add',
              }}
              onClick={() => history.push(routePaths.priceList.create)}
            >
              {tPage('listing.actionButton')}
            </Button>
          </Styles.FilterContainer>
          <PaginatedTable
            count={totalPages}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            onPageChange={(event, page) => pushHistoryQuery({ page }, history)}
            onClickRow={(row: typeof rows[0]) =>
              history.push(resolvePath(routePaths.priceList.edit, { id: row.id }))
            }
            defaultOrder={String(historyQuery?.sort) || ''}
          />
        </DefaultStyles.Wrapper>
      </Flex>
    </DefaultStyles.Container>
  );
}

import { FormHandles, Scope } from '@unform/core';
import { Table, UnDateTimePicker, UnHiddenInput, UnMaskInput } from 'components';
import { usePax } from 'hooks/store';
import { memo, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  formRef: RefObject<FormHandles>;
}

const ThrowTableComponent = ({ formRef }: IProps): JSX.Element => {
  const [tPage] = useTranslation('pages/pax');
  const { t } = useTranslation();

  const {
    throwPage: { headers, paxList },
    loadings,
    setDisableFilter,
  } = usePax();

  const checkDisableFilter = useCallback(() => {
    const formData = formRef.current?.getData();

    if (formData) {
      formData.paxList.every((pax: { visitors: number | string }) => {
        setDisableFilter(!!pax.visitors);

        if (pax.visitors) return false;

        return true;
      });
    }
  }, []);

  const handleBlurField = useCallback((index: number) => {
    checkDisableFilter();

    const fieldValue = formRef.current?.getFieldValue(`paxList[${index}].visitors`);
    const lastValue = formRef.current?.getFieldValue(`paxList[${index}].lastValue`);
  }, []);

  return (
    <Table
      tableVariant="secondary"
      isLoading={loadings.getPaxList}
      columns={headers.map(header => ({
        ...header,
        headerName: header.headerName && tPage(header.headerName),
      }))}
      rows={paxList.map((pax, index) => ({
        ...pax,
        district: `${pax.addressOperation.city} / ${pax.addressOperation.uf}`,
        date: (
          <Scope path={`paxList[${index}]`}>
            <UnDateTimePicker name="created_at" formRef={formRef}></UnDateTimePicker>
          </Scope>
        ),
        input: (
          <Scope path={`paxList[${index}]`}>
            <UnMaskInput
              name="visitors"
              clearHelperTextSpacing
              contained
              startIcon={{ name: 'people', color: 'text', size: 1.2 }}
              onBlur={() => handleBlurField(index)}
              placeholder="pages/pax:visitorsPlaceholder"
              parseToFloat
              notDisplayError
              style={{ width: '100px' }}
            />
            <UnHiddenInput name="operation_id" value={pax.addressOperation.operation_id} />
            {/* TODO: remove */}
            <UnHiddenInput name="user_id" value={1} />
            <UnHiddenInput name="lastValue" value={pax.paxOperation?.visitors || 0} />
          </Scope>
        ),
      }))}
    />
  );
};

export const ThrowTable = memo(ThrowTableComponent);

import { memo, forwardRef } from 'react';

import { SwitchProps as MuiSwitchProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as Styles from './styles';

interface SwitchProps extends MuiSwitchProps {
  label?: string;
  leftLabel?: boolean;
}

const BaseSwitch = forwardRef<any, SwitchProps>(({ label, leftLabel, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <Styles.Container>
      {leftLabel && label && <label>{t(label)}</label>}
      <Styles.Switch {...props} inputRef={ref} />
      {!leftLabel && label && <label>{t(label)}</label>}
    </Styles.Container>
  );
});

export const Switch = memo(BaseSwitch);
